export const FIELD_NAMES = {
	PHOTO: "photoUrl",
	RSS_FEED_URL: "url",
	OLD_HOST: "oldHost",
	MEDIA_FORMAT: "mediaFormat",
	TERMS_OF_USE: "termsOfUse"
}

export const HOSTING_PROVIDERS = [
	"Acast",
	"Anchor",
	"Blubrry",
	"Buzzsprout",
	"Captivate",
	"Ivoox",
	"Libsyn",
	"Megaphone",
	"Omny Studio",
	"Podbean",
	"Podomatic",
	"RedCircle",
	"Simplecast",
	"Soundcloud",
	"Spreaker",
	"Other"
]
