import {
	TOGGLE_NEW_EMAIL_MODAL_VISIBLE,
	TOGGLE_NAVIGATION_ALLOWED_FLAG,
	CHANGE_MY_ACCOUNT_SETTINGS_PAGE_NEXT_LOCATION,
	TOGGLE_DELETE_ACCOUNT_MODAL_VISIBLE
} from "constants/actionTypes"

const initialState = {
	newEmailModalVisible: false,
	navigationAllowed: false,
	nextLocation: null,
	deleteAccountModalVisible: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_NEW_EMAIL_MODAL_VISIBLE:
			return {
				...state,
				newEmailModalVisible: !state.newEmailModalVisible
			}
		case TOGGLE_NAVIGATION_ALLOWED_FLAG:
			return {
				...state,
				navigationAllowed: !state.navigationAllowed
			}

		case CHANGE_MY_ACCOUNT_SETTINGS_PAGE_NEXT_LOCATION:
			return {
				...state,
				nextLocation: action.payload.location
			}
		case TOGGLE_DELETE_ACCOUNT_MODAL_VISIBLE:
			return {
				...state,
				deleteAccountModalVisible: !state.deleteAccountModalVisible
			}
		default:
			return state
	}
}
