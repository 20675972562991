import { useQuery } from "react-query"
import { keysToCamel } from "utils/camelCaseUtils"
import { findPodcastInfoByPodcastID } from "../podcastInfo.service"
import { FindPodcastInfoByPodcastIDResponse } from "../types/podcastInfo"

export const usePodcastInfo = (podcastID: string) => {
	return useQuery<FindPodcastInfoByPodcastIDResponse>(
		["podcastInfo", podcastID],
		async () => {
			const { data } = await findPodcastInfoByPodcastID(podcastID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!podcastID, retry: 3 }
	)
}
