import React, { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import useWindowSize from "hooks/useWindowSize"
import { Menu, Tooltip } from "antd"
import {
	PodcastsIcon,
	PodcastsFilledIcon,
	DashboardIcon,
	DashboardFilledIcon,
	RecordingsIcon,
	RecordingsFilledIcon,
	ContactsIcon,
	ContactsFilledIcon,
	CalendarIcon,
	CalendarFilledIcon,
	AnalyticsIcon,
	AnalyticsFilledIcon,
	CollapsedNewEpisodeIcon
} from "assets/icons/Sidebar"
import { toggleAddNewEpisodeDrawer } from "redux/actions/Episodes/AddNewEpisode"
import * as ROUTES from "constants/routes"
import * as STRINGS from "constants/strings/Sidebar"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodcastDropdown from "./PodcastDropdown"

import "./Sidebar.scss"
import { useState } from "react"
import { setSelectedPodcast } from "redux/actions/App"
import { usePodcasts } from "services/queries/Podcasts"
import { RootState } from "index"

export function Sidebar() {
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const sidebarCollapsed = useSelector((state: RootState) => state.sidebar.sidebarCollapsed)
	const dispatch = useDispatch()
	const location = useLocation()

	const [selectedMenuItem, setSelectedMenuItem] = useState<string>("")

	useEffect(() => {
		let currentRoute
		if (location.pathname.includes(ROUTES.MY_PODCASTS)) {
			currentRoute = location.pathname.split("/")[1]
		} else {
			currentRoute = location.pathname.split("/")[2]
		}
		setSelectedMenuItem(`/${currentRoute}`)
	}, [location])

	const size = useWindowSize()

	const [leftSidebarMenuClass, setLeftSidebarMenuClass] = useState<string | undefined>(undefined)
	const [isShowHideMode, setIsShowHideMode] = useState(false)
	const collapsedStatus = isShowHideMode ? undefined : sidebarCollapsed

	useEffect(() => {
		setIsShowHideMode(size.width < 576)
	}, [size])

	useEffect(() => {
		if (!isShowHideMode) {
			setLeftSidebarMenuClass(sidebarCollapsed ? "left-sidebar-collapsed" : "left-sidebar")
		} else {
			setLeftSidebarMenuClass(sidebarCollapsed ? "left-sidebar-hidden left-sidebar" : "left-sidebar")
		}
	}, [sidebarCollapsed, isShowHideMode])

	const { status, data: podcastData } = usePodcasts(authUser.uid)

	if (status === "error") {
		return <Menu className={leftSidebarMenuClass}></Menu>
	}

	if (status === "loading") {
		return <Menu className={leftSidebarMenuClass}></Menu>
	}

	if (!podcastData) {
		return <Menu className={leftSidebarMenuClass}></Menu>
	}

	if (!selectedPodcast && podcastData.podcasts) {
		dispatch(setSelectedPodcast(podcastData.podcasts[0]))
	}

	const returnAppropriateIcon = (path: string, filledIcon: React.FC, normalIcon: React.FC<any>) => {
		if (location.pathname.includes(path)) {
			return React.createElement(filledIcon)
		} else {
			return React.createElement(normalIcon, { className: "left-sidebar-menu-icon" })
		}
	}

	const profileUrlPath = selectedPodcast?.profileUrl ?? "default"

	return (
		<Menu
			className={leftSidebarMenuClass}
			defaultSelectedKeys={[ROUTES.MY_PODCASTS]}
			selectedKeys={[selectedMenuItem]}
			mode="inline"
			inlineCollapsed={collapsedStatus}
		>
			<PodcastDropdown sidebarCollapsed={collapsedStatus} />
			<Menu.Item
				className="left-sidebar-expanded"
				key={ROUTES.MY_PODCASTS}
				icon={returnAppropriateIcon(ROUTES.MY_PODCASTS, PodcastsFilledIcon, PodcastsIcon)}
			>
				<Link to={ROUTES.MY_PODCASTS}>{STRINGS.MY_PODCASTS}</Link>
			</Menu.Item>
			<Menu.Item
				className="left-sidebar-expanded"
				key={ROUTES.EPISODES}
				icon={returnAppropriateIcon(ROUTES.EPISODES, DashboardFilledIcon, DashboardIcon)}
			>
				<Link to={`/${profileUrlPath}${ROUTES.EPISODES}`}>{STRINGS.EPISODES}</Link>
			</Menu.Item>
			<Menu.Item
				className="left-sidebar-expanded"
				key={ROUTES.RECORDINGS}
				icon={returnAppropriateIcon(ROUTES.RECORDINGS, RecordingsFilledIcon, RecordingsIcon)}
			>
				<Link to={`/${profileUrlPath}${ROUTES.RECORDINGS}`}>{STRINGS.RECORDINGS}</Link>
			</Menu.Item>
			<Menu.Item
				className="left-sidebar-expanded"
				key={ROUTES.GUEST_PORTAL}
				icon={returnAppropriateIcon(ROUTES.GUEST_PORTAL, ContactsFilledIcon, ContactsIcon)}
			>
				<Link to={`/${profileUrlPath}${ROUTES.GUEST_PORTAL}`}>{STRINGS.GUEST_PORTAL}</Link>
			</Menu.Item>
			<Menu.Item
				className="left-sidebar-expanded"
				key={ROUTES.CALENDAR}
				icon={returnAppropriateIcon(ROUTES.CALENDAR, CalendarFilledIcon, CalendarIcon)}
			>
				<Link to={`/${profileUrlPath}${ROUTES.CALENDAR}`}>{STRINGS.CALENDAR}</Link>
			</Menu.Item>
			<Menu.Item
				className="left-sidebar-expanded"
				key={ROUTES.ANALYTICS}
				icon={returnAppropriateIcon(ROUTES.ANALYTICS, AnalyticsFilledIcon, AnalyticsIcon)}
			>
				<Link to={`/${profileUrlPath}${ROUTES.ANALYTICS}`}>{STRINGS.ANALYTICS}</Link>
			</Menu.Item>
			{collapsedStatus ? (
				<div className="new-episode-collapsed-button-container">
					<PodpalButton
						shape="circle"
						type="primary"
						icon={<CollapsedNewEpisodeIcon />}
						onClick={() => dispatch(toggleAddNewEpisodeDrawer())}
						disabled={podcastData.podcasts ? false : true}
					/>
				</div>
			) : (
				<div className="new-episode-button-container">
					{selectedPodcast?.isComplete ? (
						<Tooltip
							placement="right"
							title="The update status of this podcast has been set to “Complete.” To add more episodes, please change the status of this show to “Active.”"
						>
							<PodpalButton
								type="primary"
								size="large"
								shape="round"
								onClick={() => dispatch(toggleAddNewEpisodeDrawer())}
								disabled
							>
								Add Episode
							</PodpalButton>
						</Tooltip>
					) : (
						<PodpalButton
							type="primary"
							size="large"
							shape="round"
							onClick={() => dispatch(toggleAddNewEpisodeDrawer())}
							disabled={podcastData.podcasts ? false : true}
						>
							Add Episode
						</PodpalButton>
					)}
				</div>
			)}
		</Menu>
	)
}
