import React, { useState } from "react"
import { useQueryClient } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Alert, Row, Col, Checkbox } from "antd"
import { InfoCircleFilled } from "@ant-design/icons"
import { EPISODES } from "constants/routes"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalModal from "../../PodpalModal"
import { toggleDeleteEpisodeModalVisible } from "redux/actions/Episodes"
import { deleteEpisode } from "services/episode.service"
import { deleteRecording, updateRecording } from "services/recording.service"
import { useEpisodeRecording } from "services/queries/Recordings"
import useToggle from "hooks/useToggle"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { useHistory } from "react-router"

const StyledText = styled.p`
	color: ${props => props.theme.gray7};
	font: ${props => props.theme.fontSm};
`

// One style on the modal didn't necessitate an entire css file.
const StyledPodpalModal = styled(PodpalModal).attrs(props => ({
	isPublishedEpisode: props.isPublishedEpisode || ""
}))`
	min-width: 324px !important;
	${props =>
		props.isPublishedEpisode &&
		`
		.ant-modal-body {
			padding-top: 0px;
		}
	`}
`

const DeleteEpisodeModal = () => {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()
	const history = useHistory()

	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const visible = useSelector(state => state.episode.deleteEpisodeModalVisible)
	const episode = useSelector(state => state.episode.episodeForDeleteEpisodeModal)

	const [preserveRecording, setPreserveRecording] = useState(false)
	const [deleteLoading, toggleDeleteLoading] = useToggle(false)
	const { data: recordingData } = useEpisodeRecording(episode?.id)

	const handleClose = () => {
		dispatch(toggleDeleteEpisodeModalVisible())
		setPreserveRecording(false)
	}

	const handleDelete = async () => {
		toggleDeleteLoading()
		try {
			await deleteEpisode(episode.id)
			if (recordingData?.recording) {
				if (!preserveRecording) {
					await deleteRecording(recordingData.recording)
				} else {
					await updateRecording({
						...recordingData.recording,
						episodeId: ""
					})
				}
			}
			queryClient.invalidateQueries(["episodes", selectedPodcast.id])
			successAlert(
				preserveRecording || !recordingData?.recording ? "Episode deleted!" : "Episode and attached recording deleted!"
			)
			toggleDeleteLoading()
			handleClose()
			history.push(`/${selectedPodcast?.profileUrl}${EPISODES}`)
		} catch (e) {
			toggleDeleteLoading()
			errorAlert("Sorry, we were unable to delete your episode.")
		}
	}

	return (
		<StyledPodpalModal
			centered
			maskClosable={false}
			visible={visible}
			title="Delete Episode"
			onCancel={handleClose}
			footer={[
				<PodpalButton key="cancel" variant="secondary" onClick={handleClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton key="delete" loading={deleteLoading} danger onClick={handleDelete}>
					Delete
				</PodpalButton>
			]}
			width={324}
			isPublishedEpisode={episode?.isPublished}
		>
			<Row justify="center" align="middle" gutter={[0, 16]}>
				{episode?.isPublished && (
					<Alert
						banner
						type="error"
						message="This is a previously published episode! Deleting this episode will remove it from your RSS Feed and may impact data & analytics related to this episode."
						style={{ minWidth: "324px" }}
						icon={<InfoCircleFilled />}
					/>
				)}
				<Col>
					<StyledText>
						This episode and recording file (if attached) will be <strong>permanently deleted</strong> from Podpal.
					</StyledText>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Checkbox
						checked={preserveRecording}
						disabled={!recordingData?.recording}
						onChange={() => setPreserveRecording(prev => !prev)}
					>
						Keep file in My Recordings
					</Checkbox>
				</Col>
			</Row>
		</StyledPodpalModal>
	)
}

export default DeleteEpisodeModal
