import { useState, useCallback } from "react"

/**
 * Utility hook for quick initialization of a toggle.
 * Source: https://www.joshwcomeau.com/snippets/react-hooks/use-toggle/
 */
export default function useToggle(initialValue: boolean = false) {
	const [value, setValue] = useState<boolean>(initialValue)
	const toggle = useCallback(() => {
		setValue((v: boolean) => !v)
	}, [])

	return [value, toggle]
}
