import * as React from "react"
import { AxiosResponse } from "axios"
import { useDispatch } from "react-redux"
import {
	CreateNotificationRequest,
	CreateNotificationResponse,
	DeleteNotificationRequest,
	DeleteNotificationResponse,
	MarkNotificationReadRequest,
	MarkNotificationReadResponse
} from "services/types/notifications"
import { createNotification, deleteNotification, markNotificationRead } from "services/notifications.service"
import {
	deleteNotificationAction,
	markNotificationReadAction,
	prependNewNotificationsAction
} from "redux/actions/Navbar/NotificationsDropdown"

export const useNotificationsHook = () => {
	const dispatch = useDispatch()

	const createNewNotification = React.useCallback((createNotificationRequest: CreateNotificationRequest) => {
		createNotification(createNotificationRequest)
			.then((createNotificationResponse: AxiosResponse<CreateNotificationResponse>) => {
				const newNotification = createNotificationResponse.data.notification
				dispatch(prependNewNotificationsAction([newNotification]))
			})
			.catch(() => {
				// TODO: Do something...
			})
	}, [])

	const markExistingNotificationRead = React.useCallback((markNotificationReadRequest: MarkNotificationReadRequest) => {
		markNotificationRead(markNotificationReadRequest)
			.then((markNotificationReadResponse: AxiosResponse<MarkNotificationReadResponse>) => {
				const markedNotification = markNotificationReadResponse.data.marked_notification
				dispatch(markNotificationReadAction(markedNotification))
			})
			.catch(() => {
				// TODO: Do something...
			})
	}, [])

	const deleteExistingNotification = React.useCallback((deleteNotificationRequest: DeleteNotificationRequest) => {
		deleteNotification(deleteNotificationRequest).then(
			(deleteNotificationResponse: AxiosResponse<DeleteNotificationResponse>) => {
				const deletedNotificationId = deleteNotificationResponse.data.notification_id
				dispatch(deleteNotificationAction(deletedNotificationId))
			}
		)
	}, [])

	return { createNewNotification, markExistingNotificationRead, deleteExistingNotification }
}
