import React from "react"
import Icon from "@ant-design/icons"

const CHECK_CIRCLE_FILLED = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 24 24" width="12">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
	</svg>
)

export const CheckCircleFilled = props => <Icon component={CHECK_CIRCLE_FILLED} {...props} />

const TRASH_FILLED = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
	</svg>
)

export const TrashFilled = props => <Icon component={TRASH_FILLED} {...props} />

const CHECK_CIRCLE_OUTLINED = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.3827 8.27344H15.2834C15.0444 8.27344 14.817 8.38828 14.6764 8.58516L10.992 13.6945L9.32325 11.3789C9.18263 11.1844 8.95763 11.0672 8.71622 11.0672H7.617C7.46465 11.0672 7.37559 11.2406 7.46465 11.3648L10.385 15.4148C10.454 15.5111 10.5449 15.5896 10.6503 15.6437C10.7556 15.6978 10.8724 15.7261 10.9908 15.7261C11.1093 15.7261 11.226 15.6978 11.3314 15.6437C11.4368 15.5896 11.5277 15.5111 11.5967 15.4148L16.5327 8.57109C16.6241 8.44688 16.535 8.27344 16.3827 8.27344Z"
			fill="#4D4D4D"
		/>
		<path
			d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
			fill="#4D4D4D"
		/>
	</svg>
)

export const CheckCircleOutlined = className => <Icon className={className} component={CHECK_CIRCLE_OUTLINED} />

const CHECK_CIRCLE_COMPLETE = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="10.0002" cy="10.0002" r="6.66667" fill="white" />
		<path
			d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM14.3192 6.73438L9.6183 13.2522C9.5526 13.3439 9.46599 13.4187 9.36564 13.4702C9.26529 13.5218 9.15411 13.5486 9.0413 13.5486C8.92848 13.5486 8.8173 13.5218 8.71695 13.4702C8.6166 13.4187 8.52999 13.3439 8.46429 13.2522L5.6808 9.39509C5.59598 9.27679 5.6808 9.11161 5.82589 9.11161H6.87277C7.10045 9.11161 7.31696 9.22098 7.45089 9.40848L9.04018 11.6138L12.5491 6.74777C12.683 6.5625 12.8973 6.45089 13.1272 6.45089H14.1741C14.3192 6.45089 14.404 6.61607 14.3192 6.73438Z"
			fill="#34CC8D"
		/>
	</svg>
)

export const CheckCircleComplete = className => <Icon className={className} component={CHECK_CIRCLE_COMPLETE} />

const X_ICON = () => (
	<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.57152 5.09375L10.6757 0.204688C10.7445 0.123438 10.6867 0 10.5804 0H9.33268C9.2592 0 9.18884 0.0328124 9.14037 0.0890624L5.75537 4.12187L2.37037 0.0890624C2.32347 0.0328124 2.25311 0 2.17806 0H0.930382C0.824063 0 0.766213 0.123438 0.835008 0.204688L4.93922 5.09375L0.835008 9.98281C0.819597 10.0009 0.809711 10.0231 0.806522 10.0466C0.803333 10.0702 0.806975 10.0942 0.817017 10.1157C0.827059 10.1373 0.843078 10.1555 0.863173 10.1682C0.883268 10.1809 0.906594 10.1876 0.930382 10.1875H2.17806C2.25155 10.1875 2.32191 10.1547 2.37037 10.0984L5.75537 6.06562L9.14037 10.0984C9.18727 10.1547 9.25763 10.1875 9.33268 10.1875H10.5804C10.6867 10.1875 10.7445 10.0641 10.6757 9.98281L6.57152 5.09375Z"
			fill="#4D4D4D"
		/>
	</svg>
)

export const XIcon = className => <Icon className={className} component={X_ICON} />

const MORE_ICON = () => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="#4d4d4d" height="24" viewBox="0 0 24 24" width="24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
	</svg>
)

export const MoreIcon = props => <Icon {...props} component={MORE_ICON} />

const WARNING_EXCLAMATION_ICON = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FCBA14">
		<path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
	</svg>
)

export const WarningExclamationIcon = () => <Icon component={WARNING_EXCLAMATION_ICON} />

const PLAY_ICON = () => (
	<svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.53 0.95625C0.863951 0.53997 0 1.01881 0 1.80425V13.1958C0 13.9812 0.863951 14.46 1.53 14.0438L10.6432 8.348C11.2699 7.95633 11.2699 7.04367 10.6432 6.652L1.53 0.95625Z"
			fill="white"
		/>
	</svg>
)

export const PlayIcon = props => <Icon {...props} component={PLAY_ICON} />

const PAUSE_ICON = () => (
	<svg width="12" height="15" viewBox="0 0 12 15" fill="none">
		<path
			d="M8 13.8138C8 14.3661 8.44771 14.8138 9 14.8138H11C11.5523 14.8138 12 14.3661 12 13.8138V1C12 0.447715 11.5523 0 11 0H9C8.44771 0 8 0.447715 8 1V13.8138ZM0 13.8138C0 14.3661 0.447715 14.8138 1 14.8138H3C3.55228 14.8138 4 14.3661 4 13.8138V1C4 0.447715 3.55228 0 3 0H1C0.447715 0 0 0.447715 0 1V13.8138Z"
			fill="#F5F5F5"
		/>
	</svg>
)

export const PauseIcon = props => <Icon {...props} component={PAUSE_ICON} />
