import { Alert, Col, Row } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "index"
import { updateSelectedPodcastFromPodcastId } from "redux/actions/App"

const Banner301RedirectRequested = () => {
	const podcastData = useSelector((state: RootState) => state.app.selectedPodcast)
	console.log({ podcastData })
	const redirectedAt = podcastData?.redirectedAt
	const isRedirected = podcastData?.isRedirected
	console.log({ isRedirected })
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(updateSelectedPodcastFromPodcastId(podcastData?.id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [podcastData?.id])

	// only show for redirected podcasts
	if (!isRedirected) {
		return null
	}

	return (
		<Alert
			banner
			type="warning"
			message={
				<Row gutter={6} className="alert-row">
					<Col className="alert-text">
						A 301 Redirect request for this RSS Feed was processed on {moment(redirectedAt).format("MM/DD/YY [at] hh:mm A")}.
						Please note that any further updates in Podpal won't affect your RSS feed or update your podcast content on
						registered platforms; use your new host for changes. The podcast will count towards your plan limits until deleted
						from Podpal. You can safely remove it after {moment(redirectedAt).add(7, "days").format("MM/DD/YY")}.
					</Col>
				</Row>
			}
			closable={false}
			icon={<ExclamationCircleFilled />}
		></Alert>
	)
}

export default Banner301RedirectRequested
