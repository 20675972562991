import { Col, Progress, Row } from "antd"
import PodpalCard from "components/common/Cards/PodpalCard"
import { POD } from "constants/routes"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import { toggleChangePlannedReleaseDateModalVisible } from "redux/actions/Episodes"
import { findPodcast } from "services/podcast.service"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { useEpisode } from "services/queries/Episodes"
import styled, { css } from "styled-components"
import { keysToCamel } from "utils/camelCaseUtils"
import {
	setEpisodeIdForReleaseDateModal,
	setEpisodeIdForScheduledReleaseDateModal,
	toggleChangeScheduledReleaseDateModalVisible
} from "redux/actions/Episodes"
import StatusButton from "components/common/Buttons/StatusButton"

const StyledProgress = styled(Progress)`
	&&&.ant-progress-status-success {
		.ant-progress-circle-path {
			stroke: ${({ theme }) => theme.podpalGreenLight};
		}
		.ant-progress-text {
			color: ${({ theme }) => theme.textPrimary};
		}
	}

	.ant-progress-inner {
		height: 75px !important;
		width: 75px !important;
		font: ${({ theme }) => theme.fontSm} !important;

		.ant-progress-text {
			font-weight: 500;
		}

		.ant-progress-circle-trail,
		.ant-progress-circle-path {
			stroke-width: 16px;
		}
	}

	${({ percent }) =>
		percent < 26 &&
		css`
			&& .ant-progress-circle-path {
				stroke: ${({ theme }) => theme.podpalRedLight};
			}
		`}

	${({ percent }) =>
		percent > 25 &&
		percent < 76 &&
		css`
			&& .ant-progress-circle-path {
				stroke: ${({ theme }) => theme.podpalYellowLight};
			}
		`}

		${({ percent }) =>
		percent > 76 &&
		css`
			&& .ant-progress-circle-path {
				stroke: ${({ theme }) => theme.podpalBlue};
			}
		`}
`

const EpisodeStatus = ({ className }) => {
	const { episodeId } = useParams()
	const history = useHistory()
	const {
		isLoading: episodeProgressLoading,
		isError: episodeProgressError,
		data: episodeProgressData
	} = useEpisodeProgress(episodeId)
	const { isLoading: episodeLoading, isError: episodeError, data: episodeData } = useEpisode(episodeId)
	const dispatch = useDispatch()

	if (episodeProgressLoading || episodeLoading) {
		return (
			<PodpalCard>
				<div>Loading...</div>
			</PodpalCard>
		)
	}

	if (episodeProgressError || episodeError) {
		return (
			<PodpalCard>
				<div>Error occurred while retrieving episode status.</div>
			</PodpalCard>
		)
	}

	const handleStatusModalClick = async () => {
		if (episodeData.episode.scheduledReleaseDate) {
			dispatch(setEpisodeIdForScheduledReleaseDateModal(episodeData.episode.id))
			dispatch(toggleChangeScheduledReleaseDateModalVisible())
		} else if (episodeData.episode.isPublished) {
			try {
				const podcast = await findPodcast(episodeData.episode.podcastId)
				const camelPodcast = keysToCamel(podcast)
				const { profileUrl } = camelPodcast.data.podcast
				history.push(`${POD}/${profileUrl}/${episodeData.episode.id}`)
			} catch (e) {
				console.error(e)
			}
		} else {
			dispatch(setEpisodeIdForReleaseDateModal(episodeId))
			dispatch(toggleChangePlannedReleaseDateModalVisible())
		}
	}

	return (
		<PodpalCard className={className}>
			<Row justify="center" align="middle" gutter={[8, 8]}>
				<Col span={24} style={{ textAlign: "center" }}>
					<h2>Episode Status</h2>
				</Col>
				<Col>
					<StyledProgress
						type="circle"
						strokeLinecap="square"
						percent={episodeProgressData.episodeProgress.percentCompleted}
						format={percent => `${percent}%`}
					/>
				</Col>
				<Col span={24} style={{ textAlign: "center" }}>
					<StatusButton
						onClick={handleStatusModalClick}
						plannedReleaseDate={episodeData.episode.plannedReleaseDate}
						scheduledReleaseDate={episodeData.episode.scheduledReleaseDate}
						publishedDate={episodeData.episode.lastPublishedDate}
					/>
				</Col>
			</Row>
		</PodpalCard>
	)
}

export default EpisodeStatus
