import React from "react"
import { Row, Col } from "antd"
import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
import calendarZeroStateImage from "assets/zerostates/Calendar.svg"

export function CalendarPage() {
	return (
		<div className="home-page-container">
			<Row justify="center" align="middle" style={{ height: "100%" }}>
				<Col>
					<ZeroStateCard
						title="Sync Your Calendar"
						description="Sync your personal calendar so you don’t overbook! You can coordinate your episode and interview schedule with calendar events."
						image={calendarZeroStateImage}
						comingSoon
					/>
				</Col>
			</Row>
		</div>
	)
}
