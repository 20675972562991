import { ADD_CHANGE_TO_LOGIN_FIELD, TOGGLE_FORGOT_PASSWORD_MODAL } from "constants/actionTypes"

export const addChangeToLoginField = (fieldName, fieldValue) => ({
	type: ADD_CHANGE_TO_LOGIN_FIELD,
	payload: {
		fieldName: fieldName,
		fieldValue: fieldValue
	}
})

export const toggleForgotPasswordModal = () => ({
	type: TOGGLE_FORGOT_PASSWORD_MODAL
})
