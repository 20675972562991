import React from "react"
import { NewPodcastFormDrawer } from "components/common/Drawers/NewPodcastFormDrawer"
import NewEpisodeFormDrawer from "components/common/Drawers/NewEpisodeFormDrawer"
import ImportPodcastDrawer from "components/common/Drawers/ImportPodcastDrawer"

export function DrawerContainer() {
	return (
		<>
			<NewPodcastFormDrawer />
			<NewEpisodeFormDrawer />
			<ImportPodcastDrawer />
		</>
	)
}
