import React, { useState } from "react"
import styled from "styled-components"
import { Input, Form, Row, Col, Select, Space, Checkbox, Tooltip, Radio } from "antd"
import { MEDIA_FORMAT_OPTIONS } from "constants/strings/UserSettings/MyPodcasts/Form"
import { TERMS_AND_CONDITIONS_REQUIRED } from "constants/strings/Auth"
import { WarningExclamationIcon } from "assets/icons/Common/SimpleFeedback"
import { CheckCircleOutlined } from "@ant-design/icons"
import PodpalModal from "components/common/Modals/PodpalModal"
import { useDispatch, useSelector } from "react-redux"
import {
	setConfigForImportPodcastDrawer,
	toggleAddNewPodcastDrawer,
	toggleImportPodcastModalVisible
} from "redux/actions/Podcasts/AddNewPodcast"
import importPodcast from "assets/podcasts/importpodcast.svg"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalRadioButtonGroup from "components/common/Buttons/PodpalRadioGroup"
import useToggle from "hooks/useToggle"
import { parseRSS } from "services/podcast.service"
import { MigrationVerificationStatus } from "services/types/migrationVerification"
import { useMigrationVerification } from "services/queries/MigrationVerification"
import { createMigrationVerification } from "services/migrationVerification.service"
import { useQueryClient } from "react-query"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { ownerEmailNotFoundModal } from "components/common/Modals/Podcasts/PodcastModals"
import colors from "styles/scss/color.scss"
import { useUserInfo } from "services/queries/UserInfo"
import { SubscriptionType } from "services/types/userInfo"
import { FIELD_NAMES, HOSTING_PROVIDERS } from "constants/strings/Podcasts"

const { Option } = Select

const StyledHeading = styled.p`
	font: ${props => props.theme.fontLg};
	color: ${props => props.theme.textPrimary};
`
const StyledText = styled.p`
	color: ${props => props.theme.textSecondary};
	font: ${props => props.theme.fontSm};
`
const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 0;
	}

	label {
		display: flex;
		align-items: center;
		font: ${props => props.theme.fontSm} !important;
		font-weight: 500 !important;
		line-height: 12px !important;
		color: ${props => props.theme.textPrimary};
		margin-bottom: ${props => props.theme.spaceXs};
	}
`

const ImportPodcastModal = () => {
	const authUser = useSelector(state => state.app.authUser)
	const { data: userInfoData } = useUserInfo(authUser.uid)
	const isOnFreeOrStarterPlan =
		!userInfoData?.userInfo.subscriptionType ||
		userInfoData?.userInfo.subscriptionType === SubscriptionType.free ||
		userInfoData?.userInfo.subscriptionType === SubscriptionType.starter

	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const [verificationForm] = Form.useForm()
	const queryClient = useQueryClient()

	const visible = useSelector(state => state.addNewPodcast.importPodcastModalVisible)

	const MODAL_SEQUENECES = {
		SELECT_FLOW: "SELECT_FLOW",
		IMPORT_EXISTING_SHOW: "IMPORT_EXISTING_SHOW",
		VERIFY_RSS_FEED_OWNERSHIP: "VERIFY_RSS_FEED_OWNERSHIP",
		OWNERSHIP_VERIFIED: "OWNERSHIP_VERIFIED",
		REQUEST_301_REDIRECT: "REQUEST_301_REDIRECT"
	}
	const [termsOfUseChecked, toggleTermsOfUseChecked] = useToggle(false)
	const [currentSequence, setCurrentSequence] = useState(MODAL_SEQUENECES.SELECT_FLOW)

	const [podcastName, setPodcastName] = useState(null)
	const [ownerEmail, setOwnerEmail] = useState(null)
	const [migrationVerificationID, setMigrationVerificationID] = useState(null)

	const { data: migrationVerificationData } = useMigrationVerification(migrationVerificationID)

	const handleClose = () => {
		form.resetFields()
		verificationForm.resetFields()
		if (termsOfUseChecked) {
			toggleTermsOfUseChecked()
		}
		dispatch(toggleImportPodcastModalVisible())
		if (currentSequence === MODAL_SEQUENECES.OWNERSHIP_VERIFIED) {
			// we're on the last step, so don't reset the sequence. Without this check, the initial modal flashes up
			return
		}
		setCurrentSequence(MODAL_SEQUENECES.SELECT_FLOW)
	}

	const handleOk = async () => {
		const fields = [FIELD_NAMES.RSS_FEED_URL, FIELD_NAMES.TERMS_OF_USE]
		await form.validateFields(fields)
		queryClient.removeQueries(["parseRSS"])
		dispatch(
			setConfigForImportPodcastDrawer({
				[FIELD_NAMES.RSS_FEED_URL]: form.getFieldValue(FIELD_NAMES.RSS_FEED_URL),
				[FIELD_NAMES.MEDIA_FORMAT]: form.getFieldValue(FIELD_NAMES.MEDIA_FORMAT),
				[FIELD_NAMES.OLD_HOST]: form.getFieldValue(FIELD_NAMES.OLD_HOST)
			})
		)
		handleClose()
	}

	const handleAddNewPodcast = () => {
		dispatch(toggleImportPodcastModalVisible())
		dispatch(toggleAddNewPodcastDrawer())
	}

	const handleImportExistingPodcast = () => {
		setCurrentSequence(MODAL_SEQUENECES.IMPORT_EXISTING_SHOW)
	}

	const handleBackToSelectFlow = () => {
		setCurrentSequence(MODAL_SEQUENECES.SELECT_FLOW)
	}

	const selectFlowBody = (
		<>
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<StyledHeading>Let's add your show!</StyledHeading>
				</Col>
			</Row>
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<img src={importPodcast} alt="add-podcast" />
				</Col>
			</Row>
			<Row justify="center">
				<Col offset={1} span={12}>
					<StyledText>Start from scratch or import your existing RSS feed to get started.</StyledText>
				</Col>
			</Row>
		</>
	)

	const HOSTING_PROVIDER_OPTIONS = HOSTING_PROVIDERS.map(provider => (
		<Option key={provider} value={provider}>
			{provider}
		</Option>
	))

	const importExistingShowBody = (
		<>
			<Row gutter={[0, 16]}>
				<Col>
					<StyledText>
						We’re excited that you’ve decided to migrate your podcast to Podpal! This process is super easy!
					</StyledText>
				</Col>
			</Row>
			<Row gutter={[0, 12]}>
				<Col>
					<StyledText></StyledText>
				</Col>
			</Row>
			<StyledForm
				form={form}
				initialValues={{ mediaFormat: MEDIA_FORMAT_OPTIONS.AUDIO }}
				layout="vertical"
				onFinish={handleOk}
			>
				<Row gutter={[0, 12]}>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.RSS_FEED_URL}
							label="Current RSS Feed URL"
							tooltip={{
								placement: "right",
								title:
									"Please enter your current RSS Feed URL below and we will import your cover art, show description, basic channel settings, and episode data (titles, show notes, and media files) into your new setup on Podpal."
							}}
							validateFirst
							validateTrigger="onBlur"
							rules={[
								{
									required: true,
									message: "Your feed is required."
								},
								{
									type: "url",
									message: "Your feed is invalid."
								},
								{
									validator: async (rule, value) => {
										try {
											const { data } = await parseRSS(value)

											setPodcastName(data?.podcast?.title ?? null)
											setOwnerEmail(data?.podcast?.owner_email ?? null)
											return Promise.resolve()
										} catch (e) {
											return Promise.reject("Your feed is invalid.")
										}
									}
								}
							]}
						>
							<Input name={FIELD_NAMES.RSS_FEED_URL} placeholder="Paste your current (old) RSS Feed URL here." size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 12]}>
					<Col span={24}>
						<Form.Item name={FIELD_NAMES.OLD_HOST} label="Old Host Name">
							<Select name={FIELD_NAMES.OLD_HOST} placeholder="Where are you migrating from?" size="large">
								{HOSTING_PROVIDER_OPTIONS}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col>
						<Form.Item
							name={FIELD_NAMES.MEDIA_FORMAT}
							label="Media Format"
							tooltip={{
								placement: "right",
								title: (
									<div>
										<div>
											Indicates the type of media format of your show. The chosen format type for this podcast cannot be changed
											after podcast creation.
										</div>
										<br />
										<div>
											<strong>Audio</strong>
										</div>
										<ul>
											<li>Supported by all podcast streaming channels.</li>
											<li>M4A & MP3 file format.</li>
										</ul>
										<div>
											<strong>Video</strong>
										</div>
										<ul>
											<li>Only supported by Apple Podcasts.</li>
											<li>MOV, MP4, and M4V file format.</li>
										</ul>
									</div>
								)
							}}
							rules={[
								{
									required: true
								}
							]}
						>
							<PodpalRadioButtonGroup name={FIELD_NAMES.MEDIA_FORMAT} size="large">
								<Radio.Button value={MEDIA_FORMAT_OPTIONS.AUDIO}>Audio</Radio.Button>
								<Tooltip
									placement="right"
									title={isOnFreeOrStarterPlan ? "Upgrade to a Podpal Pro or Agency Plan to import a video podcast." : ""}
								>
									<Radio.Button value={MEDIA_FORMAT_OPTIONS.VIDEO} disabled={isOnFreeOrStarterPlan}>
										Video
									</Radio.Button>
								</Tooltip>
							</PodpalRadioButtonGroup>
						</Form.Item>
					</Col>
				</Row>
				<Row align="middle">
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.TERMS_OF_USE}
							valuePropName="checked"
							rules={[
								{
									required: true,
									message: "" // empty string to override the default message. Otherwise the `validator` below duplicates the message
								},
								{
									validator: () => {
										return !termsOfUseChecked ? Promise.reject(TERMS_AND_CONDITIONS_REQUIRED) : Promise.resolve()
									}
								}
							]}
						>
							<Checkbox name={FIELD_NAMES.TERMS_OF_USE} checked={termsOfUseChecked} onChange={() => toggleTermsOfUseChecked()}>
								<StyledText>
									By importing this feed, I agree to the{" "}
									<a href="https://www.podpal.com/legal/terms-of-service" target="_blank" rel="noopener noreferrer">
										Terms of Service
									</a>{" "}
									and verify that I own the rights to this podcast.
								</StyledText>
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</StyledForm>
		</>
	)

	const verifyRSSOwnershipBody = (
		<>
			<Form form={verificationForm}>
				<Row gutter={[0, 12]}>
					<Col>
						<StyledText>
							Before we can import your podcast, you must verify that you own this RSS Feed. Podpal uses the email address in
							the “Owner Email” tag of the RSS Feed for verification.
						</StyledText>
					</Col>
				</Row>
				<Row gutter={[0, 12]}>
					<Col>
						<StyledText>
							<strong>Please enter the 6-digit verification code that was emailed to the show owner.</strong>
						</StyledText>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name="verificationCode"
							validateFirst
							validateTrigger="onBlur"
							rules={[
								{
									required: true,
									message: "Please enter the verification code."
								},
								{
									validator: async (rule, value) => {
										if (parseInt(value) === migrationVerificationData?.migrationVerification.code) {
											return Promise.resolve()
										} else {
											return Promise.reject("The code you entered is incorrect.")
										}
									}
								}
							]}
						>
							<Input placeholder="123456" size="large" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	)

	const ownershipVerifiedBody = (
		<>
			<Row>
				<Col>
					<StyledText>
						Thank you for verifying that you own this podcast. You can now proceed with switching to Podpal!
					</StyledText>
				</Col>
			</Row>
		</>
	)

	const selectFlowFooter = [
		<PodpalButton key="import" variant="secondary" onClick={handleImportExistingPodcast}>
			Import an Existing Show
		</PodpalButton>,
		<PodpalButton key="new" type="primary" onClick={handleAddNewPodcast}>
			Start a New Podcast
		</PodpalButton>
	]

	const importExistingFlowFooter = [
		<PodpalButton key="back" variant="secondary" onClick={handleBackToSelectFlow}>
			Back
		</PodpalButton>,
		<PodpalButton
			key="next"
			type="primary"
			onClick={async () => {
				const fields = [FIELD_NAMES.RSS_FEED_URL, FIELD_NAMES.TERMS_OF_USE]
				await form.validateFields(fields)
				if (!ownerEmail) {
					handleClose()
					ownerEmailNotFoundModal()
					return
				}
				try {
					const { data } = await createMigrationVerification({
						migrationVerificationStatus: MigrationVerificationStatus.pending,
						podcastName,
						ownerEmail
					})
					setMigrationVerificationID(data.migration_verification_id)
					queryClient.invalidateQueries(["migrationVerification"])
					setCurrentSequence(MODAL_SEQUENECES.VERIFY_RSS_FEED_OWNERSHIP)
				} catch (e) {
					errorAlert("Failed to send verification email")
				}
			}}
		>
			Next
		</PodpalButton>
	]

	const verifyRSSOwnershipFooter = [
		<PodpalButton key="back" variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton
			key="next"
			type="primary"
			onClick={async () => {
				try {
					await verificationForm.validateFields()
					setCurrentSequence(MODAL_SEQUENECES.OWNERSHIP_VERIFIED)
				} catch (e) {}
			}}
		>
			Next
		</PodpalButton>
	]

	const ownershipVerifiedFooter = [
		<PodpalButton key="back" variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton key="ok" type="primary" onClick={handleOk}>
			Next
		</PodpalButton>
	]

	let title, body, footer, modalWidth
	switch (currentSequence) {
		case MODAL_SEQUENECES.SELECT_FLOW:
			title = "Add Podcast"
			body = selectFlowBody
			footer = selectFlowFooter
			modalWidth = 480
			break
		case MODAL_SEQUENECES.IMPORT_EXISTING_SHOW:
			title = "Import an Existing Show"
			body = importExistingShowBody
			footer = importExistingFlowFooter
			modalWidth = 420
			break
		case MODAL_SEQUENECES.VERIFY_RSS_FEED_OWNERSHIP:
			title = (
				<Space size={12}>
					<WarningExclamationIcon />
					{"Please verify podcast ownership!"}
				</Space>
			)
			body = verifyRSSOwnershipBody
			footer = verifyRSSOwnershipFooter
			modalWidth = 420
			break
		case MODAL_SEQUENECES.OWNERSHIP_VERIFIED:
			title = (
				<Space size={12}>
					<CheckCircleOutlined style={{ color: colors.podpalGreenLight }} />
					{"Podcast ownership verified!"}
				</Space>
			)
			body = ownershipVerifiedBody
			footer = ownershipVerifiedFooter
			modalWidth = 420
			break
		default:
			break
	}

	return (
		<PodpalModal
			maskClosable={false}
			centered
			className="import-podcast-modal"
			visible={visible}
			onOk={handleOk}
			onCancel={handleClose}
			width={modalWidth}
			title={title}
			footer={footer}
		>
			{body}
		</PodpalModal>
	)
}

export default ImportPodcastModal
