import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { MY_PODCASTS } from "constants/routes"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "index"
import firebase from "auth/firebase"
import { useLocation } from "react-router"
import {
	signupUserWithFacebook,
	signupUserWithGoogle,
	toggleLoadingSpinnerOverlayVisible
} from "redux/actions/GetStarted"
import { loginUserWithFacebook, loginUserWithGoogle } from "services/firebase.service"

// this component's main purpose is to persist the loading spinner when the user gets redirected
// back to Podpal. Thus the logic in the function, but all it renders is its children.
export function AuthLoading({ children }: { children: React.ReactNode }) {
	const history = useHistory()
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const location = useLocation()
	const provider = location.state && location.state.provider
	const page = location.state && location.state.page
	const dispatch = useDispatch()
	console.log({ provider, page })

	const isLoadingSpinnerVisible = useSelector((state: RootState) => state.signup.loadingSpinnerOverlayVisible)
	if (!isLoadingSpinnerVisible) {
		dispatch(toggleLoadingSpinnerOverlayVisible())
	}

	useEffect(() => {
		// login flows
		const handleLoginWithGoogle = async () => {
			dispatch(toggleLoadingSpinnerOverlayVisible())
			try {
				await loginUserWithGoogle()
				dispatch(toggleLoadingSpinnerOverlayVisible())
			} catch (e) {
				dispatch(toggleLoadingSpinnerOverlayVisible())
			}
		}
		const handleLoginWithFacebook = async () => {
			dispatch(toggleLoadingSpinnerOverlayVisible())
			try {
				await loginUserWithFacebook()
				dispatch(toggleLoadingSpinnerOverlayVisible())
			} catch (e) {
				dispatch(toggleLoadingSpinnerOverlayVisible())
			}
		}

		// signup flows
		const handleGoogleSignUp = async () => {
			dispatch(signupUserWithGoogle())
		}
		const handleFacebookSignUp = async () => {
			dispatch(signupUserWithFacebook())
		}

		if (provider === "google" && page === "signIn") {
			handleLoginWithGoogle()
		} else if (provider === "facebook" && page === "signIn") {
			handleLoginWithFacebook()
		} else if (provider === "google" && page === "getStarted") {
			handleGoogleSignUp()
		} else if (provider === "facebook" && page === "getStarted") {
			handleFacebookSignUp()
		}
	}, [dispatch, provider, page])

	// check if user is logged in. If so, redirect to my podcasts page
	useEffect(() => {
		firebase.auth.getRedirectResult().then(console.log)
		if (authUser) {
			dispatch(toggleLoadingSpinnerOverlayVisible())
			history.push(MY_PODCASTS)
		}
	}, [authUser, dispatch, history])

	return children
}
