import React from "react"
import { TermsAndConditionsModal } from "components/common/Modals/User/TermsAndConditionsModal"
import NewEmailModal from "components/common/Modals/User/NewEmailModal"
import { DeleteAccountModal } from "components/common/Modals/User/DeleteAccountModal"
import AddNewPodcastConfirmationModal from "components/common/Modals/Podcasts/AddNewPodcastConfirmationModal"
import ImportPodcastModal from "components/common/Modals/Podcasts/ImportPodcastModal"
import { Redirect301Modal } from "components/common/Modals/Podcasts/Redirect301Modal"
import { DeletePodcastModal } from "components/common/Modals/Podcasts/DeletePodcastModal"
import AddNewEpisodeConfirmationModal from "components/common/Modals/Episodes/AddNewEpisodeConfirmationModal"
import DeleteEpisodeModal from "components/common/Modals/Episodes/DeleteEpisodeModal"
import ImportEpisodesStatusModal from "components/common/Modals/Episodes/ImportEpisodesStatusModal"
import ChangeLastPublishedDateModal from "components/common/Modals/Episodes/ChangeLastPublishedDateModal"
import { ChangePlannedReleaseDateModal } from "components/common/Modals/Episodes/ChangePlannedReleaseDateModal"
import { ChangeScheduledReleaseDateModal } from "components/common/Modals/Episodes/ChangeScheduledReleaseDateModal"
import UploadRecordingModal from "components/common/Modals/Recordings/UploadRecordingModal"
import { ChooseRecordingModal } from "components/common/Modals/Recordings/ChooseRecordingModal"
import DeleteRecordingModal from "components/common/Modals/Recordings/DeleteRecordingModal"
import Request301Modal from "components/common/Modals/RSSAndDistribution/Request301RedirectModal"
import Request301OutModal from "components/common/Modals/Podcasts/Redirect301OutModal"
import Stop301RedirectModal from "components/common/Modals/RSSAndDistribution/Stop301RedirectModal"
import { VideoTutorialModal } from "components/common/Modals/HelpCenter/VideoTutorialModal"
import { WhatsNewModal } from "components/common/Modals/HelpCenter/WhatsNewModal"
import { InviteTeamModal } from "components/common/Modals/TeamManagement/InviteTeamModal"
import { ManageRolesModal } from "components/common/Modals/TeamManagement/ManageRolesModal"
import { LimitsExceededModal } from "components/common/Modals/SubscriptionAndBilling/LimitsExceededModal"

export function ModalContainer() {
	return (
		<>
			<TermsAndConditionsModal />
			<NewEmailModal />
			<DeleteAccountModal />
			<AddNewPodcastConfirmationModal />
			<ImportPodcastModal />
			<Redirect301Modal />
			<DeletePodcastModal />
			<AddNewEpisodeConfirmationModal />
			<DeleteEpisodeModal />
			<ImportEpisodesStatusModal />
			<ChangeLastPublishedDateModal />
			<ChangePlannedReleaseDateModal />
			<ChangeScheduledReleaseDateModal />
			<UploadRecordingModal />
			<ChooseRecordingModal />
			<DeleteRecordingModal />
			<Request301Modal />
			<Request301OutModal />
			<Stop301RedirectModal />
			<VideoTutorialModal />
			<WhatsNewModal />
			<InviteTeamModal />
			<ManageRolesModal />
			<LimitsExceededModal />
		</>
	)
}
