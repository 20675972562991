import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useParams } from "react-router"
import { Col, Row, Upload, Tooltip, Menu, Dropdown } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useQueryClient } from "react-query"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { UploadRecordingIcon } from "assets/icons/Episodes"
import PodpalCard from "components/common/Cards/PodpalCard"
import PodpalButton from "components/common/Buttons/PodpalButton"
import UploadRecordingCard from "components/common/Cards/UploadRecordingCard"
import { toggleChooseRecordingModalVisible, setConfigForChooseRecordingModal } from "redux/actions/Recordings"
import { useEpisode } from "services/queries/Episodes"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import useRecordingsUpload from "hooks/Recordings/useRecordingsUpload"
import { EpisodeManagementTooltip } from "./EpisodeManagementTooltip"
import { useDownload } from "hooks/useDownload"

const { Dragger } = Upload

const StyledText = styled.span`
	font: ${props => props.theme.fontXs};
	color: ${props => props.theme.textSecondary};
`

const StyledSecondaryText = styled.span`
	font: ${props => props.theme.fontSm};
	color: ${props => props.theme.textSecondary};
`

const Recording = ({ className }) => {
	const queryClient = useQueryClient()
	const dispatch = useDispatch()

	const { episodeId } = useParams()
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)

	const uploadCancelTokenRef = useRef(null)

	const { handleDownload } = useDownload()
	const { data: episodeData } = useEpisode(episodeId)
	const { data: episodeProgressData } = useEpisodeProgress(episodeId)

	const {
		setup,
		inviteGuests,
		scheduleInterview,
		uploadAudio,
		writeShowNotes,
		reviewEpisode
	} = episodeProgressData?.episodeProgress

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: reviewEpisode ?? 0
		}
	]

	const handleMarkAsDone = async progressStep => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.COMPLETED
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.COMPLETED })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleMarkAsInProgress = async progressStep => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.IN_PROGRESS
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.IN_PROGRESS })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
			queryClient.invalidateQueries(["episode", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const {
		beforeUpload,
		loading,
		media,
		handleLoadedMedia,
		progress,
		filename,
		beforeReplaceUpload,
		uploadMedia,
		mediaFileName,
		mediaUploadedTime,
		handleRemoveRecording,
		isReplacingRecording
	} = useRecordingsUpload({
		episodeId,
		episodeData,
		selectedPodcast,
		handleMarkAsDone,
		handleMarkAsInProgress,
		uploadCancelTokenRef
	})

	useEffect(() => {
		return () => {
			if (uploadCancelTokenRef?.current) {
				// ESLint is disabled for this line because when the recommendation is followed we don't have the correct value in the ref.
				uploadCancelTokenRef.current.cancel() // eslint-disable-line react-hooks/exhaustive-deps
			}
		}
	}, [])

	const isVideoPodcast = selectedPodcast?.mediaFormat === "video"

	const uploadMenu = isReplacing => (
		<Menu>
			<Menu.Item disabled style={{ cursor: "unset" }}>
				Add a file from...
			</Menu.Item>
			<Menu.Item>
				<Upload
					accept={isVideoPodcast ? ".mp4,.m4v,.mov" : ".mp3,.m4a"}
					showUploadList={false}
					listType="picture"
					beforeUpload={isReplacing ? beforeReplaceUpload : beforeUpload}
					customRequest={uploadMedia}
				>
					My Computer
				</Upload>
			</Menu.Item>
			<Menu.Item
				onClick={() => {
					dispatch(setConfigForChooseRecordingModal({ isReplacing: true, episodeID: episodeId }))
					dispatch(toggleChooseRecordingModalVisible())
				}}
			>
				My Recordings
			</Menu.Item>
		</Menu>
	)

	return (
		<PodpalCard className={className}>
			<Row justify="end" align="middle">
				<EpisodeManagementTooltip
					tooltipText={
						<span>
							Supported file types
							<ul>
								<li>
									<strong>Audio Podcast</strong>: M4A & MP3.
								</li>
								<li>
									<strong>Video Podcast</strong>: MOV, MP4, and M4V.
								</li>
							</ul>
						</span>
					}
				/>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col>
					<h2>My Recording</h2>
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 16]}>
				<Col span={24}>
					<div style={{ display: media || loading ? "none" : undefined }}>
						<Dragger
							accept={isVideoPodcast ? ".mp4,.m4v,.mov" : ".mp3,.m4a"}
							beforeUpload={beforeUpload}
							showUploadList={false}
							customRequest={uploadMedia}
						>
							<Row justify="center" align="middle">
								<Col span={24} style={{ textAlign: "center" }}>
									<UploadRecordingIcon />
								</Col>
								<Col span={24} style={{ textAlign: "center" }}>
									<h3>Click or drag to upload new file</h3>
								</Col>
								<Col span={24} style={{ textAlign: "center" }}>
									<StyledSecondaryText>
										<i>Supported file types include M4A, MP3, MOV, MP4, and M4V.</i>
									</StyledSecondaryText>
								</Col>
							</Row>
						</Dragger>
					</div>
					{loading && <UploadRecordingCard filename={filename} progress={progress} ref={uploadCancelTokenRef} />}
					{media && (!loading || !isReplacingRecording) && (
						<>
							<Row justify="center" gutter={[0, 8]}>
								<Col>
									{isVideoPodcast ? (
										<video controls controlsList="nodownload" onLoadedMetadata={handleLoadedMedia} style={{ width: "100%" }}>
											<source src={media} type="video/mp4" />
										</video>
									) : (
										<audio controls controlsList="nodownload" onLoadedMetadata={handleLoadedMedia}>
											<source src={media} type="audio/mp3" />
											<source src={media} type="audio/mp4" />
										</audio>
									)}
								</Col>
							</Row>
							<Row justify="center">
								<Col>
									<StyledText>
										<strong>{mediaFileName}</strong>
									</StyledText>
								</Col>
							</Row>
							<Row justify="center">
								<Col>
									<StyledText>{mediaUploadedTime}</StyledText>
								</Col>
							</Row>
						</>
					)}
				</Col>
			</Row>
			{media ? (
				<Row justify="center" align="middle" gutter={8}>
					<Col>
						<PodpalButton
							type="primary"
							size="large"
							disabled={!media}
							onClick={() => handleDownload({ url: media, filename: mediaFileName })}
						>
							Download
						</PodpalButton>
					</Col>
					<Col>
						{media ? (
							<Dropdown overlay={uploadMenu(true)} trigger={["click"]} className="episode-card-more">
								<PodpalButton size="large" variant="secondary">
									Replace
								</PodpalButton>
							</Dropdown>
						) : (
							<PodpalButton variant="secondary" size="large" disabled>
								Replace
							</PodpalButton>
						)}
					</Col>
					<Col>
						{episodeData?.episode?.isPublished ? (
							<Tooltip placement="bottomRight" title="Once an episode has been published, you cannot remove the recording.">
								<PodpalButton variant="secondary" size="large" disabled>
									Remove
								</PodpalButton>
							</Tooltip>
						) : (
							<PodpalButton variant="secondary" size="large" onClick={handleRemoveRecording} disabled={!media}>
								Remove
							</PodpalButton>
						)}
					</Col>
				</Row>
			) : (
				<Row justify="center" align="middle">
					<Col>
						<Dropdown overlay={uploadMenu(false)} trigger={["click"]} className="episode-card-more">
							<PodpalButton size="large" type="primary">
								Upload
							</PodpalButton>
						</Dropdown>
					</Col>
				</Row>
			)}
		</PodpalCard>
	)
}

export default Recording
