import { useQuery } from "react-query"
import { keysToCamel } from "utils/camelCaseUtils"
import { findEpisodeReviewsByEpisodeID } from "../episodeReview.service"
import { FindEpisodeReviewsByEpisodeIDResponse } from "../types/episodeReview"

export const useEpisodeReviews = (episodeID: string) => {
	return useQuery<FindEpisodeReviewsByEpisodeIDResponse>(
		["episodeReviews", episodeID],
		async () => {
			const { data } = await findEpisodeReviewsByEpisodeID(episodeID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!episodeID }
	)
}
