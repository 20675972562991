import React from "react"
import styled from "styled-components"
import { Card, Row, Col } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { VideoCamIcon } from "assets/icons/Navbar"

const StyledSecondaryText = styled.span`
	font: ${({ theme }) => theme.fontSm};
	color: ${({ theme }) => theme.textSecondary};
`

const StyledCard = styled(Card)`
	width: 414px;
	@media only screen and (max-width: 576px) {
		width: 360px;
	}
	border-radius: ${({ theme }) => theme.radiusMd};

	iframe {
		width: 100%;
		height: 100%;
		// border-radius: ${({ theme }) => theme.radiusLg};
		border: none;
	}

	.ant-card-head {
		border-radius: ${({ theme }) => theme.radiusLg} ${({ theme }) => theme.radiusLg} 0 0;
	}

	.ant-card-body {
		// padding: ${({ theme }) => theme.spaceSm};
		padding: 0;
		height: 200px;
	}

	.ant-card-actions {
		padding: 0 ${({ theme }) => theme.spaceMd} 0 ${({ theme }) => theme.spaceMd};
		li {
			border: none;
			&:last-child {
				text-align: end;
			}
		}

		border-radius: 0 0 ${({ theme }) => theme.radiusLg} ${({ theme }) => theme.radiusLg};
	}
`

interface VideoTutorialCardProps {
	title: String
	iframeUrl: string
	videoUrl: string
}

export function VideoTutorialCard({ title, iframeUrl, videoUrl }: VideoTutorialCardProps) {
	return (
		<StyledCard
			title={title}
			actions={[
				<Row gutter={8} align="middle">
					<Col style={{ paddingTop: "4px" }}>
						<VideoCamIcon />
					</Col>
					<Col>
						<StyledSecondaryText>2 mins</StyledSecondaryText>
					</Col>
				</Row>,
				<PodpalButton type="primary" href={videoUrl} target="_blank" rel="noopener noreferrer">
					Watch
				</PodpalButton>
			]}
		>
			<Row justify="center" align="middle">
				<Col span={24} style={{ height: "200px" }}>
					<iframe
						id="tutorial-player"
						src={iframeUrl}
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</Col>
			</Row>
		</StyledCard>
	)
}
