import React from "react"
import { Row, Col } from "antd"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import PodpalButton from "components/common/Buttons/PodpalButton"
import paymentMethodImage from "assets/subscriptionandbilling/paymentmethod.svg"
import paymentMethodMobileImage from "assets/subscriptionandbilling/paymentmethod-mobile.svg"
import "components/UserSettings/SubscriptionAndBilling/SubscriptionAndBilling.scss"
import { useSelector } from "react-redux"
import { useUserInfo } from "services/queries/UserInfo"
import { useStripeCustomerPortal } from "hooks/SubscriptionAndBilling/useStripeCustomerPortal"
import layout from "styles/scss/layout.scss"

const PaymentMethodPage = () => {
	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	const authUser = useSelector(state => state.app.authUser)

	const { data } = useUserInfo(authUser.uid)
	const { openCustomerPortal } = useStripeCustomerPortal({ customerId: data?.userInfo?.customerId })

	const paymentMethodBanner = (
		<Col>
			<Row>
				<img src={isMobile ? paymentMethodMobileImage : paymentMethodImage} alt="current-plan" />
			</Row>
		</Col>
	)

	return (
		<Row align="middle" justify="center" className="subscription-and-billing-tab" gutter={48}>
			<Col>
				<Row>
					<Col>
						{isMobile ? (
							<h2 style={{ marginTop: layout.spaceLg }}>We Accept Multiple Forms of Payment!</h2>
						) : (
							<h2>
								We Accept Multiple Forms of
								<br />
								Payment!
							</h2>
						)}
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col>
						{isMobile ? (
							<p>
								Do you need to change your payment method? No problem. Just visit the your Customer Portal and make the switch
								to a method that works best for you.
							</p>
						) : (
							<p>
								Do you need to change your payment method? No
								<br />
								problem. Just visit the your Customer Portal and make
								<br />
								the switch to a method that works best for you.
							</p>
						)}
					</Col>
				</Row>
				{isMobile && <Row gutter={[0, { xs: 24 }]}>{paymentMethodBanner}</Row>}
				<Row gutter={[12, { xs: 16 }]}>
					<Col xs={24} lg={16}>
						{isMobile ? (
							<PodpalButton shape="round" fullwidth type="primary" size="large" onClick={openCustomerPortal}>
								Change Payment Method
							</PodpalButton>
						) : (
							<PodpalButton type="primary" size="large" onClick={openCustomerPortal}>
								Change Payment Method
							</PodpalButton>
						)}
					</Col>
				</Row>
			</Col>
			{!isMobile && paymentMethodBanner}
		</Row>
	)
}

export default PaymentMethodPage
