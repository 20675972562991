import axios from "axios"
import { InviteTeamRequest } from "./types/teamManagement"

export const inviteTeam = ({ inviterId, podcastRoles }: InviteTeamRequest) =>
	axios.post("/api/twirp/podpal.administration.TeamManagementService/InviteTeam", {
		inviterId,
		podcastRoles
	})

export const findPodcastTeam = (podcastId: string) =>
	axios.post("/api/twirp/podpal.administration.TeamManagementService/FindTeam", {
		podcastId
	})

export const removeMember = (podcastRoleId: string | undefined) =>
	axios.post("/api/twirp/podpal.administration.TeamManagementService/RemoveMember", {
		podcastRoleId
	})
