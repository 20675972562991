import {
	MARK_ALL_NOTIFICATIONS_READ,
	MARK_NOTIFICATION_READ,
	DELETE_ALL_NOTIFICATIONS,
	RETRIEVE_NOTIFICATIONS,
	PREPEND_NEW_NOTIFICATIONS,
	DELETE_NOTIFICATION,
	APPEND_NEW_NOTIFICATIONS,
	UPDATE_UNREAD_NOTIFICATIONS_COUNT
} from "constants/actionTypes"
import { PodpalNotification } from "services/types/notifications"

export const markNotificationReadAction = (markedNotification: PodpalNotification) => ({
	type: MARK_NOTIFICATION_READ,
	payload: {
		markedNotification
	}
})

export const markAllNotificationsReadAction = (markedNotifications: PodpalNotification[]) => ({
	type: MARK_ALL_NOTIFICATIONS_READ,
	payload: {
		markedNotifications
	}
})

export const deleteAllNotificationsAction = () => ({
	type: DELETE_ALL_NOTIFICATIONS
})

export const deleteNotificationAction = (deletedNotificationId: string) => ({
	type: DELETE_NOTIFICATION,
	payload: {
		deletedNotificationId
	}
})

export const retrieveNotificationsAction = (retrievedNotifications: PodpalNotification[]) => ({
	type: RETRIEVE_NOTIFICATIONS,
	payload: {
		notifications: retrievedNotifications
	}
})

export const prependNewNotificationsAction = (newNotifications: PodpalNotification[]) => ({
	type: PREPEND_NEW_NOTIFICATIONS,
	payload: {
		notifications: newNotifications
	}
})

export const appendNewNotificationsAction = (newNotifications: PodpalNotification[]) => ({
	type: APPEND_NEW_NOTIFICATIONS,
	payload: {
		notifications: newNotifications
	}
})

export const updateUnreadNotificationsCountAction = (unreadNotificationsCount: number) => ({
	type: UPDATE_UNREAD_NOTIFICATIONS_COUNT,
	payload: {
		unreadNotificationsCount
	}
})
