import React from "react"
import { Row, Col } from "antd"
import { BackArrowIcon } from "assets/icons/Episodes"
import { useHistory } from "react-router"
import { MY_PODCASTS } from "constants/routes"
import styled from "styled-components"
import { VideoTutorialCard } from "components/common/Cards/HelpCenter/VideoTutorialCard"

const StyledHeader = styled.span`
	font: ${({ theme }) => theme.fontLg};
	color: ${({ theme }) => theme.textPrimary};
`

interface Tutorial {
	title: string
	iframeUrl: string
	videoUrl: string
}

export const VideoTutorialsPage = () => {
	const history = useHistory()

	const TUTORIALS: Tutorial[] = [
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		},
		{
			title: "How to Create a New Podcast",
			iframeUrl: "https://www.youtube.com/embed/THCmck_57M0",
			videoUrl: "https://www.youtube.com/watch?v=THCmck_57M0"
		}
	]

	const tutorialCards = TUTORIALS.map(tutorial => (
		<Col xs={24} lg={12} xl={8} key={tutorial.title} flex="none">
			<VideoTutorialCard title={tutorial.title} iframeUrl={tutorial.iframeUrl} videoUrl={tutorial.videoUrl} />
		</Col>
	))

	return (
		<div className="home-page-container">
			<Row justify="start" align="middle" gutter={[16, 24]} style={{ width: "100%" }}>
				<Col>
					<BackArrowIcon onClick={() => history.push(MY_PODCASTS)} />
				</Col>
				<Col>
					<StyledHeader>Video Tutorials</StyledHeader>
				</Col>
			</Row>
			<Row gutter={[24, 24]}>{tutorialCards}</Row>
		</div>
	)
}
