import React from "react"
import { Route, Switch, Redirect, withRouter } from "react-router-dom"
import {
	ProfileIcon,
	ProfileFilledIcon,
	VPNKeyIcon,
	VPNKeyFilledIcon,
	AppsFilledIcon,
	AppsFilledHoverIcon
} from "assets/icons/UserSettings/MyAccount"
import GenericSettingsPage from "containers/SettingsPage/GenericSettingsPage"
import ProfileForm from "components/UserSettings/MyAccount/ProfileForm"
import EmailPasswordForm from "components/UserSettings/MyAccount/EmailPasswordForm"
import AppsAndIntegrations from "components/UserSettings/MyAccount/AppsAndIntegrations"

import "./MyAccountPage.scss"
import { APPS_AND_INTEGRATIONS, EMAIL_AND_PASSWORD, MY_ACCOUNT, PROFILE, SETTINGS, NOT_FOUND } from "constants/routes"

const MyAccountPage = props => {
	const basePath = `${SETTINGS}${MY_ACCOUNT}`
	let data = [
		{
			KEY: 1,
			ICON: ProfileIcon,
			FILLED_ICON: ProfileFilledIcon,
			TITLE: "Profile",
			PATH: PROFILE,
			CONTENT: <ProfileForm />
		},
		{
			KEY: 2,
			ICON: VPNKeyIcon,
			FILLED_ICON: VPNKeyFilledIcon,
			TITLE: "Email & Password",
			PATH: EMAIL_AND_PASSWORD,
			CONTENT: <EmailPasswordForm />
		},
		{
			KEY: 3,
			ICON: AppsFilledIcon,
			FILLED_ICON: AppsFilledHoverIcon,
			TITLE: "Apps & Integrations",
			PATH: APPS_AND_INTEGRATIONS,
			CONTENT: <AppsAndIntegrations />
		}
	]

	const accountTabRoutes = data.map(page => {
		return (
			<Route key={page.PATH} path={`${basePath}${page.PATH}`}>
				{page.CONTENT}
			</Route>
		)
	})

	return (
		<GenericSettingsPage heading="My Account" basePath={basePath} settingsTabsArray={data}>
			<Switch>
				{accountTabRoutes}
				<Redirect to={NOT_FOUND} />
			</Switch>
		</GenericSettingsPage>
	)
}

export default withRouter(MyAccountPage)
