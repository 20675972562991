import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import { composeWithDevTools } from "redux-devtools-extension"
// @ts-ignore
import { rootReducer } from "redux/reducers"
import storage from "redux-persist/lib/storage"

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["app"]
}

let middleware = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
	let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)))
	let persistor = persistStore(store)
	return { store, persistor }
}
