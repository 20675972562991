import { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getSignedUrl, uploadFile } from "services/upload.service"
import { createRecording, validateRecordingFileName, replaceRecording } from "services/recording.service"
import { duplicateFilenameModal, renameFileModal } from "components/common/Modals/Episodes/EpisodeManagementModals"
import {
	toggleUploadRecordingModalVisible,
	setProgressForUploadRecordingModal,
	setConfigForUploadRecordingModal
} from "redux/actions/Recordings"
import { UNABLE_TO_CREATE_RECORDING, RECORDING_CREATED, UNABLE_TO_UPLOAD_RECORDING } from "constants/strings/Episodes"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import { useQueryClient } from "react-query"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { mapFileTypeToExtension } from "utils/recording"

/**
 * This hook is used to provide functionality to upload recordings at a podcast level, i.e., in the recordings page, zero state card, etc.
 *
 */
export const usePodcastRecordingsUpload = () => {
	const queryClient = useQueryClient()
	const dispatch = useDispatch()

	const visible = useSelector(state => state.recording.uploadRecordingModalVisible)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const isAudioPodcast = selectedPodcast?.mediaFormat === "audio"

	const [uploadConfig, setUploadConfig] = useState(null)
	const [duration, setDuration] = useState(null)
	const [fileName, setFileName] = useState(null)
	const [fileType, setfileType] = useState("")
	const [fileSize, setFileSize] = useState(null)

	const progress = useSelector(state => state.recording.uploadRecordingProgress)

	const setProgress = progress => dispatch(setProgressForUploadRecordingModal(progress))
	useEffect(() => {
		if (progress >= 100) {
			if (visible) {
				dispatch(toggleUploadRecordingModalVisible())
			}
			dispatch(setProgressForUploadRecordingModal(0))
		}
	}, [dispatch, visible, progress])
	const uploadCancelTokenRef = useRef(null)
	useEffect(() => {
		return () => {
			if (uploadCancelTokenRef?.current) {
				// ESLint is disabled for this line because when the recommendation is followed we don't have the correct value in the ref.
				uploadCancelTokenRef.current.cancel() // eslint-disable-line react-hooks/exhaustive-deps
			}
		}
	}, [])

	const extractDuration = file => {
		// Create an A/V DOM element to extract duration.
		return new Promise((resolve, reject) => {
			let mediaElement = null
			let fileUrl = URL.createObjectURL(file)
			if (isAudioPodcast) {
				mediaElement = document.createElement("audio")
				mediaElement.src = fileUrl
				mediaElement.ondurationchange = () => {
					setDuration(mediaElement.duration)
					resolve(mediaElement.duration)
				}
			} else {
				mediaElement = document.createElement("video")
				mediaElement.src = fileUrl
				mediaElement.ondurationchange = () => {
					setDuration(mediaElement.duration)
					resolve(mediaElement.duration)
				}
			}
		})
	}

	const handleCreateRecording = async recording => {
		await createRecording(recording)
		queryClient.invalidateQueries(["recordings", selectedPodcast.id])
		queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
		queryClient.invalidateQueries(["podcastInfo", selectedPodcast.id])
	}

	const handleKeepBothRecordings = async (file, newFileName) => {
		try {
			const mediaDuration = await extractDuration(file)
			const resp = await getSignedUrl(
				process.env.REACT_APP_RECORDINGS_BUCKET,
				`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
				file.type
			)
			const uploadConfig = {
				url: resp?.data?.url,
				bucket: resp?.data?.bucket,
				object: resp?.data?.object
			}
			dispatch(toggleUploadRecordingModalVisible())
			dispatch(setConfigForUploadRecordingModal({ filename: file.name, uploadCancelTokenRef: uploadCancelTokenRef }))
			const url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			// Upload failed or was canceled.
			if (!url) {
				return
			}
			setFileName(newFileName)
			setfileType(file.type)
			setFileSize(file.size)
			const recording = {
				url: url,
				duration: mediaDuration,
				createdAt: moment().format(),
				fileName: newFileName,
				fileType: file.type,
				fileSize: file.size,
				podcastId: selectedPodcast.id
			}
			handleCreateRecording(recording)
			successAlert(RECORDING_CREATED)
		} catch (e) {
			errorAlert(UNABLE_TO_CREATE_RECORDING)
		}
	}

	const handleReplaceRecording = async (file, duplicateRecordingID) => {
		try {
			const mediaDuration = await extractDuration(file)
			const resp = await getSignedUrl(
				process.env.REACT_APP_RECORDINGS_BUCKET,
				`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
				file.type
			)
			const uploadConfig = {
				url: resp?.data?.url,
				bucket: resp?.data?.bucket,
				object: resp?.data?.object
			}
			dispatch(toggleUploadRecordingModalVisible())
			dispatch(setConfigForUploadRecordingModal({ filename: file.name, uploadCancelTokenRef: uploadCancelTokenRef }))
			const url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			// Upload failed or was canceled.
			if (!url) {
				return
			}
			setFileName(file.name)
			setfileType(file.type)
			setFileSize(file.size)
			const recording = {
				url: url,
				duration: mediaDuration,
				createdAt: moment().format(),
				fileName: file.name,
				fileType: file.type,
				fileSize: file.size,
				podcastId: selectedPodcast.id
			}
			await replaceRecording(duplicateRecordingID, recording)
			queryClient.invalidateQueries(["recordings", selectedPodcast.id])
			queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
			queryClient.invalidateQueries(["podcastInfo", selectedPodcast.id])
			successAlert(RECORDING_CREATED)
		} catch (e) {
			errorAlert(UNABLE_TO_CREATE_RECORDING)
		}
	}

	const beforeUpload = async file => {
		return new Promise(async (resolve, reject) => {
			const { data } = await validateRecordingFileName(file.name, selectedPodcast.id)
			const isDuplicate = data.is_duplicate
			const isAssociatedWithEpisode = data.is_associated_with_episode
			const newFileName = data.new_file_name
			const duplicateRecordingID = data.duplicate_recording_id
			if (isDuplicate) {
				if (isAssociatedWithEpisode) {
					renameFileModal()
					reject()
				} else {
					duplicateFilenameModal(
						() => handleKeepBothRecordings(file, newFileName),
						() => handleReplaceRecording(file, duplicateRecordingID)
					)
					reject()
				}
			} else {
				const resp = await getSignedUrl(
					process.env.REACT_APP_RECORDINGS_BUCKET,
					`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
					file.type
				)
				setUploadConfig({
					url: resp?.data?.url,
					bucket: resp?.data?.bucket,
					object: resp?.data?.object
				})
				await extractDuration(file)
				setFileName(file.name)
				setfileType(file.type)
				setFileSize(file.size)
				dispatch(toggleUploadRecordingModalVisible())
				resolve()
			}
		})
	}

	const uploadMedia = async ({ file }) => {
		let url
		try {
			dispatch(setConfigForUploadRecordingModal({ filename: file.name, uploadCancelTokenRef: uploadCancelTokenRef }))
			url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			// Upload failed or was canceled.
			if (!url) {
				return
			}
		} catch (e) {
			errorAlert(UNABLE_TO_UPLOAD_RECORDING)
		}
		try {
			const recording = {
				url: url,
				duration: duration,
				createdAt: moment().format(),
				fileName: fileName,
				fileType: fileType,
				fileSize: fileSize,
				podcastId: selectedPodcast.id
			}
			handleCreateRecording(recording)
			successAlert(RECORDING_CREATED)
		} catch (e) {
			errorAlert(UNABLE_TO_CREATE_RECORDING)
		}
	}

	return {
		beforeUpload,
		uploadMedia
	}
}
