import React from "react"
import styled from "styled-components"
import { Row, Col } from "antd"
import podpalLogo from "assets/Logo_Black.png"
import PrivateBetaCard from "./PrivateBetaCard"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import { logoutUser } from "services/firebase.service"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { useHistory } from "react-router"
import { SIGN_IN } from "constants/routes"

const StyledBouncerPage = styled.div`
	width: 100%;
	height: 100vh;
	padding: ${props => props.theme.spaceLg};
	background: ${props => props.theme.neutral1};
	overflow-y: scroll;

	.logo {
		width: 151px;
		height: 59px;
		cursor: pointer;
	}

	.private-beta-mail {
		color: ${props => props.theme.podpalBlue};
	}
`

const BouncerPage = () => {
	const history = useHistory()

	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	return (
		<StyledBouncerPage>
			<Row justify={isMobile ? "center" : "start"} gutter={[0, isMobile ? 16 : 24]}>
				<Col>
					<img
						src={podpalLogo}
						className="logo"
						alt="podpal-logo"
						onClick={async () => {
							try {
								await logoutUser()
								history.push(SIGN_IN)
							} catch (e) {
								errorAlert("Failed to log out.")
							}
						}}
					/>
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col>
					<PrivateBetaCard />
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					For questions please contact us at{" "}
					<a className="private-beta-mail" href="mailto: hello@podpal.com">
						hello@podpal.com
					</a>
				</Col>
			</Row>
		</StyledBouncerPage>
	)
}

export default BouncerPage
