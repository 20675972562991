import React from "react"
import { Modal, Row, Col } from "antd"
import { DangerCrossIcon } from "assets/icons/Episodes"

export const recordingInUseModal = () => {
	Modal.error({
		title: "This recording can’t be deleted at this time.",
		centered: true,
		icon: <DangerCrossIcon />,
		content: (
			<>
				<Row gutter={[0, 8]}>
					<Col>
						This recording is attached to a previously published episode! Unpublish the episode first to delete this recording
						file.
					</Col>
				</Row>
			</>
		),
		width: 420
	})
}
