import React, { useState, useEffect } from "react"
import { Checkbox, Col, Form, Input, Row, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "index"
import "./Redirect.scss"
import { FIELD_NAMES, HOSTING_PROVIDERS } from "constants/strings/Podcasts"
import { DEFAULT_PODCAST_ARTWORK_URL } from "constants/strings"
import { SectionHeader, PodpalImage, StyledForm } from "./styled"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { toggle301RedirectOutModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { DownloadOutlined } from "@ant-design/icons"
import { usePodcastAccessValidator, usePodcastRole } from "services/queries/PodcastRole"
import { ShowAccess } from "services/types/podcastRoles"
import { NOT_FOUND } from "constants/routes"
import { useHistory } from "react-router"
import { getRSSFeed } from "services/podcast.service"
import { useDownload } from "hooks/useDownload"

const HOSTING_PROVIDER_OPTIONS = HOSTING_PROVIDERS.map(provider => (
	<Select.Option key={provider} value={provider}>
		{provider}
	</Select.Option>
))

const Redirect = () => {
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const [rssUrl, setRssUrl] = useState("")
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const history = useHistory()
	const podcastId = selectedPodcast?.id
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const { handleCreateDownloadFromContent } = useDownload()

	// no access if editor
	const { data: podcastRoleData, isLoading: isPodcastRolesLoading } = usePodcastRole({
		podcastId: podcastId,
		userId: authUser.uid
	})

	// no access if not User's Podcast
	const {
		isLoading: podcastAccessLoading,
		error: podcastAccessError,
		data: podcastAccessData
	} = usePodcastAccessValidator({ userId: authUser?.uid, podcastId: selectedPodcast?.id })

	const podcastAlbumCoverImage =
		selectedPodcast?.[FIELD_NAMES.PHOTO]?.length > 0 ? selectedPodcast?.[FIELD_NAMES.PHOTO] : DEFAULT_PODCAST_ARTWORK_URL

	const checklist = [
		"I understand that a 301 redirect will permanently reroute my Podpal feed to my new host and inform my connected platforms of the change. After the redirect, my new host will handle my podcast hosting and become the official source for future updates to my episode content.",
		"I understand this podcast will continue to be counted towards the limits of my current plan until deleted from Podpal. Billing for my Podpal plan will be unaffected by the 301 Redirect, and is only impacted by a plan upgrade, downgrade, or cancellation.",
		<>
			After the 301 Redirect is complete, I understand that Podpal suggests waiting a minimum of <b>7 days</b> before I
			remove this podcast from Podpal or delete my Podpal account.
		</>
	]

	const handleOk = async () => {
		const fields = [FIELD_NAMES.OLD_HOST, FIELD_NAMES.RSS_FEED_URL, FIELD_NAMES.TERMS_OF_USE, "checklist-3"]
		await form.validateFields(fields)
		const rssFeedUrl = form.getFieldValue(FIELD_NAMES.RSS_FEED_URL)
		dispatch(toggle301RedirectOutModalVisible(rssFeedUrl))
	}

	const handleDownloadRSSFeed = async () => {
		const resp = await getRSSFeed(selectedPodcast.id)
		const content = resp?.data?.rss_feed
		handleCreateDownloadFromContent({ content, filename: `${selectedPodcast?.profileUrl}.xml` })
	}

	useEffect(() => {
		if (selectedPodcast?.rssFeedUrl?.length > 0) {
			setRssUrl(selectedPodcast.rssFeedUrl)
		}
	}, [selectedPodcast])

	const isEditor = podcastRoleData?.podcastRole?.showAccess === ShowAccess.editor

	const notUserPodcast = !podcastAccessError && podcastAccessData && !podcastAccessData.hasAccess

	useEffect(() => {
		if (isEditor) history.push(NOT_FOUND)
		if (notUserPodcast) {
			history.push(NOT_FOUND)
		}
	}, [history, podcastAccessLoading, podcastAccessError, podcastAccessData, isEditor, notUserPodcast])

	if (podcastAccessLoading || isPodcastRolesLoading) return null
	return (
		<div className="settings-page-container rss-redirect-container">
			<div className="rss-redirect">
				<h1 className="settings-page-heading">Redirect {selectedPodcast?.title}</h1>
				<Row gutter={[0, 32]}>
					<Col>
						<p className="rss-redirect-text rss-redirect-subheading">
							We're sorry to see you leave Podpal, but we're committed to making your transition to a new hosting provider as
							easy as possible. To ensure a hassle-free transition and prevent any complications with your feed and content,
							please follow the steps below:
						</p>
					</Col>
					<Col>
						<PodpalImage width={128} src={podcastAlbumCoverImage} preview={false} />
					</Col>
					<Col span={24}>
						<SectionHeader>Step 1: Backup Your Podcast</SectionHeader>
						<Row gutter={[0, 16]}>
							<Col offset={1}>
								<h2>Download Your RSS Feed</h2>
								<p className="rss-redirect-text">
									Prior to the 301 redirect and registering your feed with a new provider, we advise downloading your RSS Feed
									which includes podcast metadata such as show notes, and links to artwork and episode recordings. This ensures
									you have a backup of all your content in case of any issues.
								</p>
							</Col>
							<Col offset={1}>
								<PodpalButton
									type="primary"
									size="large"
									rel="noopener noreferrer"
									icon={<DownloadOutlined />}
									onClick={handleDownloadRSSFeed}
								>
									Download RSS Feed
								</PodpalButton>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<SectionHeader>Step 2: Setup New Hosting</SectionHeader>
						<Row gutter={[0, 16]}>
							<Col span={22} offset={1}>
								<h2>Import Your Podcast to a New Host</h2>
								<p className="rss-redirect-text">
									Prior to requesting a 301 Redirect, you must set up your new RSS feed hosting service. This is required before
									we can process a 301 Redirect request. Follow your new host's 'Import Podcast' instructions. You will likely
									need your Podpal RSS Feed URL for this step:
								</p>
							</Col>
							<Col span={22} offset={1}>
								<Input
									value={rssUrl}
									disabled
									size="large"
									addonAfter={
										<PodpalButton
											className="rss-url-copy-button"
											type="primary"
											size="large"
											onClick={() => {
												navigator.clipboard
													.writeText(rssUrl)
													.then(() => {
														successAlert("Copied to clipboard.")
													})
													.catch(err => {
														errorAlert("Failed to copy to clipboard.")
													})
											}}
										>
											Copy
										</PodpalButton>
									}
								/>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<SectionHeader>Step 3: Redirect Your Feed</SectionHeader>
						<StyledForm form={form} layout="vertical">
							<Row gutter={[0, 16]}>
								<Col span={14} offset={1}>
									<Form.Item
										name={FIELD_NAMES.OLD_HOST}
										label="New Host Name"
										required
										rules={[
											{
												required: true,
												message: "Please select your new hosting provider."
											}
										]}
									>
										<Select placeholder="Where are you moving your feed?" size="large">
											{HOSTING_PROVIDER_OPTIONS}
										</Select>
									</Form.Item>
								</Col>
								<Col span={14} offset={1}>
									<Form.Item
										name={FIELD_NAMES.RSS_FEED_URL}
										label="New RSS Feed URL"
										validateFirst
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: "Please provide a valid RSS Feed URL."
											},
											{
												type: "url",
												message: "Please provide a valid RSS Feed URL."
											}
										]}
									>
										<Input name={FIELD_NAMES.RSS_FEED_URL} placeholder="Paste new RSS Feed URL here." size="large" />
									</Form.Item>
								</Col>
								<Col span={22} offset={1}>
									<h2>Validate Your New RSS Feed</h2>
									<p className="rss-redirect-text">
										We recommend you run your new RSS Feed through the{" "}
										<a href="https://podba.se/validate/" target="_blank" rel="noopener noreferrer">
											Podbase Podcast Validator
										</a>
										. This will let you know if there are any problems with your new feed before you implement the redirect.
									</p>
								</Col>
								<Col span={22} offset={1}>
									{checklist.map((item, index) => (
										<Form.Item
											key={index}
											name={`checklist-${index + 1}`}
											valuePropName="checked"
											rules={[
												{
													required: true,
													validateTrigger: "onSubmit",
													validator: async (rule, value) => {
														// check if ALL the items in `checklist` are checked
														for (let i = 0; i < checklist.length; i++) {
															if (!form.getFieldValue(`checklist-${i + 1}`)) {
																return Promise.reject("Please accept the 301 Redirect terms.")
															}
														}
														return Promise.resolve()
													}
												}
											]}
										>
											<Checkbox>
												<p className="rss-redirect-text">{item}</p>
											</Checkbox>
										</Form.Item>
									))}
								</Col>
								<Col span={22} offset={1}>
									<PodpalButton variant="outlined" size="large" danger onClick={handleOk}>
										Request a 301 Redirect
									</PodpalButton>
								</Col>
							</Row>
						</StyledForm>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default Redirect
