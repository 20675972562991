import { CHANGE_AUTH_USER, APP_LOADING, SET_SELECTED_PODCAST } from "constants/actionTypes"
import { keysToCamel } from "utils/camelCaseUtils"

const initialState = {
	authUser: null,
	selectedPodcast: null,
	loading: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case CHANGE_AUTH_USER:
			return {
				...state,
				authUser: action.payload.authUser
			}

		case APP_LOADING:
			return {
				...state,
				loading: action.payload.loading
			}

		case SET_SELECTED_PODCAST:
			let camelCasePodcast = keysToCamel(action.payload.podcast)
			return {
				...state,
				selectedPodcast: camelCasePodcast
			}

		default:
			return state
	}
}
