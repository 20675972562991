/**
 * This file contains the account/podcast level limits for each plan that Podpal offers.
 * -1 = Unlimited.
 */
import { SubscriptionType } from "services/types/userInfo"

export interface PlanLimits {
	podcasts: number
	episodes: number
	collaborators: number
	recordingStorageLimit: number
	downloadsPerMonth: number
}

export const FREE_TIER_LIMITS: PlanLimits = {
	podcasts: 1,
	episodes: 6,
	collaborators: 3,
	recordingStorageLimit: 5, // in GB
	downloadsPerMonth: 5000
}

export const STARTER_TIER_LIMITS: PlanLimits = {
	podcasts: 1,
	episodes: 75,
	collaborators: 3,
	recordingStorageLimit: 10, // in GB
	downloadsPerMonth: 10000
}

export const PRO_TIER_LIMITS: PlanLimits = {
	podcasts: 3,
	episodes: -1,
	collaborators: 5,
	recordingStorageLimit: 50, // in GB
	downloadsPerMonth: 50000
}

export const AGENCY_TIER_LIMITS: PlanLimits = {
	podcasts: 10,
	episodes: -1,
	collaborators: 10,
	recordingStorageLimit: 100, // in GB
	downloadsPerMonth: 150000
}

export const BETA_TIER_LIMITS: PlanLimits = {
	podcasts: 3,
	episodes: -1,
	collaborators: 5,
	recordingStorageLimit: 50, // in GB
	downloadsPerMonth: 50000
}

export function mapSubscriptionTypeToPodcastLimit(subscriptionType: SubscriptionType): PlanLimits {
	switch (subscriptionType) {
		case SubscriptionType.starter:
			return STARTER_TIER_LIMITS
		case SubscriptionType.pro:
			return PRO_TIER_LIMITS
		case SubscriptionType.agency:
			return AGENCY_TIER_LIMITS
		case SubscriptionType.beta:
			return BETA_TIER_LIMITS
		default:
			return FREE_TIER_LIMITS
	}
}
