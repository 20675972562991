import { NOT_FOUND, PAL } from "constants/routes"
import React from "react"
import { Route, Switch, Redirect } from "react-router"
import { PalProfilePage } from "./PalProfilePage"

export function PalPage() {
	return (
		<>
			<Switch>
				<Route path={`${PAL}/:profileUrl`} component={PalProfilePage} />
				<Redirect to={NOT_FOUND} />
			</Switch>
		</>
	)
}
