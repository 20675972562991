import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"

export const isReadyToPublish = episodeProgress => {
	const { setup, inviteGuests, scheduleInterview, uploadAudio, writeShowNotes, reviewEpisode } = episodeProgress

	const canPublishEpisode =
		setup === STATUS.COMPLETED &&
		(inviteGuests === STATUS.COMPLETED || inviteGuests === STATUS.SKIPPED) &&
		(scheduleInterview === STATUS.COMPLETED || scheduleInterview === STATUS.SKIPPED) &&
		uploadAudio === STATUS.COMPLETED &&
		writeShowNotes === STATUS.COMPLETED &&
		(reviewEpisode === STATUS.COMPLETED || reviewEpisode === STATUS.SKIPPED)

	return canPublishEpisode
}
