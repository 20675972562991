import { RootState } from "index"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PodpalModal from "../../PodpalModal"
import styled from "styled-components"
import { Checkbox, Col, Row } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { toggleManageRolesModalVisible } from "redux/actions/TeamManagement"
import { usePodcastInfo } from "services/queries/PodcastInfo"
import { updatePodcastInfo } from "services/podcastInfo.service"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { useQueryClient } from "react-query"

const StyledSecondaryText = styled.span`
	font: ${({ theme }) => theme.fontSm};
	color: ${({ theme }) => theme.textSecondary};
`

const StyledCheckboxGroup = styled(Checkbox.Group)`
	display: flex;
	flex-direction: column;

	.ant-checkbox-wrapper:not(:last-child) {
		margin-bottom: ${({ theme }) => theme.spaceSm};
	}
`

export function ManageRolesModal() {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	const visible = useSelector((state: RootState) => state.teamManagement.manageRolesModalVisible)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const ROLES_OPTIONS = ["Host", "Co-Host", "Producer", "Audio Engineer", "Copywriter", "Marketing Manager"]

	const [selectedRoles, setSelectedRoles] = useState<CheckboxValueType[]>([])

	const { data } = usePodcastInfo(selectedPodcast?.id)

	useEffect(() => {
		if (data?.podcastInfo.teamRoles) {
			setSelectedRoles(data.podcastInfo.teamRoles)
		}
	}, [data])

	const handleClose = () => {
		dispatch(toggleManageRolesModalVisible())
	}

	const handleSave = async () => {
		const teamRoles: string[] = selectedRoles.map((role: CheckboxValueType) => role.toLocaleString() || "")
		try {
			await updatePodcastInfo({
				...data?.podcastInfo,
				teamRoles: teamRoles
			})
			queryClient.invalidateQueries(["podcastInfo", selectedPodcast?.id])
			handleClose()
			successAlert("Succesfully saved team roles.")
		} catch (e) {
			errorAlert("Failed to save team roles.")
		}
	}

	return (
		<PodpalModal
			centered
			title="Manage Team Roles"
			width={480}
			visible={visible}
			footer={[
				<PodpalButton variant="secondary" onClick={handleClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton type="primary" onClick={handleSave}>
					OK
				</PodpalButton>
			]}
			onCancel={handleClose}
		>
			<Row gutter={[0, 12]}>
				<Col>
					<StyledSecondaryText>
						Podcast teams have different types of collaborators. Choose the ones that apply to your show. We’ll only display
						these options in your dropdown menu.
					</StyledSecondaryText>
				</Col>
			</Row>
			<Row>
				<Col>
					<StyledCheckboxGroup
						options={ROLES_OPTIONS}
						defaultValue={selectedRoles}
						onChange={checkedValues => setSelectedRoles(checkedValues)}
					/>
				</Col>
			</Row>
		</PodpalModal>
	)
}
