import React from "react"
import { Modal, Row, Col } from "antd"
import { WarningQuestionMarkIcon, DangerCrossIcon } from "assets/icons/Episodes"
import type from "styles/scss/type.scss"
import colors from "styles/scss/color.scss"
import guestInviteImage from "assets/episodes/guestInvite.svg"
import PodpalButton from "components/common/Buttons/PodpalButton"

const { confirm } = Modal

export const importFailedModal = () => {
	Modal.error({
		title: "Import Failed!",
		centered: true,
		content:
			"Sorry, we ran into an error with your RSS Feed. Please check your URL and try again! If this problem persist please contact support at" +
			<a href="mailto:helpdesk@podpal.com">helpdesk@podpal.com</a>
	})
}

export const duplicateFilenameModal = (handleKeepBothRecordings, handleReplaceRecording) => {
	confirm({
		title: "Oops...This filename already exists!",
		centered: true,
		icon: <WarningQuestionMarkIcon />,
		content: (
			<Row>
				<Col>Would you like to replace the file or keep both?</Col>
			</Row>
		),
		cancelText: "Keep Both",
		onCancel: close => {
			close()
			handleKeepBothRecordings()
		},
		okText: "Replace",
		onOk: close => {
			close()
			handleReplaceRecording()
		},
		width: 420
	})
}

export function renameFileModal(isSpecialChars = false) {
	Modal.error({
		title: "Please rename your file and try again!",
		centered: true,
		icon: <DangerCrossIcon />,
		content: (
			<>
				<Row gutter={[0, 8]}>
					<Col>
						{isSpecialChars
							? "This filename includes special characters."
							: "This filename is currently being used for another one of your episodes."}
					</Col>
				</Row>
				<Row>
					<Col>
						{isSpecialChars
							? "RSS Feed requirements state that recording filenames cannot include special characters."
							: "RSS Feed requirements state that the filename of each episode recording must be unique."}
					</Col>
				</Row>
			</>
		),
		width: 420
	})
}

export function confirmPublishNowModal(handlePublishNow) {
	confirm({
		title: "Are you sure you want to publish now?",
		centered: true,
		icon: <WarningQuestionMarkIcon />,
		content: (
			<>
				<Row>
					<Col>When you click "Confirm" below, this episode will be published to your RSS feed immediately.</Col>
				</Row>
			</>
		),
		cancelText: "Cancel",
		okText: "Confirm",
		onOk: handlePublishNow
	})
}

export function confirmRepublishModal(handleRepublish) {
	confirm({
		title: "Are you sure you want to republish?",
		centered: true,
		icon: <WarningQuestionMarkIcon />,
		content: (
			<>
				<Row gutter={[0, 8]}>
					<Col>
						This episode was previously published. Republishing will update your RSS Feed, and as a result, update this
						episode in all registered podcast streaming platforms.
					</Col>
				</Row>
				<Row>
					<Col>
						Furthermore, any modifications to the Episode Number, Season Number, or Last Published Date, may impact the
						ordering of your episodes.
					</Col>
				</Row>
			</>
		),
		cancelText: "Cancel",
		okText: "Republish",
		onOk: handleRepublish
	})
}

const headerStyle = {
	font: type.fontLg,
	color: colors.textPrimary
}

const textStyle = {
	font: type.fontMd,
	color: colors.gray7,
	fontWeight: 400
}

const colStyle = {
	textAlign: "center"
}

export const getOnTheShowComingSoonModal = podcastName => {
	confirm({
		centered: true,
		icon: null,
		maskClosable: true,
		content: (
			<>
				<Row justify="center" align="middle" gutter={[24, 24]}>
					<Col span={24} style={colStyle}>
						<span style={headerStyle}>{`Interested in being a guest on ${podcastName}?`}</span>
					</Col>
					<Col span={24} style={colStyle}>
						<img src={guestInviteImage} alt="invite-guest" />
					</Col>
					<Col span={18} style={colStyle}>
						<span style={textStyle}>
							{`Stick around! Soon you’ll be able to notify ${podcastName} of your interest in joining the show, right from Podpal.`}
						</span>
					</Col>
				</Row>
				<Row justify="center" align="middle">
					<Col span={24} style={colStyle}>
						<PodpalButton variant="comingSoon" size="large">
							Coming Soon!
						</PodpalButton>
					</Col>
				</Row>
			</>
		),
		okButtonProps: {
			style: {
				display: "none"
			}
		},
		cancelButtonProps: {
			style: {
				display: "none"
			}
		},
		width: 480
	})
}

export const interviewMeComingSoonModal = name => {
	confirm({
		centered: true,
		icon: null,
		maskClosable: true,
		content: (
			<>
				<Row justify="center" align="middle" gutter={[24, 24]}>
					<Col span={24} style={colStyle}>
						<span style={headerStyle}>{`Interested in interviewing ${name} on your podcast?`}</span>
					</Col>
					<Col span={24} style={colStyle}>
						<img src={guestInviteImage} alt="invite-guest" />
					</Col>
					<Col span={18} style={colStyle}>
						<span style={textStyle}>
							{`Stick around! Soon you’ll be able to send ${name} an interview request, right from Podpal.`}
						</span>
					</Col>
				</Row>
				<Row justify="center" align="middle">
					<Col span={24} style={colStyle}>
						<PodpalButton variant="comingSoon" size="large">
							Coming Soon!
						</PodpalButton>
					</Col>
				</Row>
			</>
		),
		okButtonProps: {
			style: {
				display: "none"
			}
		},
		cancelButtonProps: {
			style: {
				display: "none"
			}
		},
		width: 480
	})
}
