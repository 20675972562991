export enum CollaboratorType {
	host = "HOST",
	coHost = "CO_HOST",
	producer = "PRODUCER",
	audioEngineer = "AUDIO_ENGINEER",
	marketingManager = "MARKETING_MANAGER",
	copywriter = "COPYWRITER"
}

type CollaboratorTypeMap = Record<CollaboratorType, number>
export const CollaboratorTypeMap: CollaboratorTypeMap = {
	HOST: 0,
	CO_HOST: 1,
	PRODUCER: 2,
	AUDIO_ENGINEER: 3,
	MARKETING_MANAGER: 4,
	COPYWRITER: 5
}

export enum ShowAccess {
	owner = "OWNER",
	admin = "ADMIN",
	editor = "EDITOR"
}

type ShowAccessMap = Record<ShowAccess, number>
export const ShowAccessMap: ShowAccessMap = {
	OWNER: 0,
	ADMIN: 1,
	EDITOR: 2
}

export interface PodcastRole {
	id?: string
	userId?: string
	podcastId?: string
	collaboratorType?: CollaboratorType
	showAccess: ShowAccess
	createdAt?: string
	lastUpdatedAt?: string
	deletedAt?: string
	email?: string
	isActive?: boolean
}

export interface FindByUserIdResponse {
	requestId: string
	podcastRoles: PodcastRole[]
}

export interface FindByPodcastIdResponse {
	requestId: string
	podcastRoles: PodcastRole[]
}

export interface FindByPodcastAndUserIDRequest {
	podcastId: string
	userId: string
}
export interface FindByPodcastAndUserIDResponse {
	requestId: string
	podcastRole: PodcastRole
}

export interface ValidateAccessRequest {
	podcastId: string
	userId: string
}

export interface ValidateAccessResponse {
	requestId: string
	hasAccess?: boolean
}
