import React from "react"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import useToggle from "hooks/useToggle"
import { Row, Col, Form, Input, Modal } from "antd"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { redirectPodcastError, redirectPodcast, redirectPodcastSuccess } from "redux/actions/Podcasts"
import PodpalModal from "components/common/Modals/PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { RootState } from "index"
import { toggle301RedirectOutModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { INCORRECT_PODCAST_NAME_301_REDIRECT } from "constants/strings/UserSettings/MyAccount/Form"
import { CheckCircleOutlined } from "@ant-design/icons"
import colors from "styles/scss/color.scss"
import { DangerCrossIcon } from "assets/icons/Episodes"
import { updateSelectedPodcastFromPodcastId } from "redux/actions/App"
import styled from "styled-components"

const WaitingMessage = styled.div`
	color: ${colors.podpalBlue};
`

const RedirectPodcastModal = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [form] = Form.useForm()

	const visible = useSelector((state: RootState) => state.addNewPodcast.redirect301OutModalVisible)
	const newRssFeedUrl = useSelector((state: RootState) => state.addNewPodcast.newRssFeedUrl)
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const TITLES = {
		CONFIRMATION: "Confirm 301 Redirect",
		FINAL_CONFIRMATION: "301 Redirect Complete!",
		ERROR: "Oops! Your 301 Redirect Failed"
	}

	const FIELD_NAMES = {
		CONFIRM_PASSWORD: "password",
		CONFIRM_PODCAST: "confirmPodcast",
		NEW_RSS_FEED_URL: "newRssFeedUrl"
	}

	const [loading, setLoading] = useToggle(false)

	const toggleLoading = () => {
		if (typeof setLoading === "function") {
			setLoading()
		}
	}

	const onConfirmPodcastOk = async () => {
		toggleLoading()
		form.validateFields()

		if (form.getFieldValue(FIELD_NAMES.CONFIRM_PODCAST) === selectedPodcast.title) {
			try {
				await dispatch(redirectPodcast(selectedPodcast.id, authUser.uid, newRssFeedUrl))

				dispatch(toggle301RedirectOutModalVisible())

				toggleLoading()
				Modal.success({
					centered: true,
					title: TITLES.FINAL_CONFIRMATION,
					icon: <CheckCircleOutlined style={{ color: colors.podpalGreenLight }} />,
					content: `You have successfully completed your 301 Redirect Request. Your Podpal RSS Feed is now redirecting to your new
					host. Please allow up to 7 business days for your new host to process this request.`,
					onOk: onFinalOk
				})
			} catch (e: unknown) {
				toggleLoading()
				dispatch(redirectPodcastError())
				Modal.error({
					title: TITLES.ERROR,
					centered: true,
					icon: <DangerCrossIcon />,
					content:
						"We're sorry but an unknown failure occurred. Please try again. If this request fails repeatedly, please contact support."
				})
			}
		}
	}

	const onCancel = () => {
		dispatch(toggle301RedirectOutModalVisible())
	}

	const onFinalOk = () => {
		dispatch(updateSelectedPodcastFromPodcastId(selectedPodcast.id))
		history.push(`/settings/podcast/${selectedPodcast.id}/rss-and-distribution`)
		dispatch(redirectPodcastSuccess(selectedPodcast.id))
		successAlert("Your 301 Redirect was successfully completed!")
	}

	return (
		<PodpalModal
			centered
			maskClosable={false}
			destroyOnClose
			title={TITLES.CONFIRMATION}
			visible={visible}
			footer={[
				<PodpalButton key="cancel" variant="secondary" onClick={onCancel}>
					Cancel
				</PodpalButton>,
				<PodpalButton
					key="delete"
					className="delete-podcast-modal-ok-button"
					loading={loading}
					danger
					onClick={onConfirmPodcastOk}
				>
					Redirect Podcast
				</PodpalButton>
			]}
			width={420}
			onCancel={onCancel}
		>
			<div>
				<Row gutter={[16, 16]}>
					<Col>
						<p>
							Enter the name of your podcast in the field below to confirm your 301 redirect request and officially move your
							podcast.
						</p>
					</Col>
					<Col span={24}>
						<Form form={form} onFinish={onConfirmPodcastOk}>
							<Form.Item
								name={FIELD_NAMES.CONFIRM_PODCAST}
								required
								validateTrigger="onBlur"
								rules={[
									{
										required: true,
										message: INCORRECT_PODCAST_NAME_301_REDIRECT,
										validator: async (rule, value) => {
											if (value !== selectedPodcast.title) {
												return Promise.reject(INCORRECT_PODCAST_NAME_301_REDIRECT)
											}
											return Promise.resolve()
										}
									}
								]}
							>
								<Input name={FIELD_NAMES.CONFIRM_PODCAST} placeholder="Name of Podcast" />
							</Form.Item>
						</Form>
						{loading && <WaitingMessage>Redirecting podcast. Please do not close this window.</WaitingMessage>}
					</Col>
				</Row>
			</div>
		</PodpalModal>
	)
}

export default RedirectPodcastModal
