export const SEQUENCE_NAMES = {
	CONFIRM_PASSWORD: "Confirm Password",
	SECURITY_QUESTION: "Security Question",
	PHONE_NUMBER: "Phone Number for SMS Verification",
	CHANGE_PHONE_NUMBER: "Change Phone Number",
	VERIFICATION_CODE: "Enter Verification Code",
	REMOVE_TWOFA: "Remove Two-Factor Authentication",
	CONFIRMATION: "Account Security",
	CHANGE_EMAIL: "Change Email",
	UPDATE_EMAIL: "Update Email"
}
