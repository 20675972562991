import React, { ReactNode, useState } from "react"
import { Avatar, Col, Row, Select, Table, Form, Popconfirm, Dropdown, Menu, Tooltip } from "antd"
import { useSelector } from "react-redux"
import { RootState } from "index"
import { UserWithPodcastRole } from "services/types/user"
import styled from "styled-components"
import { CollaboratorType, ShowAccess } from "services/types/podcastRoles"
import { SelectValue } from "antd/lib/select"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { usePodcastInfo } from "services/queries/PodcastInfo"
import { updatePodcastRole } from "services/podcast-role.service"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { cannotRemoveOwnerModal } from "components/common/Modals/TeamManagement/TeamManagementModals"
import { useQueryClient } from "react-query"
import { MoreIcon } from "assets/icons/Common/SimpleFeedback"
import { usePodcastTeam } from "services/queries/Podcasts"
import { UserIcon } from "assets/icons/Common/User"
import { ManagementTooltipIcon } from "assets/icons/Episodes"
import { usePodcastRole } from "services/queries/PodcastRole"
import { removeMember } from "services/teamManagement.service"

const StyledSecondaryText = styled.span`
	font: ${({ theme }) => theme.fontSm};
	color: ${({ theme }) => theme.textSecondary};
`

const StyledPodpalText = styled.span`
	font: ${({ theme }) => theme.fontXs};
	color: ${({ theme }) => theme.podpalBlue};
`

const StyledTable = styled(Table)`
	.ant-form-item {
		max-width: 100%;
		margin-bottom: 0;
	}
`

interface Item {
	key: string
	name: string
	age: string
	address: string
}

interface EditableCellProps {
	title: string
	children: ReactNode
	editable: boolean
	record: UserWithPodcastRole
	fieldName: string
	options: any // TODO: Find the OptionsType
	handleSave: (record: Item) => void
}

const renderTeamRole = (teamRole: string | undefined): string => {
	switch (teamRole) {
		case CollaboratorType.coHost:
			return "Co-Host"
		case CollaboratorType.producer:
			return "Producer"
		case CollaboratorType.marketingManager:
			return "Marketing Manager"
		case CollaboratorType.audioEngineer:
			return "Audio Engineer"
		case CollaboratorType.copywriter:
			return "Copywriter"
		default:
			return "Host"
	}
}

const renderProjectAccess = (projectAccess: string | undefined): string => {
	switch (projectAccess) {
		case ShowAccess.admin:
			return "Admin"
		case ShowAccess.editor:
			return "Editor"
		default:
			return "Owner"
	}
}

function EditableCell({ title, children, editable, record, fieldName, options, ...restProps }: EditableCellProps) {
	const queryClient = useQueryClient()

	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const [editing, setEditing] = useState(false)
	const [value, setValue] = useState<string | undefined>(
		fieldName === "collaboratorType"
			? renderTeamRole(record?.podcastRole?.collaboratorType)
			: renderProjectAccess(record?.podcastRole?.showAccess)
	)

	const toggleEdit = () => {
		// Disallow direct show access edits for the owner.
		if (fieldName === "showAccess" && value === "Owner") {
			setEditing(false)
		} else {
			setEditing(!editing)
		}
	}

	const mapFieldValue = (fieldName: string, fieldValue: string | undefined): number => {
		if (fieldName === "collaboratorType") {
			switch (fieldValue) {
				case "Host":
					return 0
				case "Co-Host":
					return 1
				case "Producer":
					return 2
				case "Audio Engineer":
					return 3
				case "Marketing Manager":
					return 4
				case "Copywriter":
					return 5
				default:
					return -1
			}
		} else if (fieldName === "showAccess") {
			switch (fieldValue) {
				case "Owner":
					return 0
				case "Admin":
					return 1
				case "Editor":
					return 2
			}
		}
		return -1
	}

	const save = async () => {
		if (fieldName === "showAccess" && mapFieldValue(fieldName, value) === 0) {
			errorAlert("You can't remove the owner of the podcast.")
			return
		}
		try {
			const fieldValue = mapFieldValue(fieldName, value)
			if (fieldValue === -1) {
				throw new Error("Invalid field value")
			}
			await updatePodcastRole({
				...record.podcastRole,
				[fieldName]: fieldValue
			})
			queryClient.invalidateQueries(["podcastTeam", selectedPodcast?.id])
			successAlert("Changes Saved.")
			toggleEdit()
		} catch (errInfo) {
			errorAlert("Failed to save changes.")
		}
	}

	let childNode = children
	if (editable) {
		childNode = editing ? (
			<Form.Item>
				<Select
					defaultValue={value}
					options={options}
					onBlur={save}
					onChange={(value: SelectValue) => setValue(value!.toString())}
				/>
			</Form.Item>
		) : (
			<div className="test-class" onClick={toggleEdit}>
				{children}
			</div>
		)
	}

	return <td {...restProps}>{childNode}</td>
}

interface DataType {
	key: React.Key
	name: string
	age: string
	address: string
}

export function TeamManagementTable() {
	const queryClient = useQueryClient()

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const { isLoading, error, data } = usePodcastTeam(selectedPodcast?.id)
	const { data: podcastInfoData } = usePodcastInfo(selectedPodcast?.id)

	const { data: podcastRoleData } = usePodcastRole({ podcastId: selectedPodcast?.id, userId: authUser.uid })

	if (isLoading) {
		return <h4>Loading...</h4>
	} else if (error) {
		return <h4>Error loading data.</h4>
	}

	const isEditor = podcastRoleData?.podcastRole.showAccess === ShowAccess.editor

	const TEAM_ROLE_OPTIONS = podcastInfoData?.podcastInfo.teamRoles
		? podcastInfoData.podcastInfo.teamRoles.map(role => ({
				label: role,
				value: role
		  }))
		: [
				{ label: "Host", value: "Host" },
				{ label: "Co-Host", value: "Co-Host" },
				{ label: "Producer", value: "Producer" },
				{ label: "Audio Engineer", value: "Audio Engineer" },
				{ label: "Copywriter", value: "Copywriter" },
				{ label: "Marketing Manager", value: "Marketing Manager" }
		  ]

	const SHOW_ACCESS_OPTIONS = [
		// { label: "Owner", value: "Owner" },
		{ label: "Admin", value: "Admin" },
		{ label: "Editor", value: "Editor" }
	]

	let columns = [
		{
			title: "Name",
			render: (record: UserWithPodcastRole) => (
				<Row gutter={16} align="middle">
					<Col>{record.user.thumbnailUrl ? <Avatar src={record.user.thumbnailUrl} /> : <Avatar icon={<UserIcon />} />}</Col>
					<Col>
						<Row>
							<StyledSecondaryText>{`${record.user.firstName ?? ""} ${record.user.lastName ?? ""}`}</StyledSecondaryText>
						</Row>
						{!record.podcastRole.isActive && (
							<Row>
								<StyledPodpalText>Invite Pending</StyledPodpalText>
							</Row>
						)}
					</Col>
				</Row>
			),
			width: "25%"
		},
		{
			title: "Email",
			render: (record: UserWithPodcastRole) => <StyledSecondaryText>{record.podcastRole.email}</StyledSecondaryText>,
			width: "25%"
		},
		{
			title: "Team Role",
			render: (record: UserWithPodcastRole) => (
				<StyledSecondaryText>{renderTeamRole(record.podcastRole.collaboratorType)}</StyledSecondaryText>
			),
			editable: !isEditor,
			options: TEAM_ROLE_OPTIONS,
			fieldName: "collaboratorType",
			width: "20%"
		},
		{
			title: (
				<Row align="middle" gutter={4}>
					<Col>Project Access</Col>
					<Tooltip
						placement="bottomRight"
						title={
							<>
								<strong>Editor</strong>
								<ul>
									<li>Update podcast settings</li>
									<li>View, edit, and publish episodes</li>
									<li>Delete episodes</li>
									<li>Manage recordings</li>
									<li>Manage calendar</li>
									<li>View analytics</li>
								</ul>
								<strong>Admin</strong>
								<ul>
									<li>All Editor privileges</li>
									<li>Add/remove team members</li>
								</ul>
								<strong>Owner</strong>
								<ul>
									<li>All Admin privileges</li>
									<li>Manage billing & payment info</li>
									<li>Delete this podcast</li>
									<li>Transfer ownership of this podcast</li>
								</ul>
							</>
						}
					>
						<ManagementTooltipIcon />
					</Tooltip>
				</Row>
			),
			render: (record: UserWithPodcastRole) => (
				<StyledSecondaryText>{renderProjectAccess(record.podcastRole.showAccess)}</StyledSecondaryText>
			),
			editable: !isEditor,
			options: SHOW_ACCESS_OPTIONS,
			fieldName: "showAccess",
			width: "20%"
		},
		{
			title: "Action",
			render: (record: UserWithPodcastRole) => (
				<Dropdown
					overlay={
						<Menu>
							{!podcastRoleData?.podcastRole.showAccess ? (
								<Menu.Item key="remove" danger>
									<Popconfirm
										placement="bottom"
										title="Are you sure you want to remove this team member?"
										cancelText="No"
										okText="Yes"
										onConfirm={async () => {
											if (!record.podcastRole.showAccess) {
												// Default show access is owner.
												cannotRemoveOwnerModal()
												return
											}

											try {
												await removeMember(record.podcastRole.id)
												queryClient.invalidateQueries(["podcastTeam", selectedPodcast?.id])
												queryClient.invalidateQueries(["podcastInfo", selectedPodcast?.id])
												successAlert("Successfully removed team member.")
											} catch (e) {
												errorAlert("Failed to remove team member.")
											}
										}}
									>
										Remove
									</Popconfirm>
								</Menu.Item>
							) : (
								<Menu.Item key="remove" disabled>
									Remove
								</Menu.Item>
							)}
						</Menu>
					}
					trigger={["click"]}
				>
					<MoreIcon />
				</Dropdown>
			),
			width: "10%"
		}
	]

	columns = columns.map(column => ({
		...column,
		onCell: (record: DataType) => ({
			record,
			editable: column.editable,
			options: column.options,
			title: column.title,
			fieldName: column.fieldName
		})
	}))

	const components = {
		body: {
			cell: EditableCell
		}
	}

	return <StyledTable components={components} columns={columns} dataSource={data?.usersWithPodcastRoles} />
}
