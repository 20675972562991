import { useQuery } from "react-query"
import {
	FindByProfileURLPodcastResponse,
	FindByUserIDPodcastResponse,
	FindOwnedByUserIDPodcastResponse,
	FindPodcastResponse,
	FindPodcastWithInfoResponse,
	FindTeamResponse
} from "services/types/podcast"
import { keysToCamel } from "utils/camelCaseUtils"
import {
	findPodcast,
	findPodcastByProfileUrl,
	findPodcastWithInfo,
	findPodcastsByUserID,
	findOwnedPodcastsByUserID,
	parseRSS
} from "../podcast.service"
import { findPodcastTeam } from "../teamManagement.service"

export const usePodcast = (id: string | undefined) => {
	return useQuery<FindPodcastResponse>(
		["podcast", id],
		async () => {
			if (!id) return null
			const { data } = await findPodcast(id)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{
			enabled: !!id
		}
	)
}

export const usePodcastByProfileUrl = (profileUrl: string) => {
	return useQuery<FindByProfileURLPodcastResponse>(["podcast", profileUrl], async () => {
		if (!profileUrl) return null
		const { data } = await findPodcastByProfileUrl(profileUrl)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}

export const usePodcastWithInfo = (podcastID: string) => {
	return useQuery<FindPodcastWithInfoResponse>(
		["podcastWithInfo", podcastID],
		async () => {
			const { data } = await findPodcastWithInfo(podcastID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!podcastID }
	)
}

export const usePodcasts = (userId: string) => {
	return useQuery<FindByUserIDPodcastResponse>(["podcasts", userId], async () => {
		if (!userId) return null
		const { data } = await findPodcastsByUserID(userId)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}

export const useOwnedPodcasts = (userId: string) => {
	return useQuery<FindOwnedByUserIDPodcastResponse>(["ownedPodcasts", userId], async () => {
		if (!userId) return null
		const { data } = await findOwnedPodcastsByUserID(userId)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}

export const useRSSParser = (url: string) => {
	return useQuery(
		["parseRSS", url],
		async () => {
			if (!url) return null
			const { data, status } = await parseRSS(url)
			if (status !== 200) {
				throw new Error("Failed to parse RSS feed.")
			}
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!url, retry: false }
	)
}

export const usePodcastTeam = (podcastID: string) => {
	return useQuery<FindTeamResponse>(
		["podcastTeam", podcastID],
		async () => {
			const { data } = await findPodcastTeam(podcastID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!podcastID }
	)
}
