import { useQuery } from "react-query"
import { findEpisodeChecklistByEpisodeId } from "services/episodeChecklist.service"
import { keysToCamel } from "utils/camelCaseUtils"

export const useEpisodeChecklist = id => {
	return useQuery(["episodeChecklist", id], async () => {
		if (!id) return null
		const { data } = await findEpisodeChecklistByEpisodeId(id)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}
