import { TOGGLE_USER_SETTINGS_SIDEBAR_COLLAPSED } from "constants/actionTypes"

const initialState = {
	collapsed: false,
	selected: "1",
	selectedPodcast: ""
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_USER_SETTINGS_SIDEBAR_COLLAPSED:
			return { ...state, collapsed: !state.collapsed }

		default:
			return state
	}
}
