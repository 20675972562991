import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Col, Input, Row, Form, Divider } from "antd"
import { toggleForgotPasswordModal } from "redux/actions/SignIn"
import { toggleLoadingSpinnerOverlayVisible } from "redux/actions/GetStarted"
import {
	FIREBASE_ERROR_CODES,
	FIELD_NAMES,
	EMAIL_REQUIRED,
	EMAIL_INVALID,
	PASSWORD_REQUIRED,
	EMAIL_PASSWORD_INVALID
} from "constants/strings/Auth"
import { ForgotPasswordModal } from "components/common/Modals/User/ForgotPasswordModal"
import signInImage from "assets/SignIn_SignUp/signInImage.png"
import PodpalLogoBlack from "assets/Logo_Black.png"
import PodpalLogoWhite from "assets/logo.png"
import PodpalButton from "components/common/Buttons/PodpalButton"
import GoogleIcon from "assets/icons/Common/SocialMedia/Google-icon.png"
import FacebookIcon from "assets/icons/Common/SocialMedia/facebook-icon.png"
import { PodpalSocialButton } from "components/common/Buttons/PodpalSocialButton"
import { GET_STARTED, SIGN_IN_LOADING, MY_PODCASTS, SIGN_IN } from "constants/routes"
import { loginUser } from "services/firebase.service"
import { useHistory } from "react-router"
import { RootState } from "index"
import styled from "styled-components"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { emailVerifiedModal } from "components/common/Modals/User/UserModals"
import { useQueryParams } from "hooks/useQueryParams"
import { Link } from "react-router-dom"

const StyledRow = styled(Row)`
	width: 100%;
	height: 100vh;
	background: linear-gradient(90deg, ${({ theme }) => theme.neutral1} 50%, #ffffff 50%);
	align-items: center;
	@media screen and (max-width: ${({ theme }) => theme.lg}) {
		height: calc(100vh - 64px);
		background: #ffffff;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: ${({ theme }) => theme.xs}) {
		& {
			overflow-y: scroll;
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			/* Hide scrollbar for Chrome, Safari and Opera */
			::-webkit-scrollbar {
				display: none;
			}

			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
`
/*
@media screen and (max-width: 1199px)
.get-started-image, .sign-in-image {
max-width: 100%;
padding: 0 8px;
} 

*/

const FirstCol = styled(Col)`
	flex-direction: column;
	justify-content: center;
	height: auto;
	.sign-in-image {
		@media screen and (max-width: ${({ theme }) => theme.xl}) {
			max-width: 100%;
		}
		max-width: 662px;
		height: auto;
	}
	@media screen and (max-width: ${({ theme }) => theme.lg}) {
		background: ${({ theme }) => theme.neutral1};
		& {
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
	}
	@media screen and (max-width: ${({ theme }) => theme.xs}) {
		& {
			display: none;
		}
	}
`

const SecondCol = styled(Col)`
	padding: ${({ theme }) => theme.spaceLg};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	a:not(.ant-btn) {
		color: ${({ theme }) => theme.podpalBlue};
	}
	@media screen and (max-width: ${({ theme }) => theme.sm}) {
		& {
			padding-top: 48px;
		}
	}
`
const SignInFooterSpacer = styled(Col)`
	padding-left: ${({ theme }) => theme.spaceXs};
	padding-right: ${({ theme }) => theme.spaceXs};
	color: ${({ theme }) => theme.gray7};
	font: ${({ theme }) => theme.fontMd};
`

const TopBar = styled(Row)`
	width: 100%;
	height: 64px;
	padding: 0 ${({ theme }) => theme.spaceLg};
	position: absolute;
	top: 0;
	z-index: 1;
	@media screen and (max-width: ${({ theme }) => theme.lg}) {
		& {
			position: relative;
		}
	}
`

const MobileTopBar = styled(Row)`
	width: 100%;
	height: 64px;
	background: ${({ theme }) => theme.podpalBlue};
	padding: 0px 35px;
`

const PrimaryHeading = styled.span`
	font: ${({ theme }) => theme.fontLgPlus};
	color: ${({ theme }) => theme.textPrimary};
`

export function SignInPage() {
	// need to use require so that css is scoped to this component, since we are overriding the global body styles for scrollability
	require("./SignInPage.scss")
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const history = useHistory()
	const queryParams = useQueryParams()
	const screens = useBreakpoint()

	const authUser = useSelector((state: RootState) => state.app.authUser)

	useEffect(() => {
		if (queryParams.get("emailVerified") === "true") {
			emailVerifiedModal()
			history.push(SIGN_IN)
		}
	}, [history, queryParams])

	useEffect(() => {
		if (authUser) {
			// && authUser.emailVerified) {
			history.push(MY_PODCASTS)
		}
	}, [authUser, history])

	// make just sign in scrollable (for mobile), but leave the rest of the app not scrollable
	useEffect(() => {
		document.body.style.overflowY = "scroll"
		document.body.style.height = "100vh"
		return () => {
			document.body.style.overflowY = "hidden"
			document.body.style.height = "100%"
		}
	}, [])

	const handleOnFinish = async (values: any) => {
		dispatch(toggleLoadingSpinnerOverlayVisible())
		try {
			await loginUser({ email: values.email, password: values.password })
			dispatch(toggleLoadingSpinnerOverlayVisible())
		} catch (err: any) {
			if (err.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD || err.code === FIREBASE_ERROR_CODES.USER_NOT_FOUND) {
				form.setFields([
					{
						name: FIELD_NAMES.EMAIL,
						errors: [""] // Empty string denotes a validation status of "error" without an error message.
					},
					{
						name: FIELD_NAMES.PASSWORD,
						errors: [EMAIL_PASSWORD_INVALID]
					}
				])
			}
			dispatch(toggleLoadingSpinnerOverlayVisible())
		}
	}

	const handleOnFinishFailed = () => {}

	const handleLogin = (provider: string) => {
		history.push(SIGN_IN_LOADING, { provider, page: "signIn" })
	}

	return (
		<>
			{screens.xs ? (
				<MobileTopBar justify="space-between" align="middle">
					<Col>
						<a href="https://www.podpal.com/">
							<img src={PodpalLogoWhite} alt="podpal" className="logo" height={30} />
						</a>
					</Col>
					<Col>
						<PodpalButton variant="white" size="large" href={GET_STARTED}>
							Get Started
						</PodpalButton>
					</Col>
				</MobileTopBar>
			) : (
				<TopBar align="middle" justify="space-between">
					<Col>
						<a href="https://www.podpal.com/">
							<img src={PodpalLogoBlack} alt="podpal" className="logo" height={64} />
						</a>
					</Col>
					<Col>
						<Row gutter={16} align="middle">
							<Col>
								<p className="already-member-text">New to Podpal?</p>
							</Col>
							<Col>
								<PodpalButton variant="secondary" size="large" href={GET_STARTED}>
									Get Started
								</PodpalButton>
							</Col>
						</Row>
					</Col>
				</TopBar>
			)}
			<StyledRow>
				<FirstCol span={12} xs={{ order: 2, span: 24 }} xl={{ order: 1, span: 12 }}>
					<Row justify="center" align="middle" gutter={[{ lg: 40 }, { xl: 24 }]} style={{ padding: "40px" }}>
						<Col sm={12} xl={24} style={{ textAlign: "center" }}>
							<img className="sign-in-image" src={signInImage} alt="get-started" />
						</Col>
						<Col sm={12} xl={24} style={{ textAlign: "center" }} span={24}>
							<PrimaryHeading>
								Explore the world’s first All-in-One platform {screens.xl && <br />} for Podcast Management.
							</PrimaryHeading>
						</Col>
					</Row>
				</FirstCol>
				<SecondCol span={12} xs={{ order: 1, span: 24 }} xl={{ order: 2, span: 12 }}>
					<Row align="middle" justify="center" gutter={[0, 64]} style={{ marginTop: "0px" }}>
						<PrimaryHeading className="form-title">Sign In</PrimaryHeading>
					</Row>
					<Row align="middle" justify="center">
						<Col xs={24} sm={12} style={{ maxWidth: "352px" }}>
							<Form form={form} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed}>
								<Form.Item
									name={FIELD_NAMES.EMAIL}
									rules={[
										{
											required: true,
											message: EMAIL_REQUIRED
										},
										{
											type: "email",
											message: EMAIL_INVALID
										}
									]}
								>
									<Input size="large" name={FIELD_NAMES.EMAIL} placeholder="Email" />
								</Form.Item>
								<Form.Item
									name={FIELD_NAMES.PASSWORD}
									rules={[
										{
											required: true,
											message: PASSWORD_REQUIRED
										}
									]}
								>
									<Input.Password size="large" name={FIELD_NAMES.PASSWORD} placeholder="Password" />
								</Form.Item>

								<PodpalButton shape="round" fullwidth type="primary" htmlType="submit" isxl>
									Sign In
								</PodpalButton>
								<Divider plain>OR</Divider>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<PodpalSocialButton
											action={() => {
												handleLogin("google")
											}}
											icon={GoogleIcon}
										>
											Continue with Google
										</PodpalSocialButton>
									</Col>
									<Col span={24}>
										<PodpalSocialButton
											action={() => {
												handleLogin("facebook")
											}}
											icon={FacebookIcon}
										>
											Continue with Facebook
										</PodpalSocialButton>
									</Col>
								</Row>

								<Form.Item style={{ margin: "0px" }}>
									<Row justify="center" align="middle">
										<Col>
											<PodpalButton
												className="forgot-password"
												type="link"
												variant="primary"
												onClick={() => dispatch(toggleForgotPasswordModal())}
											>
												Forgot Password?
											</PodpalButton>
										</Col>
										<SignInFooterSpacer>•</SignInFooterSpacer>
										<Col>
											<Link to={GET_STARTED}>Create an Account</Link>
										</Col>
									</Row>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</SecondCol>
			</StyledRow>
			<ForgotPasswordModal />
		</>
	)
}
