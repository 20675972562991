import React from "react"
import Icon from "@ant-design/icons"

const SMALL_USER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
	</svg>
)

export const SmallUserIcon = props => <Icon component={SMALL_USER_SVG} {...props} />

const USER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
	</svg>
)

export const UserIcon = props => <Icon component={USER_SVG} {...props} />
