import axios from "axios"
import { errorAlert } from "components/common/Alerts/ErrorAlert"

export function useDownload() {
	interface HandleDownloadProps {
		url: string
		filename: string
	}
	interface HandleDownloadFromContentProps {
		content: string
		filename: string
	}

	const createLink = ({ content, filename }: HandleDownloadFromContentProps) => {
		const url = window.URL.createObjectURL(new Blob([content]))
		const link = document.createElement("a")
		link.href = url
		link.setAttribute("download", filename)
		document.body.appendChild(link)
		link.click()
	}

	const handleDownload = async ({ url, filename }: HandleDownloadProps) => {
		try {
			await axios({
				url,
				method: "GET",
				responseType: "blob"
			}).then(response => {
				createLink({ content: response.data, filename })
			})
		} catch (e) {
			errorAlert("Unable to download file.")
		}
	}

	const handleCreateDownloadFromContent = async ({ content, filename }: HandleDownloadFromContentProps) => {
		try {
			createLink({ content, filename })
		} catch (e) {
			errorAlert("Unable to download file.")
		}
	}

	return {
		handleDownload,
		handleCreateDownloadFromContent
	}
}
