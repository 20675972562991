import { useQuery } from "react-query"
import { findEpisodeProgressByEpisodeId } from "../episodeProgress.service"
import { keysToCamel } from "utils/camelCaseUtils"

export const useEpisodeProgress = id => {
	return useQuery(["episodeProgress", id], async () => {
		if (!id) return null
		const { data } = await findEpisodeProgressByEpisodeId(id)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}
