import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { CSSTransition } from "react-transition-group"
import { Link, useLocation } from "react-router-dom"
import { Avatar, Button, Menu, Divider } from "antd"
import { ArrowBackIcon } from "assets/icons/UserSettings/UserSettingsSidebar"
import { UserIcon } from "assets/icons/Common/User"
import { toggleImportPodcastModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import * as ROUTES from "constants/routes"
import * as STRINGS from "constants/strings/UserSettings"
import "./UserSettingsSidebar.scss"
import { useUser } from "services/queries/User"
import { RootState } from "index"
import { usePodcasts } from "services/queries/Podcasts"
import { Podcast } from "services/types/podcast"
import { useQueryClient } from "react-query"
import { setSelectedPodcast } from "redux/actions/App"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"

const { SubMenu } = Menu

function UserSettingsSidebarHeader({ thumbnailUrl, name }: { thumbnailUrl: string; name: string }) {
	return (
		<div className="user-settings-sidebar-header">
			{thumbnailUrl ? <Avatar size={58} src={thumbnailUrl} /> : <Avatar size={58} icon={<UserIcon />} />}
			<div className="user-settings-sidebar-header-content">
				<div className="user-settings-header-name">{name}</div>
				<div className="user-settings-header-settings-text">User Settings</div>
			</div>
		</div>
	)
}

export function UserSettingsSidebar() {
	const queryClient = useQueryClient()
	const dispatch = useDispatch()
	const location = useLocation()

	const { exceedsPodcastLimit } = useLimitChecker()

	const [selectedMenuItem, setSelectedMenuItem] = useState<string>("")
	const [selectedSubMenuItem, setSelectedSubMenuItem] = useState<string>("")

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const collapsed = useSelector((state: RootState) => state.userSettingsSidebar.collapsed)

	useEffect(() => {
		const currentRoute = location.pathname.split(ROUTES.SETTINGS)[1].split("/")[1]
		const currentPodcast = location.pathname.split(ROUTES.SETTINGS)[1].split("/")[2]
		setSelectedMenuItem(`/${currentRoute}`)
		setSelectedSubMenuItem(`${currentPodcast}`)
	}, [location])

	const { status: podcastStatus, data: podcastData } = usePodcasts(authUser.uid)
	const { status: userStatus, data: userData } = useUser(authUser.uid)

	if (userStatus === "error" || podcastStatus === "error") {
		return (
			<Menu className="user-settings-sidebar">
				<div>An error occurred</div>
			</Menu>
		)
	}

	if (userStatus === "loading" || podcastStatus === "loading") {
		return <Menu className={"user-settings-sidebar"}></Menu>
	}

	if (!userData || !podcastData) {
		return (
			<Menu className="user-settings-sidebar">
				<div>An error occurred</div>
			</Menu>
		)
	}

	const { thumbnailUrl, firstName, lastName } = userData?.user

	const name = (firstName ?? "") + " " + (lastName ?? "")

	const userSettingsSidebarFooter = (
		<div className="user-settings-sidebar-footer">
			<Link to="/">
				<Button className="back-to-podcast-button" shape="circle" icon={<ArrowBackIcon />} />
				<span className="back-to-podcast-text">back to podcast</span>
			</Link>
		</div>
	)

	const podcastLinks = podcastData?.podcasts?.map((podcast: Podcast) => (
		<Menu.Item key={podcast.id}>
			<Link
				to={`${ROUTES.SETTINGS}${ROUTES.PODCAST_SETTINGS}/${podcast.id}${ROUTES.SHOW_SETTINGS}`}
				onClick={() => {
					dispatch(setSelectedPodcast(podcast))
					queryClient.invalidateQueries(["podcast"])
				}}
			>
				{podcast.title}
			</Link>
		</Menu.Item>
	))

	if (podcastLinks) {
		podcastLinks.push(
			<Menu.Item key="startnew">
				<PodpalButton
					type="primary"
					onClick={() => {
						if (exceedsPodcastLimit()) {
							dispatch(toggleLimitsExceededModalVisible())
						} else {
							dispatch(toggleImportPodcastModalVisible())
						}
					}}
				>
					Start a New Podcast
				</PodpalButton>
			</Menu.Item>
		)
	}

	return (
		<CSSTransition in={collapsed} classNames="us-sidebar" timeout={0}>
			<Menu
				className={"user-settings-sidebar"}
				mode="inline"
				selectedKeys={[selectedMenuItem, selectedSubMenuItem]}
				defaultOpenKeys={[ROUTES.PODCAST_SETTINGS]}
			>
				<UserSettingsSidebarHeader thumbnailUrl={thumbnailUrl ?? ""} name={name} />
				<Divider className="user-settings-sidebar-header-divider" />
				<Menu.Item key={ROUTES.MY_ACCOUNT}>
					<Link to={`${ROUTES.SETTINGS}${ROUTES.MY_ACCOUNT}${ROUTES.PROFILE}`}>{STRINGS.MY_ACCOUNT}</Link>
				</Menu.Item>
				{podcastData?.podcasts?.length && podcastData?.podcasts?.length !== 0 ? (
					<SubMenu key={ROUTES.PODCAST_SETTINGS} title={STRINGS.PODCAST_SETTINGS} children={podcastLinks}></SubMenu>
				) : (
					<Menu.Item key={ROUTES.PODCAST_SETTINGS}>
						<Link to={`${ROUTES.SETTINGS}${ROUTES.PODCAST_SETTINGS}${ROUTES.NO_PODCASTS}`}>{STRINGS.PODCAST_SETTINGS}</Link>
					</Menu.Item>
				)}

				<Menu.Item key={ROUTES.SUBSCRIPTION_AND_BILLING}>
					<Link to={`${ROUTES.SETTINGS}${ROUTES.SUBSCRIPTION_AND_BILLING}${ROUTES.PLAN_USAGE}`}>
						{STRINGS.SUBSCRIPTION_AND_BILLING}
					</Link>
				</Menu.Item>
				{/* <Menu.Item key={ROUTES.NOTIFICATIONS}>
					<Link to={`${ROUTES.SETTINGS}${ROUTES.NOTIFICATIONS}`}>{STRINGS.NOTIFICATIONS}</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.TELL_YOUR_FRIENDS}>
					<Link to={`${ROUTES.SETTINGS}${ROUTES.TELL_YOUR_FRIENDS}`}>{STRINGS.TELL_YOUR_FRIENDS}</Link>
				</Menu.Item> */}
				{userSettingsSidebarFooter}
			</Menu>
		</CSSTransition>
	)
}
