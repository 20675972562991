import { Breadcrumb, Col, Row, Tag } from "antd"
import { GlobeIcon } from "assets/icons/Common/SocialMedia"
import { DEFAULT_PODCAST_ARTWORK_URL } from "constants/strings"
import React from "react"
import { useParams } from "react-router"
import { usePodcastByProfileUrl } from "services/queries/Podcasts"
import styled from "styled-components"
import { CurvedHeader } from "./CurvedHeader"
import { EpisodeList } from "./EpisodeList"
import { PodcastTitleCard } from "./PodcastTitleCard"
import { ProductionTeam } from "./ProductionTeam"

const PodPageLayout = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
	width: 100vw;
	padding-bottom: calc(${({ theme }) => theme.spaceLg} * 2);
	.main {
		margin-top: -275px;
		& > div {
			max-width: 650px;
		}

		@media only screen and (max-width: 700px) {
			& {
				margin-top: -400px;

				& > div {
					max-width: 550px;
				}
			}
		}

		@media only screen and (max-width: ${({ theme }) => theme.xs}) {
			& {
				margin-top: -500px;

				& > div {
					max-width: 90vw;
				}
			}
		}
	}
	.podcast-artwork {
		text-align: center;

		img {
			height: 250px;
			width: 250px;
			border-radius: ${({ theme }) => theme.spaceXs};
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
		}
	}

	.show-summary {
		font: ${({ theme }) => theme.fontMd};
		font-weight: 400;
	}

	h3 {
		font: ${({ theme }) => theme.fontLg};
		font-weight: 700;
	}

	.external-website {
		font: ${({ theme }) => theme.fontMd};
		font-weight: 400;
		color: ${({ theme }) => theme.neutral3};
	}

	&& .ant-breadcrumb {
		display: flex;
		flex-wrap: wrap;

		a {
			color: ${({ theme }) => theme.podpalBlue};
			&:hover {
				color: ${({ theme }) => theme.podpalBlueHoverDark};
			}
		}
		.ant-breadcrumb-separator {
			color: ${({ theme }) => theme.neutral2};
		}
	}
`

type PodcastPageUrlParams = { profileUrl: string }

export function PodcastPage() {
	const { profileUrl } = useParams<PodcastPageUrlParams>()
	const { isLoading, isError, data } = usePodcastByProfileUrl(profileUrl)

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (isError) {
		return <div>Error loading pod page.</div>
	}

	if (!data) {
		return <div>Error loading pod page.</div>
	}

	const {
		photoUrl,
		title,
		mediaFormat,
		showSummary,
		externalWebsite,
		categories,
		socialInstagram,
		socialFacebook,
		socialTwitter,
		socialLinkedin,
		socialYoutube,
		socialPatreon,
		socialCashapp,
		color,
		id,
		applePodcastsUrl,
		spotifyUrl,
		googlePodcastsUrl,
		amazonMusicUrl,
		audioBioUrl
	} = data?.podcast

	const podcastTitleProps = {
		title,
		audioBioUrl,
		mediaFormat,
		applePodcastsUrl,
		spotifyUrl,
		googlePodcastsUrl,
		amazonMusicUrl
	}

	const categoryTags = categories?.map(category => <Tag key={category}>{category}</Tag>)

	return (
		<PodPageLayout>
			<CurvedHeader color={color} />
			<Row justify="center" className="main">
				<Col xs={24} lg={18} xxl={12}>
					<Row justify="center" align="top" gutter={[{ xs: 0, sm: 32 }, 32]}>
						<Col>
							<div className="podcast-artwork">
								<img alt={title} src={photoUrl ?? DEFAULT_PODCAST_ARTWORK_URL} />
							</div>
						</Col>
						<Col flex="350px">
							<PodcastTitleCard {...podcastTitleProps} />
						</Col>
						<Col span={24}>
							<Row gutter={[0, 12]}>
								<Col span={24}>
									<h3>About the Show</h3>
								</Col>
								<Col span={24}>
									<Row justify="start" align="middle" gutter={[24, 8]}>
										{externalWebsite && (
											<Col>
												<GlobeIcon style={{ paddingRight: "8px" }} />
												<a className="external-website" href={externalWebsite} target="_blank" rel="noreferrer noopener">
													{externalWebsite}
												</a>
											</Col>
										)}
										<Col>{categoryTags}</Col>
									</Row>
								</Col>
								<Col span={24}>
									<div dangerouslySetInnerHTML={{ __html: showSummary }} />
								</Col>
								<Col span={24}>
									<Breadcrumb separator="|">
										{socialInstagram && (
											<Breadcrumb.Item href={`https://instagram.com/${socialInstagram}`}>Instagram</Breadcrumb.Item>
										)}
										{socialFacebook && (
											<Breadcrumb.Item href={`https://facebook.com/${socialFacebook}`}>Facebook</Breadcrumb.Item>
										)}
										{socialTwitter && <Breadcrumb.Item href={`https://twitter.com/${socialTwitter}`}>Twitter</Breadcrumb.Item>}
										{socialLinkedin && (
											<Breadcrumb.Item href={`https://linkedin.com/in/${socialLinkedin}`}>LinkedIn</Breadcrumb.Item>
										)}
										{socialYoutube && <Breadcrumb.Item href={socialYoutube}>YouTube</Breadcrumb.Item>}
										{socialPatreon && <Breadcrumb.Item href={`https://patreon.com/${socialPatreon}`}>Patreon</Breadcrumb.Item>}
										{socialCashapp && <Breadcrumb.Item href={`https://cash.app/${socialCashapp}`}>Cash App</Breadcrumb.Item>}
									</Breadcrumb>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<h3>Production Team</h3>
							<ProductionTeam podcastId={id} />
						</Col>
						<Col span={24}>
							<h3>Recent Episodes</h3>
							<EpisodeList podcastId={id} podcastTitle={title} profileUrl={profileUrl} />
						</Col>
					</Row>
				</Col>
			</Row>
		</PodPageLayout>
	)
}
