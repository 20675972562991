import { Col, Row } from "antd"
import React from "react"
import { usePublishedEpisodes } from "services/queries/Episodes"
import { RecentEpisodeCard } from "./RecentEpisodeCard"

interface EpisodeListProps {
	podcastId: string
	podcastTitle: string
	profileUrl: string
}

export function EpisodeList({ podcastId, podcastTitle, profileUrl }: EpisodeListProps) {
	const { isLoading, isError, data } = usePublishedEpisodes(podcastId)

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (isError) {
		return <div>Error loading recent episodes.</div>
	}

	if (!data) {
		return <div>Error loading recent episodes.</div>
	}

	const { episodesWithRecordings } = data
	const episodeCards = episodesWithRecordings?.map(({ episode, recording }) => (
		<Col key={episode.id}>
			<RecentEpisodeCard episode={episode} recording={recording} podcastTitle={podcastTitle} profileUrl={profileUrl} />
		</Col>
	))

	return <Row gutter={[0, 24]}>{episodeCards}</Row>
}
