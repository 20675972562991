export const APPLE_PODCASTS_STEPS = [
	"Sign in to iTunes Podcast Connect.",
	'Click the blue "+" icon in the top-left corner of the page.',
	"You'll be prompted to paste your Podpal RSS Feed URL (at the top of this page).",
	'Press "Validate" in iTunes Connect to confirm that details like show description, artwork, etc. are correct. If those details don\'t look right, you can update them here.',
	'If you\'re satisfied with how everything looks, click "Submit" in iTunes Connect.',
	"Once you've submitted to Apple, the review process can take anywhere from 1-10 days. You'll receive an email from Apple once it has been approved."
]

export const SPOTIFY_STEPS = [
	"Click “Get Started” on the Spotify podcast submission page, and log in.",
	"Read through Spotify’s Terms & Conditions and click “Continue.”",
	"Paste your Podpal RSS Feed URL (located at the top of this page).",
	"Verify that you own this podcast. Spotify will send a verification code to the email associated with this podcast. Enter the code and click “Next.”",
	"Confirm your show’s details and click “Submit.” The review process can take up to 24 hours.",
	"Once approved, enter your Spotify podcast URL below."
]

export const GOOGLE_PODCASTS_STEPS = [
	"Visit the Google Podcasts submission page.",
	"Log in with your Google account.",
	"Paste your Podpal RSS Feed URL (located at the top of the page).",
	"Claim your show on Google Podcast Manager.",
	"Preview your podcast feed to make sure Google has everything correct.",
	"Verify that you own this podcast. Google will send a verification code to the email associated with this podcast. Enter the code and click “Submit.”",
	"After submitting to Google, the review process can take 1-10 days.",
	"Once approved, enter your Google Podcasts URL below."
]

export const AMAZON_MUSIC_STEPS = [
	"Visit the Amazon Music submission page.",
	"Read through Amazon Music’s license agreement and click “Next.”",
	"Select “1” as the number of podcasts for submission.",
	"Enter the name of this show and paste the Podpal RSS Feed URL (located at the top of the page).",
	"Verify ownership of your podcast by providing a few details about yourself and the show.",
	"Amazon will send an email once your show is approved. It may take a few days.",
	"Once approved, enter your Amazon Music podcast URL below."
]

export const PANDORA_STEPS = [
	"Visit the Pandora podcast submission page.",
	"Click on the “Get Started” button.",
	"Log in with your Pandora account.",
	"You'll be prompted to paste your Podpal RSS Feed URL (located at the top of the page).",
	"Verify ownership of your podcast by providing a few details about yourself.",
	"Read through Pandora’s Terms and Conditions and click “Submit.”",
	"Pandora will send you an email once your show is approved. It may take four to six weeks.",
	"Once approved, enter your Pandora podcast URL below."
]

export const STITCHER_STEPS = [
	"Visit the Stitcher podcast submission page.",
	"Click on the “Join Us Today” button.",
	"You'll be prompted to paste your Podpal RSS Feed URL (located at the top of this page).",
	"Verify that you own this podcast. Stitcher will send an authorization to the email associated with this podcast. Click the link to return to Stitcher.",
	"Read through Stitcher’s Terms and Conditions and click “Agree.”",
	"Confirm your show’s details to make sure Stitcher reads your feed properly.",
	"Enter your Stitcher podcast URL below."
]

export const RADIO_PUBLIC_STEPS = [
	"Visit the RadioPublic podcast submission page.",
	"You'll be prompted to paste your Podpal RSS Feed URL (located at the top of this page). Click the “Link Podcast” button",
	"Verify that you own this podcast. RadioPublic will send an authorization to the email associated with this podcast. Click the link to return to RadioPublic.",
	"Read through RadioPublic’s Terms and Conditions and click “Agree.”",
	"Click the “View on Radio Public” link next to your podcast name. Copy the RadioPublic podcast URL in your browser’s address bar.",
	"Enter your RadioPublic podcast URL below."
]

export const IHEART_RADIO_STEPS = [
	"Visit the iHeartRadio podcast submission page.",
	"Click on the “Add Your Podcast” button.",
	"You'll be prompted to paste your Podpal RSS Feed URL (located at the top of this page).",
	"Verify that you own this podcast.",
	"Confirm your show’s details to make sure iHeartRadio reads your feed properly.",
	"Read through iHeartRadio’s Terms and Conditions and click “Submit Podcast.”",
	"Enter your iHeartRadio podcast URL below."
]
