import {
	LINK_GOOGLE_ACCOUNT_LOADING,
	LINK_GOOGLE_ACCOUNT_SUCCESS,
	LINK_GOOGLE_ACCOUNT_ERROR,
	LINK_FACEBOOK_ACCOUNT_LOADING,
	LINK_FACEBOOK_ACCOUNT_SUCCESS,
	LINK_FACEBOOK_ACCOUNT_ERROR,
	UNLINK_FACEBOOK_ACCOUNT_LOADING,
	UNLINK_FACEBOOK_ACCOUNT_SUCCESS,
	UNLINK_FACEBOOK_ACCOUNT_ERROR,
	UNLINK_GOOGLE_ACCOUNT_LOADING,
	UNLINK_GOOGLE_ACCOUNT_SUCCESS,
	UNLINK_GOOGLE_ACCOUNT_ERROR,
	SET_GOOGLE_AUTHENTICATION_ON_LOAD,
	SET_FACEBOOK_AUTHENTICATION_ON_LOAD
} from "constants/actionTypes"

import firebase from "auth/firebase"

export const linkFacebookAccount = () => async dispatch => {
	dispatch({
		type: LINK_FACEBOOK_ACCOUNT_LOADING
	})

	await firebase.auth.currentUser
		.linkWithRedirect(firebase.facebookAuthProvider)
		.then(response => {
			dispatch({
				type: LINK_FACEBOOK_ACCOUNT_SUCCESS
			})
		})
		.catch(error => {
			dispatch({
				type: LINK_FACEBOOK_ACCOUNT_ERROR,
				payload: error
			})
		})
}

export const linkGoogleAccount = () => async dispatch => {
	dispatch({
		type: LINK_GOOGLE_ACCOUNT_LOADING
	})

	await firebase.auth.currentUser
		.linkWithRedirect(firebase.googleAuthProvider)
		.then(response => {
			dispatch({
				type: LINK_GOOGLE_ACCOUNT_SUCCESS
			})
		})
		.catch(error => {
			dispatch({
				type: LINK_GOOGLE_ACCOUNT_ERROR,
				payload: error
			})
		})
}

export const unlinkFacebookAccount = () => async dispatch => {
	dispatch({
		type: UNLINK_FACEBOOK_ACCOUNT_LOADING
	})

	let providerId = firebase.facebookAuthProvider.providerId
	await firebase.auth.currentUser
		.unlink(providerId)
		.then(response => {
			dispatch({
				type: UNLINK_FACEBOOK_ACCOUNT_SUCCESS
			})
		})
		.catch(error => {
			dispatch({
				type: UNLINK_FACEBOOK_ACCOUNT_ERROR,
				payload: error
			})
		})
}

export const unlinkGoogleAccount = () => async dispatch => {
	dispatch({
		type: UNLINK_GOOGLE_ACCOUNT_LOADING
	})

	let providerId = firebase.googleAuthProvider.providerId

	await firebase.auth.currentUser
		.unlink(providerId)
		.then(response => {
			dispatch({
				type: UNLINK_GOOGLE_ACCOUNT_SUCCESS
			})
		})
		.catch(error => {
			dispatch({
				type: UNLINK_GOOGLE_ACCOUNT_ERROR,
				payload: error
			})
		})
}

export const setFacebookAuthenticationOnLoad = isAuthenticated => async dispatch => {
	dispatch({
		type: SET_FACEBOOK_AUTHENTICATION_ON_LOAD,
		payload: isAuthenticated
	})
}

export const setGoogleAuthenticationOnLoad = isAuthenticated => async dispatch => {
	dispatch({
		type: SET_GOOGLE_AUTHENTICATION_ON_LOAD,
		payload: isAuthenticated
	})
}
