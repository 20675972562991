import { mapSubscriptionTypeToPodcastLimit } from "constants/planLimits"
import { RootState } from "index"
import { useSelector } from "react-redux"
import { usePodcastInfo } from "services/queries/PodcastInfo"
import { useUserInfo } from "services/queries/UserInfo"
import { SubscriptionType } from "services/types/userInfo"

/**
 * This hook provides a suite of functions that, given a subscription type, checks whether or not a user has exceeded their limit for that particular metric.
 */
export function useLimitChecker() {
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const { data: userInfoData } = useUserInfo(authUser?.uid)
	const { data: podcastInfoData } = usePodcastInfo(selectedPodcast?.id)

	let subscriptionType = SubscriptionType.free

	if (podcastInfoData) {
		subscriptionType = podcastInfoData.podcastInfo.subscriptionType
			? podcastInfoData.podcastInfo.subscriptionType
			: SubscriptionType.free
	} else if (userInfoData) {
		subscriptionType = userInfoData.userInfo.subscriptionType
	}

	const planLimits = mapSubscriptionTypeToPodcastLimit(subscriptionType)

	const exceedsPodcastLimit = (): boolean => {
		return (userInfoData?.userInfo.numberOfPodcasts ?? 0) >= planLimits.podcasts
	}

	const exceedsEpisodesLimit = (): boolean => {
		return planLimits.episodes === -1
			? false
			: (podcastInfoData?.podcastInfo.numberOfPublishedEpisodes ?? 0) >= planLimits.episodes
	}

	const exceedsImportEpisodesLimit = (): boolean => {
		return planLimits.episodes === -1
			? false
			: (podcastInfoData?.podcastInfo.numberOfPublishedEpisodes ?? 0) > planLimits.episodes
	}

	const exceedsCollaboratorLimit = (): boolean => {
		return (podcastInfoData?.podcastInfo.numberOfCollaborators ?? 0) >= planLimits.collaborators
	}

	const exceedsStorageLimit = (): boolean => {
		return (
			(podcastInfoData?.podcastInfo.recordingStorageUsed ?? 0) >= planLimits.recordingStorageLimit * Math.pow(1024, 3)
		)
	}

	return {
		exceedsPodcastLimit,
		exceedsEpisodesLimit,
		exceedsImportEpisodesLimit,
		exceedsCollaboratorLimit,
		exceedsStorageLimit
	}
}
