import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import MyAccountPage from "./MyAccountPage"
import SubscriptionAndBillingPage from "./SubscriptionAndBillingPage"
import NotificationsPage from "./NotificationsPage"
import TellYourFriendsPage from "./TellYourFriendsPage"
import NavbarLayout from "containers/NavbarLayout"
import {
	MY_ACCOUNT,
	PODCAST_SETTINGS,
	NOTIFICATIONS,
	SETTINGS,
	SUBSCRIPTION_AND_BILLING,
	TELL_YOUR_FRIENDS,
	NO_PODCASTS,
	NOT_FOUND
} from "constants/routes"
import { PodcastPage } from "./PodcastPage"
import { EmptyPodcastPage } from "./EmptyPodcastPage"
import { UserSettingsSidebar } from "components/UserSettings/UserSettingsSidebar"
import { RootState } from "index"
import { Podcast } from "services/types/podcast"
import { usePodcasts } from "services/queries/Podcasts"
import "./SettingsPage.scss"

export function SettingsPage() {
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const { status, data: podcastData } = usePodcasts(authUser.uid)

	if (status === "error") {
		return (
			<NavbarLayout>
				<div className="settings-page">
					<UserSettingsSidebar />
				</div>
			</NavbarLayout>
		)
	}

	if (status === "loading") {
		return (
			<NavbarLayout>
				<div className="settings-page">
					<UserSettingsSidebar />
				</div>
			</NavbarLayout>
		)
	}

	const podcastRoutes = podcastData?.podcasts?.map((podcast: Podcast) => (
		<Route
			key={podcast.id}
			path={`${SETTINGS}${PODCAST_SETTINGS}/${podcast.id}`}
			render={() => <PodcastPage key={podcast.id} podcastId={podcast.id} />}
		/>
	))

	return (
		<NavbarLayout>
			<div className="settings-page">
				<UserSettingsSidebar />
				<Switch>
					{podcastRoutes}
					<Route path={`${SETTINGS}${MY_ACCOUNT}`} component={MyAccountPage} />
					<Route path={`${SETTINGS}${SUBSCRIPTION_AND_BILLING}`} component={SubscriptionAndBillingPage} />
					<Route path={`${SETTINGS}${NOTIFICATIONS}`} component={NotificationsPage} />
					<Route path={`${SETTINGS}${TELL_YOUR_FRIENDS}`} component={TellYourFriendsPage} />
					<Route path={`${SETTINGS}${PODCAST_SETTINGS}${NO_PODCASTS}`} component={EmptyPodcastPage} />
					<Redirect to={NOT_FOUND} />
				</Switch>
			</div>
		</NavbarLayout>
	)
}
