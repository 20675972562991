import { TOGGLE_SIDEBAR_COLLAPSED } from "constants/actionTypes"

const initialState = {
	sidebarCollapsed: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_SIDEBAR_COLLAPSED:
			return { ...state, sidebarCollapsed: !state.sidebarCollapsed }

		default:
			return state
	}
}
