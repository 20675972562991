export const LABEL_NAMES = {
	PHOTO: "Episode Artwork",
	TITLE: "Episode Title",
	SEASON_NUMBER: "Season #",
	EPISODE_NUMBER: "Episode #",
	LABEL: "Label",
	EPISODE_TYPE: "Episode Type",
	TRAILER_BONUS_TYPE: "Trailer or Bonus Type",
	EPISODE_PARENTAL_ADVISORY: "Episode Parental Advisory",
	ITUNES_VISIBILITY: "iTunes Visibility",
	RECORDING: "Recording File",
	EPISODE_DURATION: "Episode Duration",
	SCHEDULED_RELEASE_DATE: "Scheduled Release Date",
	LAST_PUBLISHED_DATE: "Last Published",
	EPISODE_URL: "Episode URL (Permalink)"
}

export const FIELD_NAMES = {
	PHOTO: "photoUrl",
	TITLE: "title",
	SEASON_NUMBER: "seasonNumber",
	EPISODE_NUMBER: "episodeNumber",
	LABEL: "label",
	EPISODE_TYPE: "episodeType",
	TRAILER_BONUS_TYPE: "trailerBonusType",
	EPISODE_PARENTAL_ADVISORY: "parentalAdvisory",
	ITUNES_VISIBILITY: "itunesVisibility",
	RECORDING_URL: "recordingUrl",
	DURATION: "duration",
	PLANNED_RELEASE_DATE: "plannedReleaseDate",
	SCHEDULED_RELEASE_DATE: "scheduledReleaseDate",
	LAST_PUBLISHED_DATE: "lastPublishedDate",
	EPISODE_URL: "episodeUrl"
}

export const ARTWORK_OPTIONS = {
	DEFAULT: "default",
	CUSTOM: "custom"
}

export const EPISODE_TYPE_OPTIONS = {
	FULL: "FULL",
	TRAILER: "TRAILER",
	BONUS: "BONUS"
}

export const TRAILER_BONUS_TYPE_OPTIONS = {
	SHOW: "SHOW",
	SEASON: "SEASON",
	EPISODE: "EPISODE"
}

export const TRAILER_TYPES = {
	EPISODE_TRAILER: "EPISODE_TRAILER",
	SEASON_TRAILER: "SEASON_TRAILER"
}

export const BONUS_TYPES = {
	EPISODE_BONUS: "EPISODE_BONUS",
	SEASON_BONUS: "SEASON_BONUS"
}

export const BONUS_TRAILER_TYPE_MAP = {
	EPISODE_BONUS: "Bonus Episode",
	SEASON_BONUS: "Season Bonus",
	EPISODE_TRAILER: "Episode Trailer",
	SEASON_TRAILER: "Season Trailer"
}

export const PARENTAL_ADVISORY_OPTIONS = {
	CLEAN: "clean",
	EXPLICIT: "explicit"
}

export const ITUNES_VISIBILITY_OPTIONS = {
	SHOW: "show",
	HIDE: "hide"
}

export const NOT_PUBLISHED = "Not published"
export const RECORDING_UPLOADED_TIME_DISPLAY_FORMAT = "MMM DD, YYYY [at] hh:mm A"
// eslint-disable-next-line
export const SPECIAL_CHARS_REGEX = /[\:\/\?\#\[\]\@\!\$\&\'\(\)\*\+\,\;\=]/
export const ACCEPTED_AUDIO_RECORDING_FORMATS = ["audio/x-m4a", "audio/mpeg"]
export const ACCEPTED_VIDEO_RECORDING_FORMATS = ["video/mp4", "video/x-m4v"]
export const EPISODE_ALREADY_PUBLISHED_ALERT =
	"This episode has unsynced changes! Please “Republish” to update this episode in your RSS Feed."

export const ARTWORK_REQUIRED = "Please upload custom artwork."
export const TITLE_REQUIRED = "Title cannot be empty."
export const TITLE_TOO_LONG = "Title cannot exceed 125 characters."
export const SEASON_NUMBER_REQUIRED = "Missing #."
export const INVALID_SEASON_NUMBER = "Invalid."
export const EPISODE_NUMBER_REQUIRED = "Missing #."
export const INVALID_EPISODE_NUMBER = "Invalid."
export const TOO_MANY_LABELS = "Episodes can only have 1 label."
export const LABEL_TOO_LONG = "A label cannot exceed 20 characters."
export const PHOTO_TOO_LARGE_ERROR_MESSAGE = "Please choose an image less than 16MB."
export const SUCCESSFULLY_CREATED_RECORDING = "Recording uploaded successfully!"
export const SUCCESSFULLY_REPLACED_RECORDING = "Recording replaced successfully!"
export const SUCCESSFULLY_REMOVED_RECORDING = "Recording removed successfully!"
export const UNABLE_TO_CREATE_RECORDING = "Sorry, we were unable to create your recording."
export const UNABLE_TO_SELECT_RECORDING = "Sorry, we were unable to select your recording."
export const UNABLE_TO_UPLOAD_RECORDING = "Sorry, we were unable to upload your recording."
export const UNABLE_TO_REMOVE_RECORDING = "Sorry, we were unable to remove your recording."
export const UNABLE_TO_REPLACE_RECORDING = "Sorry, we were unable to replace your recording."
export const RECORDING_CREATED = "Your recording was successfully uploaded!"

export const PUBLISH_SUCCESSFUL = "Episode published to RSS Feed!"
export const REPUBLISH_SUCCESSFUL = "This episode was republished! Your RSS Feed has been updated."
export const UNABLE_TO_PUBLISH_EPISODE = "Sorry, we were unable to publish your episode."
