import React from "react"
import { Row, Col } from "antd"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import PodpalButton from "components/common/Buttons/PodpalButton"
import invoicesImage from "assets/subscriptionandbilling/invoices.svg"
import invoicesMobileImage from "assets/subscriptionandbilling/invoices-mobile.svg"

import "components/UserSettings/SubscriptionAndBilling/SubscriptionAndBilling.scss"
import { useSelector } from "react-redux"
import { useUserInfo } from "services/queries/UserInfo"
import { useStripeCustomerPortal } from "hooks/SubscriptionAndBilling/useStripeCustomerPortal"
import { RootState } from "index"
import layout from "styles/scss/layout.scss"

const InvoicesPage = () => {
	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	const authUser = useSelector((state: RootState) => state.app.authUser)

	const { data } = useUserInfo(authUser.uid)

	const { openCustomerPortal } = useStripeCustomerPortal({ customerId: data?.userInfo?.customerId })

	const invoicesBanner = (
		<Col>
			<Row>
				<img src={isMobile ? invoicesMobileImage : invoicesImage} alt="current-plan" />
			</Row>
		</Col>
	)

	return (
		<Row align="middle" justify="center" className="subscription-and-billing-tab" gutter={48}>
			<Col>
				<Row>
					<Col>
						{isMobile ? (
							<h2 style={{ marginTop: layout.spaceLg }}>Where can I find a record of my Podpal transactions?</h2>
						) : (
							<h2>
								Where can I find a record of
								<br />
								my Podpal transactions?
							</h2>
						)}
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col>
						{isMobile ? (
							<p>We’re glad you asked! Just visit the Customer Portal to view your latest invoices and billing history.</p>
						) : (
							<p>
								We’re glad you asked! Just visit the Customer Portal to
								<br />
								view your latest invoices and billing history.
							</p>
						)}
					</Col>
				</Row>
				{isMobile && <Row gutter={[0, { xs: 24 }]}>{invoicesBanner}</Row>}
				<Row gutter={[12, { xs: 16 }]}>
					<Col xs={24} lg={16}>
						{isMobile ? (
							<PodpalButton shape="round" fullwidth type="primary" size="large" onClick={openCustomerPortal}>
								View Invoices
							</PodpalButton>
						) : (
							<PodpalButton type="primary" size="large" onClick={openCustomerPortal}>
								View Invoices
							</PodpalButton>
						)}
					</Col>
				</Row>
			</Col>
			{!isMobile && invoicesBanner}
		</Row>
	)
}

export default InvoicesPage
