import moment from "moment"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import PodpalButton from "./PodpalButton"
import { ClockCircleOutlined } from "@ant-design/icons"
import Colors from "styles/scss/color.scss"
import { NOT_PUBLISHED } from "constants/strings/Episodes"

const StyledStatusButton = styled(PodpalButton)`
	background-color: ${({ theme }) => theme.gray4};
	border: none;
	color: ${({ theme }) => theme.textSecondary};

	&:hover,
	&:focus,
	&:active {
		background-color: ${({ theme }) => theme.neutral2};
		border: none;
		color: ${({ theme }) => theme.textSecondary};
	}

	${({ status }) =>
		status === "warning" &&
		css`
			background-color: ${({ theme }) => theme.podpalYellowLight};
			border: none;
			color: ${({ theme }) => theme.neutral0};

			&:hover,
			&:focus,
			&:active {
				background-color: ${({ theme }) => theme.podpalYellowDark};
				border: none;
				color: ${({ theme }) => theme.neutral0};
			}
		`}

	${({ status, variant }) =>
		status === "success" &&
		variant === "outlined" &&
		css`
			background-color: ${({ theme }) => theme.neutral0};
			border: 1px solid ${({ theme }) => theme.podpalGreenLight};
			color: ${({ theme }) => theme.podpalGreenLight};

			&:hover,
			&:focus,
			&:active {
				background-color: ${({ theme }) => theme.gray2};
				border: 1px solid ${({ theme }) => theme.podpalGreenLight};
				color: ${({ theme }) => theme.podpalGreenLight};
			}
		`}

	${({ status, variant }) =>
		status === "success" &&
		variant !== "outlined" &&
		css`
			background-color: ${({ theme }) => theme.podpalGreenLight};
			border: none;
			color: ${({ theme }) => theme.neutral0};

			&:hover,
			&:focus,
			&:active {
				background-color: ${({ theme }) => theme.podpalGreenDark};
				border: none;
				color: ${({ theme }) => theme.neutral0};
			}
		`}

    ${({ status }) =>
		status === "error" &&
		css`
			background-color: ${({ theme }) => theme.podpalRedLight};
			border: none;
			color: ${({ theme }) => theme.neutral0};

			&:hover,
			&:focus,
			&:active {
				background-color: ${({ theme }) => theme.podpalRedDark};
				border: none;
				color: ${({ theme }) => theme.neutral0};
			}
		`}
`

const StatusButton = ({ plannedReleaseDate, scheduledReleaseDate, publishedDate, onClick }) => {
	const [buttonText, setButtonText] = useState("Select Date")
	const [status, setStatus] = useState("default")
	const [variant, setVariant] = useState("")

	useEffect(() => {
		if (scheduledReleaseDate) {
			setButtonText("Publishing Later")
			setStatus("success")
			setVariant("outlined")
		} else if (publishedDate && publishedDate !== NOT_PUBLISHED) {
			setStatus("success")
			setButtonText("Published")
		} else if (plannedReleaseDate) {
			const today = moment()
			const momentReleaseDate = moment(plannedReleaseDate).local()
			const calculatedDays = momentReleaseDate.diff(today, "days")

			if (calculatedDays > 5) {
				setButtonText(momentReleaseDate.format("MMM D, YYYY"))
				setStatus("default")
			} else if (calculatedDays <= 5 && calculatedDays > 0) {
				const dayText = Math.abs(calculatedDays) === 1 ? "day" : "days"
				setButtonText(`${Math.abs(calculatedDays)} ${dayText} left`)
				setStatus("warning")
			} else if (calculatedDays === 0 && momentReleaseDate.isAfter(today)) {
				if (momentReleaseDate.isSame(today, "day")) {
					setButtonText(`Due Today`)
					setStatus("warning")
				} else {
					setButtonText(`Due Tomorrow`)
					setStatus("warning")
				}
			} else if (calculatedDays === 0 && today.isAfter(momentReleaseDate)) {
				setButtonText(`Past Due`)
				setStatus("error")
			} else if (calculatedDays === 0) {
				setButtonText("Due Today")
				setStatus("error")
			} else if (calculatedDays < 0) {
				const dayText = Math.abs(calculatedDays) === 1 ? "day" : "days"
				setButtonText(`${Math.abs(calculatedDays)} ${dayText} late`)
				setStatus("error")
			}
		} else {
			setButtonText("Select Date")
			setStatus("default")
		}
	}, [plannedReleaseDate, scheduledReleaseDate, publishedDate])

	return (
		<StyledStatusButton
			{...((!publishedDate || publishedDate === "Not published") &&
				(plannedReleaseDate || scheduledReleaseDate) && {
					icon: (
						<ClockCircleOutlined
							style={{
								color:
									status === "default"
										? Colors.textSecondary
										: status === "success" && variant === "outlined"
										? Colors.podpalGreenLight
										: Colors.neutral0
							}}
						/>
					)
				})}
			status={status}
			variant={variant}
			onClick={onClick}
		>
			{buttonText}
		</StyledStatusButton>
	)
}

export default StatusButton
