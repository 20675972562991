import React from "react"
import { connect } from "react-redux"
import { NOT_FOUND } from "constants/routes"
import { Switch, Redirect, withRouter } from "react-router-dom"
import { SETTINGS, TELL_YOUR_FRIENDS } from "constants/routes"
import GenericSettingsPage from "containers/SettingsPage/GenericSettingsPage"
import "./TellYourFriendsPage.scss"

/**
 * @author Vidhur Kumar
 */
class TellYourFriendsPage extends React.Component {
	render() {
		let data = [
			{
				KEY: 1,
				ICON: null,
				FILLED_ICON: null,
				TITLE: "Tab 1",
				CONTENT: "Tab 1"
			},
			{
				KEY: 2,
				ICON: null,
				FILLED_ICON: null,
				TITLE: "Tab 2",
				CONTENT: "Tab 2"
			}
			// {
			// 	KEY: 3,
			// 	ICON: null,
			// 	FILLED_ICON: null,
			// 	TITLE: "Tab 3",
			// 	CONTENT: "Tab 3",
			// },
			// {
			// 	KEY: 4,
			// 	ICON: null,
			// 	FILLED_ICON: null,
			// 	TITLE: "Tab 4",
			// 	CONTENT: "Tab 4",
			// },
		]
		return (
			<GenericSettingsPage
				heading="Tell Your Friends!"
				settingsTabsArray={data}
				basePath={`${SETTINGS}${TELL_YOUR_FRIENDS}`}
			>
				<Switch>
					<Redirect to={NOT_FOUND} />
				</Switch>
			</GenericSettingsPage>
		)
	}
}

export default withRouter(connect()(TellYourFriendsPage))
