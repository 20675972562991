import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col } from "antd"
import {
	setEpisodeIdForConfirmationModal,
	toggleAddNewEpisodeConfirmationModalVisible
} from "redux/actions/Episodes/AddNewEpisode"
import PodpalModal from "components/common/Modals/PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import podcastAddedImage from "assets/podcasts/happy-feeling.svg"
import "./AddNewEpisodeConfirmationModal.scss"
import { EPISODES, MANAGE } from "constants/routes"
import { useHistory } from "react-router"

const AddNewEpisodeConfirmationModal = props => {
	const dispatch = useDispatch()

	const visible = useSelector(state => state.addNewEpisode.addNewEpisodeConfirmationModalVisible)
	const newEpisodeId = useSelector(state => state.addNewEpisode.newEpisodeId)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)

	const history = useHistory()
	const toggleModal = () => dispatch(toggleAddNewEpisodeConfirmationModalVisible())

	useEffect(() => {
		return () => {
			dispatch(setEpisodeIdForConfirmationModal(null))
		}
	}, [dispatch])

	return (
		<PodpalModal
			title="Add Episode"
			className="add-new-episode-confirmation-modal"
			centered
			visible={visible}
			onCancel={toggleModal}
			width={480}
			destroyOnClose={true}
			maskClosable={false}
			closable={false}
			footer={[
				<PodpalButton
					variant="secondary"
					onClick={() => {
						toggleModal()
						history.push(`/${selectedPodcast.profileUrl}${EPISODES}${MANAGE}/${newEpisodeId}`)
					}}
				>
					Manage Episode
				</PodpalButton>,
				<PodpalButton
					type="primary"
					onClick={() => {
						toggleModal()
						history.push(`/${selectedPodcast.profileUrl}${EPISODES}`)
					}}
				>
					OK
				</PodpalButton>
			]}
		>
			<Row align="center">
				<Col>
					<h2>Your episode was added successfully!</h2>
				</Col>
			</Row>
			<Row align="center" gutter={[0, 24]}>
				<Col>
					<img src={podcastAddedImage} alt="podcast-added" />
				</Col>
			</Row>
			<Row align="center">
				<Col>
					<p className="bold-text">Feeling good?</p>
				</Col>
			</Row>
			<Row align="center">
				<Col>
					<p>That’s just the way it should be.</p>
				</Col>
			</Row>
		</PodpalModal>
	)
}

export default AddNewEpisodeConfirmationModal
