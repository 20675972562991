import { Col, Row } from "antd"
import React from "react"
import { useParams } from "react-router"
import { useUserByProfileUrl } from "services/queries/User"
import styled from "styled-components"
import { CurvedHeader } from "../PodPage/CurvedHeader"
import { PalTitleCard } from "./PalTitleCard"
import { PodcastList } from "./PodcastList"
import noAvatar from "assets/account/noavatar.svg"

const PalPageLayout = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
	width: 100vw;

	.main {
		margin-top: -275px;
		& > div {
			max-width: 650px;
		}

		@media only screen and (max-width: 700px) {
			& {
				margin-top: -400px;

				& > div {
					max-width: 550px;
				}
			}
		}

		@media only screen and (max-width: ${({ theme }) => theme.xs}) {
			& {
				margin-top: -500px;

				& > div {
					max-width: 90vw;
				}
			}
		}
	}
	.user-thumbnail {
		text-align: center;

		img {
			height: 250px;
			width: 250px;
			border-radius: 50em;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
		}
	}
	.user-bio {
		font: ${({ theme }) => theme.fontMd};
		font-weight: 400;
	}

	h3 {
		font: ${({ theme }) => theme.fontLg};
		font-weight: 700;
	}
`

type PalPageUrlParams = { profileUrl: string }

export function PalProfilePage() {
	const { profileUrl } = useParams<PalPageUrlParams>()
	const { isLoading, isError, data } = useUserByProfileUrl(profileUrl)

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (isError) {
		return <div>Error loading pal page.</div>
	}

	if (!data) {
		return <div>Error loading pal page.</div>
	}

	const {
		profileColor,
		firstName,
		lastName,
		thumbnailUrl,
		bio,
		id,
		audioBioUrl,
		instagramHandle,
		facebookHandle,
		twitterHandle,
		linkedinHandle
	} = data.user

	const palTitleProps = {
		name: `${firstName} ${lastName}`,
		audioBioUrl,
		instagramHandle,
		facebookHandle,
		twitterHandle,
		linkedinHandle
	}

	return (
		<PalPageLayout>
			<CurvedHeader color={profileColor} />
			<Row justify="center" className="main">
				<Col xs={24} lg={18} xxl={12}>
					<Row justify="center" align="top" gutter={[{ xs: 0, sm: 32 }, 32]}>
						<Col>
							<div className="user-thumbnail">
								<img alt={`${firstName} ${lastName}`} src={thumbnailUrl ?? noAvatar} />
							</div>
						</Col>
						<Col flex="350px">
							<PalTitleCard {...palTitleProps} />
						</Col>
						<Col span={24}>
							<Row gutter={[0, 12]}>
								<Col span={24}>
									<h3>About Me</h3>
								</Col>
								<Col span={24}>
									<p className="user-bio" dangerouslySetInnerHTML={{ __html: bio ?? "" }}></p>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<h3>My Podcasts</h3>
							<PodcastList userId={id} />
						</Col>
						{/* 
						<Col span={24}>
							<h3>Featured Appearances</h3>
						</Col>
						*/}
					</Row>
				</Col>
			</Row>
		</PalPageLayout>
	)
}
