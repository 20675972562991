import { Breadcrumb, Row, Col, Menu, Dropdown, Grid, Tag, Avatar, Space, Tooltip } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { useEpisode } from "services/queries/Episodes"
import { useHistory, useParams } from "react-router"
import { EPISODES, PAL } from "constants/routes"
import styled from "styled-components"
import { BackArrowIcon, LinkIcon } from "assets/icons/Episodes"
import { useDispatch, useSelector } from "react-redux"
import { toggleDeleteEpisodeModalVisible, setEpisodeForDeleteEpisodeModal } from "redux/actions/Episodes"
import { setEpisodeIdForInitialValues, toggleAddNewEpisodeDrawer } from "redux/actions/Episodes/AddNewEpisode"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { MoreIcon } from "assets/icons/Common/SimpleFeedback"
import { UserIcon } from "assets/icons/Common/User"
import { usePodcastTeam } from "services/queries/Podcasts"

const StyledHeader = styled.div`
	h1 {
		font: ${props => props.theme.fontLg};
		color: ${props => props.theme.textPrimary};
		margin: ${props => props.theme.spaceMd} 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.ant-breadcrumb {
		span {
			color: ${({ theme }) => theme.textSecondary};
			font: ${({ theme }) => theme.fontSm};
		}
		a {
			color: ${({ theme }) => theme.textSecondary};
			&:hover {
				color: ${({ theme }) => theme.textSecondary};
			}
		}
	}

	.no-wrap {
		flex-wrap: nowrap;
	}

	.options-menu {
		font: ${props => props.theme.fontLgPlus};
		text-align: end;
	}
`

const StyledAvatar = styled(Avatar)`
	transition: ease 0.25s;
	border: none !important;
	&:hover {
		transition: ease 0.25s;
		box-shadow: 0 0 0 3px ${({ theme }) => theme.podpalBlue};
		cursor: pointer;
	}
`

const EpisodeManagementHeader = () => {
	const { episodeId } = useParams()
	const dispatch = useDispatch()
	const history = useHistory()
	const { useBreakpoint } = Grid
	const screens = useBreakpoint()

	const selectedPodcast = useSelector(state => state.app.selectedPodcast)

	const { data } = useEpisode(episodeId)
	const { data: podcastTeamData } = usePodcastTeam(selectedPodcast?.id)

	const handleEpisodeLinkCopy = () => {
		navigator.clipboard
			.writeText(window.location.href)
			.then(() => successAlert("Link copied to Clipboard!"))
			.catch(() => errorAlert("Failed to copy link to clipboard."))
	}

	const OptionsMenu = (
		<Menu>
			<Menu.Item
				key="episodeSettings"
				onClick={() => {
					dispatch(setEpisodeIdForInitialValues(data?.episode.id))
					dispatch(toggleAddNewEpisodeDrawer())
				}}
			>
				Episode Settings
			</Menu.Item>
			<Menu.Item key="share" onClick={handleEpisodeLinkCopy}>
				Share
			</Menu.Item>
			<Menu.Item
				key="delete"
				danger
				onClick={() => {
					dispatch(setEpisodeForDeleteEpisodeModal(data?.episode))
					dispatch(toggleDeleteEpisodeModalVisible())
				}}
			>
				Delete
			</Menu.Item>
		</Menu>
	)

	return (
		<StyledHeader>
			<Row gutter={[8, 8]} align="middle">
				<Col xs={22} xl={24}>
					<Breadcrumb separator="|">
						<Breadcrumb.Item>
							<Link to={`/${selectedPodcast?.profileUrl}${EPISODES}`}>{selectedPodcast?.title || ""}</Link>
						</Breadcrumb.Item>
						{data?.episode.seasonNumber && <Breadcrumb.Item>Season {data.episode.seasonNumber}</Breadcrumb.Item>}
						{data?.episode.episodeNumber && <Breadcrumb.Item>Episode {data.episode.episodeNumber}</Breadcrumb.Item>}
					</Breadcrumb>
				</Col>
				{!screens.xl && (
					<Col span={2} className="options-menu">
						<Dropdown overlay={OptionsMenu} trigger={["click"]}>
							<MoreIcon />
						</Dropdown>
					</Col>
				)}
				<Col span={24}>
					<Row className="no-wrap" align="middle" justify="start" gutter={[8]}>
						<Col>
							<BackArrowIcon onClick={() => history.push(`/${selectedPodcast.profileUrl}${EPISODES}`)} />
						</Col>
						<Col>
							<h1>{data.episode.title}</h1>
						</Col>
						{screens.xl && data.episode.label && (
							<Col>
								<Tag>{data.episode.label}</Tag>
							</Col>
						)}
						{screens.xl && (
							<Col flex="auto">
								<Space size={16}>
									<Avatar.Group maxCount={3}>
										{podcastTeamData?.usersWithPodcastRoles.map(userWithPodcastRole => (
											<Tooltip
												title={userWithPodcastRole?.user.firstName + " " + userWithPodcastRole?.user.lastName}
												placement="bottom"
											>
												{userWithPodcastRole?.user.thumbnailUrl ? (
													<StyledAvatar
														src={userWithPodcastRole.user.thumbnailUrl}
														onClick={
															userWithPodcastRole.user.username
																? () =>
																		window.open(`${process.env.REACT_APP_PAPP_URL}${PAL}/${userWithPodcastRole.user.username}`, "_blank")
																: null
														}
													/>
												) : (
													<StyledAvatar icon={<UserIcon />} />
												)}
											</Tooltip>
										))}
									</Avatar.Group>
									<Tooltip title="Copy link to episode" placement="bottom">
										<LinkIcon onClick={handleEpisodeLinkCopy} />
									</Tooltip>
								</Space>
							</Col>
						)}
						{screens.xl && (
							<Row>
								<Col span={1} className="options-menu">
									<Dropdown overlay={OptionsMenu} trigger={["click"]}>
										<MoreIcon />
									</Dropdown>
								</Col>
							</Row>
						)}
					</Row>
				</Col>
			</Row>
		</StyledHeader>
	)
}

export default EpisodeManagementHeader
