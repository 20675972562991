import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalCard from "components/common/Cards/PodpalCard"
import { interviewMeComingSoonModal } from "components/common/Modals/Episodes/EpisodeManagementModals"
import React, { useState } from "react"
import styled from "styled-components"
import { PersonAddIcon } from "../../assets/icons/User"
import { PalSocialMediaModal } from "./PalSocialMediaModal"

const StyledPodpalCard = styled(PodpalCard)<PalTitleCardProps>`
	height: 330px;
	text-align: center;
	padding: 30px 30px 15px 30px;

	h2 {
		font: 700 24px/20px Roboto, sans-serif;
	}

	.user-role {
		text-transform: capitalize;
		font: ${({ theme }) => theme.fontSm};
		font-size: 18px;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		align-items: center;

		.item {
			width: 100%;
			flex: 1 auto;

			&.listen-section {
				padding-top: 1em;
				border-top: 1px solid ${({ theme }) => theme.neutral2};
				align-self: flex-end;

				& .anticon {
					vertical-align: middle;
				}
				& svg path {
					fill: ${({ theme }) => theme.textSecondary};
				}
			}
		}
	}
`

export interface PalTitleCardProps {
	name: string
	audioBioUrl?: string
	instagramHandle?: string
	facebookHandle?: string
	twitterHandle?: string
	linkedinHandle?: string
}

export function PalTitleCard({ name, audioBioUrl, ...props }: PalTitleCardProps) {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	return (
		<StyledPodpalCard>
			<>
				<div className="container">
					<div className="item">
						<h2>{name}</h2>
						{/* <p className="user-role">Podcast Host</p> */}
					</div>
					<div className="item">
						<PodpalButton
							size="large"
							shape="round"
							type="primary"
							onClick={() => interviewMeComingSoonModal(name.split(" ")[0])}
						>
							Interview Me
						</PodpalButton>
					</div>
					{audioBioUrl && (
						<div className="item">
							<audio controls controlsList="nodownload">
								<source src={audioBioUrl} type="audio/mp3" />
								<source src={audioBioUrl} type="audio/mp4" />
							</audio>
						</div>
					)}
					<hr />
					<div className="item listen-section">
						<PodpalButton
							onClick={() => setModalIsOpen(!modalIsOpen)}
							size="large"
							icon={<PersonAddIcon />}
							variant="secondary"
						>
							Follow Me On...
						</PodpalButton>
					</div>
				</div>
				<PalSocialMediaModal visible={modalIsOpen} onClose={() => setModalIsOpen(false)} {...props} />
			</>
		</StyledPodpalCard>
	)
}
