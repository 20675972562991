import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { toggleTermsAndConditionsModalVisible } from "redux/actions/GetStarted"
import PodpalModal from "../../PodpalModal"

export function TermsAndConditionsModal() {
	const dispatch = useDispatch()

	const visible = useSelector(state => state.signup.termsAndConditionsModalVisible)

	return (
		<PodpalModal
			centered
			title="Terms And Conditions"
			visible={visible}
			width={480}
			onCancel={() => dispatch(toggleTermsAndConditionsModalVisible())}
		>
			Say something
		</PodpalModal>
	)
}
