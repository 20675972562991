import { TOGGLE_PODCAST_SETTINGS_LOADING } from "constants/actionTypes"

const initialState = {
	loading: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_PODCAST_SETTINGS_LOADING:
			return {
				...state,
				loading: action.payload
			}

		default:
			return state
	}
}
