import { Col, Row } from "antd"
import Avatar from "antd/lib/avatar/avatar"
import { POD } from "constants/routes"
import { DEFAULT_PODCAST_ARTWORK_URL } from "constants/strings"
import React from "react"
import { Link } from "react-router-dom"
import { usePodcastRoles } from "services/queries/PodcastRole"
import { usePodcasts } from "services/queries/Podcasts"
import styled from "styled-components"
import { toTitleCase } from "utils/titleCase"

const StyledCol = styled(Col)`
	text-align: center;

	span.ant-avatar {
		&:hover {
			box-shadow: 0 0 0 8px ${({ theme }) => theme.podpalBlueGhost};
			cursor: pointer;
		}
	}

	img {
		border-radius: ${({ theme }) => theme.radiusLg};
	}

	p {
		margin-top: ${({ theme }) => theme.spaceXs};
		max-width: 100px;

		&.podcast-title {
			font-weight: 500;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;

			&:hover {
				text-decoration: underline;
			}
		}
	}
`

interface PodcastListProps {
	userId: string
}

export function PodcastList({ userId }: PodcastListProps) {
	const { isLoading, isError, data } = usePodcasts(userId)
	const { isLoading: isPodcastRolesLoading, isError: isPodcastRolesError, data: podcastRoles } = usePodcastRoles(userId)

	if (isLoading || isPodcastRolesLoading) {
		return <div>Loading...</div>
	}

	if (isError || isPodcastRolesError) {
		return <div>Error loading pal page.</div>
	}

	const podcastItems = data?.podcasts?.map(podcast => {
		const collaboratorType =
			podcastRoles?.podcastRoles.find(role => role.podcastId === podcast.id)?.collaboratorType ?? "Host"

		const titleCaseCollaboratorType: string = toTitleCase(collaboratorType.replace("_", " "))

		return (
			<StyledCol key={podcast.id}>
				<Link to={`${POD}/${podcast.profileUrl}`}>
					<Avatar size={112} shape="square" src={podcast.photoUrl ?? DEFAULT_PODCAST_ARTWORK_URL} alt={podcast.title} />
				</Link>
				<Link to={`${POD}/${podcast.profileUrl}`}>
					<p className="podcast-title">{podcast.title}</p>
				</Link>
				<p>{titleCaseCollaboratorType}</p>
			</StyledCol>
		)
	})

	return <Row gutter={[24, 24]}>{podcastItems}</Row>
}
