import { combineReducers } from "redux"
import appReducer from "./App"
import loginReducer from "./Login"
import { signupReducer } from "./Signup"
import sidebarReducer from "./Sidebar"
import notificationsDropdownReducer from "./Navbar/NotificationsDropdown"
import userSettingsDropdownReducer from "./Navbar/UserSettingsDropdown"
import userSettingsSidebarReducer from "./UserSettings/UserSettingsSidebar"
import myAccountSettingsReducer from "./UserSettings/MyAccount"
import myPodcastSettingsReducer from "./UserSettings/MyPodcasts"
import accountFormReducer from "./UserSettings/MyAccount/Form"
import podcastRSSAndDistributionReducer from "./UserSettings/MyPodcasts/RSSAndDistribution"
import podcastSocialMediaFormReducer from "./UserSettings/MyPodcasts/SocialMediaForm"
import twoFAToggleReducer from "./UserSettings/MyAccount/TwoFAToggle"
import appsAndIntegrationsReducer from "./UserSettings/MyAccount/AppsAndIntegrations"
import podcastReducer from "./Podcasts"
import addNewPodcastReducer from "./Podcasts/AddNewPodcast"
import episodeReducer from "./Episodes"
import addNewEpisodeReducer from "./Episodes/AddNewEpisode"
import recordingReducer from "./Recordings"
import { teamManagementReducer } from "./TeamManagement"
import { helpCenterReducer } from "./HelpCenter"
import { subscriptionAndBillingReducer } from "./SubscriptionAndBilling"

const allReducers = combineReducers({
	app: appReducer,
	login: loginReducer,
	signup: signupReducer,
	sidebar: sidebarReducer,
	notifications: notificationsDropdownReducer,
	helpCenter: helpCenterReducer,
	userSettingsDropdown: userSettingsDropdownReducer,
	userSettingsSidebar: userSettingsSidebarReducer,
	myAccountSettings: myAccountSettingsReducer,
	myPodcastSettings: myPodcastSettingsReducer,
	accountForm: accountFormReducer,
	podcastRSSAndDistribution: podcastRSSAndDistributionReducer,
	twoFAToggle: twoFAToggleReducer,
	appsAndIntegrations: appsAndIntegrationsReducer,
	podcast: podcastReducer,
	addNewPodcast: addNewPodcastReducer,
	episode: episodeReducer,
	addNewEpisode: addNewEpisodeReducer,
	podcastSocialMediaForm: podcastSocialMediaFormReducer,
	recording: recordingReducer,
	teamManagement: teamManagementReducer,
	subscriptionAndBilling: subscriptionAndBillingReducer
})

export const rootReducer = (state, action) => {
	return allReducers(state, action)
}
