import {
	TOGGLE_NEW_EMAIL_MODAL_VISIBLE,
	TOGGLE_NAVIGATION_ALLOWED_FLAG,
	CHANGE_MY_ACCOUNT_SETTINGS_PAGE_NEXT_LOCATION,
	TOGGLE_DELETE_ACCOUNT_MODAL_VISIBLE
} from "constants/actionTypes"

export const toggleNewEmailModalVisible = () => ({
	type: TOGGLE_NEW_EMAIL_MODAL_VISIBLE
})

export const toggleNavigationAllowedFlag = () => ({
	type: TOGGLE_NAVIGATION_ALLOWED_FLAG
})

export const changeMyAccountSettingsPageNextLocation = location => ({
	type: CHANGE_MY_ACCOUNT_SETTINGS_PAGE_NEXT_LOCATION,
	payload: {
		location: location
	}
})

export const toggleDeleteAccountModalVisible = () => ({
	type: TOGGLE_DELETE_ACCOUNT_MODAL_VISIBLE
})
