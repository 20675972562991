/**
 * Account Form Default State
 */
export const defaultProfileDataState = {
	email: "",
	firstName: "",
	lastName: "",
	username: "",
	bio: "",
	dateOfBirth: "",
	userProfileUrl: "",
	thumbnailUrl: "",
	instagramHandle: "",
	facebookHandle: "",
	twitterHandle: "",
	linkedinHandle: "",
	newThumbnailFile: "",
	newEmail: "",
	newEmailConfirmPassword: "",
	newEmailVerificationCode: "",
	currentPassword: "",
	newPassword: "",
	confirmNewPassword: "",
	enableTwoFAConfirmPassword: "",
	securityQuestion: null,
	securityQuestionAnswer: "",
	phoneNumber: "",
	verificationCode: "",
	changeTwoFAConfirmPassword: "",
	changeTwoFASecurityQuestionAnswer: "",
	changeTwoFAPhoneNumber: "",
	changeTwoFAPhoneNumberCountryCode: "",
	changeTwoFAVerificationCode: "",
	removeTwoFAConfirmPassword: "",
	removeTwoFASecurityQuestionAnswer: "",
	removeTwoFAPhoneNumber: "",
	removeTwoFAVerificationCode: ""
}

export const defaultProfileDataValErrorState = {
	email: "",
	firstName: "",
	lastName: "",
	username: "",
	bio: "",
	dateOfBirth: "",
	userProfileUrl: "",
	thumbnailUrl: "",
	instagramHandle: "",
	facebookHandle: "",
	twitterHandle: "",
	linkedinHandle: "",
	newThumbnailFile: "",
	newEmail: "",
	newEmailConfirmPassword: "",
	newEmailVerificationCode: "",
	currentPassword: "",
	newPassword: "",
	confirmNewPassword: "",
	enableTwoFAConfirmPassword: "",
	securityQuestion: "",
	securityQuestionAnswer: "",
	phoneNumber: "",
	verificationCode: "",
	changeTwoFAConfirmPassword: "",
	changeTwoFASecurityQuestionAnswer: "",
	changeTwoFAPhoneNumber: "",
	changeTwoFAPhoneNumberCountryCode: "",
	changeTwoFAVerificationCode: "",
	removeTwoFAConfirmPassword: "",
	removeTwoFASecurityQuestionAnswer: "",
	removeTwoFAPhoneNumber: "",
	removeTwoFAVerificationCode: ""
}

/**
 * Podcasts Form Default State
 */
export const defaultPodcastDataState = {
	photoUrl: "",
	title: "",
	subtitle: "",
	showSummary: "",
	profileUrl: "",
	mediaFormat: "",
	parentalAdvisory: "",
	categories: [],
	language: "",
	keywords: [],
	externalWebsite: "",
	podcastEmail: "",
	countryRegion: "",
	timeZone: "",
	showType: "",
	episodeGrouping: "",
	episodeNumbering: "",
	autoScheduleOn: false,
	defaultReleaseCadence: "",
	defaultReleaseDay: "",
	defaultReleaseTime: "",
	autoPublishOn: false,
	authors: "",
	copyright: "",
	ownerName: "",
	ownerEmail: ""
}

export const defaultPodcastDataValErrorState = {
	title: "",
	subtitle: "",
	showSummary: "",
	profileUrl: "",
	mediaFormat: "",
	parentalAdvisory: "",
	categories: "",
	language: "",
	keywords: "",
	externalWebsite: "",
	podcastEmail: "",
	countryRegion: "",
	timeZone: "",
	showType: "",
	episodeGrouping: "",
	episodeNumbering: "",
	autoScheduleOn: "",
	defaultReleaseCadence: "",
	defaultReleaseDay: "",
	defaultReleaseTime: "",
	autoPublishOn: "",
	authors: "",
	copyright: "",
	ownerName: "",
	ownerEmail: ""
}

export const defaultAddNewPodcastDataState = {
	photoFile: "",
	title: "",
	showSummary: "",
	profileUrl: "",
	parentalAdvisory: "clean",
	categories: [],
	language: "",
	countryRegion: "",
	timeZone: "",
	showType: "episodic",
	episodeGrouping: "none",
	episodeNumbering: "yes"
}

export const defaultAddNewPodcastValErrorState = {
	title: "",
	showSummary: "",
	profileUrl: "",
	parentalAdvisory: "",
	categories: "",
	language: "",
	countryRegion: "",
	timeZone: "",
	showType: "",
	episodeGrouping: "",
	episodeNumbering: ""
}

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1)
	const keys2 = Object.keys(object2)

	if (keys1.length !== keys2.length) {
		return false
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false
		}
	}

	return true
}

export const validateBase64 = str => {
	const regex = /^data:image\/(?:gif|png|jpeg|bmp|webp)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/g
	return !!str.match(regex)
}

export const validateURL = str => {
	var pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i"
	) // fragment locator
	return !!pattern.test(str)
}

/**
 * Checks the validity of the email. Performs the following checks:
 * 1. Null email value.
 * 2. Acceptable email length.
 * 3. Check against regex for email format.
 * 4.
 *
 */
export const validateEmail = email => {
	if (!email) {
		return false
	}

	if (email.length > 256) {
		return false
	}

	let emailRegex = /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

	if (!emailRegex.test(email)) {
		return false
	}

	let [account, address] = email.split("@")

	if (account.length > 64) {
		return false
	}

	let domainParts = address.split(".")
	if (
		domainParts.some(part => {
			return part.length >= 64
		})
	) {
		return false
	}

	return true
}

// class Field {
//     constructor(name, errorConditions, errorValidationStatus, noErrorValidationStatus, errorMessages, noErrorMessage,
//                 validationStatusUpdateFunction, errorMessageUpdateFunction) {
//         this.name = name;
//         this.errorConditions = errorConditions;
//         this.errorValidationStatus = errorValidationStatus;
//         this.noErrorValidationStatus = noErrorValidationStatus;
//         this.errorMessages = errorMessages;
//         this.noErrorMessage = noErrorMessage;
//     }
// }

// class FormErrorHandler {

//     constructor(fields) {
//         this.fields = fields;
//     }

//     validate() {
//         for(let field in this.fields) {
//             let fieldHasErrors = false;
//             let errorMessage = '';
//             for(let i = 0; i < this.errorConditions.length; i++) {
//                 if(field.errorConditions[i]) {
//                     fieldHasErrors = true;
//                     errorMessage += field.errorMessages[i] + ' ';
//                 }
//             }

//             if(fieldHasErrors) {
//                 field.validationStatusUpdateFunction(field.errorValidationStatus);
//                 field.errorMessageUpdateFunction(errorMessage);
//             } else {
//                 field.validationStatusUpdateFunction(field.noErrorValidationStatus);
//                 field.errorMessageUpdateFunction(this.noErrorMessage);
//             }
//         }
//     }
// }
