import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { TeamManagementTable } from "./TeamManagementTable"
import { toggleInviteTeamModalVisible } from "redux/actions/TeamManagement"
import { RootState } from "index"
import { usePodcastRole } from "services/queries/PodcastRole"
import { ShowAccess } from "services/types/podcastRoles"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"
// import currentPlanImage from "assets/subscriptionandbilling/currentplan.svg"
// import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
// import layout from "styles/scss/layout.scss"

export function TeamManagement() {
	const dispatch = useDispatch()
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const { data: podcastRoleData } = usePodcastRole({ podcastId: selectedPodcast?.id, userId: authUser.uid })

	const { exceedsCollaboratorLimit } = useLimitChecker()

	const isEditor = podcastRoleData?.podcastRole.showAccess === ShowAccess.editor

	return (
		<>
			{/* <Row style={{marginTop: layout.spaceLg, height: "100%"}} justify="center" align="middle">
                <Col>
                    <ZeroStateCard 
                        title="Team Management"
                        description="Coming soon"
                        image={currentPlanImage}
                        comingSoon
                    />
                </Col>
            </Row> */}
			<Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
				<Col>
					<PodpalButton
						type="primary"
						size="large"
						onClick={() => {
							if (exceedsCollaboratorLimit()) {
								dispatch(toggleLimitsExceededModalVisible())
							} else {
								dispatch(toggleInviteTeamModalVisible())
							}
						}}
						disabled={isEditor}
					>
						Add People
					</PodpalButton>
				</Col>
				{/*               
                <Col>
                    <PodpalButton variant="secondary" size="large" disabled={podcastRoleData?.podcastRole.showAccess !== undefined}>
                        Transfer Ownership
                    </PodpalButton>
                </Col>
                <Col>
                    <PodpalButton variant="secondary" size="large" disabled>
                        Cancel Transfer
                    </PodpalButton>
                </Col>
                  */}
			</Row>
			<Row>
				<Col span={24}>
					<TeamManagementTable />
				</Col>
			</Row>
		</>
	)
}
