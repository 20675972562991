import React, { useState } from "react"
import { Avatar, Button, Row, Col, Collapse, Tooltip } from "antd"
import { PodcastDropdownIcon, AddPodcastIcon, LaunchIcon, AddPodcastHeaderIcon } from "assets/icons/Sidebar"
import { toggleImportPodcastModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { useDispatch, useSelector } from "react-redux"
import "./PodcastDropdown.scss"
import { setSelectedPodcast } from "redux/actions/App"
import { useHistory, useLocation } from "react-router"
import { EPISODES, MY_PODCASTS, POD, PODCAST_SETTINGS, SETTINGS, SHOW_SETTINGS } from "constants/routes"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { usePodcasts } from "services/queries/Podcasts"
import { RootState } from "index"
import { Podcast } from "services/types/podcast"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"

const { Panel } = Collapse

const StyledDropdownIcon = styled(PodcastDropdownIcon)`
	svg {
		transition: all 0.2s ease-out;
	}
	${({ panelOpen }) =>
		panelOpen &&
		css`
			svg {
				transform: rotate(-180deg);
			}
		`}
`

interface DropdownHeaderProps {
	sidebarCollapsed?: boolean
	selectedPodcast: Podcast
	handleIconClick: () => void
	activeKey: string | string[]
}

const DropdownHeader = ({ sidebarCollapsed, selectedPodcast, handleIconClick, activeKey }: DropdownHeaderProps) => {
	const dispatch = useDispatch()

	const { exceedsPodcastLimit } = useLimitChecker()

	const imageSize = sidebarCollapsed ? 48 : 88
	const EMPTY_ARTWORK = `https://storage.googleapis.com/${process.env.REACT_APP_PODCAST_PHOTOS_BUCKET}/1/1`
	let imageSrc = selectedPodcast?.photoUrl
	const settingsLinkHref = `${SETTINGS}${PODCAST_SETTINGS}/${selectedPodcast?.id}${SHOW_SETTINGS}`
	return (
		<div className="panel-header">
			<div className="thumbnail-container">
				<Avatar className="thumbnail" shape="square" size={imageSize} src={imageSrc ?? EMPTY_ARTWORK} />
				{!sidebarCollapsed &&
					(selectedPodcast ? (
						<Link to={`${POD}/${selectedPodcast?.profileUrl}`} target="_blank" rel="noreferrer noopener">
							<div className="thumbnail-overlay">
								<span className="thumbnail-icon">
									<LaunchIcon />
									<p>Visit Profile</p>
								</span>
							</div>
						</Link>
					) : (
						<div
							className="thumbnail-overlay"
							onClick={() => {
								if (exceedsPodcastLimit()) {
									dispatch(toggleLimitsExceededModalVisible())
								} else {
									dispatch(toggleImportPodcastModalVisible())
								}
							}}
						>
							<span className="thumbnail-icon">
								<AddPodcastHeaderIcon fill="#ffffff" />
								<p>Add Podcast</p>
							</span>
						</div>
					))}
			</div>
			{!sidebarCollapsed && selectedPodcast && (
				<>
					<p className="panel-podcast-name">
						<strong>{selectedPodcast?.title ?? null}</strong>
					</p>
					<Link to={settingsLinkHref} className="show-settings-link">
						Show Settings
					</Link>
				</>
			)}
			<Tooltip placement="right" title="Switch Podcasts">
				<StyledDropdownIcon
					onClick={handleIconClick}
					panelOpen={activeKey === "1" ? true : false}
					className={sidebarCollapsed ? "panel-icon-collapsed" : "panel-icon"}
				/>
			</Tooltip>
		</div>
	)
}

const PodcastDropdown = ({ sidebarCollapsed }: { sidebarCollapsed?: boolean }) => {
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)

	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()

	const [activeKey, setActiveKey] = useState<string | string[]>("0")

	const { exceedsPodcastLimit } = useLimitChecker()
	const { status, data: podcastData } = usePodcasts(authUser.uid)
	const EMPTY_ARTWORK = `https://storage.googleapis.com/${process.env.REACT_APP_PODCAST_PHOTOS_BUCKET}/1/1`

	if (status === "error") {
		return (
			<DropdownHeader
				sidebarCollapsed={sidebarCollapsed}
				activeKey={activeKey}
				handleIconClick={() => {}}
				selectedPodcast={selectedPodcast}
			/>
		)
	}

	if (status === "loading") {
		return (
			<DropdownHeader
				sidebarCollapsed={sidebarCollapsed}
				activeKey={activeKey}
				handleIconClick={() => {}}
				selectedPodcast={selectedPodcast}
			/>
		)
	}

	if (!podcastData) {
		return (
			<DropdownHeader
				sidebarCollapsed={sidebarCollapsed}
				activeKey={activeKey}
				handleIconClick={() => {}}
				selectedPodcast={selectedPodcast}
			/>
		)
	}

	const handleAddNewPodcastButtonClick = () => {
		if (exceedsPodcastLimit()) {
			dispatch(toggleLimitsExceededModalVisible())
		} else {
			dispatch(toggleImportPodcastModalVisible())
		}
	}

	const podcastAvatarGridSplit = sidebarCollapsed ? 24 : 8

	const handleIconClick = () => {
		activeKey === "0" ? setActiveKey("1") : setActiveKey("0")
	}

	const handleClick = (podcast: Podcast) => {
		setActiveKey("0")
		if (location.pathname?.includes(MY_PODCASTS)) {
			history.push(`/${podcast.profileUrl}${EPISODES}`)
		} else {
			// remove the profileUrl and then take only the root route. ex /profileUrl/episodes/156887-651adf68-65sdf329 --> /newProfileUrl/episodes
			const newPath = location.pathname?.split(`/${selectedPodcast.profileUrl}`)[1].split("/")
			history.push(`/${podcast.profileUrl}/${newPath[1]}`)
		}
		dispatch(setSelectedPodcast(podcast))
	}

	let podcastAvatars = podcastData?.podcasts?.map((podcast, index) => {
		if (index < 8) {
			return (
				<Col span={podcastAvatarGridSplit} key={podcast.id}>
					<div onClick={() => handleClick(podcast)}>
						<Avatar shape="square" size="default" src={podcast.photoUrl ?? EMPTY_ARTWORK} style={{ cursor: "pointer" }} />
					</div>
				</Col>
			)
		} else {
			return null
		}
	})

	if (podcastAvatars) {
		podcastAvatars[podcastAvatars.length] = (
			<Col span={podcastAvatarGridSplit} key="add-podcast-button">
				<Button className="add-podcast-button" icon={<AddPodcastIcon />} onClick={handleAddNewPodcastButtonClick} />
			</Col>
		)
	} else {
		podcastAvatars = [
			<Col span={podcastAvatarGridSplit} key="add-podcast-button">
				<Button className="add-podcast-button" icon={<AddPodcastIcon />} onClick={handleAddNewPodcastButtonClick} />
			</Col>
		]
	}

	const verticalGutter = sidebarCollapsed ? 0 : 16

	return (
		<div onClick={handleIconClick}>
			<DropdownHeader
				sidebarCollapsed={sidebarCollapsed}
				activeKey={activeKey}
				handleIconClick={handleIconClick}
				selectedPodcast={selectedPodcast}
			/>
			<Collapse
				className="podcast-dropdown"
				defaultActiveKey={["0"]}
				activeKey={activeKey}
				bordered={false}
				expandIconPosition="right"
				onChange={key => setActiveKey(key)}
			>
				<Panel key="1" showArrow={false} header={undefined}>
					<div className="more-podcasts">
						<Row gutter={[0, verticalGutter]}>{podcastAvatars}</Row>
					</div>
				</Panel>
			</Collapse>
		</div>
	)
}

export default PodcastDropdown
