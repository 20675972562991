import axios from "axios"
import { EpisodeReview } from "./types/episodeReview"

export const createEpisodeReview = (episodeReview: EpisodeReview) =>
	axios.post("/api/twirp/podpal.administration.EpisodeReviewService/Create", {
		episodeReview
	})

export const findEpisodeReviewsByEpisodeID = (episodeId: string) =>
	axios.post("/api/twirp/podpal.administration.EpisodeReviewService/FindByEpisodeID", {
		episodeId
	})

export const updateEpisodeReview = (episodeReview: EpisodeReview) =>
	axios.post("/api/twirp/podpal.administration.EpisodeReviewService/Update", {
		episodeReview
	})

export const deleteEpisodeReview = (episodeReviewId: string | undefined) =>
	axios.post("/api/twirp/podpal.administration.EpisodeReviewService/Delete", {
		episodeReviewId
	})
