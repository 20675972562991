import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "antd"
import PodpalModal from "../../PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { toggleVideoTutorialModalVisible, setVideoTutorialModalConfig } from "redux/actions/HelpCenter"
import { RootState } from "index"
import styled from "styled-components"
import { VIDEO_TUTORIALS } from "constants/routes"
import { Link } from "react-router-dom"

const StyledPodpalModal = styled(PodpalModal)`
	iframe {
		width: 100%;
		height: 100%;
		border-radius: ${({ theme }) => theme.radiusLg};
		border: none;
	}

	a {
		color: ${({ theme }) => theme.podpalBlue};
	}
`

export const VideoTutorialModal = () => {
	const dispatch = useDispatch()

	const visible = useSelector((state: RootState) => state.helpCenter.videoTutorialModalVisible)
	const config = useSelector((state: RootState) => state.helpCenter.videoTutorialModalConfig)

	const handleClose = () => {
		dispatch(toggleVideoTutorialModalVisible())
		dispatch(setVideoTutorialModalConfig(null))
	}

	return (
		<StyledPodpalModal
			centered
			destroyOnClose
			footer={
				<Row justify="space-between" align="middle">
					<Col>
						<Link
							to={VIDEO_TUTORIALS}
							onClick={() => {
								dispatch(toggleVideoTutorialModalVisible())
							}}
						>
							More Tutorials
						</Link>
					</Col>
					<Col>
						<PodpalButton type="primary" size="large" onClick={handleClose}>
							OK
						</PodpalButton>
					</Col>
				</Row>
			}
			onCancel={handleClose}
			title={config?.title}
			visible={visible}
			width={480}
		>
			<Row gutter={24}>
				<Col span={24}>
					<div>
						<iframe
							src="https://www.youtube.com/embed/THCmck_57M0"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</Col>
			</Row>
		</StyledPodpalModal>
	)
}
