import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Tabs } from "antd"
import useWindowSize from "hooks/useWindowSize"

const { TabPane } = Tabs
const GenericSettingsPage = ({ footer = null, heading, settingsTabsArray, children, basePath }) => {
	const location = useLocation()
	const [activeTab, setActiveTab] = useState(null)

	useEffect(() => {
		const currentRoute = location.pathname.split(basePath)[1]
		setActiveTab(`${currentRoute}`)
	}, [location, basePath])

	const returnAppropriateIcon = (path, filledIcon, normalIcon) => {
		if (location.pathname.includes(path)) {
			return filledIcon ? React.createElement(filledIcon) : null
		} else {
			return normalIcon ? React.createElement(normalIcon, { className: "settings-page-tab-icon" }) : null
		}
	}

	const size = useWindowSize()
	const isMobile = size.width <= 414

	return (
		<div className="settings-page-container">
			<div className="settings-page-heading-container">
				<h1 className="settings-page-heading">{heading}</h1>
				{isMobile && footer}
			</div>
			<div className="settings-page-tab-container">
				<Tabs tabPosition="top" animated={false} activeKey={activeTab}>
					{settingsTabsArray.map(settingsTab => (
						<TabPane
							forceRender={true}
							tab={
								<Link to={`${basePath}${settingsTab.PATH}`}>
									<div className="settings-page-tab-title-container">
										<span className="settings-page-tab-title-icon">
											{returnAppropriateIcon(settingsTab.PATH, settingsTab["FILLED_ICON"], settingsTab["ICON"])}
										</span>
										<span className="settings-page-tab-title-text">{settingsTab["TITLE"]}</span>
									</div>
								</Link>
							}
							key={settingsTab.PATH}
						>
							{children}
						</TabPane>
					))}
				</Tabs>
			</div>
			{!isMobile && footer}
		</div>
	)
}

export default GenericSettingsPage
