import React from "react"
import { Row, Col, Upload } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { UploadOutlined } from "@ant-design/icons"
import "./AudioUpload.scss"

const AudioUpload = ({ webAudio, localAudio, validateAudioFile, handleRemove }) => {
	const hasAudio = webAudio || localAudio
	return (
		<>
			<div style={{ display: hasAudio ? "none" : undefined }}>
				<Upload accept=".mp3,.m4a" showUploadList={false} beforeUpload={validateAudioFile} customRequest={() => {}}>
					<PodpalButton size="large" icon={<UploadOutlined />} type="primary">
						Upload Recording
					</PodpalButton>
				</Upload>
			</div>
			{hasAudio && (
				<Row gutter={8} justify="start">
					<Col className="audio-bio-col">
						<audio controls>
							<source src={localAudio || webAudio} type="audio/mp3" />
							<source src={localAudio || webAudio} type="audio/mp4" />
						</audio>
					</Col>
					<Col className="audio-bio-col">
						<PodpalButton size="large" variant="secondary" onClick={handleRemove}>
							Remove
						</PodpalButton>
					</Col>
				</Row>
			)}
		</>
	)
}

export default AudioUpload
