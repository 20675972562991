import { useQuery } from "react-query"
import {
	findPodcastRoleByPodcastAndUserID,
	findPodcastRoleByUserId,
	validateAccess
} from "services/podcast-role.service"
import { keysToCamel } from "utils/camelCaseUtils"
import {
	FindByUserIdResponse,
	FindByPodcastAndUserIDRequest,
	FindByPodcastAndUserIDResponse,
	ValidateAccessRequest,
	ValidateAccessResponse
} from "../types/podcastRoles"

export const usePodcastRole = (request: FindByPodcastAndUserIDRequest) => {
	return useQuery<FindByPodcastAndUserIDResponse>(
		["podcastRole", request.podcastId, request.userId],
		async () => {
			const { data } = await findPodcastRoleByPodcastAndUserID(request)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!request }
	)
}

export const usePodcastRoles = (userId: string) => {
	return useQuery<FindByUserIdResponse>(
		["podcastRoles", userId],
		async () => {
			const { data } = await findPodcastRoleByUserId(userId)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!userId }
	)
}

export const usePodcastAccessValidator = ({ userId, podcastId }: ValidateAccessRequest) => {
	return useQuery<ValidateAccessResponse>(
		["podcastAccessValidator", userId, podcastId],
		async () => {
			const { data } = await validateAccess({ userId, podcastId })
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!userId && !!podcastId }
	)
}
