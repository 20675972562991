import React from "react"
import { Radio } from "antd"

import "./PodpalRadioGroup.scss"

const PodpalRadioButtonGroup = ({
	buttons,
	name,
	size,
	className,
	value,
	defaultValue,
	onChange,
	optionType = "button",
	disabled,
	stacked = false,
	stackedOnMobile = false,
	onMouseEnter,
	onMouseLeave,
	children
}) => {
	return (
		<Radio.Group
			name={name}
			size={size}
			value={value}
			defaultValue={defaultValue}
			className={`radio-button-group ${stacked ? "stacked-radio-button" : ""} ${
				stackedOnMobile ? "stacked-on-mobile-radio-button" : ""
			} ${className ?? ""}`}
			onChange={onChange}
			optionType={optionType}
			options={buttons}
			disabled={disabled}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{children}
		</Radio.Group>
	)
}

export default PodpalRadioButtonGroup
