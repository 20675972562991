import { RootState } from "index"
import { Form, Input } from "antd"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PodpalModal from "../../PodpalModal"
import { FIREBASE_ERROR_CODES, FIELD_NAMES, EMAIL_REQUIRED, EMAIL_INVALID } from "constants/strings/Auth"
import { toggleForgotPasswordModal } from "redux/actions/SignIn"
import { forgotPassword } from "services/firebase.service"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { emailSentModal } from "../UserModals"

export function ForgotPasswordModal() {
	const [form] = Form.useForm()
	const dispatch = useDispatch()

	const visible = useSelector((state: RootState) => state.login.forgotPasswordModalVisible)
	const [loading, setLoading] = useState(false)

	const handleForgotPassword = async (values: any) => {
		setLoading(true)
		try {
			await forgotPassword(values.email)
			setLoading(false)
			handleClose()
			emailSentModal()
		} catch (e: any) {
			if (e.code === FIREBASE_ERROR_CODES.USER_NOT_FOUND) {
				errorAlert("The email you entered is not associated with a Podpal account.")
			} else {
				console.log(e)
				errorAlert("Failed to send a password reset email.")
			}
			setLoading(false)
		}
	}

	const handleClose = () => {
		form.resetFields()
		dispatch(toggleForgotPasswordModal())
	}

	return (
		<PodpalModal
			centered
			forceRender
			closable
			destroyOnClose
			visible={visible}
			title="Reset Password"
			onCancel={handleClose}
			footer={[
				<PodpalButton variant="secondary" onClick={handleClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton type="primary" onClick={() => form.submit()} loading={loading}>
					Send Email
				</PodpalButton>
			]}
			width={420}
		>
			<Form form={form} onFinish={handleForgotPassword}>
				<Form.Item
					name={FIELD_NAMES.EMAIL}
					rules={[
						{
							required: true,
							message: EMAIL_REQUIRED
						},
						{
							type: "email",
							message: EMAIL_INVALID
						}
					]}
				>
					<Input size="large" name={FIELD_NAMES.EMAIL} placeholder="Email" />
				</Form.Item>
			</Form>
		</PodpalModal>
	)
}
