import React, { useState } from "react"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import useToggle from "hooks/useToggle"
import { Row, Col, Form, Input } from "antd"
import { INCORRECT_PASSWORD_ERROR_MESSAGE, INCORRECT_PODCAST_NAME } from "constants/strings/UserSettings/MyAccount/Form"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { reauthenticateEmailUser } from "services/firebase.service"
import {
	toggleDeletePodcastModalVisible,
	deletePodcast,
	deletePodcastSuccess,
	deletePodcastError
} from "redux/actions/Podcasts"
import PodpalModal from "components/common/Modals/PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import deletePodcastImage from "assets/podcasts/deletepodcast.svg"
import "./DeletePodcastModal.scss"

export const DeletePodcastModal = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const visible = useSelector(state => state.podcast.deletePodcastModalVisible)
	const providerData = useSelector(state => state.app.authUser.providerData)
	const authUser = useSelector(state => state.app.authUser)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)

	const TITLES = {
		CONFIRMATION: "Delete Podcast",
		CONFIRM_PASSWORD: "Confirm Password",
		VERIFICATION_CODE: "Enter Verification Code",
		CONFIRM_PODCAST: "Confirm Podcast",
		FINAL_CONFIRMATION: "Delete Podcast "
	}

	const FIELD_NAMES = {
		CONFIRM_PASSWORD: "password",
		CONFIRM_PODCAST: "confirmPodcast"
	}

	const [fields, setFields] = useState({
		password: "",
		confirmPodcast: ""
	})
	const [validationStatuses, setValidationStatuses] = useState({
		password: "",
		confirmPodcast: ""
	})
	const [errorMessages, setErrorMessages] = useState({
		password: "",
		confirmPodcast: ""
	})

	const [isArtworkModal, setIsArtworkModal] = useState(false)
	const [loading, toggleLoading] = useToggle()
	const [title, setTitle] = useState(TITLES.CONFIRMATION)

	const handleChange = e => {
		const name = e.target.name
		const value = e.target.value
		const temp = fields
		temp[name] = value
		setFields(fields)
	}

	const changeValidationStatus = (fieldName, fieldValue) => {
		const temp = validationStatuses
		temp[fieldName] = fieldValue
		setValidationStatuses({ ...temp })
	}

	const changeErrorMessage = (fieldName, fieldValue) => {
		const temp = errorMessages
		temp[fieldName] = fieldValue
		setErrorMessages({ ...temp })
	}

	const setConfig = (title, isArtworkModal) => {
		setTitle(title)
		setIsArtworkModal(isArtworkModal)
	}

	const onConfirmationOk = () => {
		if (providerData.some(provider => provider.providerId === "password")) {
			setConfig(TITLES.CONFIRM_PASSWORD, false)
		} else {
			setConfig(TITLES.CONFIRM_PODCAST, false)
		}
	}

	const onConfirmPasswordOk = async () => {
		toggleLoading()
		try {
			await reauthenticateEmailUser({ email: authUser.email, password: fields[FIELD_NAMES.CONFIRM_PASSWORD] })
			setConfig(TITLES.CONFIRM_PODCAST, false)
			toggleLoading()
		} catch (e) {
			changeValidationStatus(FIELD_NAMES.CONFIRM_PASSWORD, "error")
			changeErrorMessage(FIELD_NAMES.CONFIRM_PASSWORD, INCORRECT_PASSWORD_ERROR_MESSAGE)
			toggleLoading()
		}
	}

	const onConfirmPodcastOk = () => {
		toggleLoading()

		if (fields.confirmPodcast !== selectedPodcast.title) {
			toggleLoading()
			changeValidationStatus(FIELD_NAMES.CONFIRM_PODCAST, "error")
			changeErrorMessage(FIELD_NAMES.CONFIRM_PODCAST, INCORRECT_PODCAST_NAME)
		} else {
			dispatch(deletePodcast(selectedPodcast.id, authUser.uid))
				.then(res => {
					toggleLoading()
					setConfig(TITLES.FINAL_CONFIRMATION, true)
				})
				.catch(err => {
					toggleLoading()
					dispatch(deletePodcastError())
				})
		}
	}

	const onCancel = () => {
		dispatch(toggleDeletePodcastModalVisible())
		setConfig(TITLES.CONFIRMATION, true)
		setFields({
			password: "",
			confirmPodcast: ""
		})
		setValidationStatuses({
			password: "",
			confirmPodcast: ""
		})
		setErrorMessages({
			password: "",
			confirmPodcast: ""
		})
	}

	const onCancelWithRedirect = () => {
		onCancel()
		history.push("/")
		dispatch(deletePodcastSuccess(selectedPodcast.id))
		successAlert("Podcast successfully deleted.")
	}

	const constructArtworkBody = (header, image, boldText, text) => {
		return (
			<div>
				<Row align="middle" justify="center" gutter={[0, 24]}>
					<Col>
						<h2 className="delete-podcast-modal-confirmation-header">{header}</h2>
					</Col>
				</Row>
				<Row align="middle" justify="center" gutter={[0, 24]}>
					<Col>
						<img src={image} alt="deletepodcast" />
					</Col>
				</Row>
				<Row align="middle" justify="center" gutter={[0, 8]}>
					<Col>
						<p className="delete-podcast-modal-confirmation-bold-text">{boldText}</p>
					</Col>
				</Row>
				<Row align="middle" justify="center">
					<Col>
						<p className="delete-podcast-modal-confirmation-text">{text}</p>
					</Col>
				</Row>
			</div>
		)
	}

	const confirmationBody = (
		<div>
			<Row align="middle" justify="center">
				<p className="delete-podcast-modal-text">
					Wait a second! When you delete this podcast, you will lose all of the following for this show:
				</p>
			</Row>
			<Row align="middle">
				<Col>
					<ul className="delete-podcast-list">
						<li>Episodes</li>
						<li>Recordings</li>
						<li>Contacts</li>
						<li>Calendar Events</li>
						<li>Analytics</li>
						<li>Podcast website (Pod page)</li>
						<li>Podcast @username for this podcast</li>
					</ul>
				</Col>
			</Row>
			<Row align="middle">
				<Col>
					<p className="delete-podcast-modal-text">Are you sure you want to do this?</p>
				</Col>
			</Row>
		</div>
	)

	const confirmPasswordBody = (
		<div>
			<Row gutter={[0, 10]}>
				<Col>
					<p className="delete-podcast-modal-description">Please enter your password to continue.</p>
				</Col>
			</Row>
			<Form>
				<Form.Item
					name={FIELD_NAMES.CONFIRM_PASSWORD}
					validateStatus={validationStatuses[FIELD_NAMES.CONFIRM_PASSWORD]}
					help={errorMessages[FIELD_NAMES.CONFIRM_PASSWORD]}
				>
					<Input.Password name={FIELD_NAMES.CONFIRM_PASSWORD} onChange={handleChange} />
				</Form.Item>
			</Form>
		</div>
	)

	const confirmPodcastBody = (
		<div>
			<Row gutter={[0, 10]}>
				<Col>
					<p className="delete-podcast-modal-description">Please enter the name of your podcast to continue.</p>
				</Col>
			</Row>
			<Form>
				<Form.Item
					name={FIELD_NAMES.CONFIRM_PODCAST}
					validateStatus={validationStatuses[FIELD_NAMES.CONFIRM_PODCAST]}
					help={errorMessages[FIELD_NAMES.CONFIRM_PODCAST]}
				>
					<Input name={FIELD_NAMES.CONFIRM_PODCAST} onChange={handleChange} />
				</Form.Item>
			</Form>
		</div>
	)

	const deletedBody = constructArtworkBody(
		"Podcast officially deleted.",
		deletePodcastImage,
		"We've successfully deleted your podcast from Podpal."
	)
	const confirmationFooter = [
		<PodpalButton key="cancel" variant="secondary" onClick={onCancel}>
			Cancel
		</PodpalButton>,
		<PodpalButton
			key="delete"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			danger
			onClick={onConfirmationOk}
		>
			Delete Podcast
		</PodpalButton>
	]
	const confirmPasswordFooter = [
		<PodpalButton key="cancel" variant="secondary" onClick={onCancel}>
			Cancel
		</PodpalButton>,
		<PodpalButton
			key="next"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			type="primary"
			onClick={onConfirmPasswordOk}
		>
			Next
		</PodpalButton>
	]
	const confirmPodcastFooter = [
		<PodpalButton
			key="delete"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			danger
			onClick={onConfirmPodcastOk}
		>
			Delete Podcast
		</PodpalButton>
	]
	const deletedFooter = [
		<PodpalButton
			key="ok"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			type="primary"
			onClick={onCancelWithRedirect}
		>
			OK
		</PodpalButton>
	]

	let body,
		footer = null

	switch (title) {
		case TITLES.CONFIRM_PASSWORD:
			body = confirmPasswordBody
			footer = confirmPasswordFooter
			break
		case TITLES.CONFIRM_PODCAST:
			body = confirmPodcastBody
			footer = confirmPodcastFooter
			break
		case TITLES.FINAL_CONFIRMATION:
			body = deletedBody
			footer = deletedFooter
			break
		default:
			body = confirmationBody
			footer = confirmationFooter
	}

	return (
		<PodpalModal
			centered
			className="delete-podcast-modal"
			closable
			maskClosable={false}
			destroyOnClose
			title={title}
			visible={visible}
			footer={footer}
			width={isArtworkModal ? 480 : 420}
			onCancel={title === TITLES.FINAL_CONFIRMATION ? onCancelWithRedirect : onCancel}
		>
			{body}
		</PodpalModal>
	)
}
