import React, { useEffect } from "react"
import styled from "styled-components"
import { Row, Col, Alert } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useEpisodesWithStatus } from "services/queries/Episodes"
import { updateMigrationInfo } from "services/migrationInfo.service"
import { useMigrationInfo } from "services/queries/MigrationInfo"
import {
	toggleImportEpisodesStatusModalVisible,
	setPodcastIDForImportEpisodesModal,
	toggleAddNewEpisodeDrawer
} from "redux/actions/Episodes/AddNewEpisode"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { MigratingIcon, MigrationSuccessIcon } from "assets/icons/Episodes"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { useQueryClient } from "react-query"
import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
import episodesZeroStateImage from "assets/zerostates/Episodes.svg"
import { RootState } from "index"
import { Redirect, Route, Switch, useHistory, useParams } from "react-router"
import { ALL, COMPLETE, EPISODES, IN_WORK, PUBLISHED, NOT_FOUND, NO_ACCESS } from "constants/routes"
import { EpisodeList } from "components/Episodes/EpisodeList"
import { NavLink } from "react-router-dom"
import { EpisodeProgressKind } from "services/episode.service"
import { QueryOptions, Operators, PropertyTypes } from "services/types/queryOptions"
import { BREAKPOINT_XS } from "constants/breakpoints"
import useWindowSize from "hooks/useWindowSize"
import { updateSelectedPodcastFromProfileUrl } from "redux/actions/App"
import { usePodcastAccessValidator } from "services/queries/PodcastRole"
import Banner301RedirectRequested from "components/Podcasts/Banner301RedirectRequested"

const StyledAlert = styled(Alert)`
	padding: 8px 12px 8px 12px !important;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.alert-row {
		flex-wrap: nowrap;
	}
	.alert-icon {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	.ant-alert-close-icon {
		position: static;
		font-size: 16px;
		width: 60px;
	}
	.anticon.anticon-close {
		width: 16px;
	}
`
const StyledSecondaryHeading = styled.p`
	font: ${({ theme }) => theme.fontSm};
	color: ${({ theme }) => theme.gray7};
`

const StyledTab = styled(Col)`
	a {
		color: ${({ theme }) => theme.gray7};
		&.is-active,
		&:hover {
			color: ${({ theme }) => theme.podpalBlue};
		}
	}
`

export function Episodes() {
	const dispatch = useDispatch()
	const history = useHistory()
	const queryClient = useQueryClient()
	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const { profileUrl } = useParams<{ profileUrl: string }>()

	const {
		isLoading: podcastAccessLoading,
		error: podcastAccessError,
		data: podcastAccessData
	} = usePodcastAccessValidator({ userId: authUser?.uid, podcastId: selectedPodcast?.id })

	useEffect(() => {
		if (selectedPodcast?.profileUrl !== profileUrl) {
			dispatch(updateSelectedPodcastFromProfileUrl(profileUrl))
		}
	}, [dispatch, selectedPodcast, profileUrl])

	useEffect(() => {
		if (!podcastAccessLoading && !podcastAccessError && podcastAccessData && !podcastAccessData.hasAccess) {
			history.push(NO_ACCESS)
		}
	}, [history, podcastAccessLoading, podcastAccessError, podcastAccessData])

	const { data: migrationInfoData } = useMigrationInfo(selectedPodcast?.id)

	const migratedEpisodes = migrationInfoData?.migrationInfo?.migratedEpisodes ?? 0
	const failedEpisodes = migrationInfoData?.migrationInfo?.failedEpisodes ?? 0
	const totalEpisodes = migrationInfoData?.migrationInfo?.totalEpisodes ?? 0
	const migrationPercentCompleted = Math.trunc(((migratedEpisodes + failedEpisodes) / totalEpisodes) * 100)

	const basePath = `/${selectedPodcast?.profileUrl}${EPISODES}`

	const queryOptions: QueryOptions = {
		filters: [
			{
				propertyName: "PodcastID",
				propertyValue: (selectedPodcast?.id as string) ?? "",
				propertyType: PropertyTypes.string,
				operator: Operators.equal
			}
		],
		orders: [
			{
				propertyName: "IsPublished",
				sort: "ASC"
			},
			{
				propertyName: "CreatedAt",
				sort: "DESC"
			},
			{
				propertyName: "LastPublishedDate",
				sort: "DESC"
			}
			// {
			// 	propertyName: "SeasonNumber",
			// 	sort: "DESC",
			// },
			// {
			// 	propertyName: "EpisodeNumber",
			// 	sort: "DESC",
			// },
			// {
			// 	propertyName: "CreatedAt",
			// 	sort: "DESC",
			// }
		]
	}

	const { status: allEpisodesStatus, data: allEpisodes } = useEpisodesWithStatus({
		podcastId: selectedPodcast?.id,
		kind: "0",
		queryOptions
	})

	if (allEpisodesStatus === "error" || podcastAccessError) {
		return <div>Error loading episodes</div>
	}

	if (allEpisodesStatus === "loading" || podcastAccessLoading) {
		return <div>Loading episodes</div>
	}

	const alertText =
		migrationPercentCompleted < 100 ? (
			"We're hard at work importing your podcast! Please hang tight, and we'll notify you as soon as it's all set up and ready to go."
		) : (
			<>
				Your podcast import was successful! But, for future updates in Podpal to affect your RSS Feed, you need to finalize
				a{" "}
				<a
					href="http://helpcenter.podpal.com/en/articles/6680666-what-is-a-301-redirect"
					target="_blank"
					rel="noopener noreferrer"
				>
					301 Redirect
				</a>{" "}
				with your previous host. Completing a 301 Redirect is essential to avoiding duplicates on listening platforms
				(Apple, Spotify, etc.) and to retaining your existing subscribers.
			</>
		)

	return (
		<div className="banner-page-container">
			<Banner301RedirectRequested />
			{migrationInfoData?.migrationInfo?.isMigrating && (
				<StyledAlert
					banner
					type={migrationPercentCompleted < 100 ? "warning" : "success"}
					message={
						<Row gutter={6} className="alert-row">
							<Col className="alert-icon">
								{migrationPercentCompleted < 100 ? <MigratingIcon /> : <MigrationSuccessIcon />}
							</Col>
							<Col className="alert-text">
								{migrationPercentCompleted}% Complete - {alertText}{" "}
								<PodpalButton
									type="link"
									variant="primary"
									size="small"
									onClick={() => {
										dispatch(setPodcastIDForImportEpisodesModal(selectedPodcast?.id))
										dispatch(toggleImportEpisodesStatusModalVisible())
									}}
								>
									{migrationPercentCompleted < 100 ? "View Progress" : "View Summary"}
								</PodpalButton>
							</Col>
						</Row>
					}
					closable={migrationPercentCompleted >= 100}
					onClose={async () => {
						try {
							await updateMigrationInfo({
								...migrationInfoData?.migrationInfo,
								isMigrating: false
							})
							queryClient.invalidateQueries(["migrationInfo", selectedPodcast?.id])
						} catch (e) {
							errorAlert("Sorry, an unknown error occurred.")
						}
					}}
					showIcon={false}
				/>
			)}
			<div className="home-page-container">
				{allEpisodes?.episodesWithProgress ? (
					<>
						{!isMobile && (
							<Row justify="start" align="middle" gutter={[0, 12]}>
								<Col>
									<StyledSecondaryHeading>{selectedPodcast?.title}</StyledSecondaryHeading>
								</Col>
							</Row>
						)}
						<h1 className="home-page-heading">Episodes</h1>
						<Row gutter={[24, 24]}>
							<StyledTab>
								<NavLink activeClassName="is-active" to={`${basePath}${ALL}`}>
									All
								</NavLink>
							</StyledTab>
							<StyledTab>
								<NavLink activeClassName="is-active" to={`${basePath}${IN_WORK}`}>
									In Work
								</NavLink>
							</StyledTab>
							<StyledTab>
								<NavLink activeClassName="is-active" to={`${basePath}${COMPLETE}`}>
									Complete
								</NavLink>
							</StyledTab>
							<StyledTab>
								<NavLink activeClassName="is-active" to={`${basePath}${PUBLISHED}`}>
									Published
								</NavLink>
							</StyledTab>
							<Col span={24}>
								<Switch>
									<Redirect exact from={basePath} to={`${basePath}${ALL}`} />
									<Route path={`${basePath}${ALL}`}>
										<EpisodeList kind={EpisodeProgressKind.all} />
									</Route>
									<Route path={`${basePath}${IN_WORK}`}>
										<EpisodeList kind={EpisodeProgressKind.inWork} />
									</Route>
									<Route path={`${basePath}${COMPLETE}`}>
										<EpisodeList kind={EpisodeProgressKind.complete} />
									</Route>
									<Route path={`${basePath}${PUBLISHED}`}>
										<EpisodeList kind={EpisodeProgressKind.published} />
									</Route>
									<Redirect to={NOT_FOUND} />
								</Switch>
							</Col>
						</Row>
					</>
				) : (
					<Row justify="center" align="middle" style={{ height: "100%" }}>
						<Col>
							<ZeroStateCard
								title="Add Your First Episode"
								description="Now that you’ve added your show to Podpal, it’s time to create your first episode!"
								image={episodesZeroStateImage}
								primaryButtonProps={{
									text: "Add Episode",
									disabled: !selectedPodcast,
									disabledTooltipText: !selectedPodcast ? "Create a podcast to add an episode." : undefined,
									onClick: () => dispatch(toggleAddNewEpisodeDrawer())
								}}
							/>
						</Col>
					</Row>
				)}
			</div>
		</div>
	)
}
