import axios from "axios"
import { v4 as uuidv4 } from "uuid"

export const createRecording = recording =>
	axios.post("/api/twirp/podpal.administration.RecordingService/Create", {
		requestId: uuidv4(),
		recording
	})

export const findRecordingsByPodcastId = (podcastId, queryOptions) =>
	axios.post("/api/twirp/podpal.administration.RecordingService/FindByPodcastID", {
		requestId: uuidv4(),
		podcastId,
		queryOptions
	})

export const findRecordingByEpisodeId = episodeId =>
	axios.post("/api/twirp/podpal.administration.RecordingService/FindByEpisodeID", {
		requestId: uuidv4(),
		episodeId
	})

export const updateRecording = newRecording =>
	axios.post("/api/twirp/podpal.administration.RecordingService/Update", {
		requestId: uuidv4(),
		newRecording
	})

export const deleteRecording = recording =>
	axios.post("/api/twirp/podpal.administration.RecordingService/Delete", {
		requestId: uuidv4(),
		recording
	})

export const validateRecordingFileName = (fileName, podcastId) =>
	axios.post("/api/twirp/podpal.administration.RecordingService/ValidateFileName", {
		requestId: uuidv4(),
		fileName,
		podcastId
	})

export const replaceRecording = (oldRecordingId, newRecording) =>
	axios.post("/api/twirp/podpal.administration.RecordingService/Replace", {
		requestId: uuidv4(),
		oldRecordingId,
		newRecording
	})
