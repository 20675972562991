import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { PodcastInfo } from "./types/podcastInfo"

export const findPodcastInfoByPodcastID = (podcastId: string) =>
	axios.post("/api/twirp/podpal.iam.PodcastInfoService/FindByPodcastID", {
		requestId: uuidv4(),
		podcastId
	})

export const updatePodcastInfo = (podcastInfo: PodcastInfo) =>
	axios.post("/api/twirp/podpal.iam.PodcastInfoService/Update", {
		requestId: uuidv4(),
		podcastInfo
	})
