import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { UserInfo } from "./types/userInfo"

export const findUserInfoByUserID = (userId: string) =>
	axios.post("/api/twirp/podpal.iam.UserInfoService/FindByUserID", {
		requestId: uuidv4(),
		userId
	})

export const findOwnerUserInfoByPodcastID = (podcastId: string) =>
	axios.post("/api/twirp/podpal.iam.UserInfoService/FindOwnerByPodcastID", {
		requestId: uuidv4(),
		podcastId
	})

export const updateUserInfo = (userInfo: UserInfo) =>
	axios.post("/api/twirp/podpal.iam.UserInfoService/Update", {
		requestId: uuidv4(),
		userInfo
	})
