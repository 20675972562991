import { useState } from "react"
import colors from "styles/scss/color.scss"

export default function useColorPicker({ onColorChange }) {
	const [selectedColor, setSelectedColor] = useState(colors.podpalBlue)
	const [showColorPicker, setShowColorPicker] = useState(false)

	const handleColorPickerClick = () => {
		setShowColorPicker(true)
	}

	const handleColorPickerClose = () => {
		setShowColorPicker(false)
	}

	const handleColorChange = colorValue => {
		setSelectedColor(colorValue.hex)
		onColorChange(colorValue.hex)
	}

	const setDefaultColor = value => {
		setSelectedColor(value)
	}

	return {
		setDefaultColor,
		selectedColor,
		setSelectedColor,
		showColorPicker,
		handleColorPickerClose,
		handleColorPickerClick,
		handleColorChange
	}
}
