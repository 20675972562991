import { Card, Col, Row } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { RootState } from "index"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import PodpalModal from "../../PodpalModal"
import { toggleWhatsNewModalVisible } from "redux/actions/HelpCenter"

const StyledSecondaryText = styled.span`
	color: ${({ theme }) => theme.textSecondary};
	font: ${({ theme }) => theme.fontXs};
`

const StyledTitle = styled.span`
	color: ${({ theme }) => theme.textPrimary};
	font: ${({ theme }) => theme.fontMd};
`

interface WhatsNewCardProps {
	date: string
	title: string
	description: string
	blogUrl: string
}

const StyledCard = styled(Card)`
	height: 180px;
	a {
		color: ${({ theme }) => theme.podpalBlue};
	}
`

function WhatsNewCard({ date, title, description, blogUrl }: WhatsNewCardProps) {
	return (
		<StyledCard bordered={false}>
			<Row gutter={[0, 8]}>
				<Col>
					<StyledSecondaryText>{date}</StyledSecondaryText>
				</Col>
			</Row>
			<Row gutter={[0, 8]}>
				<Col>
					<StyledTitle>{title}</StyledTitle>
				</Col>
			</Row>
			<Row gutter={[0, 8]}>
				<StyledSecondaryText>{description}</StyledSecondaryText>
			</Row>
			<Row>
				<Col>
					<a href={blogUrl} target="_blank" rel="noopener noreferrer">
						Learn more on our blog
					</a>
				</Col>
			</Row>
		</StyledCard>
	)
}

const StyledPodpalModal = styled(PodpalModal)`
	.ant-modal-body {
		padding: 0;
	}

	.ant-modal-header {
		border-bottom: 1px solid ${({ theme }) => theme.neutral2};
	}

	.ant-modal-body {
		max-height: 360px;
		overflow-y: scroll;
	}

	.ant-card:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.neutral2};
	}

	.ant-modal-footer {
		border-top: 1px solid ${({ theme }) => theme.neutral2};
	}
`

export function WhatsNewModal() {
	const dispatch = useDispatch()
	const visible = useSelector((state: RootState) => state.helpCenter.whatsNewModalVisible)

	const WHATS_NEW: WhatsNewCardProps[] = [
		{
			date: "August 1, 2021",
			title: "We've officially launched! 🚀",
			description:
				"Welcome to Podpal, the all-in-one podcast management platform! We're so excited that you're here and hope that we can assist you with becoming a more efficient and less overwhelmed podcaster. Take a look around, explore the features, and please don't hesitate to reach out to us if you run into a problem. We're here to help! Our team can be reached by email at support@podpal.com.",
			blogUrl: "https://youtu.be/THCmck_57M0"
		}
	]

	const cards = WHATS_NEW.map(props => <WhatsNewCard key={props.blogUrl} {...props} />)

	return (
		<StyledPodpalModal
			centered
			title="What's New?"
			width={480}
			visible={visible}
			footer={[<PodpalButton type="primary">Learn More</PodpalButton>]}
			onCancel={() => dispatch(toggleWhatsNewModalVisible())}
		>
			{cards}
		</StyledPodpalModal>
	)
}
