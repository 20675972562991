import {
	TOGGLE_ADD_NEW_PODCAST_DRAWER,
	TOGGLE_IMPORT_PODCAST_DRAWER,
	SET_INITIAL_VALUES_FOR_IMPORT_PODCAST_DRAWER,
	SET_CONFIG_FOR_IMPORT_PODCAST_DRAWER,
	TOGGLE_ADD_NEW_PODCAST_CONFIRMATION_MODAL_VISIBLE,
	TOGGLE_MISSING_ARTWORK_MODAL_VISIBLE,
	ADD_CHANGE_TO_ADD_NEW_PODCAST_FORM,
	SET_NEW_PODCAST_ID_FOR_CONFIRMATION_MODAL,
	TOGGLE_IMPORT_PODCAST_MODAL_VISIBLE,
	TOGGLE_301_REDIRECT_MODAL_VISIBLE,
	TOGGLE_301_REDIRECT_OUT_MODAL_VISIBLE,
	SET_PODCAST_ID_FOR_301_REDIRECT_MODAL
} from "constants/actionTypes"

const initialState = {
	addNewPodcastDrawerVisible: false,
	importPodcastDrawerVisible: false,
	importPodcastDrawerInitialValues: null,
	addNewPodcastConfirmationModalVisible: false,
	missingArtworkModalVisible: false,
	podcastAlbumCoverImage: "",
	categories: [],
	episodeNumbering: "yes",
	episodeGrouping: "none",
	showType: "episodic",
	parentalAdvisory: "clean",
	mediaFormat: "audio",
	newPodcastId: null,
	importPodcastModalVisible: false,
	importPodcastConfig: null,
	redirect301ModalVisible: false,
	redirect301ModalPodcastID: null,
	redirect301OutModalVisible: false,
	newRssFeedUrl: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_ADD_NEW_PODCAST_DRAWER:
			return {
				...state,
				addNewPodcastDrawerVisible: !state.addNewPodcastDrawerVisible
			}
		case TOGGLE_IMPORT_PODCAST_DRAWER:
			return {
				...state,
				importPodcastDrawerVisible: !state.importPodcastDrawerVisible
			}
		case SET_INITIAL_VALUES_FOR_IMPORT_PODCAST_DRAWER:
			return {
				...state,
				importPodcastDrawerInitialValues: action.payload.values
			}
		case SET_CONFIG_FOR_IMPORT_PODCAST_DRAWER:
			return {
				...state,
				importPodcastConfig: action.payload
			}
		case TOGGLE_ADD_NEW_PODCAST_CONFIRMATION_MODAL_VISIBLE:
			return {
				...state,
				addNewPodcastConfirmationModalVisible: !state.addNewPodcastConfirmationModalVisible
			}

		case TOGGLE_MISSING_ARTWORK_MODAL_VISIBLE:
			return {
				...state,
				missingArtworkModalVisible: !state.missingArtworkModalVisible
			}

		case ADD_CHANGE_TO_ADD_NEW_PODCAST_FORM:
			return {
				...state,
				[action.payload.fieldName]: action.payload.fieldValue
			}

		case SET_NEW_PODCAST_ID_FOR_CONFIRMATION_MODAL:
			return {
				...state,
				newPodcastId: action.payload
			}

		case TOGGLE_IMPORT_PODCAST_MODAL_VISIBLE:
			return {
				...state,
				importPodcastModalVisible: !state.importPodcastModalVisible
			}
		case TOGGLE_301_REDIRECT_MODAL_VISIBLE:
			return {
				...state,
				redirect301ModalVisible: !state.redirect301ModalVisible
			}
		case TOGGLE_301_REDIRECT_OUT_MODAL_VISIBLE:
			return {
				...state,
				redirect301OutModalVisible: !state.redirect301OutModalVisible,
				newRssFeedUrl: action.payload.newRssFeedUrl
			}
		case SET_PODCAST_ID_FOR_301_REDIRECT_MODAL:
			return {
				...state,
				redirect301ModalPodcastID: action.payload.podcastID
			}
		default:
			return state
	}
}
