import {
	TOGGLE_UPLOAD_RECORDING_MODAL_VISIBLE,
	TOGGLE_CHOOSE_RECORDING_MODAL_VISIBLE,
	SET_CONFIG_FOR_CHOOSE_RECORDING_MODAL,
	SET_PROGRESS_FOR_UPLOAD_RECORDING_MODAL,
	SET_CONFIG_FOR_UPLOAD_RECORDING_MODAL,
	TOGGLE_DELETE_RECORDING_MODAL_VISIBLE,
	SET_CONFIG_FOR_DELETE_RECORDING_MODAL
} from "constants/actionTypes"

const initialState = {
	uploadRecordingModalVisible: false,
	chooseRecordingModalVisible: false,
	uploadRecordingProgress: 0,
	chooseRecordingModalConfig: null,
	uploadRecordingConfig: null,
	deleteRecordingModalVisible: false,
	deleteRecordingModalConfig: null
}

const recordingReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_UPLOAD_RECORDING_MODAL_VISIBLE:
			return {
				...state,
				uploadRecordingModalVisible: !state.uploadRecordingModalVisible
			}
		case TOGGLE_CHOOSE_RECORDING_MODAL_VISIBLE:
			return {
				...state,
				chooseRecordingModalVisible: !state.chooseRecordingModalVisible
			}
		case TOGGLE_DELETE_RECORDING_MODAL_VISIBLE:
			return {
				...state,
				deleteRecordingModalVisible: !state.deleteRecordingModalVisible
			}
		case SET_CONFIG_FOR_DELETE_RECORDING_MODAL:
			return {
				...state,
				deleteRecordingModalConfig: action.payload
			}
		case SET_PROGRESS_FOR_UPLOAD_RECORDING_MODAL:
			return {
				...state,
				uploadRecordingProgress: action.payload.progress
			}
		case SET_CONFIG_FOR_UPLOAD_RECORDING_MODAL:
			return {
				...state,
				uploadRecordingConfig: action.payload
			}
		case SET_CONFIG_FOR_CHOOSE_RECORDING_MODAL:
			return {
				...state,
				chooseRecordingModalConfig: action.payload
			}
		default:
			return state
	}
}

export default recordingReducer
