import { TOGGLE_INVITE_TEAM_MODAL, TOGGLE_MANAGE_ROLES_MODAL } from "constants/actionTypes"

const initialState = {
	inviteTeamModalVisible: false,
	manageRolesModalVisible: false
}

export function teamManagementReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_INVITE_TEAM_MODAL:
			return {
				...state,
				inviteTeamModalVisible: !state.inviteTeamModalVisible
			}
		case TOGGLE_MANAGE_ROLES_MODAL:
			return {
				...state,
				manageRolesModalVisible: !state.manageRolesModalVisible
			}
		default:
			return state
	}
}
