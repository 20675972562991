import React from "react"
import { CurvedHeader } from "./CurvedHeader"
import { Breadcrumb, Col, Row, Tag, Grid } from "antd"
import { useHistory, useParams } from "react-router"
import { useEpisode } from "services/queries/Episodes"
import { usePodcastByProfileUrl } from "services/queries/Podcasts"
import styled from "styled-components"
import { BackArrowIcon } from "assets/icons/Episodes"
import { POD } from "constants/routes"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { PauseIcon, PlayIcon } from "assets/icons/Common/SimpleFeedback"
import { Link } from "react-router-dom"
import { useEpisodeRecording } from "services/queries/Recordings"
import { useMediaPlayer } from "hooks/useMediaPlayer"
import { EpisodeFlag } from "components/Episodes/EpisodeCard/EpisodeFlag"

const EpisodePageLayout = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
	width: 100vw;

	.main {
		margin-top: -275px;
		& > div {
			max-width: 650px;
		}

		@media only screen and (max-width: 700px) {
			& {
				margin-top: -275px;

				& > div {
					max-width: 550px;
				}
			}
		}

		@media only screen and (max-width: ${({ theme }) => theme.xs}) {
			& {
				margin-top: -275px;

				& > div {
					max-width: 90vw;
				}
			}
		}
	}

	.episode-artwork {
		text-align: center;

		img {
			height: 250px;
			width: 250px;
			border-radius: ${({ theme }) => theme.spaceXs};
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
		}
	}

	h2 {
		font: ${({ theme }) => theme.fontLg};
		font-weight: 700;
	}

	h1 {
		margin: 0;
		font: ${({ theme }) => theme.fontLg};
		color: ${({ theme }) => theme.textPrimary};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@media only screen and (max-width: ${({ theme }) => theme.xs}) {
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			white-space: normal;
		}
	}

	.show-notes {
		font: ${({ theme }) => theme.fontSm};
		font-weight: 400;
		& > * {
			font: inherit;
		}
		a {
			color: ${({ theme }) => theme.podpalBlue};
		}
	}

	p {
		font: ${({ theme }) => theme.fontSm};
		font-weight: 400;
	}

	&& .ant-breadcrumb {
		span {
			color: ${({ theme }) => theme.gray7};
		}
		a {
			color: ${({ theme }) => theme.gray7};
			&:hover {
				color: ${({ theme }) => theme.podpalBlueHoverDark};
			}
		}
		.ant-breadcrumb-separator {
			color: ${({ theme }) => theme.gray7};
		}
	}

	.avatar-container {
		.avatar-overlay {
			background-color: ${({ theme }) => theme.podpalRedLight};
			text-align: center;
			position: absolute;
			width: 250px;
			height: 14px;
			font-size: 12px;
			line-height: 15px;
			font-weight: 500;
			color: ${({ theme }) => theme.neutral0};
			border-radius: 0 0 4px 4px;
			margin-top: -12px;
		}
	}
`

type EpisodePageUrlParams = {
	episodeId: string
	profileUrl: string
}

export function EpisodePage() {
	const { episodeId, profileUrl } = useParams<EpisodePageUrlParams>()
	const history = useHistory()

	const { useBreakpoint } = Grid
	const screens = useBreakpoint()

	const { isLoading: isEpisodeLoading, isError: isEpisodeError, data: episodeData } = useEpisode(episodeId)
	const { isLoading: isPodcastLoading, isError: isPodcastError, data: podcastData } = usePodcastByProfileUrl(profileUrl)
	const { isLoading: isRecordingLoading, isError: isRecordingError, data: recordingData } = useEpisodeRecording(
		episodeId
	)
	const { currentMedia, setCurrentMedia, isPlaying, setIsPlaying, setVisible } = useMediaPlayer()

	if (isEpisodeLoading || isPodcastLoading || isRecordingLoading) {
		return <div>Loading...</div>
	}

	if (isEpisodeError || isPodcastError || isRecordingError) {
		return <div>Error loading episode.</div>
	}

	if (!episodeData || !podcastData || !recordingData) {
		return <div>Error loading episode.</div>
	}

	const { title, photoUrl, episodeNumber, seasonNumber, label, showNotes, id } = episodeData?.episode
	const { title: podcastTitle, color } = podcastData?.podcast

	const handlePlayPauseClick = () => {
		if (!(currentMedia?.episodeId === id)) {
			setCurrentMedia(recordingData.recording)
			if (!isPlaying) {
				setIsPlaying(true)
				setVisible(true)
			}
		} else if (currentMedia?.episodeId === id) {
			setIsPlaying(prevState => !prevState)
			setVisible(true)
		}
	}

	const playButton = (
		<Col>
			<PodpalButton
				icon={isPlaying && currentMedia?.episodeId === id ? <PauseIcon /> : <PlayIcon />}
				type="primary"
				onClick={handlePlayPauseClick}
			>
				{isPlaying && currentMedia?.episodeId === id ? "Pause" : "Play"}
			</PodpalButton>
		</Col>
	)

	return (
		<EpisodePageLayout>
			<CurvedHeader color={color} isEpisodePage={true} />
			<Row justify="center" className="main">
				<Col xs={22} lg={18} xxl={12}>
					<Row justify="center" align="top" gutter={[32, 32]}>
						<Col>
							<div className="avatar-container">
								<div className="episode-artwork">
									<img alt={title} src={photoUrl} />
									<EpisodeFlag episode={episodeData?.episode} />
								</div>
							</div>
						</Col>
						<Col span={24}>
							<Row gutter={[0, 4]}>
								<Col span={24}>
									<Breadcrumb separator="|">
										{podcastTitle && (
											<Breadcrumb.Item>
												<Link to={`${POD}/${profileUrl}`}>{podcastTitle}</Link>
											</Breadcrumb.Item>
										)}
										{seasonNumber && <Breadcrumb.Item>Season {seasonNumber}</Breadcrumb.Item>}
										{episodeNumber && <Breadcrumb.Item>Episode {episodeNumber}</Breadcrumb.Item>}
									</Breadcrumb>
								</Col>
								<Col span={24}>
									<Row wrap={false} align="middle">
										<Col flex="25px">
											<BackArrowIcon onClick={() => history.push(`${POD}/${profileUrl}`)} />
										</Col>
										<Col span={20}>
											<h1>{title}</h1>
										</Col>
										{screens.lg && playButton}
									</Row>
								</Col>
								<Col span={24}>
									<Row align="middle">
										<Col flex="auto" offset={1}>
											{label && <Tag>{label}</Tag>}
										</Col>
										{!screens.lg && playButton}
									</Row>
								</Col>
								<Col span={24}>
									<hr />
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<h2>Show Notes</h2>
							<div className="show-notes" dangerouslySetInnerHTML={{ __html: showNotes ?? "" }}></div>
						</Col>
						<Col span={24}>
							<h2>Production Team</h2>
							<p>Meet the awesome creators of the {podcastTitle} podcast!</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</EpisodePageLayout>
	)
}
