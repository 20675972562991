// SIGN UP AND GET STARTED FLOW
export const SIGN_IN = "/sign-in"
export const SIGN_IN_LOADING = "/sign-in-loading"
export const GET_STARTED = "/get-started"
export const GET_STARTED_LOADING = "/get-started-loading"
export const BOUNCER = "/bouncer"

// EXTERNAL ROUTES
export const POD = "/pod"
export const PAL = "/pal"
export const EPISODE = "/episode"

// APP
export const HOME = "/home"
export const NOT_FOUND = "/not-found"
export const NO_ACCESS = "/no-access"
export const MY_PODCASTS = "/my-podcasts"
export const EPISODES = "/episodes"
export const RECORDINGS = "/recordings"
export const GUEST_PORTAL = "/guest-portal"
export const CALENDAR = "/calendar"
export const ANALYTICS = "/analytics"
export const VIDEO_TUTORIALS = "/video-tutorials"

// EPISODES ROUTES
export const ALL = "/all"
export const IN_WORK = "/in-work"
export const COMPLETE = "/complete"
export const PUBLISHED = "/published"
export const MANAGE = "/manage"

// SETTINGS
export const SETTINGS = "/settings"

// MY ACCOUNT
export const MY_ACCOUNT = "/my-account"
export const PROFILE = "/profile"
export const EMAIL_AND_PASSWORD = "/email-and-password"
export const APPS_AND_INTEGRATIONS = "/apps-and-integrations"
export const SECURITY = "/security"

// PODCAST SETTINGS
export const PODCAST_SETTINGS = "/podcast"
export const NO_PODCASTS = "/no-podcasts"
export const SHOW_SETTINGS = "/show-settings"
export const TEAM_MANAGEMENT = "/team-management"
export const RSS_AND_DISTRIBUTION = "/rss-and-distribution"
export const SOCIAL_MEDIA = "/social-media"
export const ADS_AND_SPONSORS = "/ads-and-sponsors"
export const REDIRECT = "/redirect"

// SUBSCRIPTION AND BILLING
export const SUBSCRIPTION_AND_BILLING = "/subscription-and-billing"
export const PLAN_USAGE = "/plan-usage"
export const OFFERS_AND_UPGRADES = "/offers-and-upgrades"
export const PAYMENT_METHOD = "/payment-method"
export const INVOICES = "/invoices"

// NOTIFICATIONS
export const NOTIFICATIONS = "/notifications"

// TELL YOUR FRIENDS
export const TELL_YOUR_FRIENDS = "/tell-your-friends"
