import * as React from "react"

interface NotificationDaysAgoProps {
	notificationDate: Date
}

enum SecondsPer {
	minute = 60,
	hour = 3600,
	day = 86400,
	thirtyOneDays = 2678000
}

export const NotificationDaysAgo: React.FC<NotificationDaysAgoProps> = ({ notificationDate }) => {
	const getDaysAgoText = React.useCallback(() => {
		const today = new Date()

		let difference = Math.floor((today.getTime() - notificationDate.getTime()) / 1000) // difference in seconds
		let unit = ""

		if (difference < SecondsPer.minute) {
			return "Just now"
		}

		if (difference < SecondsPer.hour) {
			difference = Math.floor(difference / SecondsPer.minute)
			unit = "minute"
		} else if (difference < SecondsPer.day) {
			difference = Math.floor(difference / SecondsPer.hour)
			unit = "hour"
		} else if (difference < SecondsPer.thirtyOneDays) {
			difference = Math.floor(difference / SecondsPer.day)
			unit = "day"
		} else {
			const notificationDateObj = new Date(notificationDate)
			const str = notificationDateObj.toLocaleString("en-US", {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true
			})
			return str
		}

		return `${difference} ${unit}${difference > 1 || difference === 0 ? "s" : ""} ago`
	}, [notificationDate])

	return <div className="days-ago-text">{getDaysAgoText()}</div>
}
