import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { Prompt } from "react-router"
import { DatePicker, Form, Input, Upload, Row, Col } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import noAvatar from "assets/account/noavatar.svg"
import useColorPicker from "components/common/Input/ColorPicker/useColorPicker"
import moment from "moment"
import ColorPicker from "components/common/Input/ColorPicker"
import ImgCrop from "antd-img-crop"
import {
	FIELD_NAMES,
	LABEL_NAMES,
	NAME_WITH_NUMBERS_ERROR_MESSAGE,
	NAME_TOO_SHORT_ERROR_MESSAGE,
	NAME_TOO_LONG_ERROR_MESSAGE,
	USERNAME_UNAVAILABLE_ERROR_MESSAGE,
	USERNAME_AVAILABLE_MESSAGE,
	USERNAME_INVALID_ERROR_MESSAGE,
	INVALID_BIO_FIELD_ERROR_MESSAGE,
	BIRTHDAY_TOO_YOUNG_ERROR_MESSAGE,
	PHOTO_TOO_LARGE_ERROR_MESSAGE
} from "constants/strings/UserSettings/MyAccount/Form"
import { updateUser, validateUsername } from "services/user.service"
import { useUser } from "services/queries/User"
import PodpalButton from "components/common/Buttons/PodpalButton"
import "./ProfileForm.scss"
import { getBase64 } from "utils/b64"
import { toggleDeleteAccountModalVisible } from "redux/actions/UserSettings/MyAccount"
import AudioUpload from "components/common/Uploads/AudioUpload"
import useAudioUpload from "components/common/Uploads/AudioUpload/useAudioUpload"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import useToggle from "hooks/useToggle"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { useQueryClient } from "react-query"
import styled from "styled-components"
import RichTextEditor from "components/common/Input/RichTextEditor"
import useRichTextEditor from "hooks/useRichTextEditor"

const StyledDangerText = styled.span`
	color: ${({ theme }) => theme.podpalRedLight};
	font: ${({ theme }) => theme.fontSm};
`

const ProfileForm = () => {
	const authUser = useSelector(state => state.app.authUser)

	const { isLoading, error, data } = useUser(authUser.uid)

	if (isLoading) {
		return <h4>Loading...</h4>
	}

	if (error) {
		return <h4>Error loading data.</h4>
	}

	return <ProfileFormComponent initialValues={data.user} />
}

const ProfileFormComponent = ({ initialValues }) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const queryClient = useQueryClient()

	const [fieldsModified, setFieldsModified] = useState(false)

	const [profileImage, setProfileImage] = useState(initialValues.thumbnailUrl)
	const [birthday, setBirthday] = useState(moment(initialValues.dateOfBirth))
	const [birthdayValStatus, setBirthdayValStatus] = useState(null)
	const [birthdayErrorMessage, setBirthdayErrorMessage] = useState(null)

	const maxBioLength = 4000

	const { content, handleChange: handleShowSummaryChange, setLength: setShowSummaryLength } = useRichTextEditor(
		initialValues.bio,
		maxBioLength
	)

	const [bioModified, setBioModified] = useState(false)
	useEffect(() => {
		if (initialValues && content && content !== initialValues.bio) {
			setBioModified(true)
		}
	}, [content, initialValues])

	const [audioBioFile, setAudioBioFile] = useState(null)
	const {
		webAudio,
		setWebAudio,
		localAudio,
		setLocalAudio,
		audioErrorMessage,
		setAudioErrorMessage,
		audioBioRemoved,
		setAudioBioRemoved,
		validateAudioFile,
		handleRemoveLocalAudio
	} = useAudioUpload({
		maxDurationSeconds: 60,
		onLoad: result => {
			setAudioBioFile(result)
			setFieldsModified(true)
		},
		onRemove: () => {
			setAudioBioFile(null)
			setFieldsModified(true)
		}
	})

	// TODO: Fix audio bio compliance issues.
	useEffect(() => {
		const aud = initialValues.audioBioUrl
		if (aud && !audioBioRemoved) {
			setWebAudio(`${aud}?noCache=${Math.floor(Math.random() * 1000000)}`)
		}
	}, [audioBioRemoved, initialValues, setWebAudio]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (!audioBioFile) {
			setLocalAudio(null)
		}
	}, [audioBioFile, setLocalAudio])

	const [profileColor, setProfileColor] = useState(initialValues.profileColor)
	const {
		showColorPicker,
		selectedColor,
		setSelectedColor,
		handleColorPickerClose,
		handleColorChange,
		handleColorPickerClick
	} = useColorPicker({
		defaultColor: profileColor,
		onColorChange: value => {
			setProfileColor(value)
			setFieldsModified(true)
		}
	})
	useEffect(() => {
		if (profileColor) {
			setSelectedColor(profileColor)
		}
	}, [profileColor, setSelectedColor])

	useEffect(() => {
		if (initialValues.username) {
			form.validateFields([FIELD_NAMES.USERNAME])
		}
	}, [initialValues, form])

	const checkPhotoFileSize = file => {
		return new Promise((resolve, reject) => {
			const sizeMB = Math.round(file.size / Math.pow(1024, 2))
			if (sizeMB >= 16) {
				reject(PHOTO_TOO_LARGE_ERROR_MESSAGE)
			}
			getBase64(file, imageUrl => {
				setProfileImage(imageUrl)
				setFieldsModified(true)
			})
			resolve()
		})
	}

	const [creatorProfileUrl, setCreatorProfileUrl] = useState(
		`${process.env.REACT_APP_PAPP_URL.slice(8)}/pal/${initialValues.username}`
	)
	useEffect(() => {
		if (initialValues.username) {
			setCreatorProfileUrl(`${process.env.REACT_APP_PAPP_URL.slice(8)}/pal/${initialValues.username}`)
		} else {
			setCreatorProfileUrl("Select a username to get a creator profile URL")
		}
	}, [initialValues.username])

	const handleValidateUsername = async username => {
		if (username === initialValues.username) {
			return Promise.resolve(USERNAME_AVAILABLE_MESSAGE)
		}
		if (/^\d+$/.test(username) || !/^[A-Za-z0-9_.]*$/i.test(username)) {
			return Promise.reject(USERNAME_INVALID_ERROR_MESSAGE)
		}
		try {
			const { data } = await validateUsername(username)
			const isValid = data.isValid ?? false
			if (isValid) {
				return Promise.resolve(USERNAME_AVAILABLE_MESSAGE)
			} else {
				return Promise.reject(USERNAME_UNAVAILABLE_ERROR_MESSAGE)
			}
		} catch (e) {
			errorAlert("Sorry, we were unable to validate your username.")
			return Promise.reject(USERNAME_UNAVAILABLE_ERROR_MESSAGE)
		}
	}

	const checkIfModified = (changedValues, allValues) => {
		let modified = false
		for (const [key, value] of Object.entries(changedValues)) {
			if (!_.isEqual(value, initialValues[key])) {
				modified = true
			}
		}
		setFieldsModified(modified)
	}

	const [loading, toggleLoading] = useToggle(false)
	const handleSubmit = async values => {
		if ((birthdayValStatus && birthdayErrorMessage) || audioErrorMessage) {
			errorAlert("Please fix errors to save.")
			return
		}
		toggleLoading()
		try {
			if (!audioBioRemoved) {
				values.audioBioFile = audioBioFile
			} else {
				values.audioBioUrl = null
			}

			// New photo uploaded
			if (profileImage !== initialValues.thumbnailUrl) {
				values.newThumbnailFile = profileImage
			} else {
				values.thumbnailUrl = profileImage
			}
			values.bio = content ?? initialValues.bio
			values.dateOfBirth = birthday.format("YYYY-MM-DD")
			values.profileColor = profileColor
			await updateUser({
				...initialValues,
				...values
			})
			successAlert("Changes Saved.")
			queryClient.invalidateQueries(["user", initialValues.id])
			toggleLoading()
			setFieldsModified(false)
			setBioModified(false)
		} catch (e) {
			errorAlert("Sorry, we were unable to update your information.")
			toggleLoading()
			setFieldsModified(false)
		}
	}

	const handleCancel = () => {
		form.resetFields()
		setBirthday(moment(initialValues.dateOfBirth))
		setBirthdayValStatus(null)
		setBirthdayErrorMessage(null)
		setAudioBioRemoved(false)
		setAudioErrorMessage(null)
		setProfileColor(initialValues.profileColor)
		setFieldsModified(false)
	}

	let uploadBody = null
	if (profileImage) {
		uploadBody = (
			<div className="profile-form-thumbnail-container">
				<img className="profile-form-thumbnail" src={profileImage} alt="avatar" />
				<div className="profile-form-thumbnail-overlay">
					<span className="profile-form-thumbnail-icon">
						<UploadOutlined />
					</span>
				</div>
			</div>
		)
	} else {
		uploadBody = (
			<div className="profile-form-thumbnail-container">
				<img src={noAvatar} alt="avatar" />
				<div className="profile-form-thumbnail-overlay">
					<span className="profile-form-thumbnail-icon">
						<UploadOutlined />
					</span>
				</div>
			</div>
		)
	}

	return (
		<div className="profile-form">
			<Form
				form={form}
				onFinish={handleSubmit}
				onFinishFailed={() => errorAlert("Please fix errors to save.")}
				layout="vertical"
				initialValues={initialValues}
				onValuesChange={checkIfModified}
			>
				<Row>
					<Form.Item
						name={FIELD_NAMES.PHOTO}
						label={LABEL_NAMES.PHOTO}
						tooltip={{
							placement: "right",
							title: "We recommend file size of at least 2MB (16MB max)."
						}}
					>
						<Input hidden value={profileImage} />
						<ImgCrop
							aspect={1}
							modalWidth={336}
							shape="round"
							hasZoom={true}
							grid
							beforeCrop={null}
							modalOk="Apply"
							modalCancel="Cancel"
							modalTitle="Crop Image"
						>
							<Upload
								accept=".jpg,.jpeg,.png"
								beforeUpload={checkPhotoFileSize}
								showUploadList={false}
								listType="picture-card"
							>
								{uploadBody}
							</Upload>
						</ImgCrop>
					</Form.Item>
				</Row>
				<Row gutter={16}>
					<Col xs={24} md={12}>
						<Form.Item
							name={FIELD_NAMES.FIRST_NAME}
							label={LABEL_NAMES.FIRST_NAME}
							tooltip={{ placement: "right", title: "Your first name. Must be 2 or more characters." }}
							rules={[
								{
									min: 2,
									message: NAME_TOO_SHORT_ERROR_MESSAGE
								},
								{
									max: 25,
									message: NAME_TOO_LONG_ERROR_MESSAGE
								},
								{
									validator: (rule, value) => {
										if (/\d/.test(value)) {
											return Promise.reject(NAME_WITH_NUMBERS_ERROR_MESSAGE)
										}
										return Promise.resolve()
									}
								}
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							name={FIELD_NAMES.LAST_NAME}
							label={LABEL_NAMES.LAST_NAME}
							tooltip={{ placement: "right", title: "Your last name. Must be 2 or more characters." }}
							rules={[
								{
									min: 2,
									message: NAME_TOO_SHORT_ERROR_MESSAGE
								},
								{
									max: 25,
									message: NAME_TOO_LONG_ERROR_MESSAGE
								},
								{
									validator: (rule, value) => {
										if (/\d/.test(value)) {
											return Promise.reject(NAME_WITH_NUMBERS_ERROR_MESSAGE)
										}
										return Promise.resolve()
									}
								}
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={24} md={12}>
						<Form.Item
							name={FIELD_NAMES.USERNAME}
							label={LABEL_NAMES.USERNAME}
							tooltip={{
								placement: "right",
								title: (
									<span>
										Usernames must meet the following requirements:
										<ul>
											<li>Not taken by another user</li>
											<li>Less than 14 characters</li>
											<li>Cannot be all numeric</li>
											<li>Must contain only letters, numbers, periods, and underscores</li>
										</ul>
									</span>
								)
							}}
							rules={[
								{
									max: 14,
									message: USERNAME_INVALID_ERROR_MESSAGE
								},
								{
									validator: (rule, value) => handleValidateUsername(value)
								}
							]}
							hasFeedback
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							label={LABEL_NAMES.BIRTHDAY}
							tooltip={{ placement: "right", title: "Your birthday in MM/DD/YYYY." }}
							help={birthdayErrorMessage}
							validateStatus={birthdayValStatus}
						>
							<DatePicker
								value={birthday}
								onChange={(date, datestring) => {
									setBirthday(date)
									if (moment().diff(date, "years") < 13) {
										setBirthdayValStatus("error")
										setBirthdayErrorMessage(BIRTHDAY_TOO_YOUNG_ERROR_MESSAGE)
									} else {
										setBirthdayValStatus(null)
										setBirthdayErrorMessage(null)
									}
									setFieldsModified(true)
								}}
								format="MM/DD/YYYY"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.CREATOR_PROFILE_URL}
							label={LABEL_NAMES.CREATOR_PROFILE_URL}
							tooltip={{
								placement: "right",
								title:
									"Your public Podpal profile where people everywhere can learn more about you as a podcaster, and listen to episodes from your shows and guest appearances on other podcasts."
							}}
						>
							<div className="profile-url-container">
								<Input
									className="disabled-profile-form-field"
									name={FIELD_NAMES.CREATOR_PROFILE_URL}
									value={creatorProfileUrl}
									disabled
								/>
								<PodpalButton
									className="profile-url-copy-button"
									size="large"
									type="primary"
									target="_blank"
									disabled={!creatorProfileUrl}
									href={`https://${creatorProfileUrl}`}
									rel="noopener noreferrer"
								>
									Visit
								</PodpalButton>
							</div>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.BIO}
							tooltip={{
								placement: "right",
								title: "Your personal bio that shows up on your public Podpal profile. 500 characters max."
							}}
							label={LABEL_NAMES.BIO}
							rules={[
								{
									max: 500,
									message: INVALID_BIO_FIELD_ERROR_MESSAGE
								}
							]}
						>
							<RichTextEditor
								placeholder="Tell us a little more about yourself and why you started podcasting!"
								setValue={handleShowSummaryChange}
								defaultValue={initialValues?.bio}
								height="200px"
								setLength={setShowSummaryLength}
							/>
							{/* <TextArea
								name={FIELD_NAMES.BIO}
								rows={4}
								placeholder="Tell us a little more about yourself and why you started podcasting!"
							/> */}
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Item
							name={FIELD_NAMES.AUDIO_BIO}
							label={LABEL_NAMES.AUDIO_BIO}
							tooltip={{
								placement: "right",
								title:
									"Upload a 60-second audio bio so fans and fellow podcasters can learn more about you (MP3 or M4A required)."
							}}
							help={audioErrorMessage}
							validateStatus={audioErrorMessage && "error"}
						>
							<AudioUpload
								localAudio={localAudio}
								webAudio={webAudio}
								validateAudioFile={validateAudioFile}
								handleRemove={handleRemoveLocalAudio}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Item
							label={LABEL_NAMES.COLOR_PICKER}
							tooltip={{
								placement: "right",
								title: "This color is used as the theme on your public profile or (Pal Page)."
							}}
						>
							<ColorPicker
								selectedColor={selectedColor}
								handleColorPickerClose={handleColorPickerClose}
								showColorPicker={showColorPicker}
								handleColorPickerClick={handleColorPickerClick}
								handleColorChange={handleColorChange}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.INSTAGRAM_HANDLE}
							tooltip={{ placement: "right", title: "" }}
							label={LABEL_NAMES.INSTAGRAM_HANDLE}
						>
							<Input addonBefore="instagram.com/" placeholder="enter your handle" />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.FACEBOOK_HANDLE}
							tooltip={{ placement: "right", title: "" }}
							label={LABEL_NAMES.FACEBOOK_HANDLE}
						>
							<Input addonBefore="facebook.com/" placeholder="enter your handle" />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.TWITTER_HANDLE}
							tooltip={{ placement: "right", title: "" }}
							label={LABEL_NAMES.TWITTER_HANDLE}
						>
							<Input addonBefore="twitter.com/" placeholder="enter your handle" />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name={FIELD_NAMES.LINKEDIN_HANDLE}
							tooltip={{ placement: "right", title: "" }}
							label={LABEL_NAMES.LINKEDIN_HANDLE}
						>
							<Input addonBefore="linkedin.com/in/" placeholder="enter your handle" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<StyledDangerText>
							Clicking the button below will <strong>permanently delete</strong> your account and all of your podcasts from
							Podpal. Your information will be gone forever. Once you delete your account, there’s no going back.
						</StyledDangerText>
					</Col>
					<Col span={24}>
						<PodpalButton danger variant="outlined" size="large" onClick={() => dispatch(toggleDeleteAccountModalVisible())}>
							Delete Account
						</PodpalButton>
					</Col>
				</Row>
				<footer className="footer-actions">
					<Row gutter={8} justify="start" align="middle">
						<Col>
							<PodpalButton
								type="primary"
								size="large"
								loading={loading}
								htmlType="submit"
								disabled={!fieldsModified && !bioModified}
							>
								Save
							</PodpalButton>
						</Col>
						<Col>
							<PodpalButton variant="secondary" size="large" onClick={handleCancel} disabled={!fieldsModified && !bioModified}>
								Cancel
							</PodpalButton>
						</Col>
					</Row>
				</footer>
			</Form>
			<Prompt when={fieldsModified} message={() => window.confirm("Do you want to leave without saving?")} />
		</div>
	)
}

export default ProfileForm
