import { EPISODE_TYPE_OPTIONS } from "constants/strings/Episodes"
import React from "react"
import { Episode } from "services/episode.service"
import { mapEpisodeTypeToText } from "utils/recording"

interface Props extends Partial<Episode> {
	episode: Pick<Episode, "episodeType" | "trailerType" | "bonusType">
	// the rest of the Episode type is not needed, but optional
}
export function EpisodeFlag({ episode }: Props) {
	const { episodeType, trailerType, bonusType } = episode

	// only display flag if episode is a bonus or trailer
	if (!episodeType) return null
	if (![EPISODE_TYPE_OPTIONS.BONUS, EPISODE_TYPE_OPTIONS.TRAILER].includes(episodeType)) return null

	const flagText = mapEpisodeTypeToText({ episodeType, trailerType, bonusType })

	return (
		<>
			<div className="avatar-overlay">{flagText}</div>
		</>
	)
}
