import React from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Avatar, Row, Col, Table, Popconfirm, Tooltip } from "antd"
import { MY_PODCASTS } from "constants/routes"
import { MigratingIcon, MigrationSuccessIcon, MigrationErrorIcon } from "assets/icons/Episodes"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalModal from "../../PodpalModal"
import { toggleImportEpisodesStatusModalVisible } from "redux/actions/Episodes/AddNewEpisode"
import { usePodcast } from "services/queries/Podcasts"
import { deletePodcastService } from "services/podcast.service"
import { useMigratingEpisodes } from "services/queries/Episodes"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { useHistory } from "react-router"
import { useMigrationInfo } from "services/queries/MigrationInfo"

const StyledHeading = styled.p`
	font: ${props => props.theme.fontLg};
	color: ${props => props.theme.textPrimary};
`

const StyledText = styled.p`
	color: ${props => props.theme.gray7};
	font: ${props => props.theme.fontSm};
	text-align: center;
`

const StyledPrimaryText = styled.span`
	color: ${props => props.theme.textPrimary};
	font: ${props => props.theme.fontSm};
`

const SecondaryDangerButton = styled(PodpalButton)`
	&:hover {
		background: ${props => props.theme.podpalRedDark};
		border-color: ${props => props.theme.podpalRedDark};
		color: ${props => props.theme.podpalBlueGhost};
	}
`

const ImportEpisodesStatusModal = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const visible = useSelector(state => state.addNewEpisode.importEpisodesStatusModalVisible)
	const authUser = useSelector(state => state.app.authUser)
	const podcastID = useSelector(state => state.addNewEpisode.importEpisodesPodcastID)

	const { data: podcastData } = usePodcast(podcastID)
	const { data } = useMigratingEpisodes(podcastID)
	const { data: migrationInfoData } = useMigrationInfo(podcastID)

	const handleClose = () => {
		dispatch(toggleImportEpisodesStatusModalVisible())
	}

	const handleCancelImport = async () => {
		try {
			await deletePodcastService(podcastID, authUser.uid)
			dispatch(toggleImportEpisodesStatusModalVisible())
			history.push(MY_PODCASTS)
			successAlert("Your podcast import was successfully canceled.")
		} catch (e) {
			errorAlert("Sorry, we couldn't cancel the podcast import process.")
		}
	}

	const columns = [
		{
			title: "Status",
			render: (text, record) => {
				if (record.migrationStatus === "MIGRATION_LOADING" || record.migrationStatus === "MIGRATION_PENDING") {
					return <MigratingIcon />
				} else if (record.migrationStatus === "MIGRATION_SUCCESS") {
					return <MigrationSuccessIcon />
				} else {
					return <MigrationErrorIcon />
				}
			},
			align: "center",
			width: "20%"
		},
		{
			title: "Image",
			render: (text, record) => <Avatar shape="square" size={40} src={record.photoUrl ?? podcastData?.podcast.photoUrl} />,
			width: "20%"
		},
		{
			title: "Episode Title",
			render: (text, record) => {
				if (record.migrationStatus === "MIGRATION_ERROR") {
					return (
						<Tooltip placement="bottomRight" title={record.migrationErrorMessage}>
							<StyledPrimaryText style={{ color: "red" }}>{record.title}</StyledPrimaryText>
						</Tooltip>
					)
				} else {
					return <StyledPrimaryText>{record.title}</StyledPrimaryText>
				}
			},
			width: "40%"
		},
		{
			title: "Episode Number",
			render: (text, record) => {
				return (
					<StyledPrimaryText style={record.migrationStatus === "MIGRATION_ERROR" ? { color: "red" } : null}>
						{record.episodeNumber}
					</StyledPrimaryText>
				)
			},
			align: "center",
			width: "40%"
		}
	]

	const migratedEpisodes = migrationInfoData?.migrationInfo?.migratedEpisodes ?? 0
	const failedEpisodes = migrationInfoData?.migrationInfo?.failedEpisodes ?? 0
	const totalEpisodes = migrationInfoData?.migrationInfo?.totalEpisodes ?? 0
	const migrationPercentCompleted = Math.trunc(((migratedEpisodes + failedEpisodes) / totalEpisodes) * 100)

	const cancelImportButton =
		migrationPercentCompleted < 100 ? (
			<Popconfirm
				placement="top"
				title="Are you sure to cancel this task?"
				cancelText="No"
				okText="Yes"
				onConfirm={handleCancelImport}
			>
				<SecondaryDangerButton variant="secondary">Cancel Import</SecondaryDangerButton>
			</Popconfirm>
		) : null

	return (
		<PodpalModal
			centered
			maskClosable={false}
			visible={visible}
			footer={[
				cancelImportButton,
				<PodpalButton type="primary" onClick={() => handleClose()}>
					OK
				</PodpalButton>
			]}
			onCancel={() => handleClose()}
		>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col>
					<StyledHeading>
						{migrationPercentCompleted < 100 ? "Importing your episodes" : "Podcast Import Complete!"}
					</StyledHeading>
				</Col>
			</Row>
			<Row justify="center" gutter={[0, 16]}>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={data?.episodes}
						size="middle"
						pagination={{ pageSize: 25, showSizeChanger: false }}
						scroll={{ y: 336 }}
					/>
				</Col>
			</Row>
			{migrationPercentCompleted < 100 ? (
				<>
					<Row justify="center" align="middle">
						<Col>
							<StyledText>
								<strong>This may take a while...</strong>
							</StyledText>
						</Col>
					</Row>
					<Row justify="center" align="middle">
						<Col span={18}>
							<StyledText>Feel free to wait, or come back later. We’ll send you an email when your show is ready!</StyledText>
						</Col>
					</Row>
				</>
			) : (
				<>
					<Row justify="center" align="middle">
						<Col>
							<StyledText>
								<strong>Import Summary</strong>
							</StyledText>
						</Col>
					</Row>
					<Row justify="center" align="middle">
						<Col>
							<StyledText>
								Total Episodes: <strong>{totalEpisodes}</strong>
							</StyledText>
						</Col>
					</Row>
					<Row justify="center" align="middle">
						<Col>
							<StyledText>
								Successfully Imported: <strong>{migratedEpisodes}</strong>
							</StyledText>
						</Col>
					</Row>
					<Row justify="center" align="middle">
						<Col>
							<StyledText>
								Failed: <strong>{failedEpisodes}</strong>
							</StyledText>
						</Col>
					</Row>
				</>
			)}
		</PodpalModal>
	)
}

export default ImportEpisodesStatusModal
