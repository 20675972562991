import { useQuery } from "react-query"
import {
	findEpisodeById,
	FindEpisodeResponse,
	findEpisodes,
	findEpisodesWithProgress,
	FindEpisodesWithProgressResponse,
	findPublishedEpisodesByPodcastId,
	FindPublishedEpisodesByPodcastIDRequest
} from "services/episode.service"
import { Operators, PropertyTypes, QueryOptions } from "../types/queryOptions"
import { keysToCamel } from "utils/camelCaseUtils"

export const useEpisode = (id: string | undefined) => {
	return useQuery<FindEpisodeResponse>(
		["episode", id],
		async () => {
			if (!id) return null
			const { data } = await findEpisodeById(id)
			const first = { request_id: data.request_id, episode: data.episodes[0] ?? null }
			const camelResponse = keysToCamel(first)
			return camelResponse
		},
		{
			enabled: !!id
		}
	)
}

export interface UseEpisodesWithStatusProps {
	podcastId: string
	kind: string
	queryOptions: QueryOptions
}

export function useEpisodesWithStatus({ podcastId, kind, queryOptions }: UseEpisodesWithStatusProps) {
	return useQuery<FindEpisodesWithProgressResponse>(
		["episodes", podcastId, kind],
		async () => {
			const { data } = await findEpisodesWithProgress({ kind, queryOptions })
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{
			enabled: !!podcastId
		}
	)
}

export const useMigratingEpisodes = (podcastId: string) => {
	return useQuery(
		["migratingEpisodes", podcastId],
		async () => {
			if (!podcastId) return null
			const queryOptions: QueryOptions = {
				filters: [
					{
						propertyName: "PodcastID",
						propertyValue: podcastId,
						propertyType: PropertyTypes.string,
						operator: Operators.equal
					}
				],
				orders: [
					{
						propertyName: "CreatedAt",
						sort: "DESC"
					},
					{
						propertyName: "LastPublishedDate",
						sort: "ASC"
					}
				]
			}
			const { data } = await findEpisodes({ queryOptions })
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ refetchInterval: 10000 }
	)
}

export const useEpisodes = (podcastId: string) => {
	return useQuery<FindEpisodeResponse>(
		["episodes", podcastId],
		async () => {
			if (!podcastId) return null
			const queryOptions: QueryOptions = {
				filters: [
					{
						propertyName: "PodcastID",
						propertyValue: podcastId,
						propertyType: PropertyTypes.string,
						operator: Operators.equal
					}
				]
			}
			const { data } = await findEpisodes({ queryOptions })
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!podcastId }
	)
}

export const usePublishedEpisodes = (podcastId: string) => {
	return useQuery<FindPublishedEpisodesByPodcastIDRequest>(["publishedEpisodes", podcastId], async () => {
		if (!podcastId) return null
		const { data } = await findPublishedEpisodesByPodcastId(podcastId)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}
