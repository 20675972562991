import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { Sidebar } from "components/Sidebar"
import { Episodes } from "./EpisodesPage"
import RecordingsPage from "./RecordingsPage"
import { GuestPortalPage } from "./GuestPortalPage"
import { CalendarPage } from "./CalendarPage"
import AnalyticsPage from "./AnalyticsPage"
import "./HomePage.scss"
import NavbarLayout from "containers/NavbarLayout"
import {
	CALENDAR,
	GUEST_PORTAL,
	EPISODES,
	RECORDINGS,
	MY_PODCASTS,
	ANALYTICS,
	VIDEO_TUTORIALS,
	NOT_FOUND
} from "constants/routes"
import { MyPodcastsPage } from "./MyPodcastsPage"
import { VideoTutorialsPage } from "containers/VideoTutorialsPage"
import { EpisodeManagement } from "components/Episodes/EpisodeManagement"

export function HomePage() {
	return (
		<NavbarLayout>
			<div className="home-page">
				<Sidebar />
				<Switch>
					<Redirect from="/" exact to={`${MY_PODCASTS}`} />
					<Route path={`${VIDEO_TUTORIALS}`} component={VideoTutorialsPage} />
					<Route path={`${MY_PODCASTS}`} component={MyPodcastsPage} />
					<Route path={`/:profileUrl${EPISODES}/manage/:episodeId`} component={EpisodeManagement} />
					<Route path={`/:profileUrl${EPISODES}`} component={Episodes} />
					<Route path={`/:profileUrl${RECORDINGS}`} component={RecordingsPage} />
					<Route path={`/:profileUrl${GUEST_PORTAL}`} component={GuestPortalPage} />
					<Route path={`/:profileUrl${CALENDAR}`} component={CalendarPage} />
					<Route path={`/:profileUrl${ANALYTICS}`} component={AnalyticsPage} />
					<Redirect to={NOT_FOUND} />
				</Switch>
			</div>
		</NavbarLayout>
	)
}
