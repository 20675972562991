import React from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"
import colors from "styles/scss/color.scss"
import Loader from "react-loader-spinner"
import { RootState } from "index"

const StyledLoader = styled(Loader)`
	position: fixed;
	display: flex;
	min-height: 100%;
	min-width: 100%;
	z-index: 99; /* Set to 2 to overlay Layout Header. */
	opacity: 1;
	justify-content: center;
	align-items: center;
`
interface LoadingOverlayProps {
	visible: boolean
}
const LoadingOverlay = styled.div`
	position: absolute;
	min-height: 100%;
	min-width: 100%;
	z-index: 3; /* Set to 3 to overlay Layout Header but not affect PodpalLoader's opacity. */
	background: rgba(0, 0, 0, 0.65); /**/

	${(props: LoadingOverlayProps) =>
		!props.visible &&
		css`
			display: none;
		`}
`

const PodpalLoadingSpinnerOverlay = () => {
	const visible = useSelector((state: RootState) => state.signup.loadingSpinnerOverlayVisible)

	return (
		<>
			<LoadingOverlay visible={visible} />
			<StyledLoader visible={visible} type="Oval" color={colors.podpalBlue} height={100} width={100} />
		</>
	)
}

export default PodpalLoadingSpinnerOverlay
