import app from "firebase/app"
import "firebase/auth"

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_LOCAL_DOMAIN || process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

class Firebase {
	constructor() {
		app.initializeApp(firebaseConfig)

		this.app = app
		this.auth = app.auth()
		this.facebookAuthProvider = new app.auth.FacebookAuthProvider()
		this.googleAuthProvider = new app.auth.GoogleAuthProvider()
	}
}

const firebase = new Firebase()

export default firebase
