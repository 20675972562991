import { User } from "./user"

export enum ReviewType {
	finalReview = "FINAL_REVIEW",
	episodeSetup = "EPISODE_SETUP",
	audioQuality = "AUDIO_QUALITY",
	videoQuality = "VIDEO_QUALITY",
	showNotes = "SHOW_NOTES"
}

export enum ReviewStatus {
	notStarted = "NOT_STARTED",
	inReview = "IN_REVIEW",
	revise = "REVISE",
	accept = "ACCEPT"
}

export interface EpisodeReview {
	id?: string
	episodeId?: string
	reviewerId?: string
	reviewType?: ReviewType
	reviewStatus?: ReviewStatus
	createdAt?: string
	lastUpdatedAt?: string
	deletedAt?: string
	isDeleted?: boolean
}

export interface UserWithEpisodeReview {
	user?: User
	episodeReview: EpisodeReview
}

export interface FindEpisodeReviewsByEpisodeIDResponse {
	requestId: string
	usersWithEpisodeReviews: UserWithEpisodeReview[]
}
