import React, { useState } from "react"
import { Checkbox, Row, Col, Table } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { useSelector, useDispatch } from "react-redux"
import { useEpisodeRecording, useUnusedPodcastRecordings } from "services/queries/Recordings"
import { toggleChooseRecordingModalVisible } from "redux/actions/Recordings"
import PodpalModal from "../../PodpalModal"
import styled from "styled-components"
import { Operators, PropertyTypes } from "services/types/queryOptions"
import { useQueryClient } from "react-query"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { RECORDING_UPLOADED_TIME_DISPLAY_FORMAT, UNABLE_TO_SELECT_RECORDING } from "constants/strings/Episodes"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { updateRecording } from "services/recording.service"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import useToggle from "hooks/useToggle"
import moment from "moment"
import { useEpisode } from "services/queries/Episodes"
import { updateEpisode } from "services/episode.service"

const StyledHeader = styled.span`
	color: ${props => props.theme.textPrimary};
	font: ${props => props.theme.fontLg};
`

export function ChooseRecordingModal() {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const visible = useSelector(state => state.recording.chooseRecordingModalVisible)
	const config = useSelector(state => state.recording.chooseRecordingModalConfig)

	const [loading, toggleLoading] = useToggle(false)
	const [selectedRecording, setSelectedRecording] = useState(null)

	const { error, data } = useUnusedPodcastRecordings(selectedPodcast?.id, {
		filters: [
			{
				propertyName: "EpisodeID",
				propertyValue: "",
				propertyType: PropertyTypes.string,
				operator: Operators.equal
			}
		]
	})

	const { data: episodeData } = useEpisode(config?.episodeID)
	const { data: episodeProgressData } = useEpisodeProgress(config?.episodeID)
	const { data: recordingData } = useEpisodeRecording(config?.episodeID)

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: episodeProgressData?.episodeProgress?.setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: episodeProgressData?.episodeProgress?.inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: episodeProgressData?.episodeProgress?.scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: episodeProgressData?.episodeProgress?.uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: episodeProgressData?.episodeProgress?.writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: episodeProgressData?.episodeProgress?.reviewEpisode ?? 0
		}
	]

	const handleMarkAsDone = async progressStep => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.COMPLETED
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.COMPLETED })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleClose = () => {
		dispatch(toggleChooseRecordingModalVisible())
		setSelectedRecording(null)
	}

	const handleSubmit = async () => {
		toggleLoading()
		try {
			if (config?.isReplacing) {
				await updateRecording({
					...recordingData?.recording,
					episodeId: ""
				})
				if (episodeData?.episode.isPublished) {
					await updateEpisode({
						...episodeData.episode,
						publishedEpisodeModified: true
					})
					queryClient.invalidateQueries(["episode", config?.episodeID])
				}
			}

			if (config.episodeID) {
				await updateRecording({
					...selectedRecording,
					episodeId: config.episodeID
				})
			} else {
				config.setRecording(selectedRecording)
				config.setRecordingKey(selectedRecording.id)
				config.setMedia(selectedRecording.url)
			}
			handleMarkAsDone("uploadAudio")
			queryClient.invalidateQueries(["recording", config?.episodeID])
			queryClient.invalidateQueries(["unusedRecordings", selectedPodcast?.id])
			setSelectedRecording(null)
			dispatch(toggleChooseRecordingModalVisible())
			toggleLoading()
			successAlert("Recording successfully added from library.")
		} catch (e) {
			toggleLoading()
			errorAlert(UNABLE_TO_SELECT_RECORDING)
		}
	}

	const columns = [
		{
			title: "",
			render: (text, record) => (
				<Checkbox
					checked={selectedRecording?.id === record.recording.id}
					onChange={e => {
						if (selectedRecording?.fileName === record.recording.fileName) {
							setSelectedRecording(null)
						} else {
							setSelectedRecording(record.recording)
						}
					}}
				/>
			),
			width: "10%"
		},
		{
			title: "File Name",
			render: (text, record) => record.recording.fileName,
			sorter: (a, b) => a.recording.fileName.localeCompare(b.recording.fileName),
			width: "50%"
		},
		{
			title: "Date Added",
			render: (text, record) => moment(record.recording.createdAt).format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT),
			sorter: (a, b) => moment(a.recording.createdAt).format().localeCompare(moment(b.recording.createdAt).format()),
			width: "50%"
		}
	]

	return (
		<PodpalModal
			centered
			maskClosable={false}
			closable={false}
			visible={visible}
			width={480}
			footer={[
				<PodpalButton variant="secondary" onClick={handleClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton
					type={!selectedRecording ? null : "primary"}
					disabled={!selectedRecording}
					loading={loading}
					onClick={handleSubmit}
				>
					Select Recording
				</PodpalButton>
			]}
		>
			<Row gutter={[0, 24]} justify="center" align="middle">
				<Col>
					<StyledHeader>My Recordings</StyledHeader>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{error ? (
						<h4>Error loading data.</h4>
					) : (
						<Table
							columns={columns}
							dataSource={data?.recordingsWithEpisodes}
							locale={{
								emptyText: "No recordings available."
							}}
							pagination={{ pageSize: 6, showSizeChanger: false }}
							scroll={{ y: 577 }}
						/>
					)}
				</Col>
			</Row>
		</PodpalModal>
	)
}
