import React, { useRef, useState, useEffect } from "react"
import ReactQuill, { Quill } from "react-quill"
import { Col, Row, Avatar, Popover } from "antd"
import Icon, { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { SmilePlusIcon } from "../../../../assets/icons/Comments"
import { SendIcon } from "./Icons/Send"
import { AtPeopleIcon } from "./Icons/AtPeople"
import { useEmojiPicker } from "hooks/useEmojiPicker"
import PodpalButton from "components/common/Buttons/PodpalButton"
import "react-quill/dist/quill.snow.css"
import styled from "styled-components"
import EmojiPicker from "components/common/Input/EmojiPicker"
import "./index.scss"
import "antd/dist/antd.css"

const StyledDiv = styled.div`
	width: 100%;
	background-color: transparent;
	position: relative;
	bottom: 34px;
	padding: 0 8px;
`

const TeamMemberDropdown = styled.div`
	width: 202px;
	padding: 12px;
`

const TeamMemberButton = styled.button`
	padding: 2px;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	&:hover {
		background-color: #f0f6ff;
	}
`

const TeamMemberName = styled.span`
	margin-left: 8px;
`

const TeamMemberRow = ({ fullName, avatarUrl, onClick }) => {
	return (
		<TeamMemberButton onClick={onClick}>
			<Avatar src={avatarUrl} size={"large"} />
			<TeamMemberName>{fullName}</TeamMemberName>
		</TeamMemberButton>
	)
}

// Register Quill classes/styles/attributes so they work without quill css dependencies
const Parchment = Quill.import("parchment")
class IndentAttributor extends Parchment.Attributor.Style {
	add(node, value) {
		if (value === 0) {
			this.remove(node)
			return true
		} else {
			return super.add(node, `${value}em`)
		}
	}
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
	scope: Parchment.Scope.BLOCK,
	whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
})

Quill.register(IndentStyle, true)

var DirectionAttribute = Quill.import("attributors/attribute/direction")
Quill.register(DirectionAttribute, true)

var AlignClass = Quill.import("attributors/class/align")
Quill.register(AlignClass, true)

var DirectionClass = Quill.import("attributors/class/direction")
Quill.register(DirectionClass, true)

var AlignStyle = Quill.import("attributors/style/align")
Quill.register(AlignStyle, true)

var DirectionStyle = Quill.import("attributors/style/direction")
Quill.register(DirectionStyle, true)

const formats = ["bold", "italic", "underline", "align", "blockquote", "list", "bullet", "indent", "link"]
const modules = {
	toolbar: [
		["bold", "italic", "underline"],
		[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
		// [
		// 	{
		// 		align: [
		// 			// defaults from theme
		// 		],
		// 	},
		// ],
		["link"]
	]
}

const StyledReactQuill = styled(ReactQuill).attrs(props => ({
	maxHeight: props.height || ""
}))`
	height: ${props => props.height};
	max-height: ${props => props.height};
	.ql-toolbar {
		border: 1px solid #d9d9d9;
		border-bottom: none;
		border-radius: 4px 4px 0px 0px;

		.ql-formats button {
			color: #000000;
		}
	}
	.ql-container {
		overflow: scroll !important;
		border: 1px solid #d9d9d9 !important;
		border-radius: 0px 0px 4px 4px;
		transition: border 0.3s ease-in-out;
		padding-bottom: ${props => (props.showFooter ? "20px" : "0")};
		&:hover {
			border: 1px solid #789aff !important;
		}
	}
`

const RichTextEditor = ({
	defaultValue,
	setValue,
	setLength,
	placeholder,
	height,
	showFooter,
	onSendButtonClick,
	onCancelEditClick,
	onFinishEditClick,
	isEditing,
	teamMembers
}) => {
	const reactQuillRef = useRef(null)
	const [showTeamMember, setShowTeamMember] = useState(false)
	const [loading, setLoading] = useState(false)
	const { showEmojiPicker, toggleEmojiPicker, onClickOutside } = useEmojiPicker()

	const quillEditor = reactQuillRef?.current?.getEditor()
	const editorText = quillEditor?.getText()
	const isSendButtonDisabled = !editorText?.trim()

	const handleChange = content => {
		setValue(content)
	}

	const onEmojiSelected = emojiEntity => {
		const quillEditor = reactQuillRef?.current?.getEditor()
		console.log("selectEmoji", emojiEntity)
		toggleEmojiPicker()
		const selection = quillEditor.getSelection(true)
		quillEditor.insertText(selection.index, emojiEntity.emoji)
	}

	const onSend = async () => {
		setLoading(true)
		onSendButtonClick()
		const editor = reactQuillRef?.current?.getEditor().root
		editor.innerHTML = ""
		setLoading(false)
	}

	const onTeamMemberItemClick = (fullName, userName, insertAtSign) => {
		var selection = quillEditor?.getSelection(true)
		var delta = {
			// The program crashes when retain is set to 0.
			// Potential bug in quill.js?
			ops: [
				{ retain: selection.index },
				{ insert: `${insertAtSign ? "@" : ""}${fullName}`, attributes: { link: `https://app.podpal.com/pal/${userName}` } }
			]
		}
		quillEditor.updateContents(delta)
		setShowTeamMember(false)
	}

	React.useEffect(() => {
		window.addEventListener("keydown", e => {
			if (reactQuillRef.current.getEditor().hasFocus()) {
				if (e.key === "@") {
					setShowTeamMember(true)
				}
			}
		})
	}, [])

	const onVisibleChange = visible => {
		setShowTeamMember(visible)
	}

	const teamMemberContent = (
		<TeamMemberDropdown>
			<h3>Production team</h3>
			{teamMembers?.map(({ fullName, avatar, userName }) => (
				<TeamMemberRow fullName={fullName} avatarUrl={avatar} onClick={() => onTeamMemberItemClick(fullName, userName)} />
			))}
		</TeamMemberDropdown>
	)

	return (
		<div className="editor">
			<StyledReactQuill
				ref={el => {
					reactQuillRef.current = el
					setLength(reactQuillRef?.current?.getEditor().getLength() - 1) // This -1 seems necessary but I'm not sure why... Thanks Quill >:(
				}}
				theme="snow"
				placeholder={placeholder}
				defaultValue={defaultValue}
				height={height}
				onChange={handleChange}
				modules={modules}
				formats={formats}
			/>
			{showFooter && (
				<StyledDiv>
					<Row justify="space-between">
						<Col>
							<div style={{ display: "inline-block", position: "relative" }}>
								<PodpalButton size="middle" variant="transparent" onClick={toggleEmojiPicker} title="Pick emoji">
									<SmilePlusIcon />
								</PodpalButton>
								<EmojiPicker open={showEmojiPicker} onEmojiSelected={onEmojiSelected} onClickOutside={onClickOutside} />
							</div>
							<Popover
								content={teamMemberContent}
								visible={showTeamMember}
								trigger={"click"}
								onVisibleChange={onVisibleChange}
							>
								<PodpalButton
									size="middle"
									variant="transparent"
									title="At team members"
									onClick={() => setShowTeamMember(prev => !prev)}
								>
									<AtPeopleIcon />
								</PodpalButton>
							</Popover>
						</Col>
						<Col style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
							{isEditing ? (
								<div>
									<PodpalButton
										onClick={onCancelEditClick}
										type="primary"
										size="small"
										style={{
											paddingTop: "2px",
											width: "34px",
											backgroundColor: "#F1564B",
											borderColor: "#f1564B",
											marginRight: "4px"
										}}
										// disabled={isSendButtonDisabled}
									>
										<CloseOutlined />
									</PodpalButton>
									<PodpalButton
										onClick={onFinishEditClick}
										type="primary"
										size="small"
										style={{ paddingTop: "2px", width: "34px", backgroundColor: "#34CC8D", borderColor: "#34CC8D" }}
										// disabled={isSendButtonDisabled}
									>
										<CheckOutlined />
									</PodpalButton>
								</div>
							) : (
								<PodpalButton
									onClick={onSend}
									type="primary"
									size="small"
									style={{ paddingTop: "2px", width: "34px" }}
									disabled={isSendButtonDisabled}
									loading={loading}
								>
									{!loading && <SendIcon />}
								</PodpalButton>
							)}
						</Col>
					</Row>
				</StyledDiv>
			)}
		</div>
	)
}

export default RichTextEditor
