import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import GenericSettingsPage from "containers/SettingsPage/GenericSettingsPage"
import {
	SettingsIcon,
	SettingsFilledIcon,
	TeamManagementIcon,
	TeamManagementFilledIcon,
	HostingAndRSSIcon,
	HostingAndRSSFilledIcon,
	SocialMediaIcon,
	SocialMediaFilledIcon
	// AdsAndSponsorsIcon,
	// AdsAndSponsorsFilledIcon,
} from "assets/icons/UserSettings/MyPodcasts"
import { togglePodcastSettingsLoading } from "redux/actions/UserSettings/MyPodcasts"
import { ShowSettingsForm } from "components/UserSettings/MyPodcasts/ShowSettingsForm"
import { retrieveSinglePodcast } from "redux/actions/Podcasts"
import RSSAndDistribution from "components/UserSettings/MyPodcasts/RSSAndDistribution"
import SocialMediaForm from "components/UserSettings/MyPodcasts/SocialMediaForm"
import {
	SETTINGS,
	SHOW_SETTINGS,
	// ADS_AND_SPONSORS,
	TEAM_MANAGEMENT,
	SOCIAL_MEDIA,
	PODCAST_SETTINGS,
	RSS_AND_DISTRIBUTION,
	NOT_FOUND,
	REDIRECT
} from "constants/routes"
import { Route, Switch, Redirect } from "react-router"
import { setSelectedPodcast } from "redux/actions/App"
import "./PodcastPage.scss"
import { TeamManagement } from "components/UserSettings/MyPodcasts/TeamManagement"
import RedirectPage from "components/UserSettings/MyPodcasts/Redirect"
import Banner301RedirectRequested from "components/Podcasts/Banner301RedirectRequested"

export const PodcastPage = ({ podcastId }) => {
	const dispatch = useDispatch()
	const podcastData = useSelector(state => state.app.selectedPodcast)

	const basePath = `${SETTINGS}${PODCAST_SETTINGS}/${podcastId}`

	useEffect(() => {
		dispatch(togglePodcastSettingsLoading(true))
		dispatch(retrieveSinglePodcast(podcastId)).then(res => {
			const categories = res.data?.podcast?.categories
			if (categories) {
				res.data.podcast.categories = categories.map(category => ({ value: category, label: category }))
			}
			dispatch(setSelectedPodcast(res.data.podcast))
			dispatch(togglePodcastSettingsLoading(false))
		})
	}, [podcastId, dispatch])

	let data = [
		{
			KEY: 1,
			ICON: SettingsIcon,
			FILLED_ICON: SettingsFilledIcon,
			TITLE: "Show Settings",
			PATH: SHOW_SETTINGS,
			CONTENT: <ShowSettingsForm />
		},
		{
			KEY: 2,
			ICON: TeamManagementIcon,
			FILLED_ICON: TeamManagementFilledIcon,
			TITLE: "Team Management",
			PATH: TEAM_MANAGEMENT,
			CONTENT: <TeamManagement />
		},
		{
			KEY: 3,
			ICON: HostingAndRSSIcon,
			FILLED_ICON: HostingAndRSSFilledIcon,
			TITLE: "RSS & Distribution",
			PATH: RSS_AND_DISTRIBUTION,
			CONTENT: <RSSAndDistribution />
		},
		{
			KEY: 4,
			ICON: SocialMediaIcon,
			FILLED_ICON: SocialMediaFilledIcon,
			TITLE: "Social Media",
			PATH: SOCIAL_MEDIA,
			CONTENT: <SocialMediaForm />
		}
		// {
		// 	KEY: 5,
		// 	ICON: AdsAndSponsorsIcon,
		// 	FILLED_ICON: AdsAndSponsorsFilledIcon,
		// 	TITLE: "Ads & Sponsors",
		// 	PATH: ADS_AND_SPONSORS,
		// 	CONTENT: <AdsAndSponsors />,
		// },
	]

	const podcastSubRoutes = data.map(tab => {
		return (
			<Route key={tab.PATH} path={`${basePath}${tab.PATH}`}>
				{tab.CONTENT}
			</Route>
		)
	})

	return (
		<div className="banner-page-container">
			<Banner301RedirectRequested />
			<Switch>
				<Route exact path={`${basePath}${REDIRECT}`}>
					<RedirectPage />
				</Route>
				<GenericSettingsPage heading={podcastData?.title ?? null} settingsTabsArray={data} basePath={basePath}>
					<Switch>
						{podcastSubRoutes}
						<Redirect to={NOT_FOUND} />
					</Switch>
				</GenericSettingsPage>
			</Switch>
		</div>
	)
}
