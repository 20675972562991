import axios from "axios"
import { v4 as uuidv4 } from "uuid"

export const createPodcast = data =>
	axios.post("/api/twirp/podpal.administration.PodcastService/Create", {
		podcast: data,
		userId: data["userId"],
		thumbnailFile: data["photoUrl"]
	})

export const findPodcast = podcastId =>
	axios.post("/api/twirp/podpal.administration.PodcastService/Find", {
		requestId: uuidv4(),
		podcastId: podcastId
	})

export const findPodcastWithInfo = podcastId =>
	axios.post("/api/twirp/podpal.administration.PodcastService/FindWithInfo", {
		podcastId
	})

export const findPodcastByProfileUrl = profileUrl =>
	axios.post("/api/twirp/podpal.administration.PodcastService/FindByProfileURL", {
		requestId: uuidv4(),
		profileUrl
	})

export const findPodcastsByUserID = userId =>
	axios.post("/api/twirp/podpal.administration.PodcastService/FindByUserID", {
		requestId: uuidv4(),
		userId
	})

export const findOwnedPodcastsByUserID = userId =>
	axios.post("/api/twirp/podpal.administration.PodcastService/FindOwnedByUserID", {
		requestId: uuidv4(),
		userId
	})

export const updatePodcast = ({ podcastId, userId, data }) =>
	axios.post("/api/twirp/podpal.administration.PodcastService/Update", {
		requestId: uuidv4(),
		podcastId,
		updatedPodcast: data,
		thumbnailFile: data["newPhotoFile"],
		audioBioFile: data["audioBioFile"],
		userId
	})

export const validatePodcastProfileUrlService = podcastProfileUrl =>
	axios.post("/api/twirp/podpal.administration.PodcastService/ValidatePodcastProfileURL", {
		podcastProfileUrl
	})

export const deletePodcastService = (podcastId, userId) =>
	axios.post("/api/twirp/podpal.administration.PodcastService/Delete", {
		requestId: uuidv4(),
		podcastId,
		userId
	})

export const redirectPodcastService = (podcastId, userId, newRssFeedUrl) =>
	axios.post("/api/twirp/podpal.administration.PodcastService/Redirect", {
		requestId: uuidv4(),
		podcastId,
		userId,
		newRssFeedUrl
	})

export const getRSSFeed = (podcastId, userId) =>
	axios.post("/api/twirp/podpal.administration.PodcastService/GetRSSFeed", {
		requestId: uuidv4(),
		podcastId,
		userId
	})

export const parseRSS = url =>
	axios.post("/api/twirp/podpal.administration.PodcastService/ParseRSS", {
		url
	})

export const migratePodcast = (rssFeedUrl, podcast, userId, oldHost) =>
	axios.post("/api/twirp/podpal.administration.PodcastService/Migrate", {
		requestId: uuidv4(),
		rssFeedUrl,
		podcast,
		userId,
		oldHost
	})
