export enum SubscriptionType {
	free = "FREE",
	starter = "STARTER",
	pro = "PRO",
	agency = "AGENCY",
	beta = "BETA"
}

type SubscriptionTypeMap = Record<SubscriptionType, number>
export const SubscriptionTypeMap: SubscriptionTypeMap = {
	FREE: 0,
	STARTER: 1,
	PRO: 2,
	AGENCY: 3,
	BETA: 4
}

export interface UserInfo {
	id: string
	userId: string
	subscriptionType: SubscriptionType
	numberOfPodcasts: number
	createdAt: string
	lastUpdatedAt: string
	deletedAt: string
	customerId: string
	isMonthly: boolean
	subscriptionActive: boolean
}

export interface FindUserInfoByUserIDResponse {
	requestId: string
	userInfo: UserInfo
}

export interface FindOwnerUserInfoByPodcastIDResponse {
	requestId: string
	userInfo: UserInfo
}
