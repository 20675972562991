import React from "react"
import { Row, Col } from "antd"
import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
import analyticsZeroStateImage from "assets/zerostates/Analytics.svg"

const AnalyticsPage = () => {
	return (
		<div className="home-page-container">
			<Row justify="center" align="middle" style={{ height: "100%" }}>
				<Col>
					<ZeroStateCard
						title="See Who’s Listening"
						description="Get audience insights and analytics to help drive your content strategy and market your show to advertisers and sponsors."
						image={analyticsZeroStateImage}
						comingSoon
					/>
				</Col>
			</Row>
		</div>
	)
}

export default AnalyticsPage
