import * as React from "react"
// import { Button, Avatar } from "antd"
// import { UserOutlined } from "@ant-design/icons"
// import { REVIEW_STATUS_GREEN_CHECK, REVIEW_STATUS_RED_X } from "assets/icons/UserSettings/Notifications"
// import { ReviewStatus } from "services/types/episodeReview"
// import { REVIEW_STATUS, REVIEW_TYPE } from "components/Episodes/EpisodeManagement/EpisodeReviewCard"

import { PodpalNotification } from "services/types/notifications"

interface NotificationActionProps {
	notification: PodpalNotification
}

export const NotificationAction: React.FC<NotificationActionProps> = ({ notification }) => {
	switch (notification.type) {
		// case NotificationType.episodeupdatedreviewstatus:
		// 	const getReviewStatusIcon = (reviewStatus: ReviewStatus) => {
		// 		switch (reviewStatus) {
		// 			case ReviewStatus.accept:
		// 				return <REVIEW_STATUS_GREEN_CHECK />
		// 			default:
		// 				return <REVIEW_STATUS_RED_X />
		// 		}
		// 	}
		// 	const episodeUpdatedReviewStatusNotification = notification.data as EpisodeUpdatedReviewStatusNotification
		// 	return (
		// 		<div className="notification-card-rounded-rectangle">
		// 			<div className="notification-card-rounded-rectangle-review-status-state-container">
		// 				{getReviewStatusIcon(episodeUpdatedReviewStatusNotification.previousReviewStatus)}
		// 				<div className="notification-card-rounded-rectangle-review-status-text">
		// 					{REVIEW_STATUS.find(val => val.value === episodeUpdatedReviewStatusNotification.previousReviewStatus)?.label}
		// 				</div>
		// 				<b>→</b>
		// 				{getReviewStatusIcon(episodeUpdatedReviewStatusNotification.newReviewStatus)}
		// 				<div className="notification-card-rounded-rectangle-review-status-text">
		// 					{REVIEW_STATUS.find(val => val.value === episodeUpdatedReviewStatusNotification.newReviewStatus)?.label}
		// 				</div>
		// 			</div>
		// 		</div>
		// 	)
		// case NotificationType.episodereviewerassignedupdated:
		// 	const episodeReviewerAssignedUpdatedNotification = notification.data as EpisodeReviewerAssignedUpdatedNotification
		// 	return (
		// 		<div className="notification-card-rounded-rectangle">
		// 			<div className="notification-card-rounded-rectangle-review-status-state-container">
		// 				<Avatar className="notification-card-avatar" size={32} icon={<UserOutlined />} />
		// 				<b>→</b>
		// 				<div className="notification-card-rounded-rectangle-review-status-text">
		// 					{REVIEW_TYPE.find(val => val.value === episodeReviewerAssignedUpdatedNotification.reviewType)?.label} Review
		// 				</div>
		// 			</div>
		// 		</div>
		// 	)
		default:
			return <></>
	}
}
