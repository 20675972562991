import { POD, NOT_FOUND } from "constants/routes"
import React from "react"
import { EpisodePage } from "containers/PodPage/EpisodePage"
import { PodcastPage } from "containers/PodPage/PodcastPage"
import { Route, Switch, Redirect } from "react-router"
import { MediaPlayerProvider } from "hooks/useMediaPlayer"
import { MediaPlayer } from "./MediaPlayer"

export function PodPage() {
	return (
		<>
			<MediaPlayerProvider>
				<Switch>
					<Route path={`${POD}/:profileUrl/:episodeId`} component={EpisodePage} />
					<Route path={`${POD}/:profileUrl`} component={PodcastPage} />
					<Redirect to={NOT_FOUND} />
				</Switch>
				<MediaPlayer />
			</MediaPlayerProvider>
		</>
	)
}
