import React, { ReactNode } from "react"
import { Row, Col, Card, Tooltip } from "antd"
import styled from "styled-components"
import PodpalButton from "components/common/Buttons/PodpalButton"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"

const StyledHeader = styled.span`
	font: ${props => props.theme.fontLgPlus};
	color: ${props => props.theme.textPrimary};
`

const StyledMobileHeader = styled.span`
	font: 700 24px/32px Roboto, sans-serif;
	color: ${props => props.theme.textPrimary};
`

const StyledText = styled.span`
	font: ${props => props.theme.fontMd};
	font-weight: 400 !important;
	color: ${props => props.theme.textPrimary};
`

const StyledCard = styled(Card)`
	min-width: 354px;
	max-width: 760px;
	border-radius: 16px;
	padding: ${({ theme }) => theme.spaceLg} 32px;
`

interface PrimaryButtonProps {
	text: string
	disabled?: boolean
	disabledTooltipText?: string
	onClick?: () => void
}

interface SecondaryButtonProps {
	visible: boolean
	text: string
	onClick?: () => void
}

interface ZeroStateCardProps {
	title: string
	description: string
	image: string
	primaryButtonProps?: PrimaryButtonProps
	secondaryButtonProps?: SecondaryButtonProps
	customPrimaryButton?: ReactNode
	comingSoon?: boolean
}

export const ZeroStateCard = ({
	title,
	description,
	image,
	customPrimaryButton,
	primaryButtonProps,
	secondaryButtonProps,
	comingSoon
}: ZeroStateCardProps) => {
	const size = useWindowSize()
	const isMobile = size.width > 0 ? size.width <= BREAKPOINT_XS : false // Need to check as it's 0 for a moment when we switch pages.

	const centerOnMobile = isMobile ? "center" : undefined

	const primaryButton = comingSoon ? (
		<PodpalButton variant="comingSoon" size="large">
			Coming Soon!
		</PodpalButton>
	) : (
		customPrimaryButton ?? (
			<PodpalButton
				type="primary"
				size="large"
				onClick={primaryButtonProps?.onClick}
				disabled={primaryButtonProps?.disabled}
			>
				{primaryButtonProps?.text ?? "Primary"}
			</PodpalButton>
		)
	)
	return (
		<StyledCard bordered={false}>
			<Row justify={centerOnMobile} align="middle" gutter={isMobile ? [0, 24] : 0}>
				<Col xs={24} sm={12} order={isMobile ? 2 : 1}>
					<Row justify={centerOnMobile} align="middle" gutter={[0, 24]}>
						<Col>{isMobile ? <StyledMobileHeader>{title}</StyledMobileHeader> : <StyledHeader>{title}</StyledHeader>}</Col>
					</Row>
					<Row justify={centerOnMobile} align="middle" gutter={[0, 24]}>
						<Col style={isMobile ? { textAlign: "center" } : undefined}>
							<StyledText>{description}</StyledText>
						</Col>
					</Row>
					<Row justify={centerOnMobile} align="middle" gutter={8}>
						<Col>
							{primaryButtonProps?.disabledTooltipText ? (
								<Tooltip placement="bottomRight" title={primaryButtonProps.disabledTooltipText}>
									{primaryButton}
								</Tooltip>
							) : (
								primaryButton
							)}
						</Col>
						{secondaryButtonProps?.visible && (
							<Col>
								<PodpalButton type="primary" variant="outlined" size="large" onClick={secondaryButtonProps?.onClick}>
									{secondaryButtonProps?.text ?? "Secondary"}
								</PodpalButton>
							</Col>
						)}
					</Row>
				</Col>
				<Col xs={24} sm={12} order={isMobile ? 1 : 2}>
					<img src={image} alt="zero-state" width="100%" />
				</Col>
			</Row>
		</StyledCard>
	)
}
