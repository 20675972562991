import {
	ADD_CHANGE_TO_GET_STARTED_FIELD,
	FIREBASE_USER_AUTH_CREATION_LOADING,
	FIREBASE_USER_AUTH_CREATION_SUCCESS,
	FIREBASE_USER_AUTH_CREATION_ERROR,
	DATASTORE_USER_ENTITY_CREATION_ERROR,
	TOGGLE_LOADING_SPINNER_OVERLAY_VISIBLE,
	TOGGLE_TERMS_AND_CONDITIONS_MODAL_VISIBLE
} from "constants/actionTypes"

const initialState = {
	termsAndConditions: false,
	error: null,
	firebaseUserCreated: false,
	loadingSpinnerOverlayVisible: false,
	termsAndConditionsModalVisible: false
}

export function signupReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_CHANGE_TO_GET_STARTED_FIELD:
			const newState = { ...state }
			newState[action.payload.fieldName] = action.payload.fieldValue
			return newState

		case FIREBASE_USER_AUTH_CREATION_LOADING:
			return {
				...state,
				loading: true
			}

		case FIREBASE_USER_AUTH_CREATION_SUCCESS:
			return {
				...state,
				firebaseUserCreated: true
			}

		case FIREBASE_USER_AUTH_CREATION_ERROR:
			return {
				...state,
				error: action.payload.error
			}

		case DATASTORE_USER_ENTITY_CREATION_ERROR:
			return {
				...state,
				error: action.payload.error
			}

		case TOGGLE_LOADING_SPINNER_OVERLAY_VISIBLE:
			return {
				...state,
				loadingSpinnerOverlayVisible: !state.loadingSpinnerOverlayVisible
			}
		case TOGGLE_TERMS_AND_CONDITIONS_MODAL_VISIBLE:
			return {
				...state,
				termsAndConditionsModalVisible: !state.termsAndConditionsModalVisible
			}
		default:
			return state
	}
}
