import React from "react"
import { TreeSelect } from "antd"

import "./BasicTreeSelect.scss"

/**
 * @author Vidhur Kumar
 */
const BasicTreeSelect = ({
	id,
	defaultValue,
	size,
	treeDefaultExpandAll,
	onChange,
	treeData,
	placeholder,
	value,
	getPopupContainer,
	maxTagCount = 1
}) => {
	return (
		<TreeSelect
			id={id}
			className="multiple-tree-select"
			defaultValue={defaultValue}
			size={size}
			treeDefaultExpandAll={treeDefaultExpandAll}
			onChange={onChange}
			treeData={treeData}
			placeholder={placeholder}
			treeCheckable={true}
			showArrow={true}
			multiple={true}
			maxTagCount={maxTagCount}
			treeCheckStrictly={true}
			virtual={false}
			value={value}
			dropdownMatchSelectWidth={false}
			getPopupContainer={getPopupContainer}
		/>
	)
}

export default BasicTreeSelect
