import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col, DatePicker, TimePicker, Form } from "antd"
import PodpalModal from "components/common/Modals/PodpalModal"
import { setEpisodeIdForReleaseDateModal, toggleChangePlannedReleaseDateModalVisible } from "redux/actions/Episodes"
import "./ChangeReleaseDateModal.scss"
import { useEpisode } from "services/queries/Episodes"
import { useQueryClient } from "react-query"
import { updateEpisode } from "services/episode.service"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { FIELD_NAMES } from "constants/strings/UserSettings/MyPodcasts/Form"
import moment from "moment"
import PodpalButton from "components/common/Buttons/PodpalButton"
import useToggle from "hooks/useToggle"

export const ChangePlannedReleaseDateModal = () => {
	const [releaseDate, setReleaseDate] = useState(null)
	const [releaseTime, setReleaseTime] = useState(null)
	const [loading, toggleLoading] = useToggle(false)

	const visible = useSelector(state => state.episode.changePlannedReleaseDateModalVisible)
	const episodeId = useSelector(state => state.episode.episodeIdForPlannedReleaseDate)
	const { isLoading, isError, data } = useEpisode(episodeId)
	const queryClient = useQueryClient()
	const dispatch = useDispatch()
	const [form] = Form.useForm()

	useEffect(() => {
		if (data?.episode?.plannedReleaseDate) {
			const date = moment(data.episode.plannedReleaseDate)
			setReleaseDate(date)
			setReleaseTime(date)
		} else {
			setReleaseDate(moment())
			setReleaseTime(moment())
		}
	}, [data])

	if (isLoading) {
		return (
			<PodpalModal>
				<p>Loading...</p>
			</PodpalModal>
		)
	}

	if (isError) {
		return (
			<PodpalModal>
				<p>Error loading release date.</p>
			</PodpalModal>
		)
	}

	const handleCancel = () => {
		dispatch(setEpisodeIdForReleaseDateModal(null))
		dispatch(toggleChangePlannedReleaseDateModalVisible())
	}

	const handleSave = async () => {
		toggleLoading()
		try {
			let datetime = releaseDate
			if (datetime) {
				datetime.set({ hour: releaseTime.get("hour"), minute: releaseTime.get("minute"), seconds: 0 })
			} else if (releaseTime) {
				throw new Error("Release Date exists but Release Time has a value. Both must have value to save a Release Date.")
			}
			await updateEpisode({ ...data.episode, [FIELD_NAMES.PLANNED_RELEASE_DATE]: datetime })
			successAlert("Successfully updated release date.")
			dispatch(toggleChangePlannedReleaseDateModalVisible())
			dispatch(setEpisodeIdForReleaseDateModal(null))
			queryClient.invalidateQueries(["episodes", data.episode.podcastId])
			queryClient.invalidateQueries(["episode", episodeId])
			setReleaseDate(null)
			setReleaseTime(null)
			toggleLoading()
		} catch (e) {
			if (!releaseDate && releaseTime) {
				errorAlert("Release Date required to save.")
			} else if (!releaseTime && releaseDate) {
				errorAlert("Release Time required to save.")
			} else {
				errorAlert("Unable to save Release Date.")
			}
			toggleLoading()
		}
	}

	return (
		<PodpalModal
			centered
			className="change-release-date-modal"
			visible={visible}
			title="Select Release Date"
			onOk={handleSave}
			onCancel={handleCancel}
			width={420}
			destroyOnClose
			footer={[
				<PodpalButton variant="secondary" onClick={handleCancel}>
					Cancel
				</PodpalButton>,
				<PodpalButton type="primary" onClick={handleSave} loading={loading}>
					OK
				</PodpalButton>
			]}
		>
			<Form form={form} onFinish={handleSave}>
				<Form.Item name={FIELD_NAMES.PLANNED_RELEASE_DATE} rules={[{ required: true }]}>
					<Row gutter={[0, 12]}>
						<Col>
							<p>Select the scheduled release date and time for this episode.</p>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={14}>
							<DatePicker
								style={{ width: "100%" }}
								value={releaseDate}
								onChange={time => setReleaseDate(time)}
								format="dddd, M/D/YYYY"
								size="large"
							/>
						</Col>
						<Col span={10}>
							<TimePicker
								style={{ width: "100%" }}
								use12Hours
								format="h:mm A"
								value={releaseTime}
								onChange={time => setReleaseTime(time)}
								size="large"
							/>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</PodpalModal>
	)
}
