import React, { ComponentProps } from "react"
import Icon, { CheckCircleFilled, SyncOutlined, CloseCircleFilled, EllipsisOutlined } from "@ant-design/icons"
import colors from "styles/scss/color.scss"
import styled from "styled-components"

const BACK_ARROW_SVG = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M17.4376 8.10938H3.72435L11.9322 0.984375C12.0634 0.869531 11.9837 0.65625 11.8103 0.65625H9.73607C9.64466 0.65625 9.55794 0.689062 9.48997 0.747656L0.632941 8.43281C0.551821 8.50313 0.486763 8.59006 0.442175 8.68772C0.397588 8.78537 0.374512 8.89147 0.374512 8.99883C0.374512 9.10618 0.397588 9.21228 0.442175 9.30994C0.486763 9.4076 0.551821 9.49453 0.632941 9.56484L9.54153 17.2969C9.57669 17.3273 9.61888 17.3438 9.66341 17.3438H11.8079C11.9814 17.3438 12.0611 17.1281 11.9298 17.0156L3.72435 9.89062H17.4376C17.5408 9.89062 17.6251 9.80625 17.6251 9.70312V8.29688C17.6251 8.19375 17.5408 8.10938 17.4376 8.10938Z"
			fill="black"
		/>
	</svg>
)

export const BackArrowIcon = (props: ComponentProps<typeof Icon>) => <Icon component={BACK_ARROW_SVG} {...props} />

const LINK_SVG = () => (
	<svg width="18" height="10" viewBox="0 0 18 10" fill="none">
		<path
			d="M2.25033 5.00016C2.25033 3.57516 3.40866 2.41683 4.83366 2.41683H8.16699V0.833496H4.83366C2.53366 0.833496 0.666992 2.70016 0.666992 5.00016C0.666992 7.30016 2.53366 9.16683 4.83366 9.16683H8.16699V7.5835H4.83366C3.40866 7.5835 2.25033 6.42516 2.25033 5.00016ZM5.66699 5.8335H12.3337V4.16683H5.66699V5.8335ZM13.167 0.833496H9.83366V2.41683H13.167C14.592 2.41683 15.7503 3.57516 15.7503 5.00016C15.7503 6.42516 14.592 7.5835 13.167 7.5835H9.83366V9.16683H13.167C15.467 9.16683 17.3337 7.30016 17.3337 5.00016C17.3337 2.70016 15.467 0.833496 13.167 0.833496Z"
			fill="#4D4D4D"
		/>
	</svg>
)

export const LinkIcon = (props: ComponentProps<typeof Icon>) => <Icon component={LINK_SVG} {...props} />

const UPLOAD_RECORDING_SVG = () => (
	<svg width="56" height="56" viewBox="0 0 56 56" fill="none">
		<g filter="url(#filter0_d)">
			<circle cx="28" cy="26" r="19" stroke="#789AFF" stroke-width="2" />
		</g>
		<path
			d="M28 28.1665C29.7983 28.1665 31.25 26.7148 31.25 24.9165V18.4165C31.25 16.6182 29.7983 15.1665 28 15.1665C26.2017 15.1665 24.75 16.6182 24.75 18.4165V24.9165C24.75 26.7148 26.2017 28.1665 28 28.1665Z"
			fill="#789AFF"
		/>
		<path
			d="M33.4167 24.9165C33.4167 27.9065 30.9901 30.3332 28.0001 30.3332C25.0101 30.3332 22.5834 27.9065 22.5834 24.9165H20.4167C20.4167 28.7407 23.2442 31.8823 26.9167 32.4132V35.7498H29.0834V32.4132C32.7559 31.8823 35.5834 28.7407 35.5834 24.9165H33.4167Z"
			fill="#789AFF"
		/>
		<defs>
			<filter
				id="filter0_d"
				x="0"
				y="0"
				width="56"
				height="56"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feOffset dy="2" />
				<feGaussianBlur stdDeviation="4" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
			</filter>
		</defs>
	</svg>
)

export const UploadRecordingIcon = (props: ComponentProps<typeof Icon>) => (
	<Icon component={UPLOAD_RECORDING_SVG} {...props} />
)

const WARNING_QUESTION_MARK_SVG = () => (
	<svg height="24px" viewBox="0 0 24 24" width="24px" fill="#FCBA14">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
	</svg>
)

export const WarningQuestionMarkIcon = (props: ComponentProps<typeof Icon>) => (
	<Icon component={WARNING_QUESTION_MARK_SVG} {...props} />
)

const DANGER_CROSS_SVG = () => (
	<svg height="24px" viewBox="0 0 24 24" width="24px" fill="#EA403B">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
	</svg>
)

export const DangerCrossIcon = (props: ComponentProps<typeof Icon>) => <Icon component={DANGER_CROSS_SVG} {...props} />

const SCHEDULED_SUCCESS_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill={colors.podpalYellowLight}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
		<path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
	</svg>
)

export const ScheduledSuccessIcon = (props: ComponentProps<typeof Icon>) => (
	<Icon component={SCHEDULED_SUCCESS_SVG} {...props} />
)

export const SuccessIcon = (props: ComponentProps<typeof Icon>) => (
	<CheckCircleFilled style={{ color: colors.podpalGreenLight }} {...props} />
)

export const MigratingIcon = () => (
	<SyncOutlined
		spin
		style={{
			color: colors.neutral0,
			background: colors.podpalBlue,
			borderRadius: "50%",
			padding: "4px",
			fontSize: "12px"
		}}
	/>
)

export const MigrationSuccessIcon = (props: ComponentProps<typeof Icon>) => (
	<CheckCircleFilled style={{ color: colors.podpalGreenLight, fontSize: "20px" }} {...props} />
)

export const MigrationErrorIcon = (props: ComponentProps<typeof Icon>) => (
	<CloseCircleFilled style={{ color: colors.podpalRedLight, fontSize: "20px" }} {...props} />
)

const MANAGEMENT_TOOLTIP_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill={colors.textSecondary}>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
	</svg>
)

export const ManagementTooltipIcon = (props: ComponentProps<typeof Icon>) => (
	<Icon component={MANAGEMENT_TOOLTIP_SVG} {...props} />
)

const ADD_REVIEW_ICON = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={colors.neutral2}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
	</svg>
)

export const AddReviewIcon = (props: ComponentProps<typeof Icon>) => <Icon component={ADD_REVIEW_ICON} {...props} />

const EXPAND_ICON = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
	</svg>
)

export const ExpandIcon = (props: ComponentProps<typeof Icon>) => <Icon component={EXPAND_ICON} {...props} />

export const ReviewNotStartedIcon = styled.span`
	border-radius: 50%;
	border: 2px solid ${({ theme }) => theme.textSecondary};
	background: #fff;
	width: 24px;
	height: 24px;
	display: inline-block;
`

export const InReviewIcon = (props: ComponentProps<typeof Icon>) => (
	<EllipsisOutlined
		style={{ background: colors.podpalYellowLight, borderRadius: "50%", color: "#fff", fontSize: "20px", padding: "2px" }}
		{...props}
	/>
)

export const ReviseReviewIcon = (props: ComponentProps<typeof Icon>) => (
	<CloseCircleFilled style={{ color: colors.podpalRedLight, fontSize: "24px" }} {...props} />
)

export const AcceptReviewIcon = (props: ComponentProps<typeof Icon>) => (
	<CheckCircleFilled style={{ color: colors.podpalGreenLight, fontSize: "24px" }} {...props} />
)
