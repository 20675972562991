import {
	TOGGLE_REQUEST_301_REDIRECT_MODAL_VISIBLE,
	TOGGLE_STOP_301_REDIRECT_MODAL_VISIBLE
} from "constants/actionTypes"

const initialState = {
	request301ModalVisible: false,
	stop301ModalVisible: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_REQUEST_301_REDIRECT_MODAL_VISIBLE:
			return {
				...state,
				request301ModalVisible: !state.request301ModalVisible
			}

		case TOGGLE_STOP_301_REDIRECT_MODAL_VISIBLE:
			return {
				...state,
				stop301ModalVisible: !state.stop301ModalVisible
			}
		default:
			return state
	}
}
