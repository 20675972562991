import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col } from "antd"
import {
	setNewPodcastIdForConfirmationModal,
	toggleAddNewPodcastConfirmationModalVisible
} from "redux/actions/Podcasts/AddNewPodcast"
import PodpalModal from "components/common/Modals/PodpalModal"
import podcastAddedImage from "assets/podcasts/happy-feeling.svg"
import "./AddNewPodcastConfirmationModal.scss"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { MY_PODCASTS, PODCAST_SETTINGS, SETTINGS, SHOW_SETTINGS } from "constants/routes"
import { useHistory } from "react-router"

const AddNewPodcastConfirmationModal = props => {
	const visible = useSelector(state => state.addNewPodcast.addNewPodcastConfirmationModalVisible)
	const podcastId = useSelector(state => state.addNewPodcast.newPodcastId)

	const history = useHistory()
	const dispatch = useDispatch()

	const toggleModal = () => dispatch(toggleAddNewPodcastConfirmationModalVisible())

	useEffect(() => {
		return () => {
			dispatch(setNewPodcastIdForConfirmationModal(null))
		}
	}, [dispatch])

	return (
		<PodpalModal
			title="Add Podcast"
			className="add-new-podcast-confirmation-modal"
			centered
			visible={visible}
			onCancel={toggleModal}
			width={420}
			destroyOnClose={true}
			maskClosable={false}
			closable={false}
			footer={[
				<PodpalButton
					variant="secondary"
					onClick={() => {
						toggleModal()
						history.push(`${SETTINGS}${PODCAST_SETTINGS}/${podcastId}${SHOW_SETTINGS}`)
					}}
				>
					Advanced Settings
				</PodpalButton>,
				<PodpalButton
					type="primary"
					onClick={() => {
						toggleModal()
						history.push(MY_PODCASTS)
					}}
				>
					OK
				</PodpalButton>
			]}
		>
			<Row align="center">
				<Col>
					<h2>Your podcast was added successfully!</h2>
				</Col>
			</Row>
			<Row align="center" gutter={[0, 24]}>
				<Col>
					<img src={podcastAddedImage} alt="podcast-added" />
				</Col>
			</Row>
			<Row align="center">
				<Col>
					<p className="bold-text">Feeling good?</p>
				</Col>
			</Row>
			<Row align="center">
				<Col>
					<p>That’s just the way it should be.</p>
				</Col>
			</Row>
		</PodpalModal>
	)
}

export default AddNewPodcastConfirmationModal
