import firebase from "auth/firebase"
import {
	ConfirmPasswordRequest,
	UpdatePasswordRequest,
	ReauthenticateEmailRequest,
	LoginRequest
} from "./types/firebase"

export const confirmPassword = (request: ConfirmPasswordRequest): Promise<firebase.auth.UserCredential> => {
	return firebase.auth.signInWithEmailAndPassword(request.email, request.password)
}

export const updateEmail = (newEmail: string): Promise<void> | void => {
	const user = firebase.auth.currentUser
	return user?.updateEmail(newEmail)
}

export const updatePassword = (request: UpdatePasswordRequest): Promise<void> => {
	return new Promise(async (resolve, reject) => {
		try {
			const userCredential = await firebase.auth.signInWithEmailAndPassword(request.email, request.currentPassword)
			userCredential.user?.updatePassword(request.newPassword)
			resolve()
		} catch (e) {
			reject(e)
		}
	})
}

export const forgotPassword = (email: string): Promise<void> => {
	return firebase.auth.sendPasswordResetEmail(email)
}

export const reauthenticateEmailUser = (
	request: ReauthenticateEmailRequest
): Promise<firebase.auth.UserCredential> | void => {
	const user = firebase.auth.currentUser
	return user?.reauthenticateWithCredential(
		firebase.app.auth.EmailAuthProvider.credential(request.email, request.password)
	)
}

function setPersistence() {
	firebase.auth.setPersistence(firebase.app.auth.Auth.Persistence.LOCAL)
}

export const loginUser = (request: LoginRequest): Promise<firebase.auth.UserCredential> => {
	setPersistence()
	return firebase.auth.signInWithEmailAndPassword(request.email, request.password)
}

export const loginUserWithGoogle = (): Promise<void> => {
	setPersistence()
	const provider = firebase.googleAuthProvider
	firebase.auth.useDeviceLanguage()

	return firebase.auth.signInWithRedirect(provider)
}

export const loginUserWithFacebook = (): Promise<void> => {
	setPersistence()
	var provider = new firebase.app.auth.FacebookAuthProvider()
	provider.addScope("user_birthday")
	firebase.auth.useDeviceLanguage()

	return firebase.auth.signInWithRedirect(provider)
}

export const logoutUser = (): Promise<void> => {
	return firebase.auth.signOut()
}

export const deleteUser = (): Promise<void> | void => {
	const user = firebase.auth.currentUser
	return user?.delete()
}
