import React from "react"
import { Row, Col } from "antd"
import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
import contactsZeroStateImage from "assets/zerostates/Contacts.svg"

export function GuestPortalPage() {
	return (
		<div className="home-page-container">
			<Row justify="center" align="middle" style={{ height: "100%" }}>
				<Col>
					<ZeroStateCard
						title="Find Your Next Guest"
						description="You can find guests for your show, schedule interviews, and connect with other podcasters in the Guest Portal! "
						image={contactsZeroStateImage}
						comingSoon
					/>
				</Col>
			</Row>
		</div>
	)
}
