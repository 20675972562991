import {
	MARK_ALL_NOTIFICATIONS_READ,
	DELETE_ALL_NOTIFICATIONS,
	RETRIEVE_NOTIFICATIONS,
	PREPEND_NEW_NOTIFICATIONS,
	MARK_NOTIFICATION_READ,
	DELETE_NOTIFICATION,
	APPEND_NEW_NOTIFICATIONS,
	UPDATE_UNREAD_NOTIFICATIONS_COUNT
} from "constants/actionTypes"
import { PodpalNotification } from "services/types/notifications"

interface NotificationState {
	notifications: PodpalNotification[]
	unreadNotificationsCount: number | null
}

const initialState: NotificationState = {
	notifications: [],
	unreadNotificationsCount: 0
}

interface NotificationAction {
	type: string
	payload?: {
		notifications?: PodpalNotification[]
		markedNotification?: PodpalNotification
		markedNotifications?: PodpalNotification[]
		deletedNotificationId?: string
		unreadNotificationsCount?: number
	}
}

export default function (state = initialState, action: NotificationAction): NotificationState {
	const notifications = [...state.notifications]
	let unreadNotificationsCount = state.unreadNotificationsCount

	switch (action.type) {
		case MARK_ALL_NOTIFICATIONS_READ:
			const markedNotifications = action.payload?.markedNotifications ?? []

			for (let i = 0; i < markedNotifications.length; i++) {
				const markedNotification = markedNotifications[i]
				const matchIdx = notifications.findIndex(notification => notification.id === markedNotification.id)
				if (matchIdx > -1) {
					notifications[matchIdx] = { ...markedNotification }
				}
			}
			if (unreadNotificationsCount !== null) {
				unreadNotificationsCount -= markedNotifications.length
			}

			return { ...state, notifications: [...notifications], unreadNotificationsCount }

		case DELETE_ALL_NOTIFICATIONS:
			return { ...state, notifications: [] }

		case PREPEND_NEW_NOTIFICATIONS:
			const notificationsToPrepend: PodpalNotification[] = action.payload?.notifications ?? []
			if (unreadNotificationsCount !== null) {
				unreadNotificationsCount += notificationsToPrepend.length
			}
			return { ...state, notifications: [...notificationsToPrepend, ...notifications], unreadNotificationsCount }

		case APPEND_NEW_NOTIFICATIONS:
			const notificationsToAppend: PodpalNotification[] = action.payload?.notifications ?? []
			return { ...state, notifications: [...notifications, ...notificationsToAppend] }

		// Current implementation of RETRIEVE_NOTIFICATIONS assumes the user won't get more than 20 new notifications before the next hook auto-refresh...
		case RETRIEVE_NOTIFICATIONS:
			let retrievedNotifications: PodpalNotification[] = action.payload?.notifications ?? []

			const reconciliatedNotifications = mergeArrays(notifications, retrievedNotifications)
			return {
				...state,
				notifications: reconciliatedNotifications
			}

		case MARK_NOTIFICATION_READ:
			const markedNotification = action.payload?.markedNotification ?? null
			if (unreadNotificationsCount !== null) {
				unreadNotificationsCount--
			}
			const markedIndex = notifications.findIndex(notification => notification.id === markedNotification?.id)
			if (markedIndex > -1 && markedNotification) {
				notifications[markedIndex] = { ...markedNotification }
			}

			return {
				...state,
				notifications,
				unreadNotificationsCount
			}

		case DELETE_NOTIFICATION:
			const deletedNotificationId = action.payload?.deletedNotificationId ?? ""
			const deletedIndex = notifications.findIndex(notification => notification.id === deletedNotificationId)
			let updatedNotifications = [...notifications]
			if (deletedIndex > -1 && deletedNotificationId) {
				updatedNotifications = updatedNotifications
					.slice(0, deletedIndex)
					.concat(updatedNotifications.slice(deletedIndex + 1))
			}

			return {
				...state,
				notifications: updatedNotifications
			}

		case UPDATE_UNREAD_NOTIFICATIONS_COUNT:
			const count = action.payload?.unreadNotificationsCount ?? null
			return {
				...state,
				unreadNotificationsCount: count
			}

		default:
			return state
	}
}

const mergeArrays = (arrA: PodpalNotification[], arrB: PodpalNotification[]) => {
	// In our spread we use arrB first instead of arrA so we can prioritize arrA.
	// This is important if arrA has client side edits (i.e. marked as read) that arrB does not
	// have yet from the server side.
	const unionArray = [...new Map([...arrB, ...arrA].map(item => [item.id, item])).values()].sort((a, b) => {
		if (a.created_date > b.created_date) {
			return -1
		} else if (a.created_date == b.created_date) {
			return 0
		} else {
			return 1
		}
	})

	return unionArray
}
