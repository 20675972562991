import axios from "axios"

export interface ProductMetadata {
	podcasts: string
	episodes: string
	collaborators: string
	hasWebsite: boolean
	storage: string
	downloadsPerMonth: string
	hasVideo: boolean
	hasAudienceInsights: boolean
}
export interface ProductAndPrice {
	name: string
	price: number
	priceId: string
	productId: string | undefined
	metadata: ProductMetadata
	description: string
}
export interface GetProductsAndPricesResponse {
	requestId: string
	productsAndPrices: ProductAndPrice[]
}

interface CreateCheckoutSessionRequest {
	customerId: string | undefined
	priceId: string | undefined
	productId: string | undefined
}

export const createCustomerPortalSession = (customerId: string | undefined) =>
	axios.post("/api/twirp/podpal.billing.BillingService/CreateCustomerPortalSession", {
		customerId
	})

export const createCheckoutSession = ({ customerId, priceId, productId }: CreateCheckoutSessionRequest) =>
	axios.post("/api/twirp/podpal.billing.BillingService/CreateCheckoutSession", {
		customerId,
		priceId,
		productId
	})
