import Icon from "@ant-design/icons"
import React from "react"

const NO_COMMENTS_ICON = () => (
	<svg width="214" height="149" viewBox="0 0 214 149" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_3471_14034)">
			<path
				d="M14.9716 0H128.134C131.97 0.00430624 135.649 1.52545 138.362 4.2297C141.075 6.93395 142.601 10.6005 142.605 14.4249V76.1334C142.601 79.9577 141.075 83.6243 138.362 86.3285C135.649 89.0328 131.97 90.5539 128.134 90.5582H14.9716C11.1348 90.5539 7.45641 89.0328 4.74339 86.3285C2.03038 83.6243 0.504311 79.9577 0.5 76.1334V14.4249C0.504311 10.6005 2.03038 6.93395 4.74339 4.2297C7.45641 1.52545 11.1348 0.00430624 14.9716 0Z"
				fill="#F5F5F5"
			/>
			<path
				d="M14.9714 4.15259H128.134C130.866 4.15564 133.485 5.23887 135.417 7.16463C137.349 9.09039 138.436 11.7014 138.439 14.4248V76.1333C138.436 78.8568 137.349 81.4678 135.417 83.3935C133.485 85.3193 130.866 86.4025 128.134 86.4056H14.9714C12.2392 86.4025 9.61976 85.3192 7.68778 83.3935C5.75581 81.4677 4.66908 78.8567 4.66602 76.1333V14.4248C4.66907 11.7014 5.7558 9.09043 7.68778 7.16467C9.61976 5.23892 12.2392 4.15567 14.9714 4.15259Z"
				fill="white"
			/>
			<rect x="22.5" y="18" width="97" height="14" rx="2" fill="#F5F5F5" />
			<rect x="22.5" y="61" width="97" height="14" rx="2" fill="#F5F5F5" />
			<rect x="22.5" y="39" width="97" height="14" rx="2" fill="#F5F5F5" />
			<path
				d="M16.0753 112.652C15.7338 112.651 15.3959 112.583 15.0809 112.451C14.6107 112.263 14.2085 111.938 13.927 111.518C13.6455 111.098 13.4977 110.603 13.5031 110.098V88.7655L42.0351 87.8086L17.8664 111.899C17.6327 112.137 17.354 112.325 17.0466 112.455C16.7391 112.584 16.409 112.651 16.0753 112.652Z"
				fill="#F5F5F5"
			/>
			<path
				d="M193.663 31.4724H80.5009C76.6641 31.4767 72.9857 32.9979 70.2727 35.7021C67.5597 38.4064 66.0336 42.0729 66.0293 45.8973V107.606C66.0336 111.43 67.5597 115.097 70.2727 117.801C72.9857 120.505 76.6641 122.026 80.5009 122.031H193.663C197.5 122.026 201.178 120.505 203.891 117.801C206.604 115.097 208.13 111.43 208.135 107.606V45.8973C208.13 42.0729 206.604 38.4064 203.891 35.7021C201.178 32.9979 197.5 31.4767 193.663 31.4724Z"
				fill="#E8E8E8"
			/>
			<path
				d="M193.663 35.625H80.5009C77.7686 35.6281 75.1492 36.7113 73.2171 38.637C71.2851 40.5628 70.1984 43.1738 70.1953 45.8973V107.606C70.1984 110.329 71.2851 112.94 73.2171 114.866C75.1492 116.792 77.7686 117.875 80.5009 117.878H193.663C196.395 117.875 199.015 116.792 200.947 114.866C202.879 112.94 203.966 110.329 203.969 107.606V45.8973C203.966 43.1738 202.879 40.5628 200.947 38.6371C199.015 36.7113 196.395 35.6281 193.663 35.625Z"
				fill="white"
			/>
			<path
				d="M185.054 143.372L160.886 119.281L189.418 120.238V141.57C189.423 142.075 189.275 142.57 188.994 142.99C188.712 143.41 188.31 143.735 187.84 143.924C187.525 144.055 187.187 144.123 186.845 144.124C186.512 144.123 186.182 144.056 185.874 143.927C185.567 143.798 185.288 143.609 185.054 143.372Z"
				fill="#E8E8E8"
			/>
			<path
				d="M112.123 84.5446C116.441 84.5446 119.942 81.0554 119.942 76.7514C119.942 72.4474 116.441 68.9583 112.123 68.9583C107.805 68.9583 104.305 72.4474 104.305 76.7514C104.305 81.0554 107.805 84.5446 112.123 84.5446Z"
				fill="#4E73FF"
			/>
			<path
				d="M137.082 84.5446C141.4 84.5446 144.901 81.0554 144.901 76.7514C144.901 72.4474 141.4 68.9583 137.082 68.9583C132.764 68.9583 129.264 72.4474 129.264 76.7514C129.264 81.0554 132.764 84.5446 137.082 84.5446Z"
				fill="#4E73FF"
			/>
			<path
				d="M162.041 84.5446C166.359 84.5446 169.859 81.0554 169.859 76.7514C169.859 72.4474 166.359 68.9583 162.041 68.9583C157.723 68.9583 154.223 72.4474 154.223 76.7514C154.223 81.0554 157.723 84.5446 162.041 84.5446Z"
				fill="#4E73FF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3471_14034">
				<rect width="213" height="149" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
)

const EDIT_ICON = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.611 7.91667C11.611 8.375 11.236 8.75 10.7777 8.75H3.27767C2.81934 8.75 2.44434 8.375 2.44434 7.91667C2.44434 7.45833 2.81934 7.08333 3.27767 7.08333H10.7777C11.236 7.08333 11.611 7.45833 11.611 7.91667ZM2.44434 4.58333C2.44434 5.04167 2.81934 5.41667 3.27767 5.41667H10.7777C11.236 5.41667 11.611 5.04167 11.611 4.58333C11.611 4.125 11.236 3.75 10.7777 3.75H3.27767C2.81934 3.75 2.44434 4.125 2.44434 4.58333ZM8.27767 11.25C8.27767 10.7917 7.90267 10.4167 7.44434 10.4167H3.27767C2.81934 10.4167 2.44434 10.7917 2.44434 11.25C2.44434 11.7083 2.81934 12.0833 3.27767 12.0833H7.44434C7.90267 12.0833 8.27767 11.7083 8.27767 11.25ZM14.9527 9.475L15.5443 8.88333C15.8693 8.55833 16.3943 8.55833 16.7193 8.88333L17.311 9.475C17.636 9.8 17.636 10.325 17.311 10.65L16.7193 11.2417L14.9527 9.475ZM14.361 10.0667L10.061 14.3667C9.986 14.4417 9.94434 14.5417 9.94434 14.6583V15.8333C9.94434 16.0667 10.1277 16.25 10.361 16.25H11.536C11.6443 16.25 11.7527 16.2083 11.8277 16.125L16.1277 11.825L14.361 10.0667Z"
			fill="#4D4D4D"
		/>
	</svg>
)

const LINK_ICON = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.167 5.83337H11.667C11.2087 5.83337 10.8337 6.20837 10.8337 6.66671C10.8337 7.12504 11.2087 7.50004 11.667 7.50004H14.167C15.542 7.50004 16.667 8.62504 16.667 10C16.667 11.375 15.542 12.5 14.167 12.5H11.667C11.2087 12.5 10.8337 12.875 10.8337 13.3334C10.8337 13.7917 11.2087 14.1667 11.667 14.1667H14.167C16.467 14.1667 18.3337 12.3 18.3337 10C18.3337 7.70004 16.467 5.83337 14.167 5.83337ZM6.66699 10C6.66699 10.4584 7.04199 10.8334 7.50033 10.8334H12.5003C12.9587 10.8334 13.3337 10.4584 13.3337 10C13.3337 9.54171 12.9587 9.16671 12.5003 9.16671H7.50033C7.04199 9.16671 6.66699 9.54171 6.66699 10ZM8.33366 12.5H5.83366C4.45866 12.5 3.33366 11.375 3.33366 10C3.33366 8.62504 4.45866 7.50004 5.83366 7.50004H8.33366C8.79199 7.50004 9.16699 7.12504 9.16699 6.66671C9.16699 6.20837 8.79199 5.83337 8.33366 5.83337H5.83366C3.53366 5.83337 1.66699 7.70004 1.66699 10C1.66699 12.3 3.53366 14.1667 5.83366 14.1667H8.33366C8.79199 14.1667 9.16699 13.7917 9.16699 13.3334C9.16699 12.875 8.79199 12.5 8.33366 12.5Z"
			fill="#4D4D4D"
		/>
	</svg>
)

const DELETE_ICON = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.00033 15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V7.5C15.0003 6.58333 14.2503 5.83333 13.3337 5.83333H6.66699C5.75033 5.83333 5.00033 6.58333 5.00033 7.5V15.8333ZM7.50033 7.5H12.5003C12.9587 7.5 13.3337 7.875 13.3337 8.33333V15C13.3337 15.4583 12.9587 15.8333 12.5003 15.8333H7.50033C7.04199 15.8333 6.66699 15.4583 6.66699 15V8.33333C6.66699 7.875 7.04199 7.5 7.50033 7.5ZM12.917 3.33333L12.3253 2.74167C12.1753 2.59167 11.9587 2.5 11.742 2.5H8.25866C8.04199 2.5 7.82533 2.59167 7.67533 2.74167L7.08366 3.33333H5.00033C4.54199 3.33333 4.16699 3.70833 4.16699 4.16667C4.16699 4.625 4.54199 5 5.00033 5H15.0003C15.4587 5 15.8337 4.625 15.8337 4.16667C15.8337 3.70833 15.4587 3.33333 15.0003 3.33333H12.917Z"
			fill="#4D4D4D"
		/>
	</svg>
)

const SMILE_PLUS_ICON = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.99967 10.3333C6.99967 9.64167 7.55801 9.08333 8.24967 9.08333C8.94134 9.08333 9.49967 9.64167 9.49967 10.3333C9.49967 11.025 8.94134 11.5833 8.24967 11.5833C7.55801 11.5833 6.99967 11.025 6.99967 10.3333ZM11.1663 17C13.108 17 14.758 15.7833 15.4247 14.0833H6.90801C7.57467 15.7833 9.22467 17 11.1663 17ZM14.083 11.5833C14.7747 11.5833 15.333 11.025 15.333 10.3333C15.333 9.64167 14.7747 9.08333 14.083 9.08333C13.3913 9.08333 12.833 9.64167 12.833 10.3333C12.833 11.025 13.3913 11.5833 14.083 11.5833ZM19.4997 3.25H17.833V4.91667H16.1663V6.58333H17.833V8.25H19.4997V6.58333H21.1663V4.91667H19.4997V3.25ZM17.833 12.4167C17.833 16.1 14.8497 19.0833 11.1663 19.0833C7.48301 19.0833 4.49967 16.1 4.49967 12.4167C4.49967 8.73333 7.48301 5.75 11.1663 5.75C12.383 5.75 13.5163 6.08333 14.4997 6.65V4.78333C13.4747 4.33333 12.3497 4.08333 11.158 4.08333C6.55801 4.08333 2.83301 7.81667 2.83301 12.4167C2.83301 17.0167 6.55801 20.75 11.158 20.75C15.7663 20.75 19.4997 17.0167 19.4997 12.4167C19.4997 11.5417 19.358 10.7083 19.108 9.91667H17.333C17.6497 10.6917 17.833 11.5333 17.833 12.4167Z"
			fill="#4D4D4D"
		/>
	</svg>
)

export const NoCommentsIcon = ({ className }) => <Icon component={NO_COMMENTS_ICON} />
export const EditIcon = ({ className }) => <Icon component={EDIT_ICON} />
export const LinkIcon = ({ className }) => <Icon component={LINK_ICON} />
export const DeleteIcon = ({ className }) => <Icon component={DELETE_ICON} />
export const SmilePlusIcon = ({ className }) => <Icon component={SMILE_PLUS_ICON} />
