import { useQuery } from "react-query"
import { keysToCamel } from "utils/camelCaseUtils"
import { findUserInfoByUserID, findOwnerUserInfoByPodcastID } from "../userInfo.service"
import { FindUserInfoByUserIDResponse, FindOwnerUserInfoByPodcastIDResponse } from "../types/userInfo"

export const useUserInfo = (userID: string) => {
	return useQuery<FindUserInfoByUserIDResponse>(
		["userInfo", userID],
		async () => {
			const { data } = await findUserInfoByUserID(userID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!userID }
	)
}

export const useOwnerUserInfo = (userID: string) => {
	return useQuery<FindOwnerUserInfoByPodcastIDResponse>(
		["userInfo", userID],
		async () => {
			const { data } = await findOwnerUserInfoByPodcastID(userID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!userID }
	)
}
