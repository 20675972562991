import PodpalCard from "components/common/Cards/PodpalCard"
import { RootState } from "index"
import { Row, Col, Avatar, Divider, Tooltip } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import styled, { DefaultTheme, ThemeContext } from "styled-components"
import { ErrorIcon, SuccessIcon } from "assets/icons/UserSettings/SubscriptionAndBilling"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { QuestionMarkIcon } from "assets/icons/UserSettings/SubscriptionAndBilling"
import { useUserInfo } from "services/queries/UserInfo"
import { SubscriptionType } from "services/types/userInfo"
import { useStripeCustomerPortal } from "hooks/SubscriptionAndBilling/useStripeCustomerPortal"
import { usePodcastWithInfo } from "services/queries/Podcasts"
import { formatFileSize } from "utils/recording"

const StyledPodpalCard = styled(PodpalCard)`
	width: 366px;
	min-height: 324px;

	button {
		width: 100%;
	}
`

const StyledAvatar = styled(Avatar)`
	border-radius: ${({ theme }) => theme.radiusMd};
`
interface TextProps {
	font: keyof DefaultTheme
	color: DefaultTheme
}

const Text = styled.span.attrs((props: TextProps) => ({
	font: props.font,
	color: props.color
}))`
	font: ${({ font }) => font};
	color: ${({ color }) => color};
`

const StyledTable = styled.table`
	width: 100%;

	tr {
		&:not(:last-child) {
			margin-bottom: ${({ theme }) => theme.spaceSm};
		}
	}

	th {
		font: ${({ theme }) => theme.fontSm};
		font-weight: 500;
		color: ${({ theme }) => theme.textPrimary};

		&:last-child {
			text-align: center;
		}
	}

	td {
		font: ${({ theme }) => theme.fontSm};
		color: ${({ theme }) => theme.textSecondary};

		&:last-child {
			text-align: center;
		}
	}
`

interface UsageCardProps {
	podcastID: string
}

export function UsageCard({ podcastID }: UsageCardProps) {
	const theme = useContext(ThemeContext)
	const authUser = useSelector((state: RootState) => state.app.authUser)

	const { data: userInfoData } = useUserInfo(authUser.uid)
	const { openCustomerPortal } = useStripeCustomerPortal({ customerId: userInfoData?.userInfo.customerId })
	const { data: podcastWithInfoData } = usePodcastWithInfo(podcastID)
	const EMPTY_ARTWORK = `https://storage.googleapis.com/${process.env.REACT_APP_PODCAST_PHOTOS_BUCKET}/1/1`
	interface UsageLimits {
		episodes: number
		collaborators: number
		storage: number
		downloadsPerMonth: number
	}

	const [limits, setLimits] = useState<UsageLimits | undefined>(undefined)

	useEffect(() => {
		switch (userInfoData?.userInfo.subscriptionType) {
			case SubscriptionType.starter:
				setLimits({
					episodes: 75,
					collaborators: 3,
					storage: 10,
					downloadsPerMonth: 10000
				})
				break
			case SubscriptionType.pro:
				setLimits({
					episodes: -1,
					collaborators: 5,
					storage: 50,
					downloadsPerMonth: 50000
				})
				break
			case SubscriptionType.agency:
				setLimits({
					episodes: -1,
					collaborators: 10,
					storage: 100,
					downloadsPerMonth: 150000
				})
				break
			case SubscriptionType.beta:
				setLimits({
					episodes: -1,
					collaborators: 5,
					storage: 50,
					downloadsPerMonth: 50000
				})
				break
			default:
				setLimits({
					episodes: 6,
					collaborators: 3,
					storage: 5,
					downloadsPerMonth: 5000
				})
				break
		}
	}, [userInfoData])

	const statusIcon = (metric: any, limit: any) => {
		if (!metric || limit === -1) return <SuccessIcon />
		return metric < limit ? <SuccessIcon /> : <ErrorIcon />
	}

	const exceedsLimit = (metric: any, limit: any) => {
		if (!metric || limit === -1) return false
		return metric < limit ? false : true
	}

	const upgradeEnabled =
		exceedsLimit(podcastWithInfoData?.podcastWithInfo.podcastInfo.numberOfPublishedEpisodes, limits?.episodes) ||
		exceedsLimit(podcastWithInfoData?.podcastWithInfo.podcastInfo.numberOfCollaborators, limits?.collaborators) ||
		exceedsLimit(
			podcastWithInfoData?.podcastWithInfo.podcastInfo.recordingStorageUsed,
			(limits?.storage ?? 0) * Math.pow(1024, 3)
		)

	return (
		<StyledPodpalCard>
			<Row>
				<Col span={6}>
					<StyledAvatar
						shape="square"
						size={64}
						src={podcastWithInfoData?.podcastWithInfo.podcast.photoUrl ?? EMPTY_ARTWORK}
					/>
				</Col>
				<Col span={16}>
					<Row>
						<Col>
							<Text font={theme.fontSm} color={theme.textPrimary}>
								<strong>{podcastWithInfoData?.podcastWithInfo.podcast.title}</strong>
							</Text>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Text font={theme.fontXs} color={theme.textPrimary}>
								<strong>Owner:</strong> {podcastWithInfoData?.podcastWithInfo.podcast.ownerName}
							</Text>
						</Col>
						<Col span={24}>
							<Text font={theme.fontXs} color={theme.textPrimary}>
								<strong>Media Format:</strong>{" "}
								{podcastWithInfoData
									? podcastWithInfoData?.podcastWithInfo?.podcast?.mediaFormat.charAt(0).toUpperCase() +
									  podcastWithInfoData?.podcastWithInfo?.podcast?.mediaFormat.slice(1)
									: "Audio"}
							</Text>
						</Col>
					</Row>
				</Col>
			</Row>
			<Divider />
			<Row justify="center" align="middle" gutter={[0, 16]}>
				<Col span={24}>
					<StyledTable>
						<thead>
							<tr>
								<th>Plan Item</th>
								<th>Usage Limits</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Row gutter={4}>
										<Col>Episodes</Col>
										<Col>
											<Tooltip
												title={
													<span>
														You can always <strong>plan</strong> unlimited episodes in Podpal. However, the number of episodes you can{" "}
														<strong>publish</strong> is limited by your subscription.
													</span>
												}
												placement="bottomRight"
											>
												<QuestionMarkIcon />
											</Tooltip>
										</Col>
									</Row>
								</td>
								<td>
									{`${podcastWithInfoData?.podcastWithInfo?.podcastInfo.numberOfPublishedEpisodes ?? 0} / ${
										limits?.episodes !== -1 ? limits?.episodes : "Unlimited"
									}`}
								</td>
								<td>
									{statusIcon(podcastWithInfoData?.podcastWithInfo?.podcastInfo.numberOfPublishedEpisodes, limits?.episodes)}
								</td>
							</tr>
							<tr>
								<td>Collaborators</td>
								<td>
									{`${podcastWithInfoData?.podcastWithInfo?.podcastInfo.numberOfCollaborators ?? 0} / ${limits?.collaborators}`}
								</td>
								<td>
									{statusIcon(podcastWithInfoData?.podcastWithInfo?.podcastInfo.numberOfCollaborators, limits?.collaborators)}
								</td>
							</tr>
							<tr>
								<td>Storage</td>
								<td>{`${formatFileSize(podcastWithInfoData?.podcastWithInfo?.podcastInfo.recordingStorageUsed, true)} / ${
									limits?.storage
								}GB`}</td>
								<td>
									<SuccessIcon />
								</td>
							</tr>
						</tbody>
					</StyledTable>
				</Col>
			</Row>
			<Row justify="center" align="middle">
				<PodpalButton type="primary" shape="round" size="large" onClick={openCustomerPortal} disabled={!upgradeEnabled}>
					Upgrade Subscription
				</PodpalButton>
			</Row>
		</StyledPodpalCard>
	)
}
