import React, { useState } from "react"
import { Row, Col, Form, Input } from "antd"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "react-redux"
import { toggleDeleteAccountModalVisible } from "redux/actions/UserSettings/MyAccount"
import PodpalModal from "../../PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import useToggle from "hooks/useToggle"
import styled from "styled-components"
import deletePodcastImage from "assets/podcasts/deletepodcast.svg"
import { reauthenticateEmailUser, deleteUser as deleteFirebaseUser } from "services/firebase.service"
import { deleteUser } from "services/user.service"
import { GET_STARTED } from "constants/routes"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"

const StyledPrimaryHeader = styled.span`
	color: ${({ theme }) => theme.textPrimary};
	font: ${({ theme }) => theme.fontLg};
`

const StyledSecondaryText = styled.span`
	color: ${({ theme }) => theme.textSecondary};
	font: ${({ theme }) => theme.fontSm};
`

const StyledPrimaryText = styled.span`
	color: ${({ theme }) => theme.textPrimary};
	font: ${({ theme }) => theme.fontSm};
`

export function DeleteAccountModal() {
	const dispatch = useDispatch()
	const history = useHistory()

	const authUser = useSelector(state => state.app.authUser)
	const visible = useSelector(state => state.myAccountSettings.deleteAccountModalVisible)

	const TITLES = {
		CONFIRMATION: "Impact of Account Deletion",
		CONFIRM_EMAIL: "Confirm Email",
		CONFIRM_PASSWORD: "Final Confirmation",
		CONFIRMATION_ARTWORK: "Account Deleted"
	}

	const [title, setTitle] = useState(TITLES.CONFIRMATION)
	const [loading, toggleLoading] = useToggle()
	const [password, setPassword] = useState(null)
	const [passwordValidationStatus, setPasswordValidationStatus] = useState(null)
	const [passwordErrorMessage, setPasswordErrorMessage] = useState(null)
	const [email, setEmail] = useState(null)
	const [emailValidationStatus, setEmailValidationStatus] = useState(null)
	const [emailErrorMessage, setEmailErrorMessage] = useState(null)

	const hasPasswordAuth = authUser.providerData.some(provider => provider.providerId === "password")

	const handleClose = () => {
		dispatch(toggleDeleteAccountModalVisible())
		setTitle(TITLES.CONFIRMATION)
	}

	const onConfirmationOk = () => {
		setTitle(TITLES.CONFIRM_EMAIL)
	}

	const handleDeleteAccount = () => {
		toggleLoading()
		return new Promise(async (resolve, reject) => {
			try {
				await deleteUser(authUser.uid)
				await deleteFirebaseUser()
				toggleLoading()
				resolve()
			} catch (e) {
				console.log(e)
				toggleLoading()
				reject()
			}
		})
	}

	const onConfirmEmail = async () => {
		if (email !== authUser.email) {
			console.log("getting here")
			setEmailValidationStatus("error")
			setEmailErrorMessage("Your email is incorrect.")
		} else {
			setEmailValidationStatus("")
			setEmailErrorMessage("")

			if (hasPasswordAuth) {
				setTitle(TITLES.CONFIRM_PASSWORD)
			} else {
				console.log("Delete account")
				try {
					await handleDeleteAccount()
					successAlert("Your account was successfully deleted.")
					handleClose()
					// setTitle(TITLES.CONFIRMATION_ARTWORK)
				} catch (e) {
					errorAlert("Failed to delete account.")
				}
			}
		}
	}

	const onConfirmPassword = async () => {
		toggleLoading()
		try {
			await reauthenticateEmailUser({ email: authUser.email, password })
			setPasswordValidationStatus("")
			setPasswordErrorMessage("")
		} catch (e) {
			setPasswordValidationStatus("error")
			setPasswordErrorMessage("Your password is invalid.")
			toggleLoading()
		}

		try {
			await handleDeleteAccount()
			successAlert("Your account was successfully deleted.")
			toggleLoading()
			// setTitle(TITLES.CONFIRMATION_ARTWORK)
			handleClose()
		} catch (e) {
			errorAlert("Failed to delete account.")
			toggleLoading()
		}
	}

	const confirmationBody = (
		<div>
			<Row align="middle" justify="center">
				<p className="delete-podcast-modal-text">
					Wait a second! When you delete this account, you will lose all of the following:
				</p>
			</Row>
			<Row align="middle">
				<Col>
					<ul className="delete-podcast-list">
						<li>Podcasts</li>
						<li>Episodes</li>
						<li>Recordings</li>
						<li>Contacts</li>
						<li>Calendar Events</li>
						<li>Analytics</li>
						<li>Podcast website (Pod page)</li>
						<li>Podcast @username for this username</li>
						<li>Profile @username for this account</li>
						<li>User website (Pal page)</li>
					</ul>
				</Col>
			</Row>
			<Row align="middle">
				<Col>
					<StyledPrimaryText>
						<strong>Essentially everything.</strong> Are you sure you want to do this?
					</StyledPrimaryText>
				</Col>
			</Row>
		</div>
	)

	const confirmEmailBody = (
		<div>
			<Row gutter={[0, 10]}>
				<Col>
					<StyledPrimaryText>Please enter your account email to continue.</StyledPrimaryText>
				</Col>
			</Row>
			<Form>
				<Form.Item name="email" validateStatus={emailValidationStatus} help={emailErrorMessage}>
					<Input onChange={e => setEmail(e.target.value)} />
				</Form.Item>
			</Form>
		</div>
	)

	const confirmPasswordBody = (
		<div>
			<Row gutter={[0, 10]}>
				<Col>
					<StyledPrimaryText>
						Please enter your password to finalize account deletion. When you click "Delete Account" below, you will be logged
						out of Podpal and your account will be gone forever .
					</StyledPrimaryText>
				</Col>
			</Row>
			<Form>
				<Form.Item name="password" validateStatus={passwordValidationStatus} help={passwordErrorMessage}>
					<Input.Password onChange={e => setPassword(e.target.value)} />
				</Form.Item>
			</Form>
		</div>
	)

	const confirmArtworkBody = (
		<>
			<Row align="middle" justify="center" gutter={[0, 24]}>
				<Col>
					<StyledPrimaryHeader>Account oficially deleted.</StyledPrimaryHeader>
				</Col>
			</Row>
			<Row align="middle" justify="center" gutter={[0, 24]}>
				<Col>
					<img src={deletePodcastImage} alt="deletepodcast" />
				</Col>
			</Row>
			<Row align="middle" justify="center" gutter={[0, 8]}>
				<Col>
					<StyledSecondaryText>
						<strong>We've successfully deleted your account from Podpal.</strong>
					</StyledSecondaryText>
				</Col>
			</Row>
		</>
	)

	const confirmationFooter = [
		<PodpalButton key="cancel" variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton
			key="delete"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			onClick={onConfirmationOk}
			type="primary"
		>
			Next
		</PodpalButton>
	]
	const confirmEmailFooter = [
		<PodpalButton key="cancel" variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton
			key="next"
			id="recaptcha-container"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			type={hasPasswordAuth ? "primary" : null}
			danger={!hasPasswordAuth}
			onClick={onConfirmEmail}
		>
			{hasPasswordAuth ? "Next" : "Delete Account"}
		</PodpalButton>
	]
	const confirmPasswordFooter = [
		<PodpalButton key="cancel" variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton
			key="next"
			id="recaptcha-container"
			className="delete-podcast-modal-ok-button"
			loading={loading}
			danger
			onClick={onConfirmPassword}
		>
			Delete Account
		</PodpalButton>
	]
	const confirmArtworkFooter = [
		<PodpalButton
			key="ok"
			type="primary"
			onClick={() => {
				handleClose()
				history.push(`${GET_STARTED}`)
			}}
		>
			OK
		</PodpalButton>
	]

	let body, footer, modalWidth
	switch (title) {
		case TITLES.CONFIRMATION:
			body = confirmationBody
			footer = confirmationFooter
			modalWidth = 480
			break
		case TITLES.CONFIRM_EMAIL:
			body = confirmEmailBody
			footer = confirmEmailFooter
			modalWidth = 420
			break
		case TITLES.CONFIRM_PASSWORD:
			body = confirmPasswordBody
			footer = confirmPasswordFooter
			modalWidth = 420
			break
		case TITLES.CONFIRMATION_ARTWORK:
			body = confirmArtworkBody
			footer = confirmArtworkFooter
			modalWidth = 480
			break
		default:
			modalWidth = 420
			break
	}

	return (
		<PodpalModal
			centered
			closable
			maskClosable={false}
			destroyOnClose
			title={title}
			visible={visible}
			footer={footer}
			width={modalWidth}
			onCancel={handleClose}
		>
			{body}
		</PodpalModal>
	)
}
