import {
	OPEN_ENABLE_TWOFA_MODAL,
	CLOSE_ENABLE_TWOFA_MODAL,
	CHANGE_ENABLE_TWOFA_MODAL_TITLE,
	OPEN_CHANGE_TWOFA_MODAL,
	CLOSE_CHANGE_TWOFA_MODAL,
	CHANGE_CHANGE_TWOFA_MODAL_TITLE,
	OPEN_REMOVE_TWOFA_MODAL,
	CLOSE_REMOVE_TWOFA_MODAL,
	CHANGE_REMOVE_TWOFA_MODAL_TITLE
} from "constants/actionTypes"

const initialState = {
	enableTwoFAModalVisible: false,
	enableTwoFAModalTitle: "Confirm Password",
	changeTwoFAModalVisible: false,
	changeTwoFAModalTitle: "Confirm Password",
	removeTwoFAModalVisible: false,
	removeTwoFAModalTitle: "Confirm Password"
}

export default function (state = initialState, action) {
	switch (action.type) {
		case OPEN_ENABLE_TWOFA_MODAL:
			return {
				...state,
				enableTwoFAModalVisible: true
			}

		case CLOSE_ENABLE_TWOFA_MODAL:
			return {
				...state,
				enableTwoFAModalVisible: false
			}

		case CHANGE_ENABLE_TWOFA_MODAL_TITLE:
			return {
				...state,
				enableTwoFAModalTitle: action.payload.title
			}

		case OPEN_CHANGE_TWOFA_MODAL:
			return {
				...state,
				changeTwoFAModalVisible: true
			}

		case CLOSE_CHANGE_TWOFA_MODAL:
			return {
				...state,
				changeTwoFAModalVisible: false
			}

		case CHANGE_CHANGE_TWOFA_MODAL_TITLE:
			return {
				...state,
				changeTwoFAModalTitle: action.payload.title
			}

		case OPEN_REMOVE_TWOFA_MODAL:
			return {
				...state,
				removeTwoFAModalVisible: true
			}

		case CLOSE_REMOVE_TWOFA_MODAL:
			return {
				...state,
				removeTwoFAModalVisible: false
			}

		case CHANGE_REMOVE_TWOFA_MODAL_TITLE:
			return {
				...state,
				removeTwoFAModalTitle: action.payload.title
			}

		default:
			return state
	}
}
