import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import useToggle from "hooks/useToggle"
import { Input, Form } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalModal from "components/common/Modals/PodpalModal"
import {
	FIELD_NAMES,
	INVALID_EMAIL_ERROR_MESSAGE,
	DUPLICATE_EMAIL_ERROR_MESSAGE
} from "constants/strings/UserSettings/MyAccount/Form"
import { SEQUENCE_NAMES } from "constants/strings/UserSettings/MyAccount/TwoFAToggle"
import { reauthenticateEmailUser, updateEmail } from "services/firebase.service"
import { toggleNewEmailModalVisible } from "redux/actions/UserSettings/MyAccount"
import { validateEmail } from "utils/formUtils"
import { withFirebase } from "auth/firebase"
import changeEmail from "assets/account/change-email.svg"
import "./NewEmailModal.scss"
import { useUser } from "services/queries/User"
import { FIREBASE_ERROR_CODES } from "constants/strings/Auth"
import { updateUser } from "services/user.service"
import { useQueryClient } from "react-query"

const NewEmailModal = () => {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	const authUser = useSelector(state => state.app.authUser)
	const visible = useSelector(state => state.myAccountSettings.newEmailModalVisible)
	const [title, setTitle] = useState(SEQUENCE_NAMES.CONFIRM_PASSWORD)

	const { data } = useUser(authUser.uid)

	const [values, setValues] = useState({
		[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]: "",
		[FIELD_NAMES.NEW_EMAIL]: "",
		[FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE]: ""
	})

	const [validationStatuses, setValidationStatuses] = useState({
		[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]: "",
		[FIELD_NAMES.NEW_EMAIL]: "",
		[FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE]: ""
	})
	const [errorMessages, setErrorMessages] = useState({
		[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]: "",
		[FIELD_NAMES.NEW_EMAIL]: "",
		[FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE]: ""
	})

	const changeValidationStatus = (fieldName, fieldValue) => {
		const temp = validationStatuses
		temp[fieldName] = fieldValue
		setValidationStatuses({ ...temp })
	}

	const changeErrorMessage = (fieldName, fieldValue) => {
		const temp = errorMessages
		temp[fieldName] = fieldValue
		setErrorMessages({ ...temp })
	}

	const [loading, toggleLoading] = useToggle()

	const handleModalClose = () => {
		dispatch(toggleNewEmailModalVisible())
		// Timeout set to ensure user doesn't see change when modal closes.
		setValues({
			[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]: "",
			[FIELD_NAMES.NEW_EMAIL]: "",
			[FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE]: ""
		})
		changeValidationStatus({
			[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]: "",
			[FIELD_NAMES.NEW_EMAIL]: "",
			[FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE]: ""
		})
		changeErrorMessage({
			[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]: "",
			[FIELD_NAMES.NEW_EMAIL]: "",
			[FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE]: ""
		})
		setTimeout(() => {
			setTitle(SEQUENCE_NAMES.CONFIRM_PASSWORD)
		}, 500)
	}

	const handleChange = event => {
		const temp = values
		temp[event.target.name] = event.target.value
		setValues({ ...temp })
	}

	const handleConfirmPassword = async () => {
		toggleLoading()
		try {
			await reauthenticateEmailUser({ email: data?.user?.email, password: values[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD] })
			toggleLoading()
			setTitle(SEQUENCE_NAMES.CHANGE_EMAIL)
		} catch (e) {
			toggleLoading()
			changeValidationStatus(FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD, "error")
			changeErrorMessage(FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD, "Password is invalid")
		}
	}

	const handleChangeEmail = () => {
		if (!validateEmail(values[FIELD_NAMES.NEW_EMAIL])) {
			changeValidationStatus(FIELD_NAMES.NEW_EMAIL, "error")
			changeErrorMessage(FIELD_NAMES.NEW_EMAIL, INVALID_EMAIL_ERROR_MESSAGE)
		} else {
			handleUpdateEmail()
		}
	}

	const handleUpdateEmail = async () => {
		toggleLoading()
		try {
			await updateEmail(values[FIELD_NAMES.NEW_EMAIL])
			await updateUser({
				...data?.user,
				email: values[FIELD_NAMES.NEW_EMAIL]
			})
			queryClient.invalidateQueries(["user", authUser.uid])
			setTitle(SEQUENCE_NAMES.UPDATE_EMAIL)
			toggleLoading()
		} catch (e) {
			toggleLoading()
			if (e.code === FIREBASE_ERROR_CODES.EMAIL_ALREADY_IN_USE) {
				changeValidationStatus(FIELD_NAMES.NEW_EMAIL, "error")
				changeErrorMessage(FIELD_NAMES.NEW_EMAIL, DUPLICATE_EMAIL_ERROR_MESSAGE)
			}
		}
	}

	let fieldName,
		fieldValue,
		fieldPlaceHolder,
		fieldType,
		fieldErrorMessage,
		fieldValidationStatus,
		onOkFunction,
		bodyTitle
	let isPasswordModal = false
	let isConfirmationModal = false
	onOkFunction = handleConfirmPassword
	switch (title) {
		case SEQUENCE_NAMES.CONFIRM_PASSWORD:
			isPasswordModal = true
			fieldName = FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD
			fieldValue = values[FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD]
			fieldPlaceHolder = "Password"
			fieldType = "password"
			fieldErrorMessage = errorMessages.newEmailConfirmPassword
			fieldValidationStatus = validationStatuses.newEmailConfirmPassword
			onOkFunction = handleConfirmPassword
			bodyTitle = "Please enter your Podpal password to continue"
			break
		case SEQUENCE_NAMES.CHANGE_EMAIL:
			isPasswordModal = false
			fieldName = FIELD_NAMES.NEW_EMAIL
			fieldValue = values[FIELD_NAMES.NEW_EMAIL]
			fieldPlaceHolder = "Enter email"
			fieldType = "email"
			fieldErrorMessage = errorMessages.newEmail
			fieldValidationStatus = validationStatuses.newEmail
			onOkFunction = handleChangeEmail
			bodyTitle = "Please enter a new email address"
			break
		case SEQUENCE_NAMES.UPDATE_EMAIL:
			isConfirmationModal = true
			break
		default:
			break
	}
	let modalClassname = isConfirmationModal ? "new-email-confirmation-modal" : "new-email-modal"
	const confirmationBody = (
		<div>
			<p className="new-email-confirmation-body-header">We’ve updated your email address.</p>
			<img src={changeEmail} alt="security-on" />
			<p className="new-email-confirmation-body-twofa-on">
				<strong>We sent a confirmation to your old email address.</strong>
			</p>
		</div>
	)
	const confirmationFooter = isConfirmationModal
		? [
				<PodpalButton key="ok" type="primary" onClick={handleModalClose}>
					OK
				</PodpalButton>
		  ]
		: [
				<PodpalButton key="cancel" variant="secondary" className="new-email-modal-cancel-button" onClick={handleModalClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton key="next" id="recaptcha-container" type="primary" loading={loading} onClick={onOkFunction}>
					Next
				</PodpalButton>
		  ]
	return (
		<>
			<PodpalModal
				className={modalClassname}
				centered
				closable
				maskClosable={false}
				destroyOnClose
				visible={visible}
				title={title}
				onOk={onOkFunction}
				onCancel={handleModalClose}
				footer={confirmationFooter}
				width={420}
			>
				{isConfirmationModal ? (
					confirmationBody
				) : (
					<div>
						<p>{bodyTitle}</p>
						<Form onFinish={onOkFunction}>
							<Form.Item name={fieldName} help={fieldErrorMessage} validateStatus={fieldValidationStatus}>
								{isPasswordModal ? (
									<Input.Password
										name={fieldName}
										type={fieldType}
										value={fieldValue}
										placeholder={fieldPlaceHolder}
										onChange={handleChange}
									/>
								) : (
									<Input
										name={fieldName}
										type={fieldType}
										value={fieldValue}
										placeholder={fieldPlaceHolder}
										onChange={handleChange}
									/>
								)}
							</Form.Item>
						</Form>
					</div>
				)}
			</PodpalModal>
		</>
	)
}

export default withFirebase(NewEmailModal)
