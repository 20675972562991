import { Col, Row } from "antd"
import React from "react"
import styled from "styled-components"
import PodpalButton from "./PodpalButton"

const StyledPodpalButton = styled(PodpalButton)`
	font: 400 16px/24px Roboto, sans-serif;
	.vertical-border {
		border-right: 1px solid ${props => props.theme.neutral2};
		height: 50px;
		position: absolute;
		margin-left: 45px;
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	.icon {
		max-height: 24px;
		margin-left: 5px;
	}
	&&:hover,
	&&:active,
	&&:focus {
		.vertical-border {
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			border-color: ${props => props.theme.neutral2};
		}
	}
`

export const PodpalSocialButton = ({ children, icon, action }) => {
	return (
		<StyledPodpalButton fullwidth isxl shape="round" variant="secondary" onClick={action}>
			<Row align="middle">
				<Col span={2}>
					<img className="icon" src={icon} alt="Google" />
				</Col>
				<div className="vertical-border"></div>
				<Col span={11} offset={5}>
					{children}
				</Col>
			</Row>
		</StyledPodpalButton>
	)
}
