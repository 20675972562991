import {
	TOGGLE_HELP_CENTER_DROPDOWN,
	TOGGLE_VIDEO_TUTORIAL_MODAL_VISIBLE,
	SET_VIDEO_TUTORIAL_MODAL_CONFIG,
	TOGGLE_WHATS_NEW_MODAL_VISIBLE
} from "constants/actionTypes"

const initialState = {
	newUpdates: [1],
	helpCenterDropdownVisible: false,
	videoTutorialModalVisible: false,
	videoTutorialModalConfig: null,
	whatsNewModalVisible: false
}

export const helpCenterReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_HELP_CENTER_DROPDOWN:
			return {
				...state,
				helpCenterDropdownVisible: !state.helpCenterDropdownVisible
			}
		case TOGGLE_VIDEO_TUTORIAL_MODAL_VISIBLE:
			return {
				...state,
				videoTutorialModalVisible: !state.videoTutorialModalVisible
			}

		case SET_VIDEO_TUTORIAL_MODAL_CONFIG:
			return {
				...state,
				videoTutorialModalConfig: action.payload
			}
		case TOGGLE_WHATS_NEW_MODAL_VISIBLE:
			return {
				...state,
				whatsNewModalVisible: !state.whatsNewModalVisible
			}
		default:
			return state
	}
}
