import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
	addChangeToField,
	signupUser,
	userSignupSuccess,
	userSignupError,
	createDataStoreUser,
	toggleLoadingSpinnerOverlayVisible,
	toggleTermsAndConditionsModalVisible
} from "redux/actions/GetStarted"

import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { PodpalSocialButton } from "components/common/Buttons/PodpalSocialButton"
import { Checkbox, Divider, Input, Col, Row, Form } from "antd"
import PodpalLogoBlack from "assets/Logo_Black.png"
import PodpalLogoWhite from "assets/logo.png"
import GoogleIcon from "assets/icons/Common/SocialMedia/Google-icon.png"
import FacebookIcon from "assets/icons/Common/SocialMedia/facebook-icon.png"
import getStartedImage from "assets/SignIn_SignUp/getStartedImage.png"
import { GET_STARTED_LOADING, MY_PODCASTS, SIGN_IN } from "constants/routes"
import {
	FIELD_NAMES,
	FIREBASE_ERROR_CODES,
	EMAIL_REQUIRED,
	EMAIL_INVALID,
	PASSWORD_REQUIRED,
	PASSWORD_REQUIRES_LOWERCASE_CHAR,
	PASSWORD_REQUIRES_UPPERCASE_CHAR,
	PASSWORD_REQUIRES_NUMBER,
	PASSWORD_REQUIRES_SPECIAL_CHAR,
	PASSWORD_TOO_SHORT,
	TERMS_AND_CONDITIONS_REQUIRED
} from "constants/strings/Auth"
import {
	DATASTORE_USER_ENTITY_CREATION_SUCCESS,
	FIREBASE_USER_AUTH_CREATION_ERROR,
	FIREBASE_USER_AUTH_CREATION_SUCCESS
} from "constants/actionTypes"
import colors from "styles/scss/color.scss"
import styled from "styled-components"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { useHistory } from "react-router"
import { sendVerificationEmail } from "services/email.service"

const StyledRow = styled(Row)`
	width: 100%;
	height: 100vh;
	background: linear-gradient(90deg, ${({ theme }) => theme.neutral1} 50%, #ffffff 50%);
	align-items: center;
	@media screen and (max-width: ${({ theme }) => theme.lg}) {
		height: calc(100vh - 64px);
		background: #ffffff;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: ${({ theme }) => theme.xs}) {
		& {
			overflow-y: scroll;
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			/* Hide scrollbar for Chrome, Safari and Opera */
			::-webkit-scrollbar {
				display: none;
			}

			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
`

const FirstCol = styled(Col)`
	background: ${({ theme }) => theme.neutral1};
	flex-direction: column;
	justify-content: center;
	height: auto;
	.get-started-image {
		@media screen and (max-width: ${({ theme }) => theme.xl}) {
			max-width: 100%;
		}
		max-width: 662px;
		height: auto;
	}
	@media screen and (max-width: ${({ theme }) => theme.lg}) {
		& {
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
	}
	@media screen and (max-width: ${({ theme }) => theme.xs}) {
		& {
			display: none;
		}
	}
`

const SecondCol = styled(Col)`
	padding: ${({ theme }) => theme.spaceLg};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	a:not(.ant-btn) {
		color: ${({ theme }) => theme.podpalBlue};
	}
	@media screen and (max-width: ${({ theme }) => theme.sm}) {
		& {
			padding-top: 48px;
		}
	}
`

const AlreadyMemberFooterText = styled.p`
	color: ${({ theme }) => theme.gray7};
`

const TopBar = styled(Row)`
	width: 100%;
	height: 64px;
	padding: 0 ${({ theme }) => theme.spaceLg};
	position: absolute;
	top: 0;
	z-index: 1;
	@media screen and (max-width: ${({ theme }) => theme.lg}) {
		& {
			position: relative;
		}
	}
`

const MobileTopBar = styled(Row)`
	width: 100%;
	height: 64px;
	background: ${({ theme }) => theme.podpalBlue};
	padding: 0px 35px;
`

const PrimaryHeading = styled.span`
	font: ${({ theme }) => theme.fontLgPlus};
	color: ${({ theme }) => theme.textPrimary};
`

const GetStartedPage = ({ termsAndConditions = false }) => {
	// need to use require so that css is scoped to this component, since we are overriding the global body styles for scrollability
	require("./GetStartedPage.scss")
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const screens = useBreakpoint()
	const history = useHistory()
	const authUser = useSelector(state => state.app.authUser)

	useEffect(() => {
		if (authUser) {
			history.push(MY_PODCASTS)
		}
	}, [authUser, history])

	// make just sign in scrollable (for mobile), but leave the rest of the app not scrollable
	useEffect(() => {
		document.body.style.overflowY = "scroll"
		document.body.style.height = "100vh"
		return () => {
			document.body.style.overflowY = "hidden"
			document.body.style.height = "100%"
		}
	}, [])

	const handleCheckboxChange = e => {
		addChangeToField(e.target.name, e.target.checked)
	}

	const handleOnFinish = async values => {
		dispatch(toggleLoadingSpinnerOverlayVisible())
		const { email, password, firstName, lastName } = values
		try {
			const newUser = await dispatch(signupUser({ email, password }))
			await dispatch(createDataStoreUser({ id: newUser.user.uid, email, firstName, lastName }))
			await sendVerificationEmail(newUser.user.uid)
			dispatch({
				type: FIREBASE_USER_AUTH_CREATION_SUCCESS,
				payload: {
					authUser: authUser
				}
			})

			dispatch({
				type: DATASTORE_USER_ENTITY_CREATION_SUCCESS
			})
			dispatch(toggleLoadingSpinnerOverlayVisible())
		} catch (e) {
			// TODO: HANDLE ERRORS FROM FIREBASE AND THE CASE IF FIRESTORE FAILS.
			if (e.code === FIREBASE_ERROR_CODES.EMAIL_ALREADY_IN_USE) {
				errorAlert("An account already exists with this email address.")
			}
			dispatch(toggleLoadingSpinnerOverlayVisible())
			dispatch({
				type: FIREBASE_USER_AUTH_CREATION_ERROR,
				payload: {
					error: e
				}
			})
		}
	}

	const handleOnFinishFailed = () => {
		errorAlert("Please fix errors to create your account.")
	}

	const handleLogin = provider => {
		history.push(GET_STARTED_LOADING, { provider, page: "getStarted" })
	}

	return (
		<>
			{screens.xs ? (
				<MobileTopBar justify="space-between" align="middle">
					<Col>
						<a href="https://www.podpal.com/">
							<img src={PodpalLogoWhite} alt="podpal" className="logo" height={30} />
						</a>
					</Col>
					<Col>
						<PodpalButton variant="white" size="large" href={SIGN_IN}>
							Sign In
						</PodpalButton>
					</Col>
				</MobileTopBar>
			) : (
				<TopBar align="middle" justify="space-between">
					<Col>
						<a href="https://www.podpal.com/">
							<img src={PodpalLogoBlack} alt="podpal" className="logo" height={64} />
						</a>
					</Col>
					<Col>
						<Row gutter={16} align="middle">
							<Col>
								<p className="already-member-text">Already a member?</p>
							</Col>
							<Col>
								<PodpalButton variant="secondary" size="large" href={SIGN_IN}>
									Sign In
								</PodpalButton>
							</Col>
						</Row>
					</Col>
				</TopBar>
			)}
			<StyledRow>
				<FirstCol span={12} xs={{ order: 2, span: 24 }} xl={{ order: 1, span: 12 }} style={{ background: colors.neutral1 }}>
					<Row justify="center" align="middle" gutter={[0, 24]} style={{ padding: "40px" }}>
						<Col sm={12} xl={24} style={{ textAlign: "center" }}>
							<img className="get-started-image" src={getStartedImage} alt="get-started" />
						</Col>
						<Col sm={12} xl={24} style={{ textAlign: "center" }} span={24}>
							<PrimaryHeading>
								Podcasting doesn’t have to be hard.
								<br />
								We make it easy.
							</PrimaryHeading>
						</Col>
					</Row>
				</FirstCol>
				<SecondCol span={12} xs={{ order: 1, span: 24 }} xl={{ order: 2, span: 12 }}>
					<Row align="middle" justify="center" gutter={[0, 64]} style={{ marginTop: "0px" }}>
						<PrimaryHeading className="form-title">Get Started</PrimaryHeading>
					</Row>
					<Row align="middle" justify="center">
						<Col xs={24} sm={12} style={{ maxWidth: "352px" }}>
							<Form form={form} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed}>
								<Form.Item
									name={FIELD_NAMES.EMAIL}
									rules={[
										{
											required: true,
											message: EMAIL_REQUIRED
										},
										{
											type: "email",
											message: EMAIL_INVALID
										}
									]}
								>
									<Input size="large" name={FIELD_NAMES.EMAIL} placeholder="Email Address" />
								</Form.Item>
								<Form.Item
									name={FIELD_NAMES.PASSWORD}
									hasFeedback
									validateFirst
									rules={[
										{
											required: true,
											message: PASSWORD_REQUIRED
										},
										{
											min: 8,
											message: PASSWORD_TOO_SHORT
										},
										{
											pattern: /^(?=.*[a-z]).{1,}$/,
											message: PASSWORD_REQUIRES_LOWERCASE_CHAR
										},
										{
											pattern: /^(?=.*[A-Z]).{1,}$/,
											message: PASSWORD_REQUIRES_UPPERCASE_CHAR
										},
										{
											pattern: /^(?=.*\d).{1,}$/,
											message: PASSWORD_REQUIRES_NUMBER
										},
										{
											pattern: /^(?=.*[@$!%*?&]).{1,}$/,
											message: PASSWORD_REQUIRES_SPECIAL_CHAR
										}
									]}
								>
									<Input.Password size="large" name={FIELD_NAMES.PASSWORD} placeholder="Password" />
								</Form.Item>
								<Form.Item
									name={FIELD_NAMES.TERMS_AND_CONDITIONS}
									valuePropName="checked"
									rules={[
										({ getFieldValue }) => ({
											validator: async () => {
												const isChecked = getFieldValue(FIELD_NAMES.TERMS_AND_CONDITIONS)
												return !isChecked ? Promise.reject(TERMS_AND_CONDITIONS_REQUIRED) : Promise.resolve()
											}
										})
									]}
								>
									<Checkbox
										className="checkbox"
										checked={termsAndConditions}
										name={FIELD_NAMES.TERMS_AND_CONDITIONS}
										onChange={handleCheckboxChange}
									>
										I have read and accept Podpal’s{" "}
										<Link
											to={{ pathname: "https://www.podpal.com/legal/terms-of-service" }}
											target="_blank"
											onClick={() => dispatch(toggleTermsAndConditionsModalVisible())}
										>
											Terms of Service
										</Link>{" "}
										and{" "}
										<a href="https://www.podpal.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
											Privacy Policy
										</a>
										.
									</Checkbox>
								</Form.Item>
								<PodpalButton shape="round" fullwidth type="primary" htmlType="submit" isxl>
									Create an Account
								</PodpalButton>
								<Divider plain>OR</Divider>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<PodpalSocialButton action={() => handleLogin("google")} icon={GoogleIcon}>
											Sign up with Google
										</PodpalSocialButton>
									</Col>
									<Col span={24}>
										<PodpalSocialButton action={() => handleLogin("facebook")} icon={FacebookIcon}>
											Sign up with Facebook
										</PodpalSocialButton>
									</Col>
								</Row>
							</Form>
							<Row justify="center" align="middle">
								<AlreadyMemberFooterText>
									Already a Member? <Link to={SIGN_IN}>Sign In</Link>
								</AlreadyMemberFooterText>
							</Row>
						</Col>
					</Row>
				</SecondCol>
			</StyledRow>
		</>
	)
}

const mapStateToProps = state => ({
	termsAndConditions: state.signup.termsAndConditions,
	loading: state.signup.loading,
	error: state.signup.error
})

function mapDispatchToProps(dispatch) {
	return {
		userSignupSuccess: () => dispatch(userSignupSuccess()),
		userSignupError: error => dispatch(userSignupError(error))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetStartedPage))
