export enum Operators {
	equal = "EQUAL",
	lessThan = "LESS_THAN",
	lessThanOrEqual = "LESS_THAN_OR_EQUAL",
	greaterThan = "GREATER_THAN",
	greaterThanOrEqual = "GREATER_THAN_OR_EQUAL",
	notEqual = "NOT_EQUAL"
}

export enum PropertyTypes {
	string = "STRING",
	date = "DATE",
	integer = "INTEGER",
	boolean = "BOOLEAN",
	float = "FLOAT"
}
export interface QueryFilter {
	propertyName: string
	propertyValue: any
	propertyType: PropertyTypes
	operator: Operators
}

export interface QueryOrder {
	propertyName: string
	sort: "ASC" | "DESC"
}

export interface QueryOptions {
	limit?: number
	orders?: QueryOrder[]
	filters?: QueryFilter[]
}
