import React from "react"
import { message } from "antd"
import { CheckCircleFilled } from "@ant-design/icons"
import colors from "styles/scss/color.scss"

export const successAlert = body => {
	message.success({
		content: body,
		icon: <CheckCircleFilled style={{ color: colors.podpalGreenLight }} />
	})
}
