import React, { useState } from "react"
import PodpalCard from "components/common/Cards/PodpalCard"
import styled from "styled-components"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { RecordingsIcon } from "assets/icons/Sidebar"
import { PodcastDistributionModal } from "./PodcastDistributionModal"
import { getOnTheShowComingSoonModal } from "components/common/Modals/Episodes/EpisodeManagementModals"

const StyledPodpalCard = styled(PodpalCard)`
	height: 330px;
	text-align: center;
	padding: 30px 30px 15px 30px;

	h2 {
		font: 700 24px/20px Roboto, sans-serif;
	}

	.media-format {
		text-transform: capitalize;
		font: ${({ theme }) => theme.fontSm};
		font-size: 18px;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		align-items: center;

		.item {
			width: 100%;
			flex: 1 auto;

			&.listen-section {
				padding-top: 1em;
				border-top: 1px solid ${({ theme }) => theme.neutral2};
				align-self: flex-end;

				button span.anticon {
					vertical-align: middle;
					& svg {
						vertical-align: middle;
					}
				}
			}
		}
	}
`

interface PodcastTitleCardProps {
	title: string
	mediaFormat: string
	audioBioUrl?: string
	applePodcastsUrl?: string
	spotifyUrl?: string
	googlePodcastsUrl?: string
	amazonMusicUrl?: string
}

export function PodcastTitleCard({ title, mediaFormat, audioBioUrl, ...props }: PodcastTitleCardProps) {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	return (
		<StyledPodpalCard>
			<div className="container">
				<div className="item">
					<h2>{title}</h2>
					<p className="media-format">{mediaFormat} podcast</p>
				</div>
				<div className="item">
					<PodpalButton size="large" shape="round" type="primary" onClick={() => getOnTheShowComingSoonModal(title)}>
						Get on the Show!
					</PodpalButton>
				</div>
				{audioBioUrl && (
					<div className="item">
						<audio controls controlsList="nodownload">
							<source src={audioBioUrl} type="audio/mp3" />
							<source src={audioBioUrl} type="audio/mp4" />
						</audio>
					</div>
				)}
				<hr />
				<div className="item listen-section">
					<PodpalButton
						onClick={() => setModalIsOpen(!modalIsOpen)}
						size="large"
						icon={<RecordingsIcon />}
						variant="secondary"
					>
						Listen On...
					</PodpalButton>
				</div>
			</div>
			<PodcastDistributionModal visible={modalIsOpen} onClose={() => setModalIsOpen(false)} {...props} />
		</StyledPodpalCard>
	)
}
