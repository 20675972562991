import { Badge, Col, Form, Row } from "antd"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import RichTextEditor from "components/common/Input/RichTextEditor"
import { useEpisode } from "services/queries/Episodes"
import { updateEpisode } from "services/episode.service"
import { useParams } from "react-router"
import { useQueryClient } from "react-query"
import React, { useEffect, useState } from "react"
import PodpalCard from "components/common/Cards/PodpalCard"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import useRichTextEditor from "hooks/useRichTextEditor"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import moment from "moment"
import colors from "styles/scss/color.scss"
import { EpisodeManagementTooltip } from "./EpisodeManagementTooltip"

const ShowNotes = ({ className }) => {
	const [form] = Form.useForm()
	const { episodeId } = useParams()
	const [disableMarkAsDone, setDisableMarkAsDone] = useState(true)
	const queryClient = useQueryClient()

	const { isLoading, isError, data } = useEpisode(episodeId)
	const {
		isError: episodeProgressError,
		isLoading: episodeProgressLoading,
		data: episodeProgressData
	} = useEpisodeProgress(episodeId)

	const maxShowNotesLength = 4000

	const { content, handleChange, setLength, overLength, contentLength } = useRichTextEditor(
		data.episode.showNotes,
		maxShowNotesLength
	)

	useEffect(() => {
		if (data.episode.showNotes) {
			setDisableMarkAsDone(false)
		}
	}, [data])

	const checkIfModified = changedFields => {
		if (changedFields.showNotes !== data.episode.showNotes) {
			setDisableMarkAsDone(true)
		}
	}

	if (isLoading || episodeProgressLoading) {
		return (
			<PodpalCard className={className}>
				<div>Loading...</div>
			</PodpalCard>
		)
	}

	if (isError || episodeProgressError) {
		return (
			<PodpalCard className={className}>
				<div>Error occurred while retrieving data.</div>
			</PodpalCard>
		)
	}

	const onFinish = values => {
		updateEpisode({ ...data.episode, ...values })
			.then(() => {
				successAlert("Saved changes to Show Notes.")
				queryClient.invalidateQueries(["episode", episodeId])
			})
			.catch(e => {
				errorAlert("Error occurred updating show notes.")
			})
	}

	const {
		setup,
		inviteGuests,
		scheduleInterview,
		uploadAudio,
		writeShowNotes,
		reviewEpisode
	} = episodeProgressData?.episodeProgress

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: reviewEpisode ?? 0
		}
	]

	const handleMarkAsDone = async progressStep => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.COMPLETED
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			const showNotes = saveDraft()
			const update = updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.COMPLETED })
			await Promise.all([showNotes, update])
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleUncheckStep = async progressStep => {
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.IN_PROGRESS
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.IN_PROGRESS })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
			queryClient.invalidateQueries(["episode", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as incomplete.")
		}
	}

	const saveDraft = async () => {
		try {
			let publishedEpisodeModified = false
			if (data.episode.isPublished) {
				publishedEpisodeModified = true
			}
			await updateEpisode({ ...data.episode, showNotes: content, publishedEpisodeModified })
			queryClient.invalidateQueries(["episode", episodeId])
		} catch (e) {
			errorAlert("Failed to save show notes draft.")
		}
	}

	const showNotesCompleted = episodeProgressData.episodeProgress.writeShowNotes === STATUS.COMPLETED

	const formatDate = date => moment(date).format("M/D/YY [at] h:mm A")

	const badgeColor = () => {
		if (showNotesChanged) return colors.podpalRedLight
		else if (showNotesCompleted) return colors.podpalGreenLight
		else if (data.episode.showNotesUpdatedAt) return colors.podpalBlue
		else return colors.neutral2
	}

	const statusText = () => {
		if (showNotesChanged) return "Unsaved changes"
		else if (showNotesCompleted) return `Completed on ${formatDate(data.episode.showNotesUpdatedAt)}`
		else if (data.episode.showNotesUpdatedAt) return `Last saved ${formatDate(data.episode.showNotesUpdatedAt)}`
		else return "Not applicable"
	}

	const showNotesChanged = content !== data.episode.showNotes

	return (
		<PodpalCard className={className}>
			<Form
				form={form}
				onFinish={onFinish}
				onValuesChange={checkIfModified}
				initialValues={{ showNotes: data.episode.showNotes }}
			>
				<Row justify="end" align="middle">
					<EpisodeManagementTooltip
						tooltipText="Sometimes called the “Episode Description” your Show Notes is the place to document your episode in written form. 
						It’s a good idea to include links to special offers or additional content! This field is required in order to publish."
					/>
				</Row>
				<Row justify="center" align="middle">
					<Col span={24} style={{ textAlign: "center" }}>
						<h2>Show Notes</h2>
					</Col>
					<Col span={24}>
						<Form.Item name="showNotes">
							<RichTextEditor
								setValue={handleChange}
								setLength={setLength}
								defaultValue={data.episode.showNotes}
								placeholder="Add your show notes here!"
								height="300px"
								maxLength={20}
							/>
							<div style={{ display: "flex", padding: 8 }}>
								<span style={{ flexGrow: 1 }}>
									<Badge color={badgeColor()} />
									{statusText()}
								</span>
								<span style={{ color: overLength ? "red" : undefined }}>{`${contentLength}/${maxShowNotesLength}`}</span>
							</div>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Row justify="center" gutter={8}>
							<Col>
								<PodpalButton size="large" variant="secondary" onClick={saveDraft} disabled={!showNotesChanged}>
									Save as Draft
								</PodpalButton>
							</Col>
							<Col>
								{showNotesCompleted ? (
									<PodpalButton size="large" variant="secondary" onClick={() => handleUncheckStep("writeShowNotes")}>
										Uncheck
									</PodpalButton>
								) : (
									<PodpalButton
										type="primary"
										size="large"
										onClick={() => handleMarkAsDone("writeShowNotes")}
										disabled={disableMarkAsDone || overLength}
									>
										Mark as Done
									</PodpalButton>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</PodpalCard>
	)
}

export default ShowNotes
