/**
 * Input field names
 */
export const LABEL_NAMES = {
	INSTAGRAM: "Instagram",
	FACEBOOK: "Facebook",
	TWITTER: "Twitter",
	LINKEDIN: "LinkedIn",
	YOUTUBE: "YouTube",
	PATREON: "Patreon",
	CASHAPP: "Cash App"
}
