import React from "react"
import { message } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import colors from "styles/scss/color.scss"

export const errorAlert = body => {
	message.error({
		content: body,
		icon: <CloseCircleFilled style={{ color: colors.podpalRedLight }} />
	})
}
