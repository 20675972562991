import axios from "axios"
import { v4 as uuidv4 } from "uuid"

export interface EpisodeProgress {
	currentStep: string
	episodeId: string
	id: string
	percentCompleted: number
	setup: number
}

export const createEpisodeProgress = (episodeId: string) =>
	axios.post("/api/twirp/podpal.administration.EpisodeProgressService/Create", {
		requestId: uuidv4(),
		episodeId
	})

export const updateEpisodeProgress = (episodeProgress: EpisodeProgress) =>
	axios.post("/api/twirp/podpal.administration.EpisodeProgressService/Update", {
		requestId: uuidv4(),
		episodeProgress
	})

export const findEpisodeProgressByEpisodeId = (episodeId: string) =>
	axios.post("/api/twirp/podpal.administration.EpisodeProgressService/FindByEpisodeID", {
		requestId: uuidv4(),
		episodeId
	})
