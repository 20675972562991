import axios from "axios"

export const createUser = user =>
	axios.post("/api/twirp/podpal.iam.UserService/Create", {
		user
	})

export const findUser = userId =>
	axios.post("/api/twirp/podpal.iam.UserService/Find", {
		userId
	})

export const findUserByProfileUrl = profileUrl =>
	axios.post("/api/twirp/podpal.iam.UserService/FindByProfileURL", {
		profileUrl
	})

export const updateUser = user =>
	axios.post("/api/twirp/podpal.iam.UserService/Update", {
		user,
		thumbnailFile: user["newThumbnailFile"],
		audioBioFile: user["audioBioFile"]
	})

export const validateUsername = username =>
	axios.post("/api/twirp/podpal.iam.UserService/ValidateUsername", {
		username
	})

export const getUsersByPodcastID = podcastId =>
	axios.post("/api/twirp/podpal.iam.UserService/GetUsersByPodcastID", {
		podcastId
	})

export const deleteUser = userId =>
	axios.post("/api/twirp/podpal.iam.UserService/Delete", {
		userId
	})

export const alertUserPasswordChanged = userId =>
	axios.post("/api/twirp/podpal.iam.UserService/AlertPasswordChanged", {
		userId
	})
