import React from "react"
import { Modal } from "antd"
import { sendVerificationEmail } from "services/email.service"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { WarningExclamationIcon } from "assets/icons/Common/SimpleFeedback"
import { CheckCircleOutlined } from "@ant-design/icons"
import colors from "styles/scss/color.scss"

export const verifyEmailModal = (userId: string) => {
	Modal.confirm({
		centered: true,
		maskClosable: true,
		title: "Verify Email",
		icon: <WarningExclamationIcon />,
		content: `Please verify your email address. We sent a verification link to your email address, 
        please verify your email to log in with these credentials.`,

		okText: "Resend Verification Email",
		onOk: async () => {
			try {
				await sendVerificationEmail(userId)
			} catch (e) {
				errorAlert("Failed to resend verification email")
			}
		},
		cancelButtonProps: {
			style: {
				display: "none"
			}
		}
	})
}

export const emailVerifiedModal = () => {
	Modal.success({
		centered: true,
		maskClosable: true,
		title: "Email Verified",
		icon: <CheckCircleOutlined style={{ color: colors.podpalGreenLight }} />,
		content: `Your email was successfully verified. Thanks for verifying your email address.`
	})
}

export const emailSentModal = () => {
	Modal.success({
		centered: true,
		title: "Email Sent!",
		icon: <CheckCircleOutlined style={{ color: colors.podpalGreenLight }} />,
		content: `We've emailed you a link to reset your password. Follow the instructions to complete your password reset.`
	})
}
