import {
	TOGGLE_ADD_NEW_PODCAST_DRAWER,
	TOGGLE_IMPORT_PODCAST_DRAWER,
	SET_INITIAL_VALUES_FOR_IMPORT_PODCAST_DRAWER,
	SET_CONFIG_FOR_IMPORT_PODCAST_DRAWER,
	TOGGLE_ADD_NEW_PODCAST_CONFIRMATION_MODAL_VISIBLE,
	TOGGLE_MISSING_ARTWORK_MODAL_VISIBLE,
	ADD_CHANGE_TO_ADD_NEW_PODCAST_FORM,
	CREATE_NEW_PODCAST_LOADING,
	CREATE_NEW_PODCAST_SUCCESS,
	CREATE_NEW_PODCAST_ERROR,
	CREATE_NEW_PODCAST_ROLE_LOADING,
	CREATE_NEW_PODCAST_ROLE_SUCCESS,
	CREATE_NEW_PODCAST_ROLE_ERROR,
	SET_NEW_PODCAST_ID_FOR_CONFIRMATION_MODAL,
	TOGGLE_IMPORT_PODCAST_MODAL_VISIBLE,
	TOGGLE_301_REDIRECT_MODAL_VISIBLE,
	TOGGLE_301_REDIRECT_OUT_MODAL_VISIBLE,
	SET_PODCAST_ID_FOR_301_REDIRECT_MODAL
} from "constants/actionTypes"
import { createPodcast } from "services/podcast.service"
import { createPodcastRole } from "services/podcast-role.service"

export const toggleAddNewPodcastDrawer = () => ({
	type: TOGGLE_ADD_NEW_PODCAST_DRAWER
})

export const toggleImportPodcastDrawer = () => ({
	type: TOGGLE_IMPORT_PODCAST_DRAWER
})

export const setInitialValuesForImportPodcastDrawer = values => ({
	type: SET_INITIAL_VALUES_FOR_IMPORT_PODCAST_DRAWER,
	payload: {
		values
	}
})

export const setConfigForImportPodcastDrawer = config => ({
	type: SET_CONFIG_FOR_IMPORT_PODCAST_DRAWER,
	payload: config
})

export const toggleAddNewPodcastConfirmationModalVisible = () => ({
	type: TOGGLE_ADD_NEW_PODCAST_CONFIRMATION_MODAL_VISIBLE
})

export const toggleMissingArtworkModalVisible = () => ({
	type: TOGGLE_MISSING_ARTWORK_MODAL_VISIBLE
})

export const addChangeToAddNewPodcastForm = (fieldName, fieldValue) => ({
	type: ADD_CHANGE_TO_ADD_NEW_PODCAST_FORM,
	payload: {
		fieldName: fieldName,
		fieldValue: fieldValue
	}
})

export const createNewPodcast = data => async dispatch => {
	dispatch({ type: CREATE_NEW_PODCAST_LOADING })

	return await createPodcast(data)
}

export const createNewPodcastSuccess = podcast => ({
	type: CREATE_NEW_PODCAST_SUCCESS,
	payload: { podcast }
})

export const createNewPodcastError = () => ({
	type: CREATE_NEW_PODCAST_ERROR
})

export const createNewPodcastRole = ({ userId, podcastId, collaboratorType, showAccess }) => async dispatch => {
	dispatch({ type: CREATE_NEW_PODCAST_ROLE_LOADING })

	return await createPodcastRole({ userId, podcastId, collaboratorType, showAccess })
}

export const dispatchCreateNewPodcastRoleSuccess = () => ({
	type: CREATE_NEW_PODCAST_ROLE_SUCCESS
})

export const dispatchCreateNewPodcastRoleError = () => ({
	type: CREATE_NEW_PODCAST_ROLE_ERROR
})

export const setNewPodcastIdForConfirmationModal = podcastId => ({
	type: SET_NEW_PODCAST_ID_FOR_CONFIRMATION_MODAL,
	payload: podcastId
})

export const toggleImportPodcastModalVisible = () => ({
	type: TOGGLE_IMPORT_PODCAST_MODAL_VISIBLE
})

export const toggle301RedirectModalVisible = () => ({
	type: TOGGLE_301_REDIRECT_MODAL_VISIBLE
})

export const toggle301RedirectOutModalVisible = newRssFeedUrl => ({
	type: TOGGLE_301_REDIRECT_OUT_MODAL_VISIBLE,
	payload: {
		newRssFeedUrl
	}
})

export const setPodcastIDFor301RedirectModal = podcastID => ({
	type: SET_PODCAST_ID_FOR_301_REDIRECT_MODAL,
	payload: {
		podcastID
	}
})
