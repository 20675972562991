import axios from "axios"
import { generateCloudStorageUrl } from "utils/cloudStorage"

export const uploadFile = async (urlConfig, file, setUploadProgress, cancelTokenRef) => {
	if (!urlConfig) return
	const { url, bucket, object } = urlConfig

	const formData = new FormData()
	formData.append("file", file)

	if (cancelTokenRef) {
		cancelTokenRef.current = axios.CancelToken.source()
	}

	try {
		await axios.put(url, file, {
			headers: {
				"Content-Type": file.type
			},
			onUploadProgress: progressEvent => {
				setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
			},
			cancelToken: cancelTokenRef?.current?.token
		})
	} catch (error) {
		console.error("error uploading to cloud storage", error)
		return
	}

	return generateCloudStorageUrl(bucket, object)
}

export const getSignedUrl = (bucket, object, contentType) => {
	return axios.post("/api/twirp/podpal.upload.UploadService/GetSignedURL", {
		bucket,
		object,
		contentType
	})
}
