import styled from "styled-components"
import { Form, Image } from "antd"

export const SectionHeader = styled.h2`
	background-color: ${({ theme }) => theme.gray3};
	padding: 16px;
	margin-bottom: 16px;
`

export const PodpalImage = styled(Image)`
	.ant-image-img {
		border-radius: ${({ theme }) => theme.radiusLg};
	}
`

export const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 0;
	}

	label {
		display: flex;
		align-items: center;
		font: ${props => props.theme.fontSm} !important;
		font-weight: 500 !important;
		line-height: 12px !important;
		color: ${props => props.theme.textPrimary};
		margin-bottom: ${props => props.theme.spaceXs};
		padding-top: ${props => props.theme.spaceXs};
		padding-bottom: ${props => props.theme.spaceXs};
	}
`
