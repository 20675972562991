import { Col, Row } from "antd"
import React from "react"
import styled from "styled-components"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import { useQueryClient } from "react-query"
import { CheckCircleComplete, CheckCircleOutlined } from "assets/icons/Common/SimpleFeedback"
import { STATUS } from "../../../constants/strings/Episodes/episodeStatusConstant"

const StyledProgressList = styled.ul`
	list-style-type: none;
	width: 100%;
	padding: 0;

	p {
		text-align: left;
		font: inherit;
	}

	li {
		font: ${({ theme }) => theme.fontMd};
		font-weight: 400;
		border-bottom: 1px solid ${({ theme }) => theme.neutral1};
		padding: 12px 0;
		min-height: 40px;
		display: flex;

		&.active {
			background-color: ${({ theme }) => theme.podpalBlueGhost};
			font-weight: 500;
			padding: 8px 0;
		}

		&.skipped {
			span.number,
			p {
				text-decoration: line-through;
			}
		}

		div {
			width: 90%;
			margin: auto;
		}

		button.ant-btn-link span {
			text-decoration: underline;
		}
	}
`

const EpisodeProgressList = ({ data }) => {
	const queryClient = useQueryClient()
	let currentStep = null

	const { setup, inviteGuests, scheduleInterview, uploadAudio, writeShowNotes, reviewEpisode } = data.episodeProgress

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: setup ?? 0,
			isSkipable: false
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: inviteGuests ?? 0,
			isSkipable: true
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: scheduleInterview ?? 0,
			isSkipable: true
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: uploadAudio ?? 0,
			isSkipable: false
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: writeShowNotes ?? 0,
			isSkipable: false
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: reviewEpisode ?? 0,
			isSkipable: true
		}
	]

	if (data.currentStep) {
		currentStep = data.currentStep
	} else {
		currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
	}

	const handleSkip = async stepIndex => {
		let updatedEpisodeProgress

		//  If invite guests or schedule interview is being skipped, also skip the other.
		if (stepIndex === 1) {
			data.episodeProgress.currentStep = steps
				.slice(stepIndex + 2)
				.find(step => step.status === STATUS.IN_PROGRESS).displayText

			updatedEpisodeProgress = {
				...data.episodeProgress,
				[steps[stepIndex].stepName]: STATUS.SKIPPED,
				[steps[stepIndex + 1].stepName]: STATUS.SKIPPED
			}
		} else if (stepIndex === 2) {
			data.episodeProgress.currentStep = steps
				.slice(stepIndex + 1)
				.find(step => step.status === STATUS.IN_PROGRESS).displayText

			updatedEpisodeProgress = {
				...data.episodeProgress,
				[steps[stepIndex].stepName]: STATUS.SKIPPED,
				[steps[stepIndex - 1].stepName]: STATUS.SKIPPED
			}
		} else if (stepIndex < 5) {
			data.episodeProgress.currentStep = steps
				.slice(stepIndex + 1)
				.find(step => step.status === STATUS.IN_PROGRESS).displayText

			updatedEpisodeProgress = { ...data.episodeProgress, [steps[stepIndex].stepName]: STATUS.SKIPPED }
		} else if (stepIndex === 5) {
			data.episodeProgress.currentStep = "Publish Episode"
			updatedEpisodeProgress = { ...data.episodeProgress, [steps[stepIndex].stepName]: STATUS.SKIPPED }
		} else {
			data.episodeProgress.currentStep = undefined
			updatedEpisodeProgress = { ...data.episodeProgress, [steps[stepIndex].stepName]: STATUS.SKIPPED }
		}

		try {
			await updateEpisodeProgress(updatedEpisodeProgress)
			queryClient.invalidateQueries(["episodeProgress", data.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Error occurred skipping this step.")
		}
	}

	const handleRestore = async stepIndex => {
		let updatedEpisodeProgress

		// If the step being restored is Invite Guests or Schedule Interview, restore both
		if (stepIndex === 1 || stepIndex === 2) {
			// Set Current Step to Invite Guests steps[1]
			data.episodeProgress.currentStep = steps[1].displayText
			updatedEpisodeProgress = {
				...data.episodeProgress,
				[steps[1].stepName]: STATUS.IN_PROGRESS,
				[steps[2].stepName]: STATUS.IN_PROGRESS
			}
		} else {
			data.episodeProgress.currentStep = steps[stepIndex].displayText
			updatedEpisodeProgress = { ...data.episodeProgress, [steps[stepIndex].stepName]: STATUS.IN_PROGRESS }
		}

		try {
			await updateEpisodeProgress(updatedEpisodeProgress)
			queryClient.invalidateQueries(["episodeProgress", data.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Error occurred restoring this step.")
		}
	}

	const progressList = steps.map((item, i) => {
		return (
			<li
				key={item.displayText}
				className={currentStep === item.displayText ? "active" : item.status === STATUS.SKIPPED ? "skipped" : undefined}
			>
				<Row align="middle" justify="space-between">
					<Col span={2}>
						<span className="number">{i + 1}</span>
					</Col>
					<Col span={item.status === STATUS.SKIPPED ? 17 : 18}>
						<p>{item.displayText}</p>
					</Col>
					{item.status === STATUS.SKIPPED ? (
						<Col span={5}>
							<PodpalButton variant="blueGhost" size="small" onClick={() => handleRestore(i)}>
								Restore
							</PodpalButton>
						</Col>
					) : (
						<>
							<Col span={2}>
								<div>{item.status === STATUS.COMPLETED ? <CheckCircleComplete /> : <CheckCircleOutlined />}</div>
							</Col>
							<Col span={2}>
								{item.isSkipable && currentStep === item.displayText && (
									<PodpalButton type="link" variant="primary" onClick={() => handleSkip(i)}>
										Skip
									</PodpalButton>
								)}
							</Col>
						</>
					)}
				</Row>
			</li>
		)
	})

	return <StyledProgressList>{progressList}</StyledProgressList>
}

export default EpisodeProgressList
