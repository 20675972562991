import {
	TOGGLE_UPLOAD_RECORDING_MODAL_VISIBLE,
	TOGGLE_CHOOSE_RECORDING_MODAL_VISIBLE,
	SET_CONFIG_FOR_CHOOSE_RECORDING_MODAL,
	SET_PROGRESS_FOR_UPLOAD_RECORDING_MODAL,
	SET_CONFIG_FOR_UPLOAD_RECORDING_MODAL,
	TOGGLE_DELETE_RECORDING_MODAL_VISIBLE,
	SET_CONFIG_FOR_DELETE_RECORDING_MODAL
} from "constants/actionTypes"

export const toggleUploadRecordingModalVisible = () => ({
	type: TOGGLE_UPLOAD_RECORDING_MODAL_VISIBLE
})

export const toggleChooseRecordingModalVisible = () => ({
	type: TOGGLE_CHOOSE_RECORDING_MODAL_VISIBLE
})

export const setConfigForChooseRecordingModal = config => ({
	type: SET_CONFIG_FOR_CHOOSE_RECORDING_MODAL,
	payload: config
})

export const setProgressForUploadRecordingModal = progress => ({
	type: SET_PROGRESS_FOR_UPLOAD_RECORDING_MODAL,
	payload: {
		progress
	}
})

export const setConfigForUploadRecordingModal = config => ({
	type: SET_CONFIG_FOR_UPLOAD_RECORDING_MODAL,
	payload: config
})

export const toggleDeleteRecordingModalVisible = () => ({
	type: TOGGLE_DELETE_RECORDING_MODAL_VISIBLE
})

export const setConfigForDeleteRecordingModal = config => ({
	type: SET_CONFIG_FOR_DELETE_RECORDING_MODAL,
	payload: config
})
