import {
	TOGGLE_REQUEST_301_REDIRECT_MODAL_VISIBLE,
	TOGGLE_STOP_301_REDIRECT_MODAL_VISIBLE
} from "constants/actionTypes"

export const toggleRequest301ModalVisible = () => ({
	type: TOGGLE_REQUEST_301_REDIRECT_MODAL_VISIBLE
})

export const toggleStop301ModalVisible = () => ({
	type: TOGGLE_STOP_301_REDIRECT_MODAL_VISIBLE
})
