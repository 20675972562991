import axios from "axios"
import { v4 as uuidv4 } from "uuid"

export const updateEpisodeChecklist = episodeChecklist =>
	axios.post("/api/twirp/podpal.administration.EpisodeChecklistService/Update", {
		requestId: uuidv4(),
		episodeChecklist
	})

export const findEpisodeChecklistByEpisodeId = episodeId =>
	axios.post("/api/twirp/podpal.administration.EpisodeChecklistService/FindByEpisodeID", {
		requestId: uuidv4(),
		episodeId
	})
