import React, { useEffect, useState } from "react"
import _ from "lodash"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { Prompt } from "react-router"
import { Form, Input, Tooltip, Row, Col } from "antd"
import {
	FIELD_NAMES,
	LABEL_NAMES,
	PASSWORD_LENGTH_REQUIREMENT,
	PASSWORD_UPPERCASE_REQUIREMENT,
	PASSWORD_LOWERCASE_REQUIREMENT,
	PASSWORD_NUMBER_REQUIREMENT,
	PASSWORD_SPECIAL_CHARACTER_REQUIREMENT,
	PASSWORD_MATCH_REQUIREMENT,
	INCORRECT_PASSWORD_ERROR_MESSAGE,
	INVALID_PASSWORD_ERROR_MESSAGE,
	CONFIRM_NEW_PASSWORD_ERROR_MESSAGE,
	EMPTY_NEW_PASSWORD_ERROR_MESSAGE
} from "constants/strings/UserSettings/MyAccount/Form"
import { PASSWORD_REQUIREMENT_CLASSNAMES } from "constants/strings/UserSettings/MyAccount/EmailPasswordForm"
import { confirmPassword, updatePassword } from "services/firebase.service"
import { toggleNewEmailModalVisible } from "redux/actions/UserSettings/MyAccount"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { useUser } from "services/queries/User"
import "./EmailPasswordForm.scss"
import useToggle from "hooks/useToggle"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import { alertUserPasswordChanged } from "services/user.service"

const StyledUl = styled.ul`
	padding-left: 18px !important;

	li {
		color: ${props => props.theme.textSecondary};
		margin-bottom: ${props => props.theme.spaceXs};
	}

	li.valid {
		color: ${props => props.theme.podpalGreenLight};
	}

	li.invalid {
		color: ${props => props.theme.podpalRedDark};
	}
`
const EmailPasswordForm = () => {
	const authUser = useSelector(state => state.app.authUser)

	const { error, data } = useUser(authUser.uid)

	if (error) {
		return <h4>Error loading data.</h4>
	}

	return <EmailPasswordFormComponent initialValues={data.user} />
}

const EmailPasswordFormComponent = ({ initialValues }) => {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const size = useWindowSize()
	const [gutter, setGutter] = useState(16)
	useEffect(() => {
		if (size.width <= BREAKPOINT_XS) {
			setGutter([0, 16])
		} else {
			setGutter(16)
		}
	}, [size, setGutter])

	const authUser = useSelector(state => state.app.authUser)
	const providerData = authUser.providerData
	let requiresPassword = false
	for (let i = 0; i < providerData.length; i++) {
		if (providerData[i].providerId === "password") {
			requiresPassword = true
			break
		}
	}

	const [loading, toggleLoading] = useToggle(false)
	const [fieldsModified, setFieldsModified] = useState(false)
	const [passwordFieldsDisabled, togglePasswordFieldsDisabled] = useToggle(true)
	const [passwordLengthClassname, setPasswordLengthClassname] = useState(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
	const [passwordUppercaseClassname, setPasswordUppercaseClassname] = useState(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
	const [passwordLowercaseClassname, setPasswordLowercaseClassname] = useState(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
	const [passwordNumberClassname, setPasswordNumberClassname] = useState(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
	const [passwordSpecialCharacterClassname, setPasswordSpecialCharacterClassname] = useState(
		PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT
	)
	const [passwordMatchClassname, setPasswordMatchClassname] = useState(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)

	const checkIfModified = (changedValues, allValues) => {
		let modified = false
		for (const [key, value] of Object.entries(changedValues)) {
			if (!_.isEqual(value, initialValues[key])) {
				modified = true
			}
		}
		setFieldsModified(modified)
	}

	const resetFields = () => {
		form.resetFields()
		setFieldsModified(false)
		togglePasswordFieldsDisabled()
		setPasswordLengthClassname(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
		setPasswordUppercaseClassname(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
		setPasswordLowercaseClassname(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
		setPasswordNumberClassname(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
		setPasswordSpecialCharacterClassname(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
		setPasswordMatchClassname(PASSWORD_REQUIREMENT_CLASSNAMES.DEFAULT)
	}

	const handleCancel = () => {
		resetFields()
	}

	const handleSubmit = async values => {
		toggleLoading()
		try {
			await updatePassword({ email: values.email, currentPassword: values.password, newPassword: values.newPassword })
			await alertUserPasswordChanged(authUser.uid)
			toggleLoading()
			resetFields()
			successAlert("Changes saved.")
		} catch (e) {
			toggleLoading()
			errorAlert("An unexpected error occurred while saving.")
		}
	}

	const checkUpperLowerAndSpecialCharacters = str => {
		let values = [0, 0, 0, 0]
		let specialChars = "$!@#?"
		for (let i = 0; i < str.length; i++) {
			let currentCharCode = str.charCodeAt(i)
			// Uppercase
			if (currentCharCode > 64 && currentCharCode < 91) {
				values[0]++
			} else if (currentCharCode > 96 && currentCharCode < 123) {
				// Lowercase
				values[1]++
			} else if (currentCharCode > 47 && currentCharCode < 58) {
				// Number
				values[2]++
			} else if (specialChars.includes(str.charAt(i))) {
				// Special characters
				values[3]++
			}
		}

		return values
	}

	const changeEmailButton = requiresPassword ? (
		<PodpalButton variant="secondary" onClick={() => dispatch(toggleNewEmailModalVisible())} size="large">
			Change Email
		</PodpalButton>
	) : (
		<Tooltip
			placement="right"
			title="Email managed by your Google/Facebook account"
			trigger="hover"
			mouseEnterDelay={0.01}
		>
			<PodpalButton disabled size="large">
				Change Email
			</PodpalButton>
		</Tooltip>
	)

	const changePasswordButton = requiresPassword ? (
		<PodpalButton variant="secondary" size="large" onClick={() => togglePasswordFieldsDisabled()}>
			Change Password
		</PodpalButton>
	) : (
		<Tooltip
			placement="right"
			title="Password managed by your Google/Facebook account"
			trigger="hover"
			mouseEnterDelay={0.01}
		>
			<PodpalButton disabled size="large">
				Change Password
			</PodpalButton>
		</Tooltip>
	)

	return (
		<div className="email-password-form">
			<Form
				layout="vertical"
				form={form}
				onFinish={handleSubmit}
				onFinishFailed={() => errorAlert("Please fix errors to save.")}
				initialValues={initialValues}
				onValuesChange={checkIfModified}
			>
				<Row gutter={gutter} align="middle">
					<Col xs={24} sm={12}>
						<Form.Item name={FIELD_NAMES.EMAIL} label={LABEL_NAMES.EMAIL}>
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					<Col xs={24} sm={6}>
						{changeEmailButton}
					</Col>
				</Row>
				<Row gutter={gutter} align="middle">
					<Col xs={24} sm={12}>
						<Form.Item
							name={FIELD_NAMES.PASSWORD}
							label={LABEL_NAMES.PASSWORD}
							hasFeedback
							validateTrigger="onBlur"
							rules={[
								{
									validator: async (rule, value) => {
										if (!value || value.length === 0) {
											return Promise.reject(INCORRECT_PASSWORD_ERROR_MESSAGE)
										}
										await confirmPassword({ email: initialValues.email, password: value })
											.then(() => Promise.resolve())
											.catch(() => Promise.reject(INCORRECT_PASSWORD_ERROR_MESSAGE))
									}
								}
							]}
						>
							<Input.Password disabled={passwordFieldsDisabled} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={6}>
						{changePasswordButton}
					</Col>
				</Row>
				<Row gutter={{ xs: 0, sm: 16 }}>
					<Col xs={24} sm={12}>
						<Form.Item
							name={FIELD_NAMES.NEW_PASSWORD}
							label={LABEL_NAMES.NEW_PASSWORD}
							hasFeedback
							rules={[
								{
									validator: (rule, value) => {
										if (!value) {
											return Promise.reject(INVALID_PASSWORD_ERROR_MESSAGE)
										}
										const values = checkUpperLowerAndSpecialCharacters(value)
										setPasswordLengthClassname(
											value.length < 8 ? PASSWORD_REQUIREMENT_CLASSNAMES.INVALID : PASSWORD_REQUIREMENT_CLASSNAMES.VALID
										)
										setPasswordUppercaseClassname(
											values[0] < 1 ? PASSWORD_REQUIREMENT_CLASSNAMES.INVALID : PASSWORD_REQUIREMENT_CLASSNAMES.VALID
										)
										setPasswordLowercaseClassname(
											values[1] < 1 ? PASSWORD_REQUIREMENT_CLASSNAMES.INVALID : PASSWORD_REQUIREMENT_CLASSNAMES.VALID
										)
										setPasswordNumberClassname(
											values[2] < 1 ? PASSWORD_REQUIREMENT_CLASSNAMES.INVALID : PASSWORD_REQUIREMENT_CLASSNAMES.VALID
										)
										setPasswordSpecialCharacterClassname(
											values[3] < 1 ? PASSWORD_REQUIREMENT_CLASSNAMES.INVALID : PASSWORD_REQUIREMENT_CLASSNAMES.VALID
										)

										if (values.includes(0) || value.length < 8) {
											return Promise.reject(INVALID_PASSWORD_ERROR_MESSAGE)
										} else {
											return Promise.resolve()
										}
									}
								}
							]}
						>
							<Input.Password disabled={passwordFieldsDisabled} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item
							name={FIELD_NAMES.CONFIRM_NEW_PASSWORD}
							label={LABEL_NAMES.CONFIRM_NEW_PASSWORD}
							hasFeedback
							rules={[
								{
									validator: (rule, value) => {
										if (!value) {
											return Promise.reject(INVALID_PASSWORD_ERROR_MESSAGE)
										}
										const newPassword = form.getFieldValue(FIELD_NAMES.NEW_PASSWORD)
										if ((!newPassword || newPassword.length === 0) && value.length > 0) {
											return Promise.reject(EMPTY_NEW_PASSWORD_ERROR_MESSAGE)
										} else if (newPassword !== value) {
											setPasswordMatchClassname(PASSWORD_REQUIREMENT_CLASSNAMES.INVALID)
											return Promise.reject(CONFIRM_NEW_PASSWORD_ERROR_MESSAGE)
										} else {
											setPasswordMatchClassname(PASSWORD_REQUIREMENT_CLASSNAMES.VALID)
											return Promise.resolve()
										}
									}
								}
							]}
						>
							<Input.Password disabled={passwordFieldsDisabled} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<label>Password Requirements</label>
					</Col>
					<Col span={24}>
						<StyledUl>
							<li className={passwordLengthClassname}>{PASSWORD_LENGTH_REQUIREMENT}</li>
							<li className={passwordUppercaseClassname}>{PASSWORD_UPPERCASE_REQUIREMENT}</li>
							<li className={passwordLowercaseClassname}>{PASSWORD_LOWERCASE_REQUIREMENT}</li>
							<li className={passwordNumberClassname}>{PASSWORD_NUMBER_REQUIREMENT}</li>
							<li className={passwordSpecialCharacterClassname}>{PASSWORD_SPECIAL_CHARACTER_REQUIREMENT}</li>
							<li className={passwordMatchClassname}>{PASSWORD_MATCH_REQUIREMENT}</li>
						</StyledUl>
					</Col>
				</Row>
				<footer className="footer-actions">
					<Row gutter={8} justify="start" align="middle">
						<Col>
							<PodpalButton type="primary" size="large" loading={loading} htmlType="submit" disabled={!fieldsModified}>
								Save
							</PodpalButton>
						</Col>
						<Col>
							<PodpalButton variant="secondary" size="large" onClick={handleCancel} disabled={!fieldsModified}>
								Cancel
							</PodpalButton>
						</Col>
					</Row>
				</footer>
			</Form>
			<Prompt when={fieldsModified} message={() => window.confirm("Do you want to leave without saving?")} />
		</div>
	)
}

export default EmailPasswordForm
