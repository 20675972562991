import React from "react"
import Icon from "@ant-design/icons"
import { Tooltip } from "antd"

const MENU_SVG = () => (
	<svg width="16" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z" fill="white" />
	</svg>
)

export const MenuIcon = ({ className, onClick, tooltipText }) => (
	<Tooltip title={tooltipText} placement={"bottomLeft"} arrowPointAtCenter>
		<Icon component={MENU_SVG} className={className} onClick={onClick} />
	</Tooltip>
)

const HELP_CENTER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="20px" height="20px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
	</svg>
)

export const HelpCenterIcon = ({ className }) => <Icon component={HELP_CENTER_SVG} className={className} />

const NOTIFICATION_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="20px" height="20px">
		<path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
	</svg>
)

export const NotificationIcon = ({ className, onClick }) => (
	<Icon component={NOTIFICATION_SVG} className={className} onClick={onClick} />
)

const HOME_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" width="22px" height="22px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
	</svg>
)

export const HomeFilledIcon = ({ className }) => <Icon component={HOME_FILLED_SVG} className={className} />

const VIDEO_CAM_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#4d4d4d">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M15 8v8H5V8h10m1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4V7c0-.55-.45-1-1-1z" />
	</svg>
)

export const VideoCamIcon = props => <Icon component={VIDEO_CAM_SVG} {...props} />
