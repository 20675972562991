import React, { useRef, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Comment as AntComment, Avatar, Tooltip } from "antd"
import { DeleteIcon, LinkIcon, EditIcon, SmilePlusIcon } from "assets/icons/Comments"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { Emoji } from "emoji-picker-react"
import CommentEditor from "./CommentEditor"
import EmojiPicker from "components/common/Input/EmojiPicker"
import { useEmojiPicker } from "hooks/useEmojiPicker"
import { formatLastUpdatedTime } from "utils/time"

const COMMENT_CONNECTOR_GAP = 60

const StyledComment = styled(AntComment)`
	.ant-comment-inner {
		padding-bottom: 0;
	}
`

const ReactionCount = styled.span`
	margin-left: 4px;
`

const CommentContainer = styled.div`
	padding-bottom: 20px;
	position: relative;
	&:hover {
		background-color: ${props => (props.showEdit ? "unset" : "#f0f6ff")};
	}
`

const ReactionContainer = styled.div`
	margin-left: 42px;
	margin-top: 4px;
	display: flex;
`

const CommentToolbarContainer = styled.div`
	position: absolute;
	right: 0;
	top: -16px;
	border: 1px solid ${props => props.theme.gray4};
	background-color: white;
`

const CommentConnector = styled.div`
	top: 60px;
	position: absolute;
	height: ${props => props.height}px;
	width: 1px;
	left: 16px;
	z-index: 10;
	background-color: ${props => props.theme.gray4};
`

const Comment = ({ comment, onUpdateComments, showConnector, teamMembers }) => {
	const authUser = useSelector(state => state.app.authUser)
	const [showCommentsToolbar, setShowCommentsToolbar] = useState(false)
	const [showEdit, setShowEdit] = useState(false)
	const { author_display_name, thumbnail_url, reactions, content } = comment
	const { showEmojiPicker, toggleEmojiPicker, onClickOutside } = useEmojiPicker()
	const commentContainerRef = useRef()

	const toggleCommentsToolbar = () => {
		setShowCommentsToolbar(showCommentsToolbar => !showCommentsToolbar)
	}

	const containerHeight = commentContainerRef?.current?.getBoundingClientRect()?.height

	const onHandleEmojiChange = unified => {
		const { uid: currUserId } = authUser
		const modifiedReactions = {}
		if (!comment?.reactions?.[unified]) {
			modifiedReactions[unified] = {
				users: [currUserId]
			}
		} else {
			const users = new Set(comment.reactions[unified].users)
			if (users.has(currUserId)) {
				users.delete(currUserId)
				if (users.size === 0) {
					delete comment.reactions[unified]
				} else {
					modifiedReactions[unified] = {
						users: Array.from(users)
					}
				}
			} else {
				users.add(currUserId)
				modifiedReactions[unified] = {
					users: Array.from(users)
				}
			}
		}
		const newComment = {
			...comment,
			reactions: {
				...comment.reactions,
				...modifiedReactions
			}
		}
		if (Object.keys(newComment.reactions).length === 0) {
			delete newComment.reactions
		}
		onUpdateComments(newComment)
	}

	const onEmojiSelected = emojiObj => {
		toggleEmojiPicker()
		onHandleEmojiChange(emojiObj.unified)
	}

	const onReactionClick = event => {
		onHandleEmojiChange(event.currentTarget.dataset.unified)
	}

	const onDeleteButtonClick = () => {
		onUpdateComments(comment, "Delete")
	}

	const onEditButtonClick = () => {
		setShowEdit(true)
	}

	const cancelEdit = () => {
		setShowEdit(false)
	}

	const reactionUnifiedArr = reactions && Object.keys(reactions)
	const showReactions = reactionUnifiedArr && reactionUnifiedArr.length > 0

	return (
		<CommentContainer
			onMouseEnter={toggleCommentsToolbar}
			onMouseLeave={toggleCommentsToolbar}
			showEdit={showEdit}
			ref={commentContainerRef}
		>
			<>
				<StyledComment
					author={<a>{author_display_name}</a>}
					avatar={<Avatar src={thumbnail_url} alt={author_display_name} />}
					content={
						showEdit ? (
							<CommentEditor
								comment={comment}
								cancelEdit={cancelEdit}
								updateComment={onUpdateComments}
								teamMembers={teamMembers}
							/>
						) : (
							<div dangerouslySetInnerHTML={{ __html: content }}></div>
						)
					}
					datetime={
						<Tooltip>
							<span>{formatLastUpdatedTime((Date.now() - new Date(comment.created_at).valueOf()) / 1000, true)}</span>
						</Tooltip>
					}
				/>
				{!showEdit && (
					<ReactionContainer>
						{showReactions &&
							reactionUnifiedArr.map(
								(unified, index) =>
									reactions[unified].users.length !== 0 && (
										<PodpalButton size="small" variant="reaction" key={index} data-unified={unified} onClick={onReactionClick}>
											<Emoji unified={unified} size={16}></Emoji>
											<ReactionCount>{reactions[unified].users.length}</ReactionCount>
										</PodpalButton>
									)
							)}
						<div style={{ display: "inline-block", position: "relative" }}>
							<PodpalButton size="small" variant="reaction" onClick={toggleEmojiPicker}>
								<SmilePlusIcon />
							</PodpalButton>
							<EmojiPicker open={showEmojiPicker} onEmojiSelected={onEmojiSelected} onClickOutside={onClickOutside} />
						</div>
					</ReactionContainer>
				)}
				{showCommentsToolbar && (
					<CommentToolbarContainer>
						<PodpalButton size="middle" variant="transparent" title="Edit comment" onClick={onEditButtonClick}>
							<EditIcon />
						</PodpalButton>
						<PodpalButton size="middle" variant="transparent" title="Delete comment" onClick={onDeleteButtonClick}>
							<DeleteIcon />
						</PodpalButton>
						{/* <PodpalButton size="middle" variant="transparent" title="Upload attachments">
							<LinkIcon />
						</PodpalButton> */}
					</CommentToolbarContainer>
				)}
				{showConnector && <CommentConnector height={containerHeight - COMMENT_CONNECTOR_GAP} />}
			</>
		</CommentContainer>
	)
}

export default Comment
