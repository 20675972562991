import React from "react"
import GenericSettingsPage from "containers/SettingsPage/GenericSettingsPage"
import {
	OffersAndUpgradesIcon,
	OffersAndUpgradesFilledIcon,
	PaymentMethodIcon,
	InvoicesIcon,
	InvoicesFilledIcon,
	CurrentPlanIcon,
	CurrentPlanFilledIcon
} from "assets/icons/UserSettings/SubscriptionAndBilling"
import { PlanUsagePage } from "components/UserSettings/SubscriptionAndBilling/PlanUsagePage"
import { OffersAndUpgradesPage } from "components/UserSettings/SubscriptionAndBilling/OffersAndUpgradesPage"
import PaymentMethodPage from "components/UserSettings/SubscriptionAndBilling/PaymentMethodPage"
import InvoicesPage from "components/UserSettings/SubscriptionAndBilling/InvoicesPage"
import "./SubscriptionAndBillingPage.scss"
import { Route, Switch, Redirect } from "react-router"
import {
	PLAN_USAGE,
	INVOICES,
	OFFERS_AND_UPGRADES,
	PAYMENT_METHOD,
	SETTINGS,
	SUBSCRIPTION_AND_BILLING,
	NOT_FOUND
} from "constants/routes"

const basePath = `${SETTINGS}${SUBSCRIPTION_AND_BILLING}`

const data = [
	{
		ICON: CurrentPlanIcon,
		FILLED_ICON: CurrentPlanFilledIcon,
		TITLE: "Plan Usage",
		PATH: PLAN_USAGE
	},
	{
		ICON: OffersAndUpgradesIcon,
		FILLED_ICON: OffersAndUpgradesFilledIcon,
		TITLE: "Offers & Upgrades",
		PATH: OFFERS_AND_UPGRADES
	},
	{
		ICON: PaymentMethodIcon,
		FILLED_ICON: PaymentMethodIcon,
		TITLE: "Payment Method",
		PATH: PAYMENT_METHOD
	},
	{
		ICON: InvoicesIcon,
		FILLED_ICON: InvoicesFilledIcon,
		TITLE: "Invoices",
		PATH: INVOICES
	}
]

const SubscriptionAndBillingPage = () => {
	return (
		<GenericSettingsPage heading="Subscription & Billing" settingsTabsArray={data} basePath={basePath}>
			<Switch>
				<Route path={`${basePath}${PLAN_USAGE}`}>
					<PlanUsagePage />
				</Route>
				<Route path={`${basePath}${OFFERS_AND_UPGRADES}`}>
					<OffersAndUpgradesPage />
				</Route>
				<Route path={`${basePath}${PAYMENT_METHOD}`}>
					<PaymentMethodPage />
				</Route>
				<Route path={`${basePath}${INVOICES}`}>
					<InvoicesPage />
				</Route>
				<Redirect to={NOT_FOUND} />
			</Switch>
		</GenericSettingsPage>
	)
}

export default SubscriptionAndBillingPage
