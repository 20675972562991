import React from "react"
import { Avatar, Menu, Dropdown, Progress, Tag, Row, Col, Skeleton, Space, Tooltip } from "antd"
import "./EpisodeCard.scss"
import { Link, useHistory } from "react-router-dom"
import { EPISODES, MANAGE, POD } from "constants/routes"
import { setEpisodeIdForInitialValues, toggleAddNewEpisodeDrawer } from "redux/actions/Episodes/AddNewEpisode"
import { useDispatch, useSelector } from "react-redux"
import {
	setEpisodeIdForReleaseDateModal,
	toggleChangePlannedReleaseDateModalVisible,
	toggleDeleteEpisodeModalVisible,
	setEpisodeForDeleteEpisodeModal,
	setEpisodeIdForScheduledReleaseDateModal,
	toggleChangeScheduledReleaseDateModalVisible
} from "redux/actions/Episodes"
import StatusButton from "components/common/Buttons/StatusButton"
import styled, { css } from "styled-components"
import PodpalCard from "components/common/Cards/PodpalCard"
import { MoreIcon } from "assets/icons/Common/SimpleFeedback"
import moment from "moment"
import { findPodcast } from "services/podcast.service"
import { keysToCamel } from "utils/camelCaseUtils"
import { Episode } from "services/episode.service"
import { useUser } from "services/queries/User"
import { RootState } from "index"
import { UserIcon } from "assets/icons/Common/User"
import { InfoCircleFilled } from "@ant-design/icons"
import { EpisodeProgress } from "services/episodeProgress.service"
import colors from "styles/scss/color.scss"
import { usePodcastTeam } from "services/queries/Podcasts"
import { UserWithPodcastRole } from "services/types/user"
import { EpisodeFlag } from "./EpisodeFlag"

interface StyledProgressProps {
	percent: number
}

const StyledProgress = styled(Progress)`
	&&.ant-progress-status-success .ant-progress-bg {
		background-color: ${({ theme }) => theme.podpalGreenLight};
	}

	&& .ant-progress-text {
		color: ${({ theme }) => theme.textSecondary};
	}

	&&.ant-progress-status-success .ant-progress-text {
		color: ${({ theme }) => theme.podpalGreenLight};
	}

	${({ percent }: StyledProgressProps) =>
		percent < 26 &&
		css`
			&& .ant-progress-bg {
				background-color: ${({ theme }) => theme.podpalRedLight};
			}
		`}

	${({ percent }) =>
		percent > 25 &&
		percent < 76 &&
		css`
			&& .ant-progress-bg {
				background-color: ${({ theme }) => theme.podpalYellowLight};
			}
		`}

		${({ percent }) =>
		percent > 76 &&
		css`
			&& .ant-progress-bg {
				background-color: ${({ theme }) => theme.podpalBlue};
			}
		`}
`

const StyledAvatar = styled(Avatar)`
	transition: ease 0.25s;
	border: none !important;
	&:hover {
		transition: ease 0.25s;
		box-shadow: 0 0 0 3px ${({ theme }) => theme.podpalBlue};
		cursor: pointer;
	}
`

interface EpisodeCardProps {
	episode: Episode
	profileUrl: string
	episodeProgress: EpisodeProgress
}

export function EpisodeCard({ episode, profileUrl, episodeProgress }: EpisodeCardProps) {
	const dispatch = useDispatch()
	const history = useHistory()
	const {
		title,
		seasonNumber,
		episodeNumber,
		label,
		photoUrl,
		id,
		plannedReleaseDate,
		scheduledReleaseDate,
		lastPublishedDate
	} = episode

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const { data: userData } = useUser(authUser.uid)

	const { data: podcastTeamData } = usePodcastTeam(selectedPodcast?.id)

	if (episode?.migrationStatus === "MIGRATION_LOADING" || episode?.migrationStatus === "MIGRATION_PENDING") {
		return (
			<PodpalCard style={{ width: 360, height: 244 }} className="episode-card-container">
				<Skeleton avatar active paragraph={{ rows: 4 }} />
			</PodpalCard>
		)
	}

	const menu = (
		<Menu>
			<Menu.Item
				onClick={({ domEvent }) => {
					domEvent.preventDefault()
					history.push(`/${selectedPodcast?.profileUrl}${EPISODES}${MANAGE}/${episode.id}`)
				}}
			>
				Manage Episode
			</Menu.Item>
			<Menu.Item
				key="settings"
				onClick={({ domEvent }) => {
					domEvent.preventDefault()
					dispatch(setEpisodeIdForInitialValues(episode.id))
					dispatch(toggleAddNewEpisodeDrawer())
				}}
			>
				Settings
			</Menu.Item>
			<Menu.Item
				key="delete"
				onClick={({ domEvent }) => {
					domEvent.preventDefault()
					dispatch(setEpisodeForDeleteEpisodeModal(episode))
					dispatch(toggleDeleteEpisodeModalVisible())
				}}
				danger
			>
				Delete
			</Menu.Item>
		</Menu>
	)

	const handleStatusModalClick = async (e: MouseEvent) => {
		e.preventDefault()
		if (episode.scheduledReleaseDate) {
			dispatch(setEpisodeIdForScheduledReleaseDateModal(episode.id))
			dispatch(toggleChangeScheduledReleaseDateModalVisible())
		} else if (episode.isPublished) {
			try {
				const podcast = await findPodcast(episode.podcastId)
				const camelPodcast = keysToCamel(podcast)
				const { profileUrl } = camelPodcast.data.podcast
				history.push(`${POD}/${profileUrl}/${episode.id}`)
			} catch (e) {
				console.error(e)
			}
		} else {
			dispatch(setEpisodeIdForReleaseDateModal(id))
			dispatch(toggleChangePlannedReleaseDateModalVisible())
		}
	}

	return (
		<Link to={`/${selectedPodcast?.profileUrl}${EPISODES}${MANAGE}/${episode.id}`}>
			<PodpalCard style={{ width: 360 }} className="episode-card-container">
				<Row gutter={[8, 8]}>
					<Col span={24}>
						<Row>
							<Col span={9}>
								<div className="avatar-container">
									<Link to={`/${selectedPodcast?.profileUrl}${EPISODES}${MANAGE}/${episode.id}`}>
										<Avatar className="episode-card-podcast-artwork" shape="square" size={96} alt={title} src={photoUrl} />
										<EpisodeFlag episode={episode} />
									</Link>
								</div>
							</Col>
							<Col span={15}>
								<Row>
									<Col span={22}>
										<div className="episode-card-season">
											{seasonNumber ? `Season ${seasonNumber}` : ""} {seasonNumber && episodeNumber ? "|" : ""}{" "}
											{episodeNumber ? `Episode ${episodeNumber}` : ""}
										</div>
									</Col>
									<Col span={2}>
										<Dropdown overlay={menu} trigger={["click"]} className="episode-card-more">
											<MoreIcon key="trigger" />
										</Dropdown>
									</Col>
									<Col span={24}>
										<div className="episode-card-title">
											<Link to={`/${profileUrl}${EPISODES}${MANAGE}/${episode.id}`}>{title}</Link>
										</div>
									</Col>
									<Col span={24}>
										<div>{label ? <Tag>{label}</Tag> : null}</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row gutter={[0, 4]}>
							<Col span={24}>
								<StyledProgress percent={episodeProgress.percentCompleted} showInfo={true} />
							</Col>
							<Col span={24}>
								<Space size={4}>
									{episode.publishedEpisodeModified && (
										<Tooltip title="This episode has unsynced changes." placement="bottomLeft">
											<InfoCircleFilled style={{ color: colors.podpalRedLight }} />
										</Tooltip>
									)}
									{episode.isPublished ? (
										<p className="episode-card-progress-text">
											{moment(lastPublishedDate).format("M/D/YYYY [at] h:mm A ") + moment.tz(moment.tz.guess()).format("z")}
										</p>
									) : (
										<p className="episode-card-progress-text">Next: {episodeProgress.currentStep}</p>
									)}
								</Space>
							</Col>
						</Row>
					</Col>
					<Col flex="auto">
						<StatusButton
							onClick={handleStatusModalClick}
							plannedReleaseDate={plannedReleaseDate}
							scheduledReleaseDate={scheduledReleaseDate}
							publishedDate={lastPublishedDate}
						/>
					</Col>
					<Col>
						<Tooltip title={userData?.user.firstName + " " + userData?.user.lastName} placement="bottom">
							{/* TODO: Make avatars redirect to the pal page. */}
							<Avatar.Group maxCount={3}>
								{podcastTeamData?.usersWithPodcastRoles.map((userWithPodcastRole: UserWithPodcastRole) => (
									<Tooltip
										title={userWithPodcastRole.user.firstName + " " + userWithPodcastRole.user.lastName}
										placement="bottom"
									>
										{userWithPodcastRole.user.thumbnailUrl ? (
											<StyledAvatar src={userWithPodcastRole.user.thumbnailUrl} />
										) : (
											<StyledAvatar icon={<UserIcon />} />
										)}
									</Tooltip>
								))}
							</Avatar.Group>
						</Tooltip>
					</Col>
				</Row>
			</PodpalCard>
		</Link>
	)
}
