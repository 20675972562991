import React, { useEffect } from "react"
import { Col, Row, Avatar, Alert } from "antd"
import { InfoCircleFilled } from "@ant-design/icons"
import { useHistory, useParams } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { updateSelectedPodcastFromPodcastId } from "redux/actions/App"
import {
	setEpisodeIdForScheduledReleaseDateModal,
	toggleChangeScheduledReleaseDateModalVisible
} from "redux/actions/Episodes"
import { useEpisode } from "services/queries/Episodes"

import PodpalCard from "components/common/Cards/PodpalCard"
import PodpalButton from "components/common/Buttons/PodpalButton"
import ShowNotes from "./ShowNotes"
import EpisodeManagementHeader from "./EpisodeManagementHeader"
import EpisodeProgress from "./EpisodeProgress"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import { EPISODE_ALREADY_PUBLISHED_ALERT } from "constants/strings/Episodes"
import { useQueryClient } from "react-query"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import EpisodeChecklist from "./EpisodeChecklist"
import Recording from "./Recording"
import { EpisodeReviewCard } from "./EpisodeReviewCard"
import EpisodeStatus from "./EpisodeStatus"
import styled from "styled-components"
import moment from "moment"
import { ScheduledSuccessIcon, SuccessIcon } from "assets/icons/Episodes"
import { EpisodeManagementTooltip } from "./EpisodeManagementTooltip"
import { usePodcastAccessValidator } from "services/queries/PodcastRole"
import { NO_ACCESS } from "constants/routes"
import { EpisodeFlag } from "../EpisodeCard/EpisodeFlag"
import Banner301RedirectRequested from "components/Podcasts/Banner301RedirectRequested"
import CommentSection from "./CommentSection"

const StyledGrid = styled.div`
	display: grid;
	grid-gap: ${({ theme }) => theme.spaceXs};
	grid-template-columns: 1fr minmax(auto, 600px);
	grid-template-rows: 1fr 1fr;
	max-width: ${({ theme }) => theme.sm};
	margin: auto;
	margin-bottom: ${({ theme }) => theme.spaceLg};
	.episode-artwork {
		grid-row: 1/2;
	}
	.episode-status {
		grid-row: 2/3;
	}

	.episode-progress {
		grid-row: 1/3;
	}

	.grid-item-full {
		grid-column: 1/3;
	}

	@media only screen and (max-width: ${({ theme }) => theme.md}) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;

		.episode-artwork {
			grid-row: 1/2;
		}
		.episode-status {
			grid-row: 1/2;
		}

		.episode-progress {
			grid-row: 2/3;
			grid-column: 1/3;
		}
	}

	.avatar-container {
		.avatar-overlay {
			background-color: ${({ theme }) => theme.podpalRedLight};
			text-align: center;
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 116px;
			height: 14px;
			font-size: 12px;
			line-height: 15px;
			font-weight: 500;
			color: ${({ theme }) => theme.neutral0};
			border-radius: 0 0 4px 4px;
		}
	}
`

export function EpisodeManagement() {
	const { episodeId } = useParams()
	const history = useHistory()
	const authUser = useSelector(state => state.app.authUser)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	const { isLoading, isError, data } = useEpisode(episodeId)
	const {
		isError: episodeProgressError,
		isLoading: episodeProgressLoading,
		data: episodeProgressData
	} = useEpisodeProgress(episodeId)
	const {
		isLoading: podcastAccessLoading,
		error: podcastAccessError,
		data: podcastAccessData
	} = usePodcastAccessValidator({ userId: authUser?.uid, podcastId: selectedPodcast?.id })

	useEffect(() => {
		if (data && selectedPodcast && data?.episode?.podcastId !== selectedPodcast.id) {
			dispatch(updateSelectedPodcastFromPodcastId(data.episode?.podcastId))
		}
	}, [selectedPodcast, data, dispatch])

	useEffect(() => {
		if (!podcastAccessLoading && !podcastAccessError && podcastAccessData && !podcastAccessData.hasAccess) {
			history.push(NO_ACCESS)
		}
	}, [history, podcastAccessLoading, podcastAccessError, podcastAccessData])

	if (isLoading || episodeProgressLoading || podcastAccessLoading) {
		return <div>Loading...</div>
	}

	if (isError || episodeProgressError || podcastAccessError) {
		return <div>Error occurred while retrieving data.</div>
	}

	const {
		setup,
		inviteGuests,
		scheduleInterview,
		uploadAudio,
		writeShowNotes,
		reviewEpisode
	} = episodeProgressData.episodeProgress

	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: reviewEpisode ?? 0
		}
	]

	const handleMarkAsDone = async progressStep => {
		try {
			const stepIndex = steps.findIndex(step => step.stepName === progressStep)
			steps[stepIndex].status = STATUS.COMPLETED
			episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.COMPLETED })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleUncheckStep = async progressStep => {
		try {
			const stepIndex = steps.findIndex(step => step.stepName === progressStep)
			steps[stepIndex].status = STATUS.IN_PROGRESS
			episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.IN_PROGRESS })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
			queryClient.invalidateQueries(["episode", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as incomplete.")
		}
	}

	let alertBanner = null
	if (data?.episode?.scheduledReleaseDate) {
		alertBanner = (
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<Alert
						type="warning"
						banner
						message={
							<Row gutter={8} justify="center" align="middle">
								<Col>
									{`This episode is scheduled to ${data?.episode?.isPublished ? "republish" : "publish"} on ${moment(
										data?.episode?.scheduledReleaseDate
									).format("MM/DD/YY [at] hh:mm A")} ${moment.tz(moment.tz.guess()).format("z")}`}
								</Col>
								<Col>
									<PodpalButton
										type="primary"
										onClick={() => {
											dispatch(setEpisodeIdForScheduledReleaseDateModal(data?.episode?.id))
											dispatch(toggleChangeScheduledReleaseDateModalVisible())
										}}
										size="small"
									>
										Reschedule
									</PodpalButton>
								</Col>
							</Row>
						}
						showIcon
						icon={<ScheduledSuccessIcon />}
					/>
				</Col>
			</Row>
		)
	} else if (data?.episode?.publishedEpisodeModified) {
		alertBanner = (
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<Alert type="error" banner message={EPISODE_ALREADY_PUBLISHED_ALERT} showIcon icon={<InfoCircleFilled />} />
				</Col>
			</Row>
		)
	} else if (data?.episode?.lastPublishedDate) {
		alertBanner = (
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<Alert
						type="success"
						banner
						message={`This episode was published on ${moment(data?.episode?.lastPublishedDate).format(
							"MM/DD/YY [at] hh:mm A"
						)} ${moment.tz(moment.tz.guess()).format("z")}`}
						showIcon
						icon={<SuccessIcon />}
					/>
				</Col>
			</Row>
		)
	}

	return (
		<div className="banner-page-container">
			<Banner301RedirectRequested />
			<div className="home-page-container">
				<EpisodeManagementHeader />
				{alertBanner}
				<StyledGrid>
					<PodpalCard className="episode-artwork">
						<Row justify="center" align="middle">
							<Col span={24} style={{ textAlign: "center" }}>
								<h2>Episode Artwork</h2>
							</Col>
							<Col>
								<div className="avatar-container">
									<Avatar
										shape="square"
										size={116}
										style={{ borderRadius: "4px" }}
										alt={data?.episode?.title}
										src={data?.episode?.photoUrl}
									/>
									<EpisodeFlag episode={data?.episode} />
								</div>
							</Col>
						</Row>
					</PodpalCard>
					<EpisodeStatus className="episode-status" />
					<EpisodeProgress className="episode-progress" />
					<EpisodeChecklist className="grid-item-full" />
					<PodpalCard className="grid-item-full">
						<Row justify="center" align="middle">
							<Col span={24} style={{ textAlign: "center" }}>
								<Row justify="end" align="middle">
									<EpisodeManagementTooltip tooltipText="Find a guest to feature on this episode by searching through our catalog or inviting one of your own!" />
								</Row>
								<h2>Invite Guests</h2>
							</Col>
							<Col span={24} style={{ textAlign: "center" }}>
								{episodeProgressData.episodeProgress.inviteGuests === STATUS.COMPLETED ? (
									<PodpalButton size="large" variant="secondary" onClick={() => handleUncheckStep("inviteGuests")}>
										Uncheck
									</PodpalButton>
								) : (
									<PodpalButton
										size="large"
										type="primary"
										onClick={() => handleMarkAsDone("inviteGuests")}
										disabled={episodeProgressData.episodeProgress.inviteGuests === STATUS.SKIPPED}
									>
										Mark as Done
									</PodpalButton>
								)}
							</Col>
						</Row>
					</PodpalCard>
					<PodpalCard className="grid-item-full">
						<Row justify="center" align="middle">
							<Col span={24} style={{ textAlign: "center" }}>
								<Row justify="end" align="middle">
									<EpisodeManagementTooltip tooltipText="When you provide your recording date, we’ll add it to your podcast calendar and send you reminders until you mark it as done!" />
								</Row>
								<h2>Schedule Interview</h2>
							</Col>
							<Col span={24} style={{ textAlign: "center" }}>
								{episodeProgressData.episodeProgress.scheduleInterview === STATUS.COMPLETED ? (
									<PodpalButton size="large" variant="secondary" onClick={() => handleUncheckStep("scheduleInterview")}>
										Uncheck
									</PodpalButton>
								) : (
									<PodpalButton
										size="large"
										type="primary"
										onClick={() => handleMarkAsDone("scheduleInterview")}
										disabled={episodeProgressData.episodeProgress.scheduleInterview === STATUS.SKIPPED}
									>
										Mark as Done
									</PodpalButton>
								)}
							</Col>
						</Row>
					</PodpalCard>
					<Recording className="grid-item-full" />
					<ShowNotes className="grid-item-full" />
					<CommentSection className="grid-item-full" episodeId={episodeId} />
					<EpisodeReviewCard className="grid-item-full" />
					{/* <PodpalCard className="grid-item-full">
					<Row justify="center" align="middle">
						<Col span={24} style={{ textAlign: "center" }}>
							<Row justify="end" align="middle">
								<EpisodeManagementTooltip tooltipText="Add a review, and assign a team member to ensure this episode meets your quality standards." />
							</Row>
							<h2>Episode Review</h2>
						</Col>
						<Col span={24} style={{ textAlign: "center" }}>
							{episodeProgressData.episodeProgress.reviewEpisode === STATUS.COMPLETED ? (
								<PodpalButton size="large" variant="secondary" onClick={() => handleUncheckStep("reviewEpisode")}>
									Uncheck
								</PodpalButton>
							) : (
								<PodpalButton
									size="large"
									type="primary"
									onClick={() => handleMarkAsDone("reviewEpisode")}
									disabled={episodeProgressData.episodeProgress.reviewEpisode === STATUS.SKIPPED}
								>
									Mark as Done
								</PodpalButton>
							)}
						</Col>
					</Row>
				</PodpalCard> */}
				</StyledGrid>
			</div>
		</div>
	)
}
