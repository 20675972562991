import { RootState } from "index"
import { Row, Col, Space } from "antd"
import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import PodpalModal from "../../PodpalModal"
import styled, { ThemeContext } from "styled-components"
import { WarningExclamationIcon } from "assets/icons/Common/SimpleFeedback"

import PodpalButton from "components/common/Buttons/PodpalButton"
import { toggle301RedirectModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { toggleImportEpisodesStatusModalVisible } from "redux/actions/Episodes/AddNewEpisode"

const StyledText = styled.p`
	color: ${props => props.theme.textSecondary};
	font: ${props => props.theme.fontSm};
`

export function Redirect301Modal() {
	const theme = useContext(ThemeContext)
	const dispatch = useDispatch()

	const visible = useSelector((state: RootState) => state.addNewPodcast.redirect301ModalVisible)

	const handleClose = () => {
		dispatch(toggle301RedirectModalVisible())
		dispatch(toggleImportEpisodesStatusModalVisible())
	}

	return (
		<PodpalModal
			centered
			maskClosable={false}
			visible={visible}
			onCancel={handleClose}
			title={
				<Space size={12}>
					<WarningExclamationIcon />
					{"Please Request a 301 Redirect!"}
				</Space>
			}
			footer={[
				<PodpalButton type="primary" onClick={handleClose}>
					OK
				</PodpalButton>
			]}
			width={420}
		>
			<Row gutter={[0, 12]}>
				<Col>
					<StyledText>
						<strong>A 301 Redirect is still required to officially move your feed from your old host to Podpal.</strong>
					</StyledText>
				</Col>
			</Row>
			<Row gutter={[0, 12]}>
				<Col>
					<ul style={{ paddingLeft: theme.spaceLg }}>
						<li>
							<StyledText>
								Please{" "}
								<a
									href="http://helpcenter.podpal.com/en/articles/6680862-how-to-switch-from-another-podcast-host-to-podpal-general"
									target="_blank"
									rel="noopener noreferrer"
								>
									request a 301 Redirect
								</a>{" "}
								from your old host prior to publishing your next episode in Podpal to make sure you don’t lose subscribers and
								that your episodes are properly indexed.
							</StyledText>
						</li>
					</ul>
				</Col>
			</Row>
			<Row gutter={[0, 12]}>
				<Col>
					<StyledText>
						This is just like changing your mailing address. The 301 Redirect will let Apple Podcasts, Spotify, and other
						podcast platforms know that your show has a new home.
					</StyledText>
				</Col>
			</Row>
			<Row>
				<Col>
					<StyledText>
						Once the 301 Redirect is in place, your current subscribers will automatically be re-subscribed to the new RSS
						Feed hosted by Podpal.
					</StyledText>
				</Col>
			</Row>
		</PodpalModal>
	)
}
