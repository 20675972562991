import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Collapse, Row, Col, Tag, Form, Input } from "antd"
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { updatePodcast } from "services/podcast.service"
import { usePodcast } from "services/queries/Podcasts"
import "./DistributionCard.scss"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"

const { Panel } = Collapse

const StyledCollapse = styled(Collapse)`
	background: ${props => props.theme.neutral0};
	border-radius: ${props => props.theme.radiusLg};
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	max-width: 637px;

	.ant-collapse-header {
		.anticon {
			margin-top: ${props => props.theme.spaceXxs};
		}
	}

	.ant-collapse-item {
		border: none;
	}

	.ant-collapse-content {
		padding: 0px 24px 0px 24px;
		.distribution-card-registration-preamble-text {
			font: ${props => props.theme.fontSm};
			color: ${props => props.theme.textSecondary};

			a {
				color: ${props => props.theme.primary};
			}
		}
		ol {
			list-style-position: inside;
			padding-left: 0;
			li {
				font: ${props => props.theme.fontSm};
				color: ${props => props.theme.textSecondary};
			}
			li:not(:last-child) {
				margin-bottom: ${props => props.theme.spaceSm};
			}
		}
	}
`

const StyledRegisteredTag = styled(Tag)`
	min-height: 32px;
	line-height: 32px;
	padding-left: ${props => props.theme.spaceMd};
	padding-right: ${props => props.theme.spaceMd};
	background: ${props => props.theme.neutral0};
	border-radius: 200px;
	border: 1px solid ${props => props.theme.neutral2};
	color: ${props => props.theme.textSecondary};
`

const RegisteredIcon = <CheckCircleFilled style={{ color: "#34CC8D" }} />
const NotRegisteredIcon = <CloseCircleFilled style={{ color: "#EA403B" }} />

const DistributionCard = ({
	distributorName,
	distributorDescription,
	distributorIcon,
	distributorRegistrationLink,
	distributorRegistrationPreamble,
	distributorSteps,
	distributorUrlValidation,
	distributorPropertyName
}) => {
	const queryClient = useQueryClient()

	const URL_FIELD_NAME = "url"
	const URL_ERROR_MESSAGE = `Please enter a valid ${distributorName} URL.`

	const authUser = useSelector(state => state.app.authUser)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const { data: podcastData } = usePodcast(selectedPodcast?.id)
	const [initialValues, setInitialValues] = useState(null)
	const [url, setUrl] = useState(null)
	const [registeredTag, setRegisteredTag] = useState(null)
	const [buttonText, setButtonText] = useState("Save")
	const [buttonDisabled, setButtonDisabled] = useState(true)
	const [buttonHref, setButtonHref] = useState(null)

	useEffect(() => {
		if (podcastData?.podcast?.[distributorPropertyName]?.length > 0) {
			setInitialValues({
				[URL_FIELD_NAME]: podcastData.podcast[distributorPropertyName]
			})
			setUrl(podcastData.podcast[distributorPropertyName])
			setRegisteredTag(<StyledRegisteredTag icon={RegisteredIcon}>Registered</StyledRegisteredTag>)
			setButtonText("Visit")
			setButtonDisabled(false)
			setButtonHref(podcastData.podcast[distributorPropertyName])
		} else {
			setRegisteredTag(<StyledRegisteredTag icon={NotRegisteredIcon}>Not Registered</StyledRegisteredTag>)
			setButtonText("Save")
			setButtonDisabled(true)
			setButtonHref(null)
		}
	}, [podcastData, distributorPropertyName, setButtonText])

	const [form] = Form.useForm()

	const handleSubmit = async values => {
		try {
			const updateData = {
				podcastId: selectedPodcast.id,
				data: {
					...podcastData.podcast,
					[distributorPropertyName]: values.url
				},
				userId: authUser.uid
			}
			await updatePodcast(updateData)
			successAlert("Changes Saved.")
			queryClient.invalidateQueries(["podcast", selectedPodcast.id])
		} catch (e) {
			console.error(e)
			errorAlert("There was an error saving.")
		}
	}

	const handleValuesChange = (changedValues, allValues) => {
		if (form.isFieldsTouched) {
			setButtonText("Save")
			setButtonHref(null)
		} else {
			setButtonText("Visit")
			setButtonHref(url)
		}
		// Only disable for empty field if there is not already a URL.
		setButtonDisabled(form.getFieldValue(URL_FIELD_NAME).length <= 0 && url?.length <= 0)
	}

	const cardHeader = (
		<>
			<Row gutter={[0, 16]} justify="space-between" align="middle">
				<Col>
					<Row gutter={8} align="middle">
						<Col>
							<img src={distributorIcon} alt="distributor-icon" />
						</Col>
						<Col>
							<p className="distribution-card-title">{distributorName}</p>
						</Col>
					</Row>
				</Col>
				<Col>{registeredTag}</Col>
			</Row>
			<Row>
				<Col offset={1}>
					<p className="distribution-card-description">{distributorDescription}</p>
				</Col>
			</Row>
		</>
	)

	const cardSteps = distributorSteps.map(step => <li>{step}</li>)

	return (
		<StyledCollapse className="distribution-card" bordered={false} expandIconPosition="right">
			<Panel header={cardHeader}>
				<>
					<Row gutter={[0, 24]}>
						<Col>
							<PodpalButton
								type="primary"
								size="large"
								href={distributorRegistrationLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								Start Registration
							</PodpalButton>
						</Col>
					</Row>
					<Row gutter={[0, 8]}>
						<Col>
							<p className="distribution-card-body-title">{`How to Register with ${distributorName}`}</p>
						</Col>
					</Row>
					<Row gutter={[0, 12]}>
						<Col style={{ marginLeft: "16px" }}>
							{
								<p className="distribution-card-registration-preamble-text">
									<a href={distributorRegistrationPreamble.link} target="_blank" rel="noopener noreferrer">
										{distributorRegistrationPreamble.linkText}
									</a>{" "}
									{distributorRegistrationPreamble.text}
								</p>
							}
						</Col>
					</Row>
					<Row gutter={[0, 24]}>
						<Col style={{ marginLeft: "16px" }}>
							<ol>{cardSteps}</ol>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form
								form={form}
								initialValues={initialValues}
								onFinish={handleSubmit}
								onFinishFailed={() => errorAlert("Please fix errors to save.")}
								layout="vertical"
								onValuesChange={handleValuesChange}
							>
								<Form.Item
									name={URL_FIELD_NAME}
									label={`${distributorName} URL`}
									hasFeedback
									validateFirst
									rules={[
										{
											type: "url",
											message: URL_ERROR_MESSAGE
										},
										{
											pattern: distributorUrlValidation,
											message: URL_ERROR_MESSAGE
										}
									]}
								>
									<Input
										name={URL_FIELD_NAME}
										placeholder={`Enter your ${distributorName} URL`}
										addonAfter={
											buttonText === "Save" ? (
												<PodpalButton
													className="distributor-url-save-button"
													disabled={buttonDisabled}
													type="primary"
													htmlType="submit"
													size="large"
												>
													{buttonText}
												</PodpalButton>
											) : (
												<PodpalButton
													className="distributor-url-save-button"
													disabled={buttonDisabled}
													type="primary"
													href={buttonHref}
													size="large"
													target="_blank"
													rel="noopener noreferrer"
												>
													{buttonText}
												</PodpalButton>
											)
										}
										size="large"
									/>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</>
			</Panel>
		</StyledCollapse>
	)
}

export default DistributionCard
