import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { SIGN_IN } from "constants/routes"

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
	const authUser = useSelector(state => state.app.authUser)

	return (
		<Route
			{...rest}
			render={props => {
				if (authUser) {
					//&& authUser.emailVerified) {
					return <Component {...rest} {...props} />
				} else {
					return (
						<Redirect
							to={{
								pathname: SIGN_IN,
								state: {
									from: props.location
								}
							}}
						/>
					)
				}
			}}
		/>
	)
}

export default ProtectedRoute
