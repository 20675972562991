import {
	RETRIEVE_PODCAST_DATA_LOADING,
	RETRIEVE_PODCAST_DATA_SUCCESS,
	RETRIEVE_PODCAST_DATA_ERROR,
	RETRIEVE_PODCAST_ROLE_BY_USER_ID_LOADING,
	RETRIEVE_PODCAST_ROLE_BY_USER_ID_SUCCESS,
	RETRIEVE_PODCAST_ROLE_BY_USER_ID_ERROR,
	RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_LOADING,
	RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_SUCCESS,
	RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_ERROR,
	TOGGLE_DELETE_PODCAST_MODAL_VISIBLE,
	DELETE_PODCAST_LOADING,
	DELETE_PODCAST_SUCCESS,
	DELETE_PODCAST_ERROR,
	REDIRECT_PODCAST_LOADING,
	REDIRECT_PODCAST_SUCCESS,
	REDIRECT_PODCAST_ERROR
} from "constants/actionTypes"
import { findPodcast, deletePodcastService, redirectPodcastService } from "services/podcast.service"
import { findPodcastRoleByUserId, findPodcastRoleByPodcastId } from "services/podcast-role.service"

export const retrievePodcastData = podcastId => async dispatch => {
	dispatch({ type: RETRIEVE_PODCAST_DATA_LOADING })

	return await findPodcast(podcastId)
}

export const retrievePodcastDataSuccess = podcast => ({
	type: RETRIEVE_PODCAST_DATA_SUCCESS,
	payload: { podcast }
})

export const retrievePodcastDataError = error => ({
	type: RETRIEVE_PODCAST_DATA_ERROR,
	payload: { error }
})

export const retrieveSinglePodcast = podcastId => async dispatch => {
	return await findPodcast(podcastId)
}

export const retrievePodcastRoleByUserId = userId => async dispatch => {
	dispatch({ type: RETRIEVE_PODCAST_ROLE_BY_USER_ID_LOADING })

	return await findPodcastRoleByUserId(userId)
}

export const retrievePodcastRoleByUserIdSuccess = podcastRoles => ({
	type: RETRIEVE_PODCAST_ROLE_BY_USER_ID_SUCCESS,
	payload: { podcastRoles }
})

export const retrievePodcastRoleByUserIdError = error => ({
	type: RETRIEVE_PODCAST_ROLE_BY_USER_ID_ERROR,
	payload: { error }
})

export const retrievePodcastRoleByPodcastId = podcastId => async dispatch => {
	dispatch({ type: RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_LOADING })

	return await findPodcastRoleByPodcastId(podcastId)
}

export const retrievePodcastRoleByPodcastIdSuccess = podcastRoles => ({
	type: RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_SUCCESS,
	payload: { podcastRoles }
})

export const retrievePodcastRoleByPodcastIdError = error => ({
	type: RETRIEVE_PODCAST_ROLE_BY_PODCAST_ID_ERROR,
	payload: { error }
})

export const toggleDeletePodcastModalVisible = () => ({
	type: TOGGLE_DELETE_PODCAST_MODAL_VISIBLE
})

export const deletePodcast = (podcastId, userId) => async dispatch => {
	dispatch({ type: DELETE_PODCAST_LOADING })

	return await deletePodcastService(podcastId, userId)
}

export const deletePodcastSuccess = podcastId => ({
	type: DELETE_PODCAST_SUCCESS,
	payload: { podcastId }
})

export const deletePodcastError = error => ({
	type: DELETE_PODCAST_ERROR,
	payload: { error }
})

export const redirectPodcast = (podcastId, userId, newRssFeedUrl) => async dispatch => {
	dispatch({ type: REDIRECT_PODCAST_LOADING })

	return await redirectPodcastService(podcastId, userId, newRssFeedUrl)
}

export const redirectPodcastSuccess = podcastId => ({
	type: REDIRECT_PODCAST_SUCCESS,
	payload: { podcastId }
})

export const redirectPodcastError = error => ({
	type: REDIRECT_PODCAST_ERROR,
	payload: { error }
})
