import React from "react"
import { Row, Col, Card } from "antd"
import styled from "styled-components"
import privateBetaImage from "assets/privatebeta.svg"
import privateBetaMobileImage from "assets/privatebetamobile.svg"
import PodpalButton from "components/common/Buttons/PodpalButton"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"

const StyledCard = styled(Card)`
	border-radius: ${props => props.theme.radiusLg};
	max-width: 488px;
	padding: ${props => props.theme.spaceLg};
	.ant-col {
		text-align: center;
	}
`

const StyledPrimaryHeading = styled.span`
	color: ${props => props.theme.textPrimary};
	font: 700 24px/32px Roboto, sans-serif;
`

const StyledPrimaryText = styled.span`
	color: ${props => props.theme.textPrimary};
	font: ${props => props.theme.fontSm};
`

const StyledSecondaryText = styled.span`
	color: ${props => props.theme.gray7};
	font: ${props => props.theme.fontSm};
`

const PrivateBetaCard = () => {
	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	return (
		<StyledCard bordered={false}>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<StyledSecondaryText>
					<strong>Sorry, this is a private party...</strong>
				</StyledSecondaryText>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 32]}>
				<StyledSecondaryText>It looks like you’re not on the list!</StyledSecondaryText>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 32]}>
				<Col span={24}>
					<img src={isMobile ? privateBetaMobileImage : privateBetaImage} alt="private-beta" width="100%" />
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 32]}>
				<Col xs={24} md={18} lg={18} span={18}>
					<StyledPrimaryHeading>There’s still time to join Podpal (Private Beta).</StyledPrimaryHeading>
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 32]}>
				<Col xs={24} md={22} lg={22} span={22}>
					<StyledPrimaryText>
						We're currently welcoming podcasters like you to our secret society! Are you interested in helping us improve the
						Podpal experience in exhange for free stuff?
					</StyledPrimaryText>
				</Col>
			</Row>
			<Row justify="center" align="middle">
				<Col>
					<PodpalButton
						type="primary"
						size="large"
						href="https://forms.gle/6fBbXAPda2TAb25A9"
						target="_blank"
						rel="noopener noreferrer"
					>
						Get Access
					</PodpalButton>
				</Col>
			</Row>
		</StyledCard>
	)
}

export default PrivateBetaCard
