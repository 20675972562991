import React from "react"
import { Table, Dropdown, Menu, Skeleton } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { recordingInUseModal } from "components/common/Modals/Recordings/RecordingModals"
import { EPISODES, MANAGE } from "constants/routes"
import { usePodcastRecordings } from "services/queries/Recordings"
import { RECORDING_UPLOADED_TIME_DISPLAY_FORMAT } from "constants/strings/Episodes"
import { toggleDeleteRecordingModalVisible, setConfigForDeleteRecordingModal } from "redux/actions/Recordings"
import { formatTime } from "utils/time"
import { formatFileSize, mapEpisodeTypeToText } from "utils/recording"
import moment from "moment"
import { useHistory } from "react-router"
import useWindowSize from "hooks/useWindowSize"
import { MoreIcon } from "assets/icons/Common/SimpleFeedback"
import { BREAKPOINT_XS } from "constants/breakpoints"
import { useDownload } from "hooks/useDownload"

const RecordingsTable = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const size = useWindowSize()
	const isMobile = size.width < BREAKPOINT_XS

	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const { handleDownload } = useDownload()
	const { isLoading, error, data } = usePodcastRecordings(selectedPodcast?.id)
	if (isLoading) {
		return <Skeleton active />
	} else if (error) {
		return <h4>Error loading recordings</h4>
	} else if (!data?.recordingsWithEpisodes) {
		return <h4>No recordings yet!</h4>
	}

	const handleDeleteRecording = (recording, episode) => {
		if (episode?.isPublished) {
			recordingInUseModal()
		} else {
			dispatch(setConfigForDeleteRecordingModal({ recording, episodeID: episode.id }))
			dispatch(toggleDeleteRecordingModalVisible())
		}
	}

	const columns = [
		{
			title: "File Name",
			key: "filename",
			render: (text, record) => record.recording.fileName,
			width: "40%",
			sorter: (a, b) => a.recording.fileName.localeCompare(b.recording.fileName),
			fixed: isMobile ? "left" : null
		},
		{
			title: "File Size",
			key: "filesize",
			render: (text, record) => formatFileSize(record.recording.fileSize, true),
			width: "10%",
			sorter: (a, b) => a.recording.fileSize - b.recording.fileSize,
			responsive: ["sm"]
		},
		{
			title: "Duration",
			key: "duration",
			render: (text, record) => formatTime(record.recording.duration),
			width: "10%",
			sorter: (a, b) => a.recording.duration - b.recording.duration,
			responsive: ["sm"]
		},
		{
			title: "Date Added",
			key: "dateadded",
			render: (text, record) => moment(record.recording.createdAt).format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT),
			width: "20%",
			defaultSortOrder: "descend",
			sorter: (a, b) => moment(a.recording.createdAt).format().localeCompare(moment(b.recording.createdAt).format()),
			responsive: ["sm"]
		},
		{
			title: "Episode",
			key: "episode",
			render: (text, record, index) => {
				let seasonNumber = record.episode.seasonNumber
				let episodeNumber = record.episode.episodeNumber
				const { episodeType, trailerType, bonusType } = record.episode

				let cellText = ""
				cellText += seasonNumber ? "S" + seasonNumber + " | " : ""
				cellText += episodeNumber ? "E" + episodeNumber + " | " : ""
				cellText += record.episode.id ? mapEpisodeTypeToText({ episodeType, trailerType, bonusType }) : ""
				return cellText.length > 0 ? cellText : "N/A"
			},
			width: "15%"
			// sorter: (a, b) => a.episode.episodeNumber - b.episode.episodeNumber
		},
		{
			title: "Action",
			key: "action",
			align: "center",
			render: (text, record) => (
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item
								key="gotoepisode"
								disabled={!record.episode.id}
								onClick={() => history.push(`/${selectedPodcast.profileUrl}${EPISODES}${MANAGE}/${record.episode.id}`)}
							>
								Go to Episode
							</Menu.Item>
							<Menu.Item
								key="download"
								onClick={() => handleDownload({ url: record.recording.url, filename: record.recording.fileName })}
							>
								Download
							</Menu.Item>
							<Menu.Item danger key="delete" onClick={() => handleDeleteRecording(record.recording, record.episode)}>
								Delete
							</Menu.Item>
						</Menu>
					}
					trigger={["click"]}
				>
					<MoreIcon />
				</Dropdown>
			),
			width: "5%"
		}
	]

	return (
		<Table
			columns={columns}
			dataSource={data?.recordingsWithEpisodes}
			pagination={{ pageSize: 50, showSizeChanger: false }}
		/>
	)
}

export default RecordingsTable
