import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Tag, Statistic, Space } from "antd"
import styled, { DefaultTheme, ThemeContext } from "styled-components"
import "components/UserSettings/SubscriptionAndBilling/SubscriptionAndBilling.scss"
import { useSelector } from "react-redux"
import { RootState } from "index"
import { useUserInfo } from "services/queries/UserInfo"
import { SubscriptionType } from "services/types/userInfo"
import { UsageCard } from "../Cards/UsageCard"
import { useOwnedPodcasts } from "services/queries/Podcasts"
import { Podcast } from "services/types/podcast"

const Container = styled.div`
	margin-top: ${({ theme }) => theme.spaceLg};
	margin-left: ${({ theme }) => theme.spaceXs};

	a {
		color: ${({ theme }) => theme.podpalBlue};
	}
`

interface TextProps {
	font: keyof DefaultTheme
	color: DefaultTheme
}

const Text = styled.span.attrs((props: TextProps) => ({
	font: props.font,
	color: props.color
}))`
	font: ${({ font }) => font};
	color: ${({ color }) => color};
`

const StyledTag = styled(Tag)`
	border-radius: ${({ theme }) => theme.radiusMd};
	background: ${({ theme }) => theme.gray2};
`

const StyledStatistic = styled(Statistic)`
	.ant-statistic-title {
		font: ${({ theme }) => theme.fontSm};
		color: ${({ theme }) => theme.textPrimary};
	}
	.ant-statistic-content {
		font: ${({ theme }) => theme.fontLgPlus};
		color: ${({ theme }) => theme.textPrimary};
		text-align: center;
	}
`

export function PlanUsagePage() {
	const theme = useContext(ThemeContext)

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const { data: userInfoData } = useUserInfo(authUser.uid)
	const { data: podcastsData } = useOwnedPodcasts(authUser.uid)

	const [planName, setPlanName] = useState<string>("")
	const [podcastLimit, setPodcastLimit] = useState<number>(0)

	useEffect(() => {
		switch (userInfoData?.userInfo.subscriptionType) {
			case SubscriptionType.starter:
				setPlanName("Starter")
				setPodcastLimit(1)
				break
			case SubscriptionType.pro:
				setPlanName("Pro")
				setPodcastLimit(3)
				break
			case SubscriptionType.agency:
				setPlanName("Agency")
				setPodcastLimit(10)
				break
			case SubscriptionType.beta:
				setPlanName("Beta")
				setPodcastLimit(3)
				break
			default:
				setPlanName("Free")
				setPodcastLimit(1)
				break
		}
	}, [userInfoData])

	const usageCards = podcastsData?.podcasts?.map((podcast: Podcast) => (
		<Col key={podcast.id}>
			<UsageCard podcastID={podcast.id} />
		</Col>
	))

	return (
		<Container>
			<Row gutter={[{ xs: 12, sm: 24 }, 24]} align="middle">
				<Col>
					<Row gutter={[0, 12]}>
						<Text color={theme.textPrimary} font={theme.fontMd}>
							Current Plan
						</Text>
					</Row>
					<Row gutter={8} align="middle">
						<Col>
							<Space size={8}>
								<Text color={theme.podpalBlue} font={theme.fontLg}>
									<strong>{planName}</strong>
								</Text>
								<StyledTag>{userInfoData?.userInfo.isMonthly ? "monthly" : "yearly"}</StyledTag>
							</Space>
						</Col>
					</Row>
				</Col>
				<Col>
					<StyledStatistic title="My Podcasts" value={userInfoData?.userInfo.numberOfPodcasts} />
				</Col>
				<Col>
					<StyledStatistic title="Podcast Limit" value={podcastLimit} />
				</Col>
			</Row>
			<Row gutter={[24, 24]}>{usageCards}</Row>
		</Container>
	)
}
