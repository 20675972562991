import { useState, useEffect } from "react"
import { useEpisodeRecording } from "services/queries/Recordings"
import { getSignedUrl, uploadFile } from "services/upload.service"
import {
	createRecording,
	updateRecording,
	validateRecordingFileName,
	replaceRecording
} from "services/recording.service"
import {
	RECORDING_UPLOADED_TIME_DISPLAY_FORMAT,
	UNABLE_TO_CREATE_RECORDING,
	UNABLE_TO_UPLOAD_RECORDING,
	UNABLE_TO_REMOVE_RECORDING,
	UNABLE_TO_REPLACE_RECORDING,
	SUCCESSFULLY_CREATED_RECORDING
} from "constants/strings/Episodes"
import { duplicateFilenameModal, renameFileModal } from "components/common/Modals/Episodes/EpisodeManagementModals"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import { updateEpisode } from "services/episode.service"
import { useQueryClient } from "react-query"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { mapFileTypeToExtension } from "utils/recording"

export default function useRecordingsUpload({
	episodeId,
	episodeData,
	selectedPodcast,
	handleMarkAsDone,
	handleMarkAsInProgress,
	uploadCancelTokenRef
}) {
	const queryClient = useQueryClient()
	const [duplicateRecordingID, setDuplicateRecordingID] = useState(null)
	const [isSameFilename, setIsSameFilename] = useState(false) // Flag is toggled when file is being replaced with one that has the same name.
	const [isReplacingRecording, setIsReplacingRecording] = useState(false) // Set to true if the "Replace" button with the dropdown is being used.
	const [progress, setProgress] = useState(0)
	const [loading, setLoading] = useState(false)
	const [uploadConfig, setUploadConfig] = useState(null)
	const [filename, setFilename] = useState(null)
	const [media, setMedia] = useState("")
	const [mediaFileName, setMediaFileName] = useState("")
	const [mediaFileType, setMediaFileType] = useState("")
	const [mediaFileSize, setMediaFileSize] = useState(null)
	const [mediaUploadedTime, setMediaUploadedTime] = useState(null)

	const { data: recordingData, isSuccess: recordingSuccess } = useEpisodeRecording(episodeId)

	const resetRecordingFields = () => {
		setLoading(false)
		setMedia(null)
		setMediaFileName("")
		setMediaFileType("")
		setMediaFileSize(null)
		setMediaUploadedTime(null)
		setDuplicateRecordingID(null)
		// setIsRemoved(false)
		setIsReplacingRecording(false)
	}

	useEffect(() => {
		const url = recordingData?.recording?.url
		const fileName = recordingData?.recording?.fileName
		const fileType = recordingData?.recording?.fileType
		const fileSize = recordingData?.recording?.fileSize
		const uploadedTime = recordingData?.recording?.createdAt
		if (url && recordingSuccess) {
			setMedia(url)
			setMediaFileName(fileName)
			setMediaFileType(fileType)
			setMediaFileSize(fileSize)
			setMediaUploadedTime(moment(uploadedTime).format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT))
		} else {
			resetRecordingFields()
		}
	}, [recordingData, recordingSuccess])

	const handleCreateRecording = async recording => {
		try {
			await createRecording(recording)
			handleMarkAsDone("uploadAudio")
			queryClient.invalidateQueries(["recording", episodeId])
			queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
		} catch (e) {
			console.error(e)
			errorAlert(UNABLE_TO_CREATE_RECORDING)
		}
	}

	const handleRemoveRecording = async () => {
		try {
			const episodeId = recordingData.recording.episodeId
			await updateRecording({
				...recordingData.recording,
				episodeId: ""
			})
			queryClient.invalidateQueries(["recording", episodeId])
			queryClient.invalidateQueries(["unusedRecordings", selectedPodcast.id])
			resetRecordingFields()
			handleMarkAsInProgress("uploadAudio")
		} catch (e) {
			console.error(e)
			errorAlert(UNABLE_TO_REMOVE_RECORDING)
		}
	}

	const handleKeepBothRecordings = async (file, newFileName) => {
		try {
			setLoading(true)
			setMediaFileName(newFileName)
			setMediaFileType(file.type)
			setMediaFileSize(file.size)
			const resp = await getSignedUrl(
				process.env.REACT_APP_RECORDINGS_BUCKET,
				`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
				file.type
			)
			const uploadConfig = {
				url: resp?.data?.url,
				bucket: resp?.data?.bucket,
				object: resp?.data?.object
			}
			const url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			setMedia(url)
			setLoading(false)
		} catch (e) {
			errorAlert(UNABLE_TO_REPLACE_RECORDING)
		}
	}

	const handleReplaceRecording = async (file, duplicateRecordingID) => {
		setDuplicateRecordingID(duplicateRecordingID)
		try {
			setLoading(true)
			setMediaFileName(file.name)
			setMediaFileType(file.type)
			setMediaFileSize(file.size)
			const resp = await getSignedUrl(
				process.env.REACT_APP_RECORDINGS_BUCKET,
				`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
				file.type
			)
			const uploadConfig = {
				url: resp?.data?.url,
				bucket: resp?.data?.bucket,
				object: resp?.data?.object
			}
			const url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			setMedia(url)
			setLoading(false)
		} catch (e) {
			errorAlert(UNABLE_TO_REPLACE_RECORDING)
		}
	}

	const beforeUpload = async file => {
		return new Promise(async (resolve, reject) => {
			const { data } = await validateRecordingFileName(file.name, selectedPodcast.id)
			const isDuplicate = data.is_duplicate
			const isAssociatedWithEpisode = data.is_associated_with_episode
			const newFileName = data.new_file_name
			const duplicateRecordingID = data.duplicate_recording_id
			const associatedEpisodeID = data.associated_episode_id
			setIsSameFilename(associatedEpisodeID === episodeData.episode.id)
			if (isDuplicate) {
				if (isAssociatedWithEpisode && associatedEpisodeID !== episodeData.episode.id) {
					renameFileModal()
					reject()
				} else {
					duplicateFilenameModal(
						() => handleKeepBothRecordings(file, newFileName),
						() => handleReplaceRecording(file, duplicateRecordingID)
					)
					reject()
				}
			} else {
				const resp = await getSignedUrl(
					process.env.REACT_APP_RECORDINGS_BUCKET,
					`${uuidv4()}${mapFileTypeToExtension(file.type)}`,
					file.type
				)
				setFilename(file.name)
				setUploadConfig({
					url: resp?.data?.url,
					bucket: resp?.data?.bucket,
					object: resp?.data?.object
				})
				resolve()
			}
		})
	}

	// Chained with the beforeUpload() method of the main upload. Used for the Upload component of the "Replace" button.
	const beforeReplaceUpload = file => {
		setIsReplacingRecording(true)
		return beforeUpload(file)
	}

	const uploadMedia = async ({ file }) => {
		setLoading(true)
		try {
			const url = await uploadFile(uploadConfig, file, setProgress, uploadCancelTokenRef)
			setMedia(url)
			setMediaFileName(file.name)
			setMediaFileType(file.type)
			setMediaFileSize(file.size)
			successAlert(SUCCESSFULLY_CREATED_RECORDING)
		} catch (e) {
			errorAlert(UNABLE_TO_UPLOAD_RECORDING)
		}
		setLoading(false)
	}

	const handleLoadedMedia = async event => {
		const duration = event?.target?.duration
		if (duplicateRecordingID) {
			try {
				if (isReplacingRecording) {
					try {
						await updateRecording({
							...recordingData.recording,
							episodeId: ""
						})
						queryClient.invalidateQueries(["unusedRecordings", selectedPodcast?.id])
					} catch (e) {
						errorAlert(UNABLE_TO_REMOVE_RECORDING)
					}
				}
				const uploadedTime = moment()
				setMediaUploadedTime(uploadedTime.format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT))
				const recording = {
					url: media,
					duration: duration,
					fileName: mediaFileName,
					fileType: mediaFileType,
					fileSize: mediaFileSize,
					episodeId: episodeId,
					podcastId: selectedPodcast.id
				}
				await replaceRecording(duplicateRecordingID, recording)
				if (episodeData?.episode?.isPublished) {
					await updateEpisode({ ...episodeData.episode, publishedEpisodeModified: true })
					queryClient.invalidateQueries(["episode", episodeId])
				}

				queryClient.invalidateQueries(["unusedRecordings", selectedPodcast?.id])
				handleMarkAsDone("uploadAudio")
				setDuplicateRecordingID(null)
			} catch (e) {
				errorAlert(UNABLE_TO_REPLACE_RECORDING)
			}
		} else if (isReplacingRecording) {
			try {
				await updateRecording({
					...recordingData.recording,
					episodeId: "",
					isDeleted: isSameFilename && duplicateRecordingID ? true : false
				}).then(async () => {
					const uploadedTime = moment()
					setMediaUploadedTime(uploadedTime.format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT))
					const newRecording = {
						url: media,
						duration: duration,
						fileName: mediaFileName,
						fileType: mediaFileType,
						fileSize: mediaFileSize,
						episodeId: episodeId,
						podcastId: selectedPodcast.id
					}
					handleCreateRecording(newRecording)
					if (episodeData?.episode?.isPublished) {
						await updateEpisode({ ...episodeData.episode, publishedEpisodeModified: true })
						queryClient.invalidateQueries(["episode", episodeId])
					}
					queryClient.invalidateQueries(["unusedRecordings", selectedPodcast?.id])
					setIsReplacingRecording(false)
					setIsSameFilename(false)
				})
			} catch (e) {
				errorAlert(UNABLE_TO_REPLACE_RECORDING)
			}
		} else if (!recordingData?.recording) {
			try {
				const uploadedTime = moment()
				setMediaUploadedTime(uploadedTime.format(RECORDING_UPLOADED_TIME_DISPLAY_FORMAT))
				const recording = {
					url: media,
					duration: duration,
					fileName: mediaFileName,
					fileType: mediaFileType,
					fileSize: mediaFileSize,
					episodeId: episodeId,
					podcastId: selectedPodcast.id
				}
				handleCreateRecording(recording)
				queryClient.invalidateQueries(["unusedRecordings", selectedPodcast?.id])
				if (episodeData?.episode?.isPublished) {
					await updateEpisode({ ...episodeData.episode, publishedEpisodeModified: true })
					queryClient.invalidateQueries(["episode", episodeId])
				}
			} catch (e) {
				errorAlert(UNABLE_TO_CREATE_RECORDING)
			}
		}
	}

	return {
		beforeUpload,
		loading,
		setLoading,
		media,
		setMedia,
		handleLoadedMedia,
		progress,
		filename,
		beforeReplaceUpload,
		uploadMedia,
		mediaFileName,
		mediaUploadedTime,
		handleRemoveRecording,
		isReplacingRecording,
		isSameFilename,
		duplicateRecordingID
		// recording,
		// handlePublish,
	}
}
