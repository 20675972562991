import {
	ADD_CHANGE_TO_GET_STARTED_FIELD,
	FIREBASE_USER_AUTH_CREATION_LOADING,
	FIREBASE_USER_AUTH_CREATION_SUCCESS,
	FIREBASE_USER_AUTH_CREATION_ERROR,
	DATASTORE_USER_ENTITY_CREATION_SUCCESS,
	DATASTORE_USER_ENTITY_CREATION_ERROR,
	TOGGLE_LOADING_SPINNER_OVERLAY_VISIBLE,
	TOGGLE_TERMS_AND_CONDITIONS_MODAL_VISIBLE
} from "constants/actionTypes"
import { createUser } from "services/user.service"
import firebase from "auth/firebase"
import moment from "moment"

export const addChangeToField = (fieldName, fieldValue) => ({
	type: ADD_CHANGE_TO_GET_STARTED_FIELD,
	payload: {
		fieldName: fieldName,
		fieldValue: fieldValue
	}
})

export const signupUser = ({ email, password }) => async dispatch => {
	dispatch({
		type: FIREBASE_USER_AUTH_CREATION_LOADING
	})

	return firebase.auth.createUserWithEmailAndPassword(email, password)
}

export const createDataStoreUser = ({ id, email, firstName, lastName }) => async dispatch => {
	dispatch({
		type: FIREBASE_USER_AUTH_CREATION_LOADING
	})
	return createUser({
		id,
		email,
		firstName,
		lastName,
		isBetaUser: false // TODO: Remove after private beta
	})
}

export const signupUserWithGoogle = () => async dispatch => {
	dispatch({
		type: FIREBASE_USER_AUTH_CREATION_LOADING
	})

	let provider = firebase.googleAuthProvider
	firebase.auth.useDeviceLanguage()

	await firebase.auth.signInWithRedirect(provider)
}

export const handleFirebaseRedirect = firebaseResponse => async dispatch => {
	dispatch({
		type: FIREBASE_USER_AUTH_CREATION_SUCCESS,
		payload: {
			authUser: firebaseResponse.user,
			firebase: firebaseResponse
		}
	})
	const provider = firebaseResponse.additionalUserInfo.providerId
	const { profile } = firebaseResponse.additionalUserInfo
	let firstName, lastName, birthday, thumbnailUrl

	switch (provider) {
		case "google.com":
			firstName = profile.given_name
			lastName = profile.family_name
			thumbnailUrl = profile.picture
			break
		case "facebook.com":
			firstName = profile.first_name
			lastName = profile.last_name
			birthday = profile.birthday ? moment(profile.birthday).format("YYYY-MM-DD") : null
			thumbnailUrl = profile.picture.data.url
			break
		default:
			break
	}

	await createUser({
		id: firebaseResponse.user.uid,
		email: profile.email,
		firstName: firstName,
		lastName: lastName,
		username: "",
		bio: "",
		dateOfBirth: birthday,
		thumbnailUrl: thumbnailUrl,
		userProfileUrl: "",
		isBetaUser: false // TODO: Remove after private beta
	})
		.then(res => {
			dispatch({
				type: DATASTORE_USER_ENTITY_CREATION_SUCCESS
			})
		})
		.catch(err => {
			dispatch({
				type: FIREBASE_USER_AUTH_CREATION_ERROR,
				payload: {
					error: err
				}
			})
		})
}

export const signupUserWithFacebook = () => async dispatch => {
	dispatch({
		type: FIREBASE_USER_AUTH_CREATION_LOADING
	})

	let provider = firebase.facebookAuthProvider
	provider.addScope("user_birthday")
	firebase.auth.useDeviceLanguage()
	await firebase.auth.signInWithRedirect(provider)
}

export const userSignupSuccess = () => ({
	type: DATASTORE_USER_ENTITY_CREATION_SUCCESS
})

export const userSignupError = error => ({
	type: DATASTORE_USER_ENTITY_CREATION_ERROR,
	payload: {
		error: error
	}
})

export const toggleLoadingSpinnerOverlayVisible = () => ({
	type: TOGGLE_LOADING_SPINNER_OVERLAY_VISIBLE
})

export const toggleTermsAndConditionsModalVisible = () => ({
	type: TOGGLE_TERMS_AND_CONDITIONS_MODAL_VISIBLE
})
