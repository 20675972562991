import {
	ADD_CHANGE_TO_LOGIN_FIELD,
	USER_LOGIN_LOADING,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_ERROR,
	FORGOT_PASSWORD_LOADING,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_ERROR,
	TOGGLE_FORGOT_PASSWORD_MODAL
} from "constants/actionTypes"

const initialState = {
	email: "",
	password: "",
	loading: false,
	error: "",
	forgotPasswordError: "",
	forgotPasswordLoading: false,
	forgotPasswordSuccess: false,
	forgotPasswordModalVisible: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case ADD_CHANGE_TO_LOGIN_FIELD:
			const newState = { ...state }
			newState[action.payload.fieldName] = action.payload.fieldValue
			return newState

		case USER_LOGIN_LOADING:
			// TODO: Please please change the state structure for this one. Can't use the same loading for both.
			return {
				...state,
				loading: true
			}

		case USER_LOGIN_SUCCESS:
			return {
				...state
			}

		case USER_LOGIN_ERROR:
			return {
				...state,
				error: action.payload.error
			}

		case FORGOT_PASSWORD_LOADING:
			return {
				...state,
				forgotPasswordLoading: true
			}

		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				forgotPasswordLoading: false,
				forgotPasswordSuccess: true
			}

		case FORGOT_PASSWORD_ERROR:
			return {
				...state,
				forgotPasswordLoading: false,
				forgotPasswordError: action.payload
			}
		case TOGGLE_FORGOT_PASSWORD_MODAL:
			return {
				...state,
				forgotPasswordModalVisible: !state.forgotPasswordModalVisible
			}
		default:
			return state
	}
}
