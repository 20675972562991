import { useQuery } from "react-query"
import { findRecordingByEpisodeId, findRecordingsByPodcastId } from "services/recording.service"
import { keysToCamel } from "utils/camelCaseUtils"

export const useEpisodeRecording = episodeId => {
	return useQuery(
		["recording", episodeId],
		async () => {
			if (!episodeId) return null
			try {
				const { data } = await findRecordingByEpisodeId(episodeId)
				const camelResponse = keysToCamel(data)
				return camelResponse
			} catch (e) {
				return null
			}
		},
		{ enabled: !!episodeId }
	)
}

export const usePodcastRecordings = podcastId => {
	return useQuery(
		["recordings", podcastId],
		async () => {
			if (!podcastId) return null
			try {
				const { data } = await findRecordingsByPodcastId(podcastId)
				const camelResponse = keysToCamel(data)
				return camelResponse
			} catch (e) {
				return null
			}
		},
		{ enabled: !!podcastId }
	)
}

export const useUnusedPodcastRecordings = (podcastId, queryOptions) => {
	return useQuery(
		["unusedRecordings", podcastId],
		async () => {
			if (!podcastId) return null
			try {
				const { data } = await findRecordingsByPodcastId(podcastId, queryOptions)
				const camelResponse = keysToCamel(data)
				return camelResponse
			} catch (e) {
				return null
			}
		},
		{ enabled: !!podcastId }
	)
}
