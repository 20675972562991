import React from "react"
import { Modal, Row, Col } from "antd"
import { DangerCrossIcon } from "assets/icons/Episodes"

export const ownerEmailNotFoundModal = () => {
	Modal.error({
		title: "Owner email not found!",
		centered: true,
		icon: <DangerCrossIcon />,
		content: (
			<>
				<Row gutter={[0, 8]}>
					<Col>
						We attempted to import the RSS Feed you provided but found that the <strong>“Owner Email”</strong> was missing.
						Please updated the <strong>“Owner Email”</strong> at your old host in order to import this podcast.
					</Col>
				</Row>
			</>
		),
		width: 420
	})
}
