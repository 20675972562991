import { PauseIcon, PlayIcon, XIcon } from "assets/icons/Common/SimpleFeedback"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { DEFAULT_PODCAST_ARTWORK_URL } from "constants/strings"
import React, { useRef, useState } from "react"
import ReactPlayer, { ReactPlayerProps } from "react-player"
import BaseReactPlayer from "react-player/base"
import styled from "styled-components"
import { useMediaPlayer } from "../../../hooks/useMediaPlayer"
import { Duration } from "./Duration"

const StyledMediaPlayer = styled.div`
	position: absolute;
	background: ${({ theme }) => theme.neutral3};
	height: 80px;
	width: 100vw;
	display: grid;
	bottom: 0;
	grid-template-columns: 1fr 3fr 60px;
	align-items: center;
	padding: 0px 16px;

	.current-media-details {
		color: ${({ theme }) => theme.neutral0};
		display: grid;
		grid-template-columns: 60px 1fr;
		column-gap: 1rem;

		.episode-artwork {
			height: 60px;
			width: 60px;
		}
		.episode-details {
			display: grid;
			p {
				color: ${({ theme }) => theme.neutral0};
			}

			.episode-season-number {
				display: inline-flex;
				flex-wrap: nowrap;
			}
		}
	}

	.play-and-seek {
		display: grid;
		column-gap: 1rem;
		align-items: center;
		grid-template-columns: max-content 3fr 1fr;

		.duration {
			color: ${({ theme }) => theme.neutral0};
		}
	}
`

interface StyledMediaContainerProps {
	visible: boolean
}

const StyledMediaContainer = styled.div.attrs((props: StyledMediaContainerProps) => ({
	visible: props.visible
}))`
	${({ visible }) =>
		!visible &&
		`
		display: none;
	`}

	.react-player {
		position: absolute;
		bottom: calc(60px + 1rem);
		right: 1rem;
	}
`

export function MediaPlayer() {
	const [played, setPlayed] = useState(0)
	const [playedSeconds, setPlayedSeconds] = useState(0)
	const [seeking, setSeeking] = useState(false)
	const [duration, setDuration] = useState(0)

	const {
		currentMedia,
		setCurrentMedia,
		episode,
		podcast,
		isPlaying,
		setIsPlaying,
		visible,
		setVisible
	} = useMediaPlayer()

	const playerRef = useRef<BaseReactPlayer<ReactPlayerProps>>(null)

	const SeasonEpisodeText = `${podcast?.title ?? ""} ${
		episode?.seasonNumber ? `| Season ${episode.seasonNumber}` : ""
	} ${episode?.episodeNumber ? `| Episode ${episode.episodeNumber}` : ""}`

	return (
		<StyledMediaContainer visible={visible}>
			<ReactPlayer
				width={320}
				height={180}
				controls={currentMedia?.fileType.includes("video") ? true : false}
				className="react-player"
				ref={playerRef}
				url={currentMedia?.url ?? ""}
				onSeek={e => console.log("onSeek", e)}
				playing={isPlaying}
				onDuration={(duration: number) => setDuration(duration)}
				onProgress={({ played, playedSeconds }) => {
					if (!seeking) {
						setPlayed(played)
						setPlayedSeconds(playedSeconds)
					}
				}}
			/>
			<StyledMediaPlayer>
				<div className="current-media-details">
					<img alt={episode?.title} src={episode?.photoUrl ?? DEFAULT_PODCAST_ARTWORK_URL} className="episode-artwork" />
					<div className="episode-details">
						<div className="episode-season-number">
							<p>{SeasonEpisodeText}</p>
						</div>
						<p>{episode?.title}</p>
					</div>
				</div>
				<div className="play-and-seek">
					<div>
						<PodpalButton
							size="large"
							shape="circle"
							variant="dark"
							onClick={() => {
								setIsPlaying(prevState => !prevState)
								setVisible(true)
							}}
							icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
						/>
					</div>
					<input
						type="range"
						min={0}
						max={0.999999}
						step="any"
						value={played}
						onMouseDown={() => setSeeking(true)}
						onChange={e => setPlayed(parseFloat(e.target.value))}
						onMouseUp={(e: React.MouseEvent<HTMLInputElement>) => {
							setSeeking(false)
							if (e && e.target) {
								const value = (e.target as HTMLInputElement).value

								playerRef.current?.seekTo(parseFloat(value))
							}
						}}
					/>
					<p className="duration">
						<Duration seconds={playedSeconds} /> {" / "}
						<Duration seconds={duration} />
					</p>
				</div>
				<div>
					<PodpalButton
						size="large"
						shape="circle"
						variant="dark"
						onClick={() => {
							setVisible(false)
							setIsPlaying(false)
							setCurrentMedia(null)
						}}
						icon={<XIcon />}
					/>
				</div>
			</StyledMediaPlayer>
		</StyledMediaContainer>
	)
}
