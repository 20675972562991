import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Badge, Popover, Divider, Button } from "antd"
import { NotificationIcon } from "assets/icons/Navbar"
import { RootState } from "../../../index"
import Notification from "./Notification"
import NoNotificationImage from "assets/navbar/no-notifications.svg"
import {
	deleteAllNotificationsAction,
	markAllNotificationsReadAction,
	retrieveNotificationsAction,
	appendNewNotificationsAction,
	updateUnreadNotificationsCountAction
} from "redux/actions/Navbar/NotificationsDropdown"
import "./NotificationsDropdown.scss"
import { v4 as uuidv4 } from "uuid"
import { findNotificationsByUserId, deleteNotifications, markNotificationsRead } from "services/notifications.service"
import { useNotificationsRefresh, useGetUnreadNotificationsCount } from "services/queries/Notifications"

const NOTIFICATIONS_COUNTER_SIZE = 99
const NOTIFICATIONS_PAGE_SIZE = 20

export const NotificationsDropdown: React.FC = () => {
	const dispatch = useDispatch()

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const userId = React.useMemo(() => authUser.uid as string, [authUser])

	const notifications = useSelector((state: RootState) => state.notifications.notifications)
	const unreadNotificationsCount = useSelector((state: RootState) => state.notifications.unreadNotificationsCount)

	const loadMoreNotificationsLink = React.useRef<string>("")
	const [loadedAllOlderNotifications, setLoadedAllOlderNotifications] = React.useState(false)

	const { data: refreshNotificationsData } = useNotificationsRefresh(userId)
	const { data: unreadNotificationsCountData } = useGetUnreadNotificationsCount(userId)

	React.useEffect(() => {
		if (unreadNotificationsCountData?.count) {
			dispatch(updateUnreadNotificationsCountAction(unreadNotificationsCountData?.count))
		}
	}, [unreadNotificationsCountData])

	React.useEffect(() => {
		const userNotifications = refreshNotificationsData?.notifications || []
		if (userNotifications.length) {
			dispatch(retrieveNotificationsAction(userNotifications))
		}
		if (!loadMoreNotificationsLink.current && !loadedAllOlderNotifications) {
			loadMoreNotificationsLink.current = refreshNotificationsData?.query_meta?.cursor ?? ""
		}
	}, [refreshNotificationsData, loadedAllOlderNotifications]) // can't list "notifications" variable as a dependency here or else we'll get infinite re-render

	const onViewOlderNotificationsClicked = React.useCallback(async () => {
		const { data: viewOlderNotificationsData } = await findNotificationsByUserId({
			request_id: uuidv4(),
			user_id: userId,
			query_options: {
				cursor: loadMoreNotificationsLink.current,
				limit: NOTIFICATIONS_PAGE_SIZE
			}
		})

		const olderNotifications = viewOlderNotificationsData?.notifications ?? []
		if (olderNotifications.length) {
			dispatch(appendNewNotificationsAction(olderNotifications))
		} else {
			setLoadedAllOlderNotifications(true)
		}
		loadMoreNotificationsLink.current = viewOlderNotificationsData?.query_meta?.cursor ?? ""
	}, [userId])

	const unreadNotifications = notifications.filter(notification => notification.status === "UNREAD")

	const notificationsComponents = notifications.map(notification => (
		<Notification key={notification.id} notification={notification} userId={userId} />
	))

	const hasUnreadNotifications = unreadNotifications.length > 0
	const noNotifications = notifications.length === 0
	const notificationFunctionText = hasUnreadNotifications ? "Mark all as read" : "Delete all"

	const notificationFunction = React.useCallback(() => {
		if (hasUnreadNotifications) {
			// marking as read
			markNotificationsRead({
				request_id: uuidv4(),
				user_id: userId,
				notification_ids: notifications
					.filter(notification => notification.status === "UNREAD")
					.map(notification => notification.id)
			}).then(result => {
				if (result.data.success) {
					dispatch(markAllNotificationsReadAction(result.data.marked_notifications ?? []))
					if (!loadedAllOlderNotifications) {
						onViewOlderNotificationsClicked()
					}
				}
			})
		} else {
			// deleting notifications
			deleteNotifications({
				request_id: uuidv4(),
				user_id: userId,
				notification_ids: notifications.map(notification => notification.id)
			}).then(result => {
				if (result.data.success) {
					dispatch(deleteAllNotificationsAction())
					if (!loadedAllOlderNotifications) {
						onViewOlderNotificationsClicked()
					}
				}
			})
		}
	}, [hasUnreadNotifications, notifications, loadedAllOlderNotifications])

	const notificationsHeader = (
		<div className="notifications-overlay">
			<div className="top-bar-popover-header-container">
				<div className="top-bar-popover-header-row">
					<div className="notifications-text">Notifications</div>
				</div>
				<div className="top-bar-popover-header-row">
					<div className="recent-activity-text">Recent Activity</div>
					<div className="mark-all-read-text" onClick={notificationFunction}>
						{notificationFunctionText}
					</div>
				</div>
			</div>
			<Divider className="top-bar-popover-header-divider" />
			<div className="notifications-container">
				{notificationsComponents}
				{!loadedAllOlderNotifications ? (
					<div className="notification-card" style={{ display: "flex", justifyContent: "center" }}>
						{
							<Button style={{ width: "100%" }} onClick={onViewOlderNotificationsClicked}>
								View older notifications
							</Button>
						}
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)

	const noNewNotificationsHeader = (
		<div className="no-notifications-overlay">
			<div className="top-bar-popover-header-container">
				<div className="notifications-text">Notifications</div>
			</div>
			<div className="no-new-notifications-image">
				<img src={NoNotificationImage} alt="No new notifications" />
			</div>
			<div className="no-new-notifications-text-container">
				<strong>Nothing new right now. </strong>
				<br />
				Start planning and get updates here!
			</div>
		</div>
	)

	return (
		<Popover
			className="notifications-dropdown"
			trigger="click"
			content={noNotifications ? noNewNotificationsHeader : notificationsHeader}
			placement="bottomRight"
			arrowPointAtCenter
		>
			<Badge count={unreadNotificationsCount} overflowCount={NOTIFICATIONS_COUNTER_SIZE}>
				<NotificationIcon className="notifications-icon" onClick={() => undefined} />
			</Badge>
		</Popover>
	)
}
