export enum MigrationVerificationStatus {
	pending = "PENDING",
	verified = "VERIFIED",
	canceled = "CANCELED"
}

export interface MigrationVerification {
	id?: string
	code?: number
	migrationVerificationStatus?: MigrationVerificationStatus
	ownerEmail?: string
	podcastName?: string
}

export interface FindMigrationVerificationResponse {
	requestId: string
	migrationVerification: MigrationVerification
}
