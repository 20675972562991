import React from "react"
import PodpalModal from "components/common/Modals/PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import styled from "styled-components"
import InstagramLogo from "assets/icons/social-media/instagram.svg"
import FacebookLogo from "assets/icons/social-media/facebook.svg"
import TwitterLogo from "assets/icons/social-media/twitter.svg"
import LinkedinLogo from "assets/icons/social-media/linkedin.svg"

const StyledList = styled.ul`
	list-style-type: none;
	margin: 0 30%;

	@media only screen and (max-width: ${({ theme }) => theme.xs}) {
		margin: 0;
	}

	li {
		padding: 1em 0;
		display: flex;
		align-items: center;
		justify-content: start;
		span {
			padding-right: ${({ theme }) => theme.spaceSm};
		}
		a {
			color: ${({ theme }) => theme.textPrimary};

			&:hover,
			&:focus {
				color: ${({ theme }) => theme.podpalBlue};
				text-decoration: underline;
			}
		}
	}
`

interface PalSocialMediaModalProps {
	instagramHandle?: string
	facebookHandle?: string
	twitterHandle?: string
	linkedinHandle?: string
	onClose: () => void
	visible: boolean
}

export function PalSocialMediaModal({
	instagramHandle,
	facebookHandle,
	twitterHandle,
	linkedinHandle,
	onClose,
	visible
}: PalSocialMediaModalProps) {
	return (
		<PodpalModal
			title="Follow Me On..."
			onCancel={onClose}
			onOk={onClose}
			footer={[
				<PodpalButton key="closeButton" type="primary" onClick={onClose}>
					Close
				</PodpalButton>
			]}
			visible={visible}
			centered
		>
			<StyledList>
				{facebookHandle && (
					<li key="facebook">
						<span>
							<img src={FacebookLogo} alt="Apple Podcasts" />
						</span>
						<a href={`https://facebook.com/${facebookHandle}`} target="_blank" rel="noreferrer noopener">
							Facebook
						</a>
					</li>
				)}
				{instagramHandle && (
					<li key="instagram">
						<span>
							<img src={InstagramLogo} alt="Spotify" />
						</span>
						<a href={`https://instagram.com/${instagramHandle}`} target="_blank" rel="noreferrer noopener">
							Instagram
						</a>
					</li>
				)}
				{twitterHandle && (
					<li key="twitter">
						<span>
							<img src={TwitterLogo} alt="Google Podcasts" />
						</span>
						<a href={`https://twitter.com/${twitterHandle}`} target="_blank" rel="noreferrer noopener">
							Twitter
						</a>
					</li>
				)}
				{linkedinHandle && (
					<li key="linkedIn">
						<span>
							<img src={LinkedinLogo} alt="Amazon Music" />
						</span>
						<a href={`https://linkedin.com/in/${linkedinHandle}`} target="_blank" rel="noreferrer noopener">
							LinkedIn
						</a>
					</li>
				)}
			</StyledList>
		</PodpalModal>
	)
}
