import styled, { css } from "styled-components"
import { Button } from "antd"

export interface PodpalButtonProps {
	type?: string
	fullwidth?: boolean
	variant?: string
	size: "small" | "middle" | "large" | "x-large" | undefined
	isxl?: boolean
}

const PodpalButton = styled(Button).attrs((props: PodpalButtonProps) => ({
	type: props.type || "",
	fullwidth: props.fullwidth,
	variant: props.variant || "",
	size: props.size,
	isxl: props.isxl
}))`
	border-radius: ${props => (props.shape !== "round" && props.shape !== "circle" ? "4px" : "500px")};
	border-color: ${props => (props.type === "primary" ? props.theme.podpalBlue : "#fff")};
	width: ${props => (props.fullwidth ? "100%" : "")};
	background: ${props => (props.type === "primary" ? props.theme.podpalBlue : "#fff")};

	// X-Large Size
	${props =>
		props.isxl &&
		css`
			height: 50px;
		`}

	// Circle shape
	${props =>
		props.shape === "circle" &&
		css`
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
		`}
		background: ${props => props.shape === "circle" && "unset"};

	// X-Large Size Rounded
	${props =>
		props.isxl &&
		props.shape === "round" &&
		css`
			border-radius: 500px;
			font-size: 16px;
		`}

	// Link
    ${props =>
					props.type === "link" &&
					css`
						padding: 0;
						background: transparent;
						border: none;
					`}

    // Primary Link Button
    ${props =>
					props.type === "link" &&
					props.variant === "primary" &&
					css`
						padding-right: 0;
						padding-left: 0;
						color: ${props.theme.podpalBlue};
						border: none;
						&&:hover,
						&&:focus,
						&&:active {
							color: ${props.theme.podpalBlueHoverDark};
						}
					`}

    // Default Button
    ${props =>
					!props.type &&
					css`
						color: ${props.theme.textSecondary};
						border-color: #d9d9d9;
						&:hover,
						&:focus,
						&:active {
							border-color: ${props.theme.podpalBlueHoverDark};
							color: ${props.theme.podpalBlueHoverDark};
							background: ${props.theme.podpalBlueHoverDark};
						}
					`}

		// Primary Button
    ${props =>
					props.type === "primary" &&
					css`
						color: ${props.theme.neutral0};
						border-color: ${props.theme.podpalBlue};
						background: ${props.theme.podpalBlue};
						&:hover,
						&:focus,
						&:active {
							border-color: ${props.theme.podpalBlueHoverDark};
							color: ${props.theme.neutral0};
							background: ${props.theme.podpalBlueHoverDark};
						}
					`}

    // Secondary Button
    ${props =>
					props.variant === "secondary" &&
					css`
						color: ${props.theme.textSecondary};
						border-color: ${props.theme.neutral2};
						background-color: ${props.theme.neutral0};
						&:hover,
						&:focus,
						&:active {
							color: ${props.theme.textSecondary};
							border-color: ${props.theme.neutral2};
							background-color: ${props.theme.neutral1};
						}
					`}

    // White Button
    ${props =>
					props.variant === "white" &&
					css`
						color: ${props.theme.neutral0};
						border-color: ${props.theme.neutral0};
						background-color: transparent;
						&&:hover,
						&&:focus,
						&&:active {
							color: ${props.theme.textSecondary};
							border-color: ${props.theme.neutral2};
							background-color: ${props.theme.neutral1};
						}
					`}
	// Transparent Button
	${props => {
		// console.log(props.theme)
		return (
			props.variant === "transparent" &&
			css`
				color: ${props.theme.textPrimary};
				border-color: ${props.theme.neutral0};
				background-color: transparent;
				padding: 4px;
				&&:hover,
				&&:focus,
				&&:active {
					border-color: ${props.theme.neutral0};
					background-color: ${props.theme.gray4};
				}
			`
		)
	}}
		
	${props =>
		props.variant === "reaction" &&
		css`
			color: ${props.theme.textPrimary};
			border-color: ${props.theme.gray4};
			background-color: white;
			width: 41px;
			display: flex;
			border-radius: 40px;
			&&:hover,
			&&:focus,
			&&:active {
				border-color: ${props.theme.neutral0};
				background-color: ${props.theme.gray4};
			}
		`}}
    
    // Primary Outlined Button
    ${props =>
					props.type === "primary" &&
					props.variant === "outlined" &&
					css`
						color: ${props.theme.podpalBlue};
						border-color: ${props.theme.podpalBlue};
						background-color: ${props.theme.neutral0};
					`}

    // Danger Button
    ${props =>
					props.danger &&
					css`
						background: ${props.theme.podpalRedDark};
						border-color: ${props.theme.podpalRedDark};
						color: ${props.theme.neutral0};
						&:hover,
						&:focus,
						&:active {
							background: ${props.theme.podpalRedExtraDark};
							border-color: ${props.theme.podpalRedExtraDark};
							color: ${props.theme.neutral0};
						}
					`}

		// Danger Outlined Button
    ${props =>
					props.danger &&
					props.variant === "outlined" &&
					css`
						background: ${props.theme.neutral0};
						border-color: ${props.theme.podpalRedDark};
						color: ${props.theme.podpalRedDark};
						&:hover,
						&:focus,
						&:active {
							background: ${props.theme.podpalRedExtraDark};
							border-color: ${props.theme.podpalRedExtraDark};
							color: ${props.theme.neutral0};
						}
					`}
	
		// Success Button
	${props =>
		props.success &&
		css`
			background: ${props.theme.podpalGreenLight};
			border-color: ${props.theme.podpalGreenLight};
			color: ${props.theme.neutral0};
			&:hover,
			&:focus,
			&:active {
				background: ${props.theme.podpalGreenDark};
				border-color: ${props.theme.podpalGreenDark};
				color: ${props.theme.neutral0};
			}
		`}

		// Success Outlined Button
		${props =>
			props.success &&
			props.variant === "outlined" &&
			css`
				color: ${props.theme.podpalGreenLight};
				border-color: ${props.theme.podpalGreenLight};
				background-color: ${props.theme.neutral0};
			`}

		// Blue Ghost button -- Used for Restore button in episode progress
		${props =>
			props.variant === "blueGhost" &&
			css`
				color: ${props.theme.podpalBlue};
				border-color: ${props.theme.podpalBlueHoverLight};
				background-color: ${props.theme.podpalBlueGhost};
				&:hover,
				&:focus,
				&:active {
					color: ${props.theme.neutral0};
					border-color: ${props.theme.podpalBlueHoverLight};
					background-color: ${props.theme.podpalBlueHoverLight};
				}
			`}

		// Primary Light -- Used for Add task button in episode checklist
		${props =>
			props.variant === "primaryLight" &&
			css`
				color: ${props.theme.podpalBlue};
				border: none;
				background-color: ${props.theme.podpalBlueGhost};
				&:hover,
				&:focus,
				&:active {
					color: ${props.theme.podpalBlueHoverDark};
					border: none;
					background-color: ${props.theme.podpalBlueGhost};
				}
			`}

		// Primary Light -- Used for Add task button in episode checklist
		${props =>
			props.variant === "dark" &&
			css`
				color: ${props.theme.neutral0};
				border: none;
				background-color: ${props.theme.neutral3};

				& svg path {
					fill: ${props.theme.neutral0};
				}
				&:hover,
				&:focus,
				&:active {
					color: ${props.theme.neutral0};
					border: none;
					background-color: ${props.theme.textSecondary};
				}
			`}
		${props =>
			props.variant === "comingSoon" &&
			css`
				background: linear-gradient(0.25turn, #4e73ff, #3ab8a7);
				color: #fff;
				border-radius: 200px;
				border-color: #fff;

				&:hover,
				&:focus,
				&:active {
					background: linear-gradient(0.25turn, #4e73ff, #3ab8a7);
					color: #fff;
					border-radius: 200px;
					border-color: #fff;
					cursor: default;
				}
			`}
`

export default PodpalButton
