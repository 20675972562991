import {
	SET_EPISODE_ID_FOR_LAST_PUBLISHED_DATE_MODAL,
	SET_EPISODE_ID_FOR_RELEASE_DATE_MODAL,
	SET_EPISODE_ID_FOR_SCHEDULED_RELEASE_DATE_MODAL,
	TOGGLE_CHANGE_LAST_PUBLISHED_DATE_MODAL_VISIBLE,
	TOGGLE_CHANGE_PLANNED_RELEASE_DATE_MODAL_VISIBLE,
	TOGGLE_CHANGE_SCHEDULED_RELEASE_DATE_MODAL_VISIBLE,
	TOGGLE_DELETE_EPISODE_MODAL_VISIBLE,
	SET_EPISODE_FOR_DELETE_EPISODE_MODAL
} from "../../../constants/actionTypes"

const initialState = {
	changeLastPublishedDateModalVisible: false,
	changePlannedReleaseDateModalVisible: false,
	changeScheduledReleaseDateModalVisible: false,
	episodeIdForPlannedReleaseDate: null,
	scheduledReleaseDateModalEpisodeID: null,
	episodeIdForLastPublishedDate: null,
	deleteEpisodeModalVisible: false,
	episodeForDeleteEpisodeModal: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_EPISODE_ID_FOR_LAST_PUBLISHED_DATE_MODAL:
			return {
				...state,
				episodeIdForLastPublishedDate: action.payload.episodeId
			}
		case SET_EPISODE_ID_FOR_RELEASE_DATE_MODAL:
			return {
				...state,
				episodeIdForPlannedReleaseDate: action.payload.episodeId
			}
		case SET_EPISODE_ID_FOR_SCHEDULED_RELEASE_DATE_MODAL:
			return {
				...state,
				scheduledReleaseDateModalEpisodeID: action.payload.episodeId
			}
		case TOGGLE_CHANGE_LAST_PUBLISHED_DATE_MODAL_VISIBLE:
			return {
				...state,
				changeLastPublishedDateModalVisible: !state.changeLastPublishedDateModalVisible
			}
		case TOGGLE_CHANGE_PLANNED_RELEASE_DATE_MODAL_VISIBLE:
			return {
				...state,
				changePlannedReleaseDateModalVisible: !state.changePlannedReleaseDateModalVisible
			}
		case TOGGLE_CHANGE_SCHEDULED_RELEASE_DATE_MODAL_VISIBLE:
			return {
				...state,
				changeScheduledReleaseDateModalVisible: !state.changeScheduledReleaseDateModalVisible
			}
		case TOGGLE_DELETE_EPISODE_MODAL_VISIBLE:
			return {
				...state,
				deleteEpisodeModalVisible: !state.deleteEpisodeModalVisible
			}
		case SET_EPISODE_FOR_DELETE_EPISODE_MODAL:
			return {
				...state,
				episodeForDeleteEpisodeModal: action.payload
			}
		default:
			return state
	}
}
