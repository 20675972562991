import React from "react"
import PodpalButton from "components/common/Buttons/PodpalButton"
import UploadRecordingCard from "components/common/Cards/UploadRecordingCard"
import { useSelector, useDispatch } from "react-redux"
import { toggleUploadRecordingModalVisible } from "redux/actions/Recordings"
import PodpalModal from "../../PodpalModal"

const UploadRecordingModal = () => {
	const dispatch = useDispatch()

	const visible = useSelector(state => state.recording.uploadRecordingModalVisible)
	const progress = useSelector(state => state.recording.uploadRecordingProgress)
	const config = useSelector(state => state.recording.uploadRecordingConfig)

	return (
		<PodpalModal
			centered
			maskClosable={false}
			closable={false}
			title="Upload Recording"
			visible={visible}
			width={420}
			footer={[
				<PodpalButton
					variant="secondary"
					onClick={() => {
						dispatch(toggleUploadRecordingModalVisible())
						config.uploadCancelTokenRef.current.cancel()
					}}
				>
					Cancel
				</PodpalButton>
			]}
		>
			<UploadRecordingCard
				filename={config?.filename}
				progress={progress}
				hasCancelButton={false}
				ref={config?.uploadCancelTokenRef}
			/>
		</PodpalModal>
	)
}

export default UploadRecordingModal
