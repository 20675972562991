import axios from "axios"
import {
	CreateNotificationRequest,
	CreateNotificationResponse,
	FindNotificationsByUserIdRequest,
	FindNotificationByUserIdResponse,
	GetUnreadNotificationsCountRequest,
	GetUnreadNotificationsCountResponse,
	MarkNotificationReadRequest,
	MarkNotificationReadResponse,
	MarkNotificationsReadRequest,
	MarkNotificationsReadResponse,
	DeleteNotificationRequest,
	DeleteNotificationResponse,
	DeleteNotificationsRequest,
	DeleteNotificationsResponse
} from "services/types/notifications"

export const createNotification = (createNotificationRequest: CreateNotificationRequest) =>
	axios.post<CreateNotificationResponse>("/api/twirp/podpal.notifications.NotificationService/Create", {
		...createNotificationRequest
	})

export const findNotificationsByUserId = (findNotificationsByUserIdRequest: FindNotificationsByUserIdRequest) =>
	axios.post<FindNotificationByUserIdResponse>("/api/twirp/podpal.notifications.NotificationService/FindByUserId", {
		...findNotificationsByUserIdRequest
	})

export const getUnreadNotificationsCount = (getUnreadNotificationsCountRequest: GetUnreadNotificationsCountRequest) =>
	axios.post<GetUnreadNotificationsCountResponse>(
		"/api/twirp/podpal.notifications.NotificationService/GetUnreadNotificationsCount",
		{
			...getUnreadNotificationsCountRequest
		}
	)

export const markNotificationRead = (markNotficationReadRequest: MarkNotificationReadRequest) =>
	axios.post<MarkNotificationReadResponse>("/api/twirp/podpal.notifications.NotificationService/MarkNotificationRead", {
		...markNotficationReadRequest
	})

export const deleteNotification = (deleteNotificationRequest: DeleteNotificationRequest) =>
	axios.post<DeleteNotificationResponse>("/api/twirp/podpal.notifications.NotificationService/DeleteNotification", {
		...deleteNotificationRequest
	})

export const markNotificationsRead = (markNotficationsReadRequest: MarkNotificationsReadRequest) =>
	axios.post<MarkNotificationsReadResponse>(
		"/api/twirp/podpal.notifications.NotificationService/MarkNotificationsRead",
		{
			...markNotficationsReadRequest
		}
	)

export const deleteNotifications = (deleteNotificationsRequest: DeleteNotificationsRequest) =>
	axios.post<DeleteNotificationsResponse>("/api/twirp/podpal.notifications.NotificationService/DeleteNotifications", {
		...deleteNotificationsRequest
	})
