import { Checkbox, Col, Input, Row, Form } from "antd"
import { XIcon } from "assets/icons/Common/SimpleFeedback"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalCard from "components/common/Cards/PodpalCard"
import React, { useRef } from "react"
import { useQueryClient } from "react-query"
import { useParams } from "react-router"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { updateEpisodeChecklist } from "services/episodeChecklist.service"
import { useEpisodeChecklist } from "services/queries/EpisodeChecklist"
import useDragNDrop from "hooks/useDragNDrop"
import classNames from "classnames"
import { EpisodeManagementTooltip } from "./EpisodeManagementTooltip"
import "./EpisodeChecklist.scss"

const EpisodeChecklist = ({ className }) => {
	const { episodeId } = useParams()
	const { isLoading, isError, data } = useEpisodeChecklist(episodeId)
	const queryClient = useQueryClient()
	const addTaskInput = useRef(null)

	const [form] = Form.useForm()

	const episodeChecklist = data?.episodeChecklist

	const numberOfChecked = episodeChecklist?.items?.filter(item => item.checked).length

	const updateChecklist = async (itemIndex, value) => {
		try {
			episodeChecklist.items[itemIndex] = { name: episodeChecklist.items[itemIndex].name, checked: value }
			await updateEpisodeChecklist(episodeChecklist)
			queryClient.invalidateQueries(["episodeChecklist", episodeId])
		} catch (e) {
			console.error(e)
			errorAlert("Error occurred updating checklist.")
		}
	}

	const deleteItem = async itemIndex => {
		try {
			const newItems = episodeChecklist.items.filter((item, i) => i !== itemIndex)
			episodeChecklist.items = newItems
			await updateEpisodeChecklist(episodeChecklist)
			queryClient.invalidateQueries(["episodeChecklist", episodeId])
		} catch (e) {
			console.error(e)
			errorAlert("Error occurred updating checklist.")
		}
	}

	const save = async values => {
		try {
			if (!episodeChecklist.items) {
				episodeChecklist.items = []
			}

			values.addChecklistItem = { name: values.addChecklistItem, checked: false }

			const allItems = [...episodeChecklist?.items, values.addChecklistItem]

			await updateEpisodeChecklist({ items: allItems, id: data.episodeChecklist.id, episodeId })
			queryClient.invalidateQueries(["episodeChecklist", episodeId])
			form.resetFields()
			addTaskInput.current.focus()
		} catch (error) {
			console.error(error)
		}
	}

	const afterDragEnd = async newList => {
		episodeChecklist.items = newList
		await updateEpisodeChecklist(episodeChecklist)
		queryClient.invalidateQueries(["episodeChecklist", episodeId])
	}

	const { onDragEnd } = useDragNDrop(episodeChecklist?.items, afterDragEnd)

	if (isLoading) return <PodpalCard>Loading...</PodpalCard>
	if (isError) return <PodpalCard>Error loading checklist.</PodpalCard>
	return (
		<PodpalCard className={className}>
			<Row justify="end" align="middle">
				<EpisodeManagementTooltip tooltipText="Want to track some of your own steps outside of Episode Progress? Just add them as tasks in your checklist!" />
			</Row>
			<Row justify="center" align="middle">
				<Col span={24} style={{ textAlign: "center" }}>
					<h2>My Checklist</h2>
				</Col>
				{episodeChecklist.items && (
					<Col span={20} offset={2}>
						<Row justify="space-between" align="middle" gutter={[8, 8]} style={{ maxHeight: "200px", overflowY: "scroll" }}>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef} style={{ width: "100%" }}>
											{episodeChecklist.items?.map((item, i) => (
												<Draggable key={i} draggableId={`${i}`} index={i}>
													{(provided, snapshot) => (
														<Col
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={classNames("checklist-item", {
																"dragging-checklist-item": snapshot.isDragging
															})}
															style={provided.draggableProps.style}
														>
															<Col span={22}>
																<Checkbox
																	key={i}
																	checked={item.checked ?? false}
																	onChange={e => updateChecklist(i, e.target.checked)}
																	className={classNames("checkbox")}
																	style={{ textDecoration: item.checked ? "line-through" : null }}
																>
																	{item.name}
																</Checkbox>
															</Col>
															<Col span={2}>
																<PodpalButton icon={<XIcon />} shape="circle" type="text" onClick={() => deleteItem(i)} />
															</Col>
														</Col>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</Row>
					</Col>
				)}
				<Col span={24}>
					<Form form={form} onFinish={save}>
						<Row justify="space-between" align="middle">
							<Col span={18} offset={3}>
								<Form.Item name="addChecklistItem">
									<Input ref={addTaskInput} placeholder="Type to add new task..." />
								</Form.Item>
							</Col>
						</Row>
						<Row justify="space-between" align="middle">
							<Col>
								{episodeChecklist.items ? `${numberOfChecked} of ${episodeChecklist.items.length} Complete` : "0 Tasks"}
							</Col>
							<Col>
								<PodpalButton htmlType="submit" variant="primaryLight" size="large" shape="round">
									Add Task
								</PodpalButton>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</PodpalCard>
	)
}

export default EpisodeChecklist
