import { CHANGE_AUTH_USER, SET_SELECTED_PODCAST } from "constants/actionTypes"
import { findPodcast, findPodcastByProfileUrl } from "services/podcast.service"

export const changeAuthUser = authUser => ({
	type: CHANGE_AUTH_USER,
	payload: {
		authUser: authUser
	}
})

export const setSelectedPodcast = podcast => ({
	type: SET_SELECTED_PODCAST,
	payload: {
		podcast
	}
})

export const updateSelectedPodcastFromPodcastId = podcastId => async dispatch => {
	await findPodcast(podcastId).then(res => {
		dispatch(setSelectedPodcast(res.data.podcast))
	})
}

export const updateSelectedPodcastFromProfileUrl = profileUrl => async dispatch => {
	const { data } = await findPodcastByProfileUrl(profileUrl)
	dispatch(setSelectedPodcast(data.podcast))
}
