import { useStripe } from "@stripe/react-stripe-js"
import { Row, Col, Space } from "antd"
import { PlanCheckIcon } from "assets/icons/UserSettings/SubscriptionAndBilling"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalCard from "components/common/Cards/PodpalCard"
import { useStripeCustomerPortal } from "hooks/SubscriptionAndBilling/useStripeCustomerPortal"
import { RootState } from "index"
import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { createCheckoutSession, ProductAndPrice } from "services/billing.service"
import { useUserInfo } from "services/queries/UserInfo"
import { SubscriptionType, SubscriptionTypeMap } from "services/types/userInfo"
import styled, { DefaultTheme, ThemeContext } from "styled-components"

const StyledPodpalCard = styled(PodpalCard)`
	max-width: 232px;
	min-height: 512px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	button {
		inset: 0;
		top: auto;
		margin: ${({ theme }) => theme.spaceLg} auto 0 auto;
		width: 100%;
	}
`

const BlueTitleText = styled.span`
	font: ${({ theme }) => theme.fontMd};
	color: ${({ theme }) => theme.podpalBlue};
`

interface TextProps {
	font: keyof DefaultTheme
	color: DefaultTheme
}

const Text = styled.span.attrs((props: TextProps) => ({
	font: props.font,
	color: props.color
}))`
	font: ${({ font }) => font};
	color: ${({ color }) => color};
`

interface PlanStatisticProps {
	metric: string | number
	text: string
}
interface PlanCardProps {
	productAndPrice: ProductAndPrice
	isMonthly: boolean
	subscriptionLevel: number // Numeric representation of subscription types.
}

function PlanStatistic({ metric, text }: PlanStatisticProps) {
	const theme = useContext(ThemeContext)
	return (
		<Space size={8}>
			<PlanCheckIcon />
			<Text font={theme.fontSm} color={theme.textSecondary}>
				<strong>{metric}</strong> {text}
			</Text>
		</Space>
	)
}

export function PlanCard({ productAndPrice, isMonthly, subscriptionLevel }: PlanCardProps) {
	const theme = useContext(ThemeContext)
	const stripe = useStripe()
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const { data: userInfoData } = useUserInfo(authUser.uid)

	const { openCustomerPortal } = useStripeCustomerPortal({ customerId: userInfoData?.userInfo.customerId })

	const handleSelect = async () => {
		try {
			const res = await createCheckoutSession({
				priceId: productAndPrice.priceId,
				productId: undefined,
				customerId: userInfoData?.userInfo.customerId
			})
			stripe?.redirectToCheckout({ sessionId: res.data.session_id })
		} catch (e) {
			errorAlert("An unexpected error occurred.")
		}
	}

	let selectPlanDisabled
	if (subscriptionLevel === 0) {
		selectPlanDisabled = true
	} else if (isMonthly) {
		selectPlanDisabled =
			subscriptionLevel <=
			SubscriptionTypeMap[userInfoData ? userInfoData.userInfo.subscriptionType : SubscriptionType.free]
	} else {
		if (userInfoData?.userInfo.isMonthly) {
			selectPlanDisabled =
				subscriptionLevel <
				SubscriptionTypeMap[userInfoData ? userInfoData.userInfo.subscriptionType : SubscriptionType.free]
		} else {
			selectPlanDisabled =
				subscriptionLevel <=
				SubscriptionTypeMap[userInfoData ? userInfoData.userInfo.subscriptionType : SubscriptionType.free]
		}
	}

	const selectPlanOnClick =
		SubscriptionTypeMap[userInfoData ? userInfoData.userInfo.subscriptionType : SubscriptionType.free] > 0
			? openCustomerPortal
			: handleSelect

	let buttonText

	// logic for different cases of button text, whether to show "Current Plan" or "Select Plan"
	const isOnFreePlan =
		!userInfoData?.userInfo.subscriptionType || userInfoData?.userInfo.subscriptionType === SubscriptionType.free
	const monthlyMatchesToggle =
		(!isMonthly && userInfoData?.userInfo.isMonthly === undefined) || (isMonthly && userInfoData?.userInfo.isMonthly)
	const subscriptionLevelMatches =
		subscriptionLevel === SubscriptionTypeMap[userInfoData?.userInfo.subscriptionType || SubscriptionType.free]
	const isFreeAndMatches = isOnFreePlan && subscriptionLevelMatches
	const monthlyAndSubscriptionMatches = monthlyMatchesToggle && subscriptionLevelMatches

	if (isFreeAndMatches || monthlyAndSubscriptionMatches) {
		buttonText = "Current Plan"
	} else {
		buttonText = "Select Plan"
	}

	return (
		<StyledPodpalCard>
			<div>
				<Row gutter={[0, 8]}>
					<Col>
						<BlueTitleText>
							<strong>{productAndPrice.name}</strong>
						</BlueTitleText>
					</Col>
				</Row>
				<Row align="middle">
					<Col>
						<Space size={8}>
							<Text font={theme.fontLgPlus}>{`$${productAndPrice.price}`}</Text>
							<Text font={theme.fontSm}>{"/ month"}</Text>
						</Space>
					</Col>
				</Row>
				<Row align="middle" gutter={[0, 8]}>
					<Col>{!isMonthly && <Text font={theme.fontXs}>*billed yearly</Text>}</Col>
				</Row>
				<Row gutter={[0, 12]}>
					<Col>
						<Text font={theme.fontSm} color={theme.textSecondary}>
							{productAndPrice.description}
						</Text>
					</Col>
				</Row>
				<Row gutter={[0, 8]}>
					<Col span={24}>
						<PlanStatistic
							metric={productAndPrice.metadata.podcasts}
							text={parseInt(productAndPrice.metadata.podcasts) > 1 ? "Podcasts" : "Podcast"}
						/>
					</Col>
					<Col span={24}>
						<PlanStatistic metric={productAndPrice.metadata.episodes} text="Episodes" />
					</Col>
					<Col span={24}>
						<PlanStatistic metric={productAndPrice.metadata.collaborators} text="Collaborators" />
					</Col>
					{productAndPrice.metadata.hasWebsite && (
						<Col span={24}>
							<PlanStatistic metric="Website" text="for your podcast" />
						</Col>
					)}
					<Col span={24}>
						<PlanStatistic metric={productAndPrice.metadata.storage} text="Storage" />
					</Col>
					<Col span={24}>
						<PlanStatistic metric={productAndPrice.metadata.downloadsPerMonth} text="Downloads/month" />
					</Col>
					<Col span={24}>
						<PlanStatistic metric="Audio" text="podcasts" />
					</Col>
					{productAndPrice.metadata.hasVideo && (
						<Col span={24}>
							<PlanStatistic metric="Video" text="podcasts" />
						</Col>
					)}
					{productAndPrice.metadata.hasAudienceInsights && (
						<Col span={24}>
							<PlanStatistic metric="Audience Insights" text="" />
						</Col>
					)}
				</Row>
			</div>
			<PodpalButton type="primary" size="large" onClick={selectPlanOnClick} disabled={selectPlanDisabled}>
				{buttonText}
			</PodpalButton>
		</StyledPodpalCard>
	)
}
