import React from "react"
import PodpalModal from "components/common/Modals/PodpalModal"
import ApplePodcastsIcon from "assets/podcasts/distribution/apple-podcasts.svg"
import SpotifyIcon from "assets/podcasts/distribution/spotify.svg"
import GooglePodcastsIcon from "assets/podcasts/distribution/google-podcasts.svg"
import AmazonMusicIcon from "assets/podcasts/distribution/amazon-music.svg"
import PodpalButton from "components/common/Buttons/PodpalButton"
import styled from "styled-components"

const StyledList = styled.ul`
	list-style-type: none;
	margin: 0 30%;

	@media only screen and (max-width: ${({ theme }) => theme.xs}) {
		margin: 0;
	}

	li {
		padding: 1em 0;
		display: flex;
		align-items: center;
		justify-content: start;
		span {
			padding-right: ${({ theme }) => theme.spaceSm};
		}
		a {
			color: ${({ theme }) => theme.textPrimary};

			&:hover,
			&:focus {
				color: ${({ theme }) => theme.podpalBlue};
				text-decoration: underline;
			}
		}
	}
`

interface PodcastDistributionModalProps {
	visible: boolean
	onClose: () => void
	applePodcastsUrl?: string
	spotifyUrl?: string
	googlePodcastsUrl?: string
	amazonMusicUrl?: string
}

export function PodcastDistributionModal({
	visible,
	onClose,
	applePodcastsUrl,
	spotifyUrl,
	googlePodcastsUrl,
	amazonMusicUrl
}: PodcastDistributionModalProps) {
	return (
		<PodpalModal
			title="Listen On..."
			onCancel={onClose}
			onOk={onClose}
			footer={[
				<PodpalButton type="primary" onClick={onClose}>
					Close
				</PodpalButton>
			]}
			visible={visible}
			centered
		>
			<StyledList>
				{applePodcastsUrl && (
					<li>
						<span>
							<img src={ApplePodcastsIcon} alt="Apple Podcasts" />
						</span>
						<a href={applePodcastsUrl} target="_blank" rel="noreferrer noopener">
							Apple Podcasts
						</a>
					</li>
				)}
				{spotifyUrl && (
					<li>
						<span>
							<img src={SpotifyIcon} alt="Spotify" />
						</span>
						<a href={spotifyUrl} target="_blank" rel="noreferrer noopener">
							Spotify
						</a>
					</li>
				)}
				{googlePodcastsUrl && (
					<li>
						<span>
							<img src={GooglePodcastsIcon} alt="Google Podcasts" />
						</span>
						<a href={googlePodcastsUrl} target="_blank" rel="noreferrer noopener">
							Google Podcasts
						</a>
					</li>
				)}
				{amazonMusicUrl && (
					<li>
						<span>
							<img src={AmazonMusicIcon} alt="Amazon Music" />
						</span>
						<a href={amazonMusicUrl} target="_blank" rel="noreferrer noopener">
							Amazon Music
						</a>
					</li>
				)}
			</StyledList>
		</PodpalModal>
	)
}
