import { useQuery } from "react-query"
import { keysToCamel } from "utils/camelCaseUtils"
import { findMigrationVerification } from "../migrationVerification.service"
import { FindMigrationVerificationResponse } from "../types/migrationVerification"

export const useMigrationVerification = (migrationVerificationID: string) => {
	return useQuery<FindMigrationVerificationResponse>(
		["migrationVerification", migrationVerificationID],
		async () => {
			const { data } = await findMigrationVerification(migrationVerificationID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!migrationVerificationID }
	)
}
