import { ReviewStatus, ReviewType } from "services/types/episodeReview"

export enum NotificationTypeNumber {
	episodepublished = 1,
	episodeadded = 2
	// episodedeleted,
	// episodeupdatedshownotes,
	// episodeupdatedreviewstatus,
	// episodereviewerassignedupdated
}

export enum NotificationTypeString {
	episodepublished = "EpisodePublished",
	episodeadded = "EpisodeAdded"
	// episodedeleted,
	// episodeupdatedshownotes,
	// episodeupdatedreviewstatus,
	// episodereviewerassignedupdated
}

interface PodcastInfo {
	name: string
	episode?: string
}

interface Username {
	name: string
}

// https://podpal.atlassian.net/browse/MVP-468
export interface EpisodePublishedNotification {
	episodePublishedRequestData: {
		published_podcast_id: string
		published_episode_id: string
	}
}

// https://podpal.atlassian.net/browse/MVP-469
export interface EpisodeAddedNotification {
	episodeAddedRequestData: {
		added_podcast_id: string
		added_episode_id: string
	}
}

// https://podpal.atlassian.net/browse/MVP-470
export interface EpisodeDeletedNotification {
	triggerUser: Username
	podcastInfo: PodcastInfo
}

// https://podpal.atlassian.net/browse/MVP-471
export interface EpisodeUpdatedShowNotesNotification {
	triggerUser: Username
	podcastInfo: PodcastInfo
}

// https://podpal.atlassian.net/browse/MVP-472
export interface EpisodeUpdatedReviewStatusNotification {
	triggerUser: Username
	podcastInfo: PodcastInfo
	updatedReviewType: ReviewType
	previousReviewStatus: ReviewStatus
	newReviewStatus: ReviewStatus
}

// https://podpal.atlassian.net/browse/MVP-473
export interface EpisodeReviewerAssignedUpdatedNotification {
	triggerUser: Username
	assignee: string
	podcastInfo: PodcastInfo
	reviewType: ReviewType
}

export type PodpalNotificationData =
	| EpisodePublishedNotification
	| EpisodeAddedNotification
	| EpisodeDeletedNotification
	| EpisodeUpdatedShowNotesNotification
	| EpisodeUpdatedReviewStatusNotification
	| EpisodeReviewerAssignedUpdatedNotification

export enum NotificationStatus {
	STATUS_UNKNOWN = "STATUS_UNKNOWN",
	UNREAD = "UNREAD",
	READ = "READ"
}

export interface PodpalNotification {
	id: string
	recipient_user_id: string
	trigger_user_id: string
	status: string
	type: string
	created_date: string
	read_date: string
	notification_data: string
	html_text: string
	html_hyperlink: string
	trigger_user_photo_url?: string
}

interface BaseNotificationRequest {
	request_id: string
}

interface BaseNotificationResponse {
	request_id: string
}

export interface CreateNotificationRequest extends BaseNotificationRequest {
	trigger_user_id: string
	type: NotificationTypeNumber
	data: PodpalNotificationData
	notification_time?: string
}

export interface CreateNotificationResponse extends BaseNotificationResponse {
	trigger_user_id: string
	notification: PodpalNotification
}

export interface FindNotificationsByUserIdRequest extends BaseNotificationRequest {
	user_id: string
	query_options?: {
		filters?: any
		orders?: any
		limit?: number
		cursor?: string
	}
}

export interface FindNotificationByUserIdResponse extends BaseNotificationResponse {
	notifications: PodpalNotification[]
	query_meta?: {
		cursor?: string
	}
}

export interface MarkNotificationReadRequest extends BaseNotificationRequest {
	user_id: string
	notification_id: string
}

export interface MarkNotificationReadResponse extends BaseNotificationResponse {
	marked_notification: PodpalNotification
	success: boolean
}

export interface MarkNotificationsReadRequest extends BaseNotificationRequest {
	user_id: string
	notification_ids: string[]
}

export interface MarkNotificationsReadResponse extends BaseNotificationResponse {
	marked_notifications: PodpalNotification[]
	success: boolean
}

export interface DeleteNotificationRequest extends BaseNotificationRequest {
	user_id: string
	notification_id: string
}

export interface DeleteNotificationResponse extends BaseNotificationResponse {
	notification_id: string
	success: boolean
}

export interface DeleteNotificationsRequest extends BaseNotificationRequest {
	user_id: string
	notification_ids: string[]
}

export interface DeleteNotificationsResponse extends BaseNotificationResponse {
	deleted_notifications_count: number
	success: boolean
}

export interface GetUnreadNotificationsCountRequest extends BaseNotificationRequest {
	user_id: string
}

export interface GetUnreadNotificationsCountResponse extends BaseNotificationResponse {
	count: number
}
