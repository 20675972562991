import React from "react"
import noAvatar from "assets/account/noavatar.svg"
import { Col, Row } from "antd"
import { toTitleCase } from "utils/titleCase"
import Avatar from "antd/lib/avatar/avatar"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { PAL } from "constants/routes"
import { usePodcastTeam } from "services/queries/Podcasts"

const StyledCol = styled(Col)`
	text-align: center;

	.collaborator-type,
	.username {
		font: ${({ theme }) => theme.fontSm};
	}

	.username {
		color: ${({ theme }) => theme.podpalBlue};
		&:hover {
			color: ${({ theme }) => theme.podpalBlueHoverDark};
			text-decoration: underline;
		}
	}

	.collaborator-type {
		margin-top: ${({ theme }) => theme.spaceXs};
	}

	span.ant-avatar {
		&:hover {
			box-shadow: 0 0 0 8px ${({ theme }) => theme.podpalBlueGhost};
			cursor: pointer;
		}
	}
`

export function ProductionTeam({ podcastId }: { podcastId: string }) {
	const { isLoading: podcastTeamLoading, isError: podcastTeamError, data: podcastTeamData } = usePodcastTeam(podcastId)

	if (podcastTeamLoading) {
		return <div>Loading...</div>
	}

	if (podcastTeamError) {
		return <div>Error loading production team.</div>
	}

	const productionTeamMembers = podcastTeamData?.usersWithPodcastRoles.map(user => {
		const titleCaseCollaboratorType = toTitleCase(user.podcastRole.collaboratorType ?? "Host")

		const { id, thumbnailUrl, username, firstName, lastName } = user.user

		const avatar = <Avatar size={88} src={thumbnailUrl ?? noAvatar} alt={`${firstName} ${lastName}`} />

		return (
			<StyledCol key={id}>
				{username ? (
					<Link to={`${PAL}/${username}`} target="_blank" rel="noopener noreferrer" className="username">
						{avatar}
					</Link>
				) : (
					avatar
				)}
				<p className="collaborator-type">{titleCaseCollaboratorType}</p>
				{username ? (
					<Link to={`${PAL}/${username}`} target="_blank" rel="noopener noreferrer" className="username">
						@{username}
					</Link>
				) : null}
			</StyledCol>
		)
	})

	return <Row gutter={[16, 16]}>{productionTeamMembers}</Row>
}
