import React from "react"
import { Row, Col, Grid } from "antd"
import { Link, useLocation } from "react-router-dom"
import { HomeFilledIcon, MenuIcon } from "assets/icons/Navbar"
import { HelpCenterDropdown } from "./HelpCenterDropdown"
import { UserSettingsDropdown } from "./UserSettingsDropdown"
import { NotificationsDropdown } from "./NotificationsDropdown"
import { MY_PODCASTS, OFFERS_AND_UPGRADES, SETTINGS, SUBSCRIPTION_AND_BILLING } from "constants/routes"
import { useDispatch, useSelector } from "react-redux"
import { toggleUserSettingsSidebarCollapsed } from "redux/actions/UserSettings/UserSettingsSidebar"
import { toggleSidebarCollapsed } from "redux/actions/Sidebar"
import styled from "styled-components"
import podpalImage from "assets/logo.png"
import { RootState } from "index"
import { useUserInfo } from "services/queries/UserInfo"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { useHistory } from "react-router"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"

const StyledBar = styled.div`
	width: 100%;
	height: 64px;
	background: ${({ theme }) => theme.podpalBlue};
	padding: ${({ theme }) => theme.spaceLg};
	display: flex;
	align-items: center;

	.menu-icon {
		transition: ease 0.25s;
		box-shadow: 0 0 0 12px transparent;
		border-radius: 200px;
		&:hover {
			transition: ease 0.25s;
			box-shadow: 0 0 0 12px ${({ theme }) => theme.podpalBlueDark};
			background: ${({ theme }) => theme.podpalBlueDark};
			border-radius: 200px;
		}
	}

	.home-icon {
		transition: ease 0.25s;
		box-shadow: 0 0 0 8px transparent;
		border-radius: 200px;
		&:hover {
			transition: ease 0.25s;
			box-shadow: 0 0 0 8px ${({ theme }) => theme.podpalBlueDark};
			background: ${({ theme }) => theme.podpalBlueDark};
			border-radius: 200px;
		}
	}
`

export function Navbar() {
	const location = useLocation()
	const history = useHistory()
	const dispatch = useDispatch()
	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	const handleMenuClick = () => {
		location.pathname.includes("/settings")
			? dispatch(toggleUserSettingsSidebarCollapsed())
			: dispatch(toggleSidebarCollapsed())
	}

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const sidebarCollapsed = useSelector((state: RootState) => state.sidebar.sidebarCollapsed)
	const userSettingsSidebarCollapsed = useSelector((state: RootState) => state.userSettingsSidebar.collapsed)

	const isOpen = location.pathname.includes(`${SETTINGS}`) ? userSettingsSidebarCollapsed : sidebarCollapsed

	const { useBreakpoint } = Grid
	const screens = useBreakpoint()

	const { data: userInfoData } = useUserInfo(authUser.uid)

	const iconColStyle = { paddingTop: "4px" } // Icons need to be aligned with the rest of the Navbar.

	return (
		<StyledBar>
			<Row justify="space-between" align="middle" style={{ width: "100%" }}>
				<Col>
					<Row gutter={24} align="middle">
						<Col>
							<MenuIcon
								tooltipText={screens.xs ? null : isOpen ? "Expand Menu" : "Collapse Menu"}
								className="menu-icon"
								onClick={() => handleMenuClick()}
							/>
						</Col>
						<Col>
							<Link to="/">
								<img src={podpalImage} alt="podpal" width="95" height="26" />
							</Link>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row gutter={24} align="middle">
						{!isMobile && (
							<Col style={iconColStyle}>
								{!userInfoData?.userInfo.subscriptionType && (
									<PodpalButton
										variant="secondary"
										size="large"
										style={{ color: "#2b2b2b" }}
										onClick={() => history.push(`${SETTINGS}${SUBSCRIPTION_AND_BILLING}${OFFERS_AND_UPGRADES}`)}
									>
										Upgrade
									</PodpalButton>
								)}
							</Col>
						)}
						<Col style={iconColStyle}>
							<Link to={MY_PODCASTS}>
								<HomeFilledIcon className="home-icon" />
							</Link>
						</Col>
						<Col style={iconColStyle}>
							<HelpCenterDropdown />
						</Col>
						<Col style={iconColStyle}>
							<NotificationsDropdown />
						</Col>
						<Col>
							<UserSettingsDropdown />
						</Col>
					</Row>
				</Col>
			</Row>
		</StyledBar>
	)
}
