import { Col, Row } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import PodpalButton from "components/common/Buttons/PodpalButton"
import PodpalCard from "components/common/Cards/PodpalCard"
import React, { useEffect } from "react"
import { useEpisode } from "services/queries/Episodes"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import EpisodeProgressList from "./EpisodeProgressList"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { STATUS } from "../../../constants/strings/Episodes/episodeStatusConstant"
import { useQueryClient } from "react-query"
import { updateEpisodeProgress } from "services/episodeProgress.service"
import { isReadyToPublish } from "./EpisodeProgressHelpers"
import { v4 as uuidv4 } from "uuid"
import {
	confirmPublishNowModal,
	confirmRepublishModal
} from "components/common/Modals/Episodes/EpisodeManagementModals"
import {
	setEpisodeIdForScheduledReleaseDateModal,
	toggleChangeScheduledReleaseDateModalVisible
} from "redux/actions/Episodes"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"
import { publishEpisode } from "services/episode.service"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { PUBLISH_SUCCESSFUL } from "constants/strings/Episodes"
import { useNotificationsHook } from "hooks/useNotificationsHook"
import { RootState } from "index"
import { NotificationTypeNumber } from "services/types/notifications"

interface EpisodeProgressProps {
	className: string
}

interface Param {
	episodeId: string
}

const EpisodeProgress: React.FC<EpisodeProgressProps> = ({ className }) => {
	const dispatch = useDispatch()
	const { episodeId } = useParams<Param>()
	const queryClient = useQueryClient()
	const { exceedsEpisodesLimit } = useLimitChecker()
	const { createNewNotification } = useNotificationsHook()

	const { data: episodeData } = useEpisode(episodeId)
	const { isLoading, isError, data } = useEpisodeProgress(episodeId)

	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const authUser = useSelector((state: RootState) => state.app.authUser)

	useEffect(() => {
		const updateCurrentStep = async () => {
			const updatedEpisodeProgress = { ...data.episodeProgress, currentStep: "Publish Episode" }
			try {
				await updateEpisodeProgress(updatedEpisodeProgress)
				queryClient.invalidateQueries(["episodeProgress", data.episodeProgress.episodeId])
			} catch (e) {
				errorAlert("Error occurred updating Episode Progress.")
			}
		}

		if (isReadyToPublish(data.episodeProgress)) {
			updateCurrentStep()
		}
	}, [data.episodeProgress, queryClient])

	if (isLoading) {
		return (
			<PodpalCard className={className}>
				<div>Loading...</div>
			</PodpalCard>
		)
	}

	if (isError) {
		return (
			<PodpalCard className={className}>
				<div>Error occurred while retrieving episode progress.</div>
			</PodpalCard>
		)
	}

	const { setup, inviteGuests, scheduleInterview, uploadAudio, writeShowNotes, reviewEpisode } = data.episodeProgress
	const canPublishEpisode =
		setup === STATUS.COMPLETED &&
		(inviteGuests === STATUS.COMPLETED || inviteGuests === STATUS.SKIPPED) &&
		(scheduleInterview === STATUS.COMPLETED || scheduleInterview === STATUS.SKIPPED) &&
		uploadAudio === STATUS.COMPLETED &&
		writeShowNotes === STATUS.COMPLETED &&
		(reviewEpisode === STATUS.COMPLETED || reviewEpisode === STATUS.SKIPPED)

	const canRepublishEpisode = episodeData?.episode?.publishedEpisodeModified

	const handlePublishNow = (isRepublish: boolean) => {
		if (exceedsEpisodesLimit() && !isRepublish) {
			dispatch(toggleLimitsExceededModalVisible())
		} else {
			confirmPublishNowModal(async () => {
				try {
					await publishEpisode({
						podcastId: selectedPodcast?.id,
						episodeId: episodeData?.episode.id || "",
						manualTrigger: true
					})
					queryClient.invalidateQueries(["episode", episodeData?.episode.id])
					successAlert(PUBLISH_SUCCESSFUL)
					createNewNotification({
						request_id: uuidv4(),
						trigger_user_id: authUser.uid,
						type: NotificationTypeNumber.episodepublished,
						data: {
							episodePublishedRequestData: {
								published_podcast_id: selectedPodcast?.id || "",
								published_episode_id: episodeData?.episode.id || ""
							}
						}
					})
				} catch (e) {
					errorAlert("Error publishing episode.")
				}
			})
		}
	}

	const handlePublish = (isRepublish: boolean) => {
		if (exceedsEpisodesLimit() && !isRepublish) {
			dispatch(toggleLimitsExceededModalVisible())
		} else {
			dispatch(setEpisodeIdForScheduledReleaseDateModal(episodeData?.episode.id))
			dispatch(toggleChangeScheduledReleaseDateModalVisible())
		}
	}

	const handleRepublish = async () => {
		confirmRepublishModal(() => handlePublish(true))
	}

	return (
		<PodpalCard style={{ paddingLeft: 0, paddingRight: 0 }} className={className}>
			<Row justify="center" align="middle">
				<Col span={24} style={{ textAlign: "center" }}>
					<h2>Episode Progress</h2>
				</Col>
				<Col span={24}>
					<EpisodeProgressList data={data} />
					<Row justify="center" align="middle" gutter={16}>
						{episodeData?.episode.isPublished ? (
							<Col>
								<PodpalButton size="large" type="primary" disabled={!canRepublishEpisode} onClick={handleRepublish}>
									Republish
								</PodpalButton>
							</Col>
						) : (
							<>
								<Col>
									<PodpalButton
										size="large"
										type="primary"
										disabled={episodeData?.episode?.isPublished ? !canRepublishEpisode : !canPublishEpisode}
										onClick={() => handlePublishNow(false)}
									>
										Publish Now
									</PodpalButton>
								</Col>
								<Col>
									<PodpalButton
										size="large"
										variant="secondary"
										disabled={
											(episodeData?.episode?.isPublished ? !canRepublishEpisode : !canPublishEpisode) ||
											episodeData?.episode?.scheduledReleaseDate
										}
										onClick={() => handlePublish(false)}
									>
										Publish Later
									</PodpalButton>
								</Col>
							</>
						)}
					</Row>
				</Col>
			</Row>
		</PodpalCard>
	)
}

export default EpisodeProgress
