import React from "react"
import { useDispatch, useSelector } from "react-redux"
import PodpalModal from "../../PodpalModal"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"
import limitsExceededImage from "assets/subscriptionandbilling/limits-exceeded.svg"
import styled from "styled-components"
import { Col, Row } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { Link } from "react-router-dom"
import { PLAN_USAGE, SETTINGS, SUBSCRIPTION_AND_BILLING } from "constants/routes"
import { useUserInfo } from "services/queries/UserInfo"
import { useStripeCustomerPortal } from "hooks/SubscriptionAndBilling/useStripeCustomerPortal"

const Heading = styled.span`
	font: ${({ theme }) => theme.fontLg};
	color: ${({ theme }) => theme.textPrimary};
	text-align: center;
`

const SecondaryText = styled.span`
	font: ${({ theme }) => theme.fontSm};
	color: ${({ theme }) => theme.textSecondary};
	text-align: center;
`

const StyledPodpalModal = styled(PodpalModal)`
	.ant-col {
		text-align: center;
	}

	a {
		color: ${({ theme }) => theme.podpalBlue};
	}
`

export function LimitsExceededModal() {
	const dispatch = useDispatch()

	const authUser = useSelector(state => state.app.authUser)
	const visible = useSelector(state => state.subscriptionAndBilling.limitsExceededModalVisible)

	const { data } = useUserInfo(authUser.uid)
	const { openCustomerPortal } = useStripeCustomerPortal({ customerId: data?.userInfo.customerId })

	const handleClose = () => {
		dispatch(toggleLimitsExceededModalVisible())
	}

	return (
		<StyledPodpalModal
			centered
			visible={visible}
			width={480}
			title="Plan Limits Exceeded"
			onCancel={handleClose}
			footer={null}
		>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col span={18}>
					<Heading>Oops! You’ve exceeded the limits of your current plan.</Heading>
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col>
					<img src={limitsExceededImage} alt="limits-exceeded" />
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col span={16}>
					<SecondaryText>
						Please <strong>upgrade your subscription</strong> to continue creating amazing podcast content!
					</SecondaryText>
				</Col>
			</Row>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Col>
					<PodpalButton
						success
						size="large"
						onClick={() => {
							handleClose()
							openCustomerPortal()
						}}
						disabled
					>
						Upgrade Subscription
					</PodpalButton>
				</Col>
			</Row>
			<Row justify="center" align="middle">
				<Col>
					<Link to={`${SETTINGS}${SUBSCRIPTION_AND_BILLING}${PLAN_USAGE}`} onClick={handleClose}>
						View Plan Usage
					</Link>
				</Col>
			</Row>
		</StyledPodpalModal>
	)
}
