import React from "react"
import { Col, Input, Row, Form } from "antd"
import PodpalButton from "components/common/Buttons/PodpalButton"
import InstagramLogo from "assets/icons/social-media/instagram.svg"
import FacebookLogo from "assets/icons/social-media/facebook.svg"
import { ReactComponent as TwitterLogo } from "assets/icons/social-media/twitter.svg"
import { ReactComponent as LinkedinLogo } from "assets/icons/social-media/linkedin.svg"
import { ReactComponent as YoutubeLogo } from "assets/icons/social-media/youtube.svg"
import PatreonLogo from "assets/icons/social-media/patreon.svg"
import CashappLogo from "assets/icons/social-media/cashapp.svg"
import { useDispatch, useSelector } from "react-redux"
import "./SocialMediaForm.scss"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import {
	submitSocialMediaFormData,
	setSocialMediaFormIsModified,
	submitSocialMediaFormDataSuccess,
	toggleSocialMediaFormIsLoading
} from "redux/actions/UserSettings/MyPodcasts/SocialMediaForm"
import { LABEL_NAMES } from "constants/strings/UserSettings/MyPodcasts/SocialMediaForm"

const SocialMediaForm = () => {
	const [form] = Form.useForm()

	const dispatch = useDispatch()

	const authUser = useSelector(state => state.app.authUser)
	const podcastData = useSelector(state => state.app.selectedPodcast)
	const isModified = useSelector(state => state.podcastSocialMediaForm.isModified)
	const isLoading = useSelector(state => state.podcastSocialMediaForm.isLoading)

	const handleCancel = () => {
		// FIXME: form reset clears fields after submit, even though initialValues are populated?
		form.resetFields()
		dispatch(setSocialMediaFormIsModified(false))
	}

	const handleSubmit = values => {
		if (podcastData.categories) {
			podcastData.categories = podcastData.categories.map(category => category.value)
		}
		dispatch(
			submitSocialMediaFormData({ podcastId: podcastData.id, data: { ...podcastData, ...values }, userId: authUser.uid })
		)
			.then(async res => {
				if (res.data) {
					const categories = res.data.updatedPodcast?.categories
					if (categories) {
						res.data.updatedPodcast.categories = categories.map(category => ({
							value: category,
							label: category
						}))
					}
					await dispatch(submitSocialMediaFormDataSuccess(res.data.updatedPodcast))
					successAlert("Changes saved!")
					dispatch(setSocialMediaFormIsModified(false))
					dispatch(toggleSocialMediaFormIsLoading(false))
				} else {
					errorAlert("Error occurred while saving.")
					dispatch(toggleSocialMediaFormIsLoading(false))
				}
			})
			.catch(async err => {
				errorAlert("Error occurred while saving.")
				dispatch(toggleSocialMediaFormIsLoading(false))
			})
	}

	const checkIfModified = () => {
		if (form.isFieldsTouched !== isModified) {
			dispatch(setSocialMediaFormIsModified(form.isFieldsTouched))
		}
	}

	return (
		<div className="show-settings-form social-form">
			<Form
				form={form}
				onFinish={handleSubmit}
				onValuesChange={checkIfModified}
				layout="vertical"
				initialValues={podcastData}
			>
				<Form.Item
					name="socialInstagram"
					label={
						<span className="label">
							<span className="label logo">
								<img src={InstagramLogo} alt="instagram" />
							</span>
							{LABEL_NAMES.INSTAGRAM}
						</span>
					}
				>
					<Input addonBefore="https://instagram.com/" name="Instagram" size="large" placeholder="enter your handle" />
				</Form.Item>
				<Form.Item
					name="socialFacebook"
					label={
						<span className="label">
							<span className="label logo">
								<img src={FacebookLogo} alt="facebook" />
							</span>
							{LABEL_NAMES.FACEBOOK}
						</span>
					}
				>
					<Input addonBefore="https://facebook.com/" name="Facebook" size="large" placeholder="enter your handle" />
				</Form.Item>
				<Form.Item
					name="socialTwitter"
					label={
						<span className="label">
							<span className="label logo">
								<TwitterLogo />
							</span>
							{LABEL_NAMES.TWITTER}
						</span>
					}
				>
					<Input addonBefore="https://twitter.com/" name="Twitter" size="large" placeholder="enter your handle" />
				</Form.Item>
				<Form.Item
					name="socialLinkedin"
					label={
						<span className="label">
							<span className="label logo">
								<LinkedinLogo />
							</span>
							{LABEL_NAMES.LINKEDIN}
						</span>
					}
				>
					<Input addonBefore="https://linkedin.com/in/" name="LinkedIn" size="large" placeholder="enter your handle" />
				</Form.Item>
				<Form.Item
					name="socialYoutube"
					label={
						<span className="label">
							<span className="label logo">
								<YoutubeLogo />
							</span>
							{LABEL_NAMES.YOUTUBE}
						</span>
					}
				>
					<Input name="YouTube" size="large" placeholder="enter your user channel URL" />
				</Form.Item>
				<Form.Item
					name="socialPatreon"
					label={
						<span className="label">
							<span className="label logo">
								<img src={PatreonLogo} alt="patreon" />
							</span>
							{LABEL_NAMES.PATREON}
						</span>
					}
				>
					<Input addonBefore="https://patreon.com/" name="Patreon" size="large" placeholder="enter your handle" />
				</Form.Item>
				<Form.Item
					name="socialCashapp"
					label={
						<span className="label">
							<span className="label logo">
								<img src={CashappLogo} alt="Cash App" />
							</span>
							{LABEL_NAMES.CASHAPP}
						</span>
					}
				>
					<Input addonBefore="https://cash.app/" name="Cash App" size="large" placeholder="enter your $Cashtag" />
				</Form.Item>

				<footer className="footer-actions">
					<Row gutter={8} justify="start" align="middle">
						<Col>
							<PodpalButton type="primary" size="large" loading={isLoading} htmlType="submit" disabled={!isModified}>
								Save
							</PodpalButton>
						</Col>
						<Col>
							<PodpalButton variant="secondary" size="large" onClick={handleCancel} disabled={!isModified}>
								Cancel
							</PodpalButton>
						</Col>
					</Row>
				</footer>
			</Form>
		</div>
	)
}

export default SocialMediaForm
