import React from "react"
import styled from "styled-components"

const Card = styled.div`
	border-radius: ${props => props.theme.radiusLg};
	height: 100%;
	width: 100%;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	padding: ${props => props.theme.spaceLg};
	background-color: ${props => props.theme.neutral0};
`

const PodpalCard = ({ children, ...props }) => {
	return <Card {...props}>{children}</Card>
}

export default PodpalCard
