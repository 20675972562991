import {
	TOGGLE_HELP_CENTER_DROPDOWN,
	TOGGLE_VIDEO_TUTORIAL_MODAL_VISIBLE,
	SET_VIDEO_TUTORIAL_MODAL_CONFIG,
	TOGGLE_WHATS_NEW_MODAL_VISIBLE
} from "constants/actionTypes"

export const toggleHelpCenterDropdown = () => ({
	type: TOGGLE_HELP_CENTER_DROPDOWN
})

export const toggleVideoTutorialModalVisible = () => ({
	type: TOGGLE_VIDEO_TUTORIAL_MODAL_VISIBLE
})

export const setVideoTutorialModalConfig = config => ({
	type: SET_VIDEO_TUTORIAL_MODAL_CONFIG,
	payload: config
})

export const toggleWhatsNewModalVisible = () => ({
	type: TOGGLE_WHATS_NEW_MODAL_VISIBLE
})
