import { Col, Row } from "antd"
import { RootState } from "index"
import React from "react"
import { useSelector } from "react-redux"
import { EpisodeProgressKind } from "services/episode.service"
import { useEpisodesWithStatus } from "services/queries/Episodes"
import { usePodcast } from "services/queries/Podcasts"
import { Operators, PropertyTypes, QueryOptions } from "services/types/queryOptions"
import { EpisodeCard } from "./EpisodeCard"

interface EpisodeListProps {
	kind: EpisodeProgressKind
}

export function EpisodeList({ kind }: EpisodeListProps) {
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const { isLoading: podcastLoading, isError: podcastError, data: podcastData } = usePodcast(selectedPodcast?.id)

	const queryOptions: QueryOptions = {
		filters: [
			{
				propertyName: "PodcastID",
				propertyValue: (selectedPodcast?.id as string) ?? "",
				propertyType: PropertyTypes.string,
				operator: Operators.equal
			}
		],
		orders: [
			{
				propertyName: "IsPublished",
				sort: "ASC"
			},
			{
				propertyName: "CreatedAt",
				sort: "DESC"
			},
			{
				propertyName: "LastPublishedDate",
				sort: "DESC"
			}
			// {
			// 	propertyName: "SeasonNumber",
			// 	sort: "DESC",
			// },
			// {
			// 	propertyName: "EpisodeNumber",
			// 	sort: "DESC",
			// },
		]
	}

	const { isLoading, isError, data } = useEpisodesWithStatus({
		podcastId: selectedPodcast?.id,
		kind: kind,
		queryOptions
	})

	if (isLoading || podcastLoading) {
		return (
			<Row>
				<Col>Loading episodes...</Col>
			</Row>
		)
	}

	if (isError || podcastError) {
		return (
			<Row>
				<Col>Error loading episodes.</Col>
			</Row>
		)
	}

	if (!podcastData) {
		return (
			<Row>
				<Col>Error loading episodes.</Col>
			</Row>
		)
	}

	const episodeCards = data?.episodesWithProgress?.map(episodeWithProgress => {
		const { episode, episodeProgress } = episodeWithProgress
		return (
			<Col key={episode.id}>
				<EpisodeCard
					key={episode.id}
					episode={episode}
					profileUrl={podcastData.podcast.profileUrl}
					episodeProgress={episodeProgress}
				/>
			</Col>
		)
	})
	return <Row gutter={[24, 24]}>{episodeCards ?? <Col>No episodes here.</Col>}</Row>
}
