import React from "react"
import { Avatar, Popover, Skeleton } from "antd"
import { UserIcon } from "assets/icons/Common/User"
import * as ROUTES from "constants/routes"
import "./UserSettingsDropdown.scss"
import { useDispatch, useSelector } from "react-redux"
import { useUser } from "services/queries/User"
import { RootState } from "index"
import { DropdownContent } from "./DropdownContent"
import { toggleUserSettingsDropdown } from "redux/actions/Navbar/UserSettingsDropdown"

export function UserSettingsDropdown() {
	const dispatch = useDispatch()
	const authUser = useSelector((state: RootState) => state.app.authUser)
	const selectedPodcast = useSelector((state: RootState) => state.app.selectedPodcast)
	const userSettingsDropdownVisible = useSelector((state: RootState) => state.userSettingsDropdown.visible)

	const { isLoading, isError, data } = useUser(authUser.uid)

	const handleVisibleChange = () => {
		dispatch(toggleUserSettingsDropdown())
	}

	const loadingSkeleton = (
		<div style={{ width: "256px", height: "314px", padding: "1rem" }}>
			<Skeleton avatar paragraph={{ rows: 3 }} />
		</div>
	)

	if (isLoading) {
		return (
			<Popover
				className="user-settings-dropdown"
				trigger="click"
				content={loadingSkeleton}
				placement="bottomRight"
				visible={userSettingsDropdownVisible}
				onVisibleChange={handleVisibleChange}
				arrowPointAtCenter
			>
				<Avatar size={38} icon={<UserIcon />} />
			</Popover>
		)
	}

	if (isError) {
		return (
			<Popover className="user-settings-dropdown" trigger="click" placement="bottomRight">
				<Avatar size={38} icon={<UserIcon />} />
			</Popover>
		)
	}

	if (!data) {
		return (
			<Popover className="user-settings-dropdown" trigger="click" placement="bottomRight">
				<Avatar size={38} icon={<UserIcon />} />
			</Popover>
		)
	}

	const { thumbnailUrl, firstName, lastName, email } = data.user

	const name = (firstName ?? "") + " " + (lastName ?? "")

	const defaultPodcastRoute = selectedPodcast
		? `${ROUTES.SETTINGS}${ROUTES.PODCAST_SETTINGS}/${selectedPodcast.id}${ROUTES.SHOW_SETTINGS}`
		: `${ROUTES.SETTINGS}${ROUTES.PODCAST_SETTINGS}${ROUTES.NO_PODCASTS}`

	return (
		<Popover
			className="user-settings-dropdown"
			trigger="click"
			content={
				<DropdownContent
					defaultPodcastRoute={defaultPodcastRoute}
					email={email}
					thumbnailUrl={thumbnailUrl ?? ""}
					name={name}
				/>
			}
			placement="bottomRight"
			visible={userSettingsDropdownVisible}
			onVisibleChange={handleVisibleChange}
			arrowPointAtCenter
		>
			{thumbnailUrl ? <Avatar size={38} src={thumbnailUrl} /> : <Avatar size={38} icon={<UserIcon />} />}
		</Popover>
	)
}
