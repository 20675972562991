export const FIELD_NAMES = {
	FIRST_NAME: "firstName",
	LAST_NAME: "lastName",
	EMAIL: "email",
	PASSWORD: "password",
	TERMS_AND_CONDITIONS: "termsAndConditions"
}

export const FIREBASE_ERROR_CODES = {
	EMAIL_ALREADY_IN_USE: "auth/email-already-in-use",
	USER_NOT_FOUND: "auth/user-not-found",
	WRONG_PASSWORD: "auth/wrong-password"
}

export const NAME_REQUIRED = "Name is required."
export const NAME_TOO_SHORT = "Name must be at least 2 characters."
export const NAME_TOO_LONG = "Name cannot exceed 25 characters."
export const NAME_ONLY_CHARS = "Name cannot include numbers."

export const EMAIL_REQUIRED = "Email is required."
export const EMAIL_INVALID = "Email must have a valid domain."

export const PASSWORD_REQUIRED = "Password is required."
export const PASSWORD_REQUIRES_LOWERCASE_CHAR = "Password must have at least 1 lowercase letter."
export const PASSWORD_REQUIRES_UPPERCASE_CHAR = "Password must have at least 1 uppercase letter."
export const PASSWORD_REQUIRES_NUMBER = "Password must have at least 1 number."
export const PASSWORD_REQUIRES_SPECIAL_CHAR = "Password must have at least 1 special character (i.e. $ ! @ # ?)."
export const PASSWORD_TOO_SHORT = "Password must be 8 or more characters."

export const EMAIL_PASSWORD_INVALID = "Incorrect email or password."

export const TERMS_AND_CONDITIONS_REQUIRED = "Please accept our Terms of Service."
