import {
	SET_EPISODE_ID_FOR_LAST_PUBLISHED_DATE_MODAL,
	SET_EPISODE_ID_FOR_RELEASE_DATE_MODAL,
	SET_EPISODE_ID_FOR_SCHEDULED_RELEASE_DATE_MODAL,
	TOGGLE_CHANGE_LAST_PUBLISHED_DATE_MODAL_VISIBLE,
	TOGGLE_CHANGE_PLANNED_RELEASE_DATE_MODAL_VISIBLE,
	TOGGLE_CHANGE_SCHEDULED_RELEASE_DATE_MODAL_VISIBLE,
	TOGGLE_DELETE_EPISODE_MODAL_VISIBLE,
	SET_EPISODE_FOR_DELETE_EPISODE_MODAL
} from "../../../constants/actionTypes"

export const toggleChangeLastPublishedDateModalVisible = () => ({
	type: TOGGLE_CHANGE_LAST_PUBLISHED_DATE_MODAL_VISIBLE
})

export const toggleChangePlannedReleaseDateModalVisible = () => ({
	type: TOGGLE_CHANGE_PLANNED_RELEASE_DATE_MODAL_VISIBLE
})

export const toggleChangeScheduledReleaseDateModalVisible = () => ({
	type: TOGGLE_CHANGE_SCHEDULED_RELEASE_DATE_MODAL_VISIBLE
})

export const setEpisodeIdForLastPublishedDateModal = episodeId => ({
	type: SET_EPISODE_ID_FOR_LAST_PUBLISHED_DATE_MODAL,
	payload: { episodeId }
})

export const setEpisodeIdForReleaseDateModal = episodeId => ({
	type: SET_EPISODE_ID_FOR_RELEASE_DATE_MODAL,
	payload: { episodeId }
})

export const setEpisodeIdForScheduledReleaseDateModal = episodeId => ({
	type: SET_EPISODE_ID_FOR_SCHEDULED_RELEASE_DATE_MODAL,
	payload: { episodeId }
})

export const toggleDeleteEpisodeModalVisible = () => ({
	type: TOGGLE_DELETE_EPISODE_MODAL_VISIBLE
})

export const setEpisodeForDeleteEpisodeModal = episode => ({
	type: SET_EPISODE_FOR_DELETE_EPISODE_MODAL,
	payload: episode
})
