import parsePhoneNumber from "libphonenumber-js"
import {
	ADD_CHANGE_TO_FORM,
	REVERT_CHANGES,
	CHANGE_VALIDATION_STATUS,
	CLEAR_VALIDATION_STATUSES,
	CHANGE_ERROR_MESSAGE,
	CLEAR_ERROR_MESSAGES,
	RETRIEVE_ACCOUNT_FORM_DATA_LOADING,
	RETRIEVE_ACCOUNT_FORM_DATA_SUCCESS,
	RETRIEVE_ACCOUNT_FORM_DATA_ERROR,
	SUBMIT_ACCOUNT_FORM_DATA_LOADING,
	SUBMIT_ACCOUNT_FORM_DATA_SUCCESS,
	SUBMIT_ACCOUNT_FORM_DATA_ERROR
} from "constants/actionTypes"
import { defaultProfileDataState, defaultProfileDataValErrorState } from "utils/formUtils"
import { FIELD_NAMES } from "constants/strings/UserSettings/MyAccount/Form"

const initialState = {
	profile: {
		view: {
			data: defaultProfileDataState,
			loading: false,
			error: null
		},

		edit: {
			data: defaultProfileDataState,
			loading: false,
			error: null,
			changed: false,
			passwordChanged: false
		}
	},

	validationStatus: defaultProfileDataValErrorState,
	errorMessages: defaultProfileDataValErrorState
}

export default function (state = initialState, action) {
	let profileViewData = state.profile.view.data
	let profileEditData = state.profile.edit.data

	const nonProfileFields = [
		FIELD_NAMES.ENABLE_TWO_FA_CONFIRM_PASSWORD,
		FIELD_NAMES.SECURITY_QUESTION,
		FIELD_NAMES.SECURITY_QUESTION_ANSWER,
		FIELD_NAMES.PHONE_NUMBER,
		FIELD_NAMES.PHONE_NUMBER_COUNTRY_CODE,
		FIELD_NAMES.VERIFICATION_CODE,
		FIELD_NAMES.CHANGE_TWO_FA_CONFIRM_PASSWORD,
		FIELD_NAMES.CHANGE_TWO_FA_SECURITY_QUESTION_ANSWER,
		FIELD_NAMES.CHANGE_TWO_FA_PHONE_NUMBER,
		FIELD_NAMES.CHANGE_TWO_FA_VERIFICATION_CODE,
		FIELD_NAMES.REMOVE_TWO_FA_CONFIRM_PASSWORD,
		FIELD_NAMES.REMOVE_TWO_FA_SECURITY_QUESTION_ANSWER,
		FIELD_NAMES.REMOVE_TWO_FA_PHONE_NUMBER,
		FIELD_NAMES.REMOVE_TWO_FA_VERIFICATION_CODE,
		FIELD_NAMES.NEW_EMAIL_CONFIRM_PASSWORD,
		FIELD_NAMES.NEW_EMAIL,
		FIELD_NAMES.NEW_EMAIL_VERIFICATION_CODE
	]

	const newPasswordFields = [FIELD_NAMES.NEW_PASSWORD, FIELD_NAMES.CONFIRM_NEW_PASSWORD]

	switch (action.type) {
		case ADD_CHANGE_TO_FORM:
			const newForm = { ...state.profile.edit.data }
			const changedProfileFormField = !nonProfileFields.includes(action.payload.fieldName)
			const changedNewPasswordField = newPasswordFields.includes(action.payload.fieldName)
			newForm[action.payload.fieldName] = action.payload.fieldValue
			return {
				...state,
				profile: {
					view: {
						data: state.profile.view.data,
						loading: false,
						error: null
					},

					edit: {
						data: newForm,
						loading: false,
						error: null,
						changed: changedProfileFormField,
						passwordChanged: changedNewPasswordField
					}
				}
			}

		case REVERT_CHANGES:
			const viewForm = { ...state.profile.view }
			return {
				...state,
				profile: {
					view: viewForm,
					edit: {
						data: viewForm.data,
						loading: false,
						error: null,
						changed: false,
						passwordChanged: false
					}
				}
			}

		case CHANGE_VALIDATION_STATUS:
			const newValidationStatus = { ...state.validationStatus }
			newValidationStatus[action.payload.fieldName] = action.payload.fieldValue
			return {
				...state,
				validationStatus: newValidationStatus
			}

		case CLEAR_VALIDATION_STATUSES:
			return {
				...state,
				validationStatus: defaultProfileDataValErrorState
			}

		case CHANGE_ERROR_MESSAGE:
			const newErrorMessages = { ...state.errorMessages }
			newErrorMessages[action.payload.fieldName] = action.payload.fieldValue
			return {
				...state,
				errorMessages: newErrorMessages
			}

		case CLEAR_ERROR_MESSAGES:
			return {
				...state,
				errorMessages: defaultProfileDataValErrorState
			}

		case RETRIEVE_ACCOUNT_FORM_DATA_LOADING:
			return {
				...state,
				profile: {
					view: {
						data: defaultProfileDataState,
						loading: true,
						error: null
					},
					edit: {
						data: defaultProfileDataState,
						loading: false,
						error: null,
						changed: false,
						passwordChanged: false
					}
				}
			}

		case RETRIEVE_ACCOUNT_FORM_DATA_SUCCESS:
			let data = action.payload.data
			if (localStorage.getItem("userThumbnailUrl") == null) {
				localStorage.setItem("userThumbnailUrl", data.thumbnail_url ? data.thumbnail_url : null)
			}
			let phoneNumber = ""

			if (data.phone_number) {
				let parsedNumber = parsePhoneNumber(data.phone_number)
				phoneNumber = parsedNumber.number
			}
			return {
				...state,
				profile: {
					view: {
						loading: false,
						data: {
							email: data.email ? data.email : "",
							firstName: data.first_name ? data.first_name : "",
							lastName: data.last_name ? data.last_name : "",
							username: data.username ? data.username : "",
							bio: data.bio ? data.bio : "",
							dateOfBirth: data.date_of_birth ? data.date_of_birth : "",
							userProfileUrl: data.user_profile_url ? data.user_profile_url : "",
							thumbnailUrl: data.thumbnail_url ? data.thumbnail_url : "",
							audioBioUrl: data.audio_bio_url || "",
							profileColor: data.profile_color || "",
							instagramHandle: data.instagram_handle ? data.instagram_handle : "",
							facebookHandle: data.facebook_handle ? data.facebook_handle : "",
							twitterHandle: data.twitter_handle ? data.twitter_handle : "",
							linkedinHandle: data.linkedin_handle ? data.linkedin_handle : "",
							newThumbnailFile: "",
							newEmail: "",
							newEmailConfirmPassword: "",
							currentPassword: "",
							newPassword: "",
							confirmNewPassword: "",
							enableTwoFAConfirmPassword: "",
							securityQuestion: data.security_question ? data.security_question : null,
							securityQuestionAnswer: data.security_question_answer ? data.security_question_answer : "",
							phoneNumber: phoneNumber,
							verificationCode: "",
							changeTwoFAConfirmPassword: "",
							changeTwoFASecurityQuestionAnswer: "",
							changeTwoFAPhoneNumber: "",
							changeTwoFAVerificationCode: "",
							removeTwoFAConfirmPassword: "",
							removeTwoFASecurityQuestionAnswer: "",
							removeTwoFAPhoneNumber: "",
							removeTwoFAVerificationCode: ""
						},
						error: ""
					},
					edit: {
						data: {
							email: data.email ? data.email : "",
							firstName: data.first_name ? data.first_name : "",
							lastName: data.last_name ? data.last_name : "",
							username: data.username ? data.username : "",
							bio: data.bio ? data.bio : "",
							dateOfBirth: data.date_of_birth ? data.date_of_birth : "",
							userProfileUrl: data.user_profile_url ? data.user_profile_url : "",
							thumbnailUrl: data.thumbnail_url ? data.thumbnail_url : "",
							audioBioUrl: data.audio_bio_url || "",
							profileColor: data.profile_color || "",
							instagramHandle: data.instagram_handle ? data.instagram_handle : "",
							facebookHandle: data.facebook_handle ? data.facebook_handle : "",
							twitterHandle: data.twitter_handle ? data.twitter_handle : "",
							linkedinHandle: data.linkedin_handle ? data.linkedin_handle : "",
							newThumbnailFile: "",
							newEmail: "",
							newEmailConfirmPassword: "",
							currentPassword: "",
							newPassword: "",
							confirmNewPassword: "",
							enableTwoFAConfirmPassword: "",
							securityQuestion: data.security_question ? data.security_question : null,
							securityQuestionAnswer: data.security_question_answer ? data.security_question_answer : "",
							phoneNumber: phoneNumber,
							verificationCode: "",
							changeTwoFAConfirmPassword: "",
							changeTwoFASecurityQuestionAnswer: "",
							changeTwoFAPhoneNumber: "",
							changeTwoFAVerificationCode: "",
							removeTwoFAConfirmPassword: "",
							removeTwoFASecurityQuestionAnswer: "",
							removeTwoFAPhoneNumber: "",
							removeTwoFAVerificationCode: ""
						},
						loading: false,
						error: "",
						changed: false,
						passwordChanged: false
					}
				}
			}

		case RETRIEVE_ACCOUNT_FORM_DATA_ERROR:
			return {
				...state,
				profile: {
					view: {
						data: defaultProfileDataState,
						loading: true,
						error: action.payload.error
					},
					edit: {
						data: defaultProfileDataState,
						loading: false,
						error: null,
						changed: false,
						passwordChanged: false
					}
				}
			}

		case SUBMIT_ACCOUNT_FORM_DATA_LOADING:
			return {
				...state,
				profile: {
					view: {
						data: profileViewData,
						loading: false,
						error: ""
					},
					edit: {
						data: profileEditData,
						loading: true,
						error: "",
						changed: false,
						passwordChanged: false
					}
				}
			}

		case SUBMIT_ACCOUNT_FORM_DATA_SUCCESS:
			// Remove cached image if user is uploading a new one.
			if (profileEditData.newThumbnailFile.length > 0) {
				localStorage.setItem("userThumbnailUrl", profileEditData.newThumbnailFile)
			}
			profileEditData.audioBioUrl = action?.payload?.data?.audio_bio_url
			return {
				...state,
				profile: {
					view: {
						data: profileEditData,
						loading: false,
						error: ""
					},
					edit: {
						data: profileEditData,
						loading: false,
						error: "",
						changed: false,
						passwordChanged: false
					}
				}
			}

		case SUBMIT_ACCOUNT_FORM_DATA_ERROR:
			return {
				...state,
				profile: {
					view: {
						data: profileViewData,
						loading: true,
						error: ""
					},
					edit: {
						data: profileEditData,
						loading: true,
						error: action.payload.error,
						changed: false,
						passwordChanged: false
					}
				}
			}

		default:
			return state
	}
}
