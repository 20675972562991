import axios from "axios"
import { MigrationVerification } from "./types/migrationVerification"

export const createMigrationVerification = (migrationVerification: MigrationVerification) =>
	axios.post("/api/twirp/podpal.administration.MigrationVerificationService/Create", {
		migrationVerification
	})

export const findMigrationVerification = (migrationVerificationId: string) =>
	axios.post("/api/twirp/podpal.administration.MigrationVerificationService/Find", {
		migrationVerificationId
	})

export const updateMigrationVerification = (migrationVerification: MigrationVerification) =>
	axios.post("/api/twirp/podpal.administration.MigrationVerificationService/Update", {
		migrationVerification
	})
