import {
	TOGGLE_ADD_NEW_EPISODE_DRAWER,
	TOGGLE_ADD_NEW_EPISODE_CONFIRMATION_MODAL_VISIBLE,
	CREATE_NEW_EPISODE_LOADING,
	CREATE_NEW_EPISODE_SUCCESS,
	CREATE_NEW_EPISODE_ERROR,
	SET_EPISODE_ID_FOR_CONFIRMATION_MODAL,
	SET_EPISODE_ID_FOR_INITIAL_VALUES,
	SET_EPISODE_LAST_PUBLISHED_DATE,
	TOGGLE_IMPORT_EPISODES_STATUS_MODAL_VISIBLE,
	SET_PODCAST_ID_FOR_IMPORT_EPISODES_MODAL
} from "constants/actionTypes"
import { createEpisode } from "services/episode.service"

export const toggleAddNewEpisodeDrawer = () => ({
	type: TOGGLE_ADD_NEW_EPISODE_DRAWER
})

export const toggleAddNewEpisodeConfirmationModalVisible = () => ({
	type: TOGGLE_ADD_NEW_EPISODE_CONFIRMATION_MODAL_VISIBLE
})

export const createNewEpisode = data => async dispatch => {
	dispatch({ type: CREATE_NEW_EPISODE_LOADING })

	return await createEpisode(data)
}

export const createNewEpisodeSuccess = episode => ({
	type: CREATE_NEW_EPISODE_SUCCESS,
	payload: { episode }
})

export const createNewEpisodeError = error => ({
	type: CREATE_NEW_EPISODE_ERROR,
	payload: { error }
})

export const setEpisodeIdForConfirmationModal = episodeId => ({
	type: SET_EPISODE_ID_FOR_CONFIRMATION_MODAL,
	payload: {
		episodeId
	}
})

export const setEpisodeIdForInitialValues = episodeId => ({
	type: SET_EPISODE_ID_FOR_INITIAL_VALUES,
	payload: {
		episodeId
	}
})

export const setEpisodeLastPublishedDate = lastPublishedDate => ({
	type: SET_EPISODE_LAST_PUBLISHED_DATE,
	payload: {
		lastPublishedDate
	}
})

export const toggleImportEpisodesStatusModalVisible = () => ({
	type: TOGGLE_IMPORT_EPISODES_STATUS_MODAL_VISIBLE
})

export const setPodcastIDForImportEpisodesModal = podcastID => ({
	type: SET_PODCAST_ID_FOR_IMPORT_EPISODES_MODAL,
	payload: {
		podcastID
	}
})
