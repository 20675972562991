import React from "react"

export const useEmojiPicker = () => {
	const [showEmojiPicker, setShowEmojiPicker] = React.useState(false)

	const toggleEmojiPicker = () => {
		setShowEmojiPicker(showEmojiPicker => !showEmojiPicker)
	}

	const onClickOutside = () => {
		if (showEmojiPicker) {
			setShowEmojiPicker(false)
		}
	}

	return {
		showEmojiPicker,
		toggleEmojiPicker,
		onClickOutside
	}
}
