import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { PodcastRole, FindByPodcastAndUserIDRequest, ValidateAccessRequest } from "./types/podcastRoles"

interface CreatePodcastRoleRequest {
	userId: string
	podcastId: string
	collaboratorType: number
	showAccess: number
}

export const createPodcastRole = ({ userId, podcastId, collaboratorType, showAccess }: CreatePodcastRoleRequest) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/Create", {
		requestId: uuidv4(),
		podcastRole: {
			userId,
			podcastId,
			collaboratorType,
			showAccess
		}
	})

interface CreatePodcastRolesRequest {
	podcastRoles: PodcastRole[]
}

export const createPodcastRoles = ({ podcastRoles }: CreatePodcastRolesRequest) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/CreateMulti", {
		podcastRoles
	})

export const findPodcastRoleByUserId = (userId: string) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/FindByUserId", {
		requestId: uuidv4(),
		userId
	})

export const findPodcastRoleByPodcastId = (podcastId: string) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/FindByPodcastId", {
		requestId: uuidv4(),
		podcastId
	})

export const findPodcastRoleByPodcastAndUserID = ({ podcastId, userId }: FindByPodcastAndUserIDRequest) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/FindByPodcastAndUserID", {
		podcastId,
		userId
	})

export const updatePodcastRole = (podcastRole: PodcastRole) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/Update", {
		requestId: uuidv4(),
		podcastRole
	})

export const validateAccess = ({ podcastId, userId }: ValidateAccessRequest) =>
	axios.post("/api/twirp/podpal.iam.PodcastRoleService/ValidateAccess", {
		podcastId,
		userId
	})
