import { Col, Dropdown, Menu, Row, Skeleton, Tooltip } from "antd"
import Avatar from "antd/lib/avatar/avatar"
import { MoreIcon } from "assets/icons/Common/SimpleFeedback"
import { UserIcon } from "assets/icons/Common/User"
import PodpalCard from "components/common/Cards/PodpalCard"
import { EPISODES, POD, PODCAST_SETTINGS, SETTINGS, SHOW_SETTINGS } from "constants/routes"
import { DEFAULT_PODCAST_ARTWORK_URL } from "constants/strings"
import React from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { setSelectedPodcast } from "redux/actions/App"
import { toggleAddNewEpisodeDrawer } from "redux/actions/Episodes/AddNewEpisode"
import { useEpisodes } from "services/queries/Episodes"
import { usePodcast } from "services/queries/Podcasts"
import { useUser } from "services/queries/User"
import styled from "styled-components"
import moment from "moment"
import { formatLastUpdatedTime } from "utils/time"

const StyledPodpalCard = styled(PodpalCard)`
	width: 200px;
	height: 312px;
	padding: ${({ theme }) => theme.spaceSm};

	.episode-count {
		text-align: center;
		font: ${({ theme }) => theme.fontXs};
	}

	.podcast-type {
		text-align: center;
		font: ${({ theme }) => theme.fontXs};
		text-transform: capitalize;
	}

	.podcast-title {
		text-align: center;
		font: ${({ theme }) => theme.fontSm};
		font-weight: 500;
		min-height: 45px;

		a {
			color: ${({ theme }) => theme.textPrimary};
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;

			&:hover {
				text-decoration: underline;
			}
		}
	}
`

const StyledSecondaryText = styled.span`
	font: ${({ theme }) => theme.fontXs};
	color: ${({ theme }) => theme.gray7};
`

const StyledAvatar = styled(Avatar)`
	transition: ease 0.25s;
	border: none !important;
	&:hover {
		transition: ease 0.25s;
		box-shadow: 0 0 0 3px ${({ theme }) => theme.podpalBlue};
		cursor: pointer;
	}
`

const PodcastCard = ({ podcast }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const { data: podcastData } = usePodcast(podcast.id)
	const { data: userData } = useUser(podcastData?.podcast?.lastUpdaterId)

	const { isLoading, isError, data } = useEpisodes(podcast.id)

	if (isLoading) {
		return (
			<StyledPodpalCard>
				<Col span={24}></Col>
				<Row align="middle" justify="center" gutter={[8, 8]} style={{ textAlign: "center" }}>
					<Col>
						<Skeleton.Input style={{ width: 150, height: "1em", borderRadius: "200px" }} active size="small" />
					</Col>
					<Col>
						<Skeleton.Input style={{ width: 100, height: "1em", borderRadius: "200px" }} active size="small" />
					</Col>
					<Col span={24}>
						<Skeleton.Avatar active style={{ width: 80, height: 80, borderRadius: "8px" }} shape="square" />
					</Col>
					<Col style={{ paddingTop: "1em" }}>
						<Skeleton.Input style={{ width: 50, height: "1em", borderRadius: "200px" }} active size="small" />
					</Col>
				</Row>
			</StyledPodpalCard>
		)
	}

	if (isError) {
		return <StyledPodpalCard>Error loading podcast.</StyledPodpalCard>
	}

	const menu = (
		<Menu>
			<Menu.Item
				key="addEpisode"
				onClick={({ domEvent }) => {
					domEvent.preventDefault()
					dispatch(toggleAddNewEpisodeDrawer())
					dispatch(setSelectedPodcast(podcast))
				}}
			>
				Add Episode
			</Menu.Item>
			<Menu.Item
				key="showSettings"
				onClick={({ domEvent }) => {
					domEvent.preventDefault()
					history.push(`${SETTINGS}${PODCAST_SETTINGS}/${podcast.id}${SHOW_SETTINGS}`)
				}}
			>
				Show Settings
			</Menu.Item>
			<Menu.Item
				key="visitprofile"
				onClick={({ domEvent }) => {
					domEvent.preventDefault()
					history.push(`${POD}/${podcast.profileUrl}`)
				}}
			>
				Visit Profile
			</Menu.Item>
		</Menu>
	)

	const numberOfEpisodes = data?.episodes?.length ?? 0

	return (
		<Link onClick={() => dispatch(setSelectedPodcast(podcast))} to={`/${podcast.profileUrl}${EPISODES}`}>
			<StyledPodpalCard>
				<Row gutter={[8, 8]} justify="center" align="middle">
					<Col span={20}></Col>
					<Col span={4}>
						<Dropdown overlay={menu} trigger={["click"]} className="episode-card-more">
							<MoreIcon key="trigger" />
						</Dropdown>
					</Col>
					<Col span={24}>
						<h2 className="podcast-title">
							<Link onClick={() => dispatch(setSelectedPodcast(podcast))} to={`/${podcast.profileUrl}${EPISODES}`}>
								{podcast.title}
							</Link>
						</h2>
					</Col>
					<Col>
						<Avatar
							style={{ borderRadius: "8px" }}
							shape="square"
							size={80}
							src={podcast.photoUrl ?? DEFAULT_PODCAST_ARTWORK_URL}
						/>
					</Col>
					<Col span={24} style={{ paddingTop: "12px" }}>
						<p className="podcast-type">
							<strong>{podcast.mediaFormat}</strong> Podcast
						</p>
					</Col>
					<Col span={24}>
						<p className="episode-count">
							<strong>{numberOfEpisodes}</strong> {numberOfEpisodes === 1 ? "Episode" : "Episodes"}
						</p>
					</Col>
					<Col span={24}></Col>
				</Row>
				{podcastData?.podcast?.lastUpdaterId && (
					<Row justify="space-between" align="middle" style={{ padding: "0 8px 8px 8px" }}>
						<Col>
							<Tooltip title={userData?.user?.firstName + " " + userData?.user?.lastName} placement="bottom">
								{userData?.user?.thumbnailUrl ? (
									<StyledAvatar src={userData.user.thumbnailUrl} />
								) : (
									<StyledAvatar icon={<UserIcon />} />
								)}
							</Tooltip>
						</Col>
						<Col>
							<StyledSecondaryText>
								{formatLastUpdatedTime(moment().diff(moment(podcastData?.podcast?.lastUpdatedAt)) / 1000)}
							</StyledSecondaryText>
						</Col>
					</Row>
				)}
			</StyledPodpalCard>
		</Link>
	)
}

export default PodcastCard
