import React, { useEffect, useRef, useState } from "react"
import { Avatar, Tag, Row, Col, Space } from "antd"
import { Link } from "react-router-dom"
import { POD } from "constants/routes"
import PodpalCard from "components/common/Cards/PodpalCard"
import styled from "styled-components"
import { LinkIcon } from "assets/icons/Episodes"
import { ClockCircleOutlined } from "@ant-design/icons"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { PauseIcon, PlayIcon } from "assets/icons/Common/SimpleFeedback"
import moment from "moment"
import { Recording } from "services/types/recording"
import { useMediaPlayer } from "hooks/useMediaPlayer"
import { Episode } from "services/episode.service"
import { EpisodeFlag } from "components/Episodes/EpisodeCard/EpisodeFlag"

const StyledPodpalCard = styled(PodpalCard)`
	padding-bottom: ${({ theme }) => theme.spaceXxs};

	.episode-card-title {
		a {
			font: ${({ theme }) => theme.fontMd};
			line-height: 16px;
			color: ${({ theme }) => theme.textPrimary};

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
	}

	.show-notes {
		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: normal;
		font: ${({ theme }) => theme.fontSm};
		& > * {
			font: inherit;
		}

		a {
			color: ${({ theme }) => theme.podpalBlue};
		}
	}

	.show-notes-title {
		font: ${({ theme }) => theme.fontSm};
	}

	.avatar-container {
		.avatar-overlay {
			background-color: ${({ theme }) => theme.podpalRedLight};
			text-align: center;
			position: absolute;
			bottom: 0px;
			left: 8;
			width: 88px;
			height: 14px;
			font-size: 12px;
			line-height: 15px;
			font-weight: 500;
			color: ${({ theme }) => theme.neutral0};
			border-radius: 0 0 7px 7px;
		}
	}

	.episode-card-podcast-artwork {
		border-radius: ${({ theme }) => theme.spaceXs} !important;
	}

	.episode-card-season {
		font: ${({ theme }) => theme.fontXs};
	}

	hr {
		margin-top: ${({ theme }) => theme.spaceXs};
	}

	.text-secondary {
		color: ${({ theme }) => theme.textSecondary};
	}

	.published-date {
		font: ${({ theme }) => theme.fontSm};
	}

	.link-icon svg {
		&:hover path {
			fill: ${({ theme }) => theme.podpalBlue};
		}
		path {
			fill: ${({ theme }) => theme.gray7};
		}
	}

	.read-more {
		color: ${({ theme }) => theme.podpalBlue};
		&:hover {
			color: ${({ theme }) => theme.podpalBlueHoverDark};
		}
	}
`

interface RecentEpisodeCardProps {
	episode: Episode
	recording: Recording
	podcastTitle: string
	profileUrl: string
}

export function RecentEpisodeCard({ episode, recording, podcastTitle, profileUrl }: RecentEpisodeCardProps) {
	const { title, seasonNumber, episodeNumber, label, photoUrl, id, showNotes, lastPublishedDate } = episode

	const [readMoreVisible, setReadMoreVisible] = useState(false)
	const showNotesRef = useRef<HTMLDivElement | null>(null)

	const { currentMedia, setCurrentMedia, isPlaying, setIsPlaying, setVisible } = useMediaPlayer()

	useEffect(() => {
		if (showNotesRef && showNotesRef.current) {
			if (
				showNotesRef.current.offsetHeight < showNotesRef.current.scrollHeight ||
				showNotesRef.current.offsetWidth < showNotesRef.current.scrollWidth
			) {
				setReadMoreVisible(true)
			}
		}
	}, [showNotes, showNotesRef])

	const handlePlayPauseClick = () => {
		if (!(currentMedia?.episodeId === id)) {
			setCurrentMedia(recording)
			if (!isPlaying) {
				setIsPlaying(true)
				setVisible(true)
			}
		} else if (currentMedia.episodeId === id) {
			setIsPlaying(prevState => !prevState)
			setVisible(true)
		}
	}

	return (
		<StyledPodpalCard className="recent-episode-card">
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Row gutter={8} wrap={false} justify="start">
						<Col flex="88px">
							<div className="avatar-container">
								<Avatar className="episode-card-podcast-artwork" shape="square" size={88} alt={podcastTitle} src={photoUrl} />
								<EpisodeFlag episode={episode} />
							</div>
						</Col>
						<Col flex="auto">
							<Row gutter={[0, 8]} align="middle">
								<Col flex="auto">
									<div className="episode-card-season">
										{podcastTitle} | {seasonNumber ? `Season ${seasonNumber}` : ""} {seasonNumber && episodeNumber ? "|" : ""}{" "}
										{episodeNumber ? `Episode ${episodeNumber}` : ""}
									</div>
								</Col>
								<Col>
									<LinkIcon
										className="link-icon"
										onClick={() =>
											navigator.clipboard
												.writeText(window.location.href + "/" + id)
												.then(() => successAlert("Link copied to Clipboard!"))
												.catch(() => errorAlert("Failed to copy link to clipboard."))
										}
									/>
								</Col>
								<Col span={24}>
									<div className="episode-card-title">
										<Link to={`${POD}/${profileUrl}/${episode.id}`}>{title}</Link>
									</div>
								</Col>
								<Col span={24}>
									<div>{label ? <Tag>{label}</Tag> : null}</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<p className="show-notes-title">
						<strong>Episode Summary</strong>
					</p>
					<div ref={showNotesRef} className="show-notes" dangerouslySetInnerHTML={{ __html: showNotes ?? "" }}></div>
					<Row justify="end">
						<Col>
							<Link
								className="read-more"
								style={{ display: readMoreVisible ? "block" : "none" }}
								to={`${POD}/${profileUrl}/${id}`}
							>
								Read More
							</Link>
						</Col>
					</Row>
					<hr />
				</Col>
				<Col span={24}>
					<Row align="middle">
						{/* <Col flex="15px">
						</Col> */}
						<Col flex="auto">
							<Space size={8}>
								<ClockCircleOutlined className="text-secondary" />
								<p className="text-secondary published-date">{moment(lastPublishedDate).format("MMM D, YYYY")}</p>
							</Space>
						</Col>
						<Col>
							<PodpalButton
								icon={isPlaying && currentMedia?.episodeId === id ? <PauseIcon /> : <PlayIcon />}
								type="primary"
								onClick={handlePlayPauseClick}
							>
								{isPlaying && currentMedia?.episodeId === id ? "Pause" : "Play"}
							</PodpalButton>
						</Col>
					</Row>
				</Col>
			</Row>
		</StyledPodpalCard>
	)
}
