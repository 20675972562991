import { Avatar, Divider, Menu } from "antd"
import { UserIcon } from "assets/icons/Common/User"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { PLAN_USAGE, MY_ACCOUNT, PROFILE, SETTINGS, SIGN_IN, SUBSCRIPTION_AND_BILLING } from "constants/routes"
import * as STRINGS from "constants/strings/UserSettings"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { toggleUserSettingsDropdown } from "redux/actions/Navbar/UserSettingsDropdown"
import { logoutUser } from "services/firebase.service"
import "./UserSettingsDropdown.scss"

interface DropdownContentProps {
	thumbnailUrl: string
	name: string
	email: string
	defaultPodcastRoute: string
}

export function DropdownContent({ thumbnailUrl, name, email, defaultPodcastRoute }: DropdownContentProps) {
	const dispatch = useDispatch()
	const history = useHistory()

	const handleSignout = async () => {
		try {
			await logoutUser()
			dispatch(toggleUserSettingsDropdown())
			history.push(SIGN_IN)
		} catch (e) {
			errorAlert("Failed to log you out.")
		}
	}

	return (
		<div className="user-settings-overlay">
			<div className="top-bar-popover-header-container">
				<div className="top-bar-popover-header-row">
					<div className="top-bar-popover-header-col">
						{thumbnailUrl ? <Avatar size={56} src={thumbnailUrl} /> : <Avatar size={56} icon={<UserIcon />} />}
					</div>
					<div className="top-bar-popover-header-col">
						<div className="user-settings-name-text">{name}</div>
						<div className="user-settings-email-text">{email}</div>
					</div>
				</div>
			</div>

			<Divider className="top-bar-popover-header-divider" />
			<Menu onSelect={() => dispatch(toggleUserSettingsDropdown())}>
				<Menu.Item key="1">
					<Link to={`${SETTINGS}${MY_ACCOUNT}${PROFILE}`}>{STRINGS.MY_ACCOUNT}</Link>
				</Menu.Item>
				<Menu.Item key="2">
					<Link to={defaultPodcastRoute}>{STRINGS.PODCAST_SETTINGS}</Link>
				</Menu.Item>
				<Menu.Item key="3">
					<Link to={`${SETTINGS}${SUBSCRIPTION_AND_BILLING}${PLAN_USAGE}`}>{STRINGS.SUBSCRIPTION_AND_BILLING}</Link>
				</Menu.Item>
				{/* <Menu.Item key="4">
					<Link to={`${SETTINGS}${NOTIFICATIONS}`}>{STRINGS.NOTIFICATIONS}</Link>
				</Menu.Item>
				<Menu.Item key="5">
					<Link to={`${SETTINGS}${TELL_YOUR_FRIENDS}`}>{STRINGS.TELL_YOUR_FRIENDS}</Link>
				</Menu.Item> */}
				<Divider className="top-bar-popover-menu-divider" />
				<Menu.Item onClick={handleSignout}>Sign Out</Menu.Item>
			</Menu>
		</div>
	)
}
