export default function useDragNDrop(list, afterDragEnd) {
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	const onDragEnd = async result => {
		// dropped outside the list
		if (!result.destination) return

		const newList = reorder(list, result.source.index, result.destination.index)
		afterDragEnd(newList)
	}

	return {
		onDragEnd
	}
}
