import {
	LINK_GOOGLE_ACCOUNT_LOADING,
	LINK_GOOGLE_ACCOUNT_SUCCESS,
	LINK_GOOGLE_ACCOUNT_ERROR,
	LINK_FACEBOOK_ACCOUNT_LOADING,
	LINK_FACEBOOK_ACCOUNT_SUCCESS,
	LINK_FACEBOOK_ACCOUNT_ERROR,
	// UNLINK_FACEBOOK_ACCOUNT_LOADING,
	UNLINK_FACEBOOK_ACCOUNT_SUCCESS,
	// UNLINK_FACEBOOK_ACCOUNT_ERROR,
	// UNLINK_GOOGLE_ACCOUNT_LOADING,
	UNLINK_GOOGLE_ACCOUNT_SUCCESS,
	// UNLINK_GOOGLE_ACCOUNT_ERROR,
	SET_FACEBOOK_AUTHENTICATION_ON_LOAD,
	SET_GOOGLE_AUTHENTICATION_ON_LOAD
} from "constants/actionTypes"

const initialState = {
	isAuthenticatedWithGoogle: false,
	isAuthenticatedWithFacebook: false,
	error: "",
	loading: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case LINK_FACEBOOK_ACCOUNT_LOADING:
			return {
				...state,
				loading: true
			}
		case LINK_GOOGLE_ACCOUNT_LOADING:
			return {
				...state,
				loading: true
			}

		case LINK_FACEBOOK_ACCOUNT_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error
			}

		case LINK_GOOGLE_ACCOUNT_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload.error
			}

		case LINK_FACEBOOK_ACCOUNT_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticatedWithFacebook: true
			}

		case LINK_GOOGLE_ACCOUNT_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticatedWithGoogle: true
			}

		case UNLINK_FACEBOOK_ACCOUNT_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticatedWithFacebook: false
			}

		case UNLINK_GOOGLE_ACCOUNT_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticatedWithGoogle: false
			}

		case SET_FACEBOOK_AUTHENTICATION_ON_LOAD:
			return {
				...state,
				isAuthenticatedWithFacebook: action.payload
			}

		case SET_GOOGLE_AUTHENTICATION_ON_LOAD:
			return {
				...state,
				isAuthenticatedWithGoogle: action.payload
			}
		default:
			return state
	}
}
