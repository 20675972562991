import React from "react"
import { Input } from "antd"
import { ChromePicker } from "react-color"
import "./index.scss"

const ColorPicker = ({
	selectedColor,
	handleColorPickerClick,
	handleColorPickerClose,
	handleColorChange,
	showColorPicker
}) => {
	return (
		<>
			<div style={{ display: "flex" }}>
				<div className="color-picker-swatch" style={{ background: selectedColor }} onClick={handleColorPickerClick} />
				<Input
					className="color-picker-input"
					name="color"
					placeholder="Choose a color theme"
					value={selectedColor}
					onClick={handleColorPickerClick}
				/>
			</div>
			{showColorPicker && (
				<div className="color-picker">
					<div className="color-picker-clickaway" onClick={handleColorPickerClose} />
					<ChromePicker color={selectedColor} onChange={handleColorChange} />
				</div>
			)}
		</>
	)
}

export default ColorPicker
