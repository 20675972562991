import { useQuery } from "react-query"
import { findMigrationInfoByPodcastID } from "services/migrationInfo.service"
import { keysToCamel } from "utils/camelCaseUtils"

export const useMigrationInfo = podcastID => {
	return useQuery(
		["migrationInfo", podcastID],
		async () => {
			if (!podcastID) return null
			const { data } = await findMigrationInfoByPodcastID(podcastID)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!podcastID, retry: 3, refetchInterval: 10000 }
	)
}
