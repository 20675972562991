import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { Episode } from "services/episode.service"
import { useEpisode } from "services/queries/Episodes"
import { usePodcast } from "services/queries/Podcasts"
import { Podcast } from "services/types/podcast"
import { Recording } from "../services/types/recording"

interface IMediaPlayerContext {
	setCurrentMedia: (media: Recording | null) => void
	currentMedia: Recording | null
	episode: Episode | null
	podcast: Podcast | null
	isPlaying: boolean
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
	visible: boolean
	setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const MediaPlayerContext = createContext<IMediaPlayerContext>({
	setCurrentMedia: () => {},
	currentMedia: null,
	episode: null,
	podcast: null,
	isPlaying: false,
	setIsPlaying: () => {},
	visible: false,
	setVisible: () => {}
})

export const useMediaPlayer = () => useContext(MediaPlayerContext)

interface MediaPlayProviderProps {
	children: ReactNode
}

export function MediaPlayerProvider({ children }: MediaPlayProviderProps) {
	const [currentMedia, setCurrentMedia] = useState<Recording | null>(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [visible, setVisible] = useState(false)
	const [podcast, setPodcast] = useState<Podcast | null>(null)
	const [episode, setEpisode] = useState<Episode | null>(null)

	const { data: episodeData } = useEpisode(currentMedia?.episodeId)
	const { data: podcastData } = usePodcast(currentMedia?.podcastId)

	useEffect(() => {
		if (episodeData) {
			setEpisode(episodeData.episode)
		}
	}, [episodeData])

	useEffect(() => {
		if (podcastData) {
			setPodcast(podcastData.podcast)
		}
	}, [podcastData])

	const contextValue = useMemo<IMediaPlayerContext>(
		() => ({
			setCurrentMedia,
			currentMedia,
			episode,
			podcast,
			isPlaying,
			setIsPlaying,
			visible,
			setVisible
		}),
		[currentMedia, setCurrentMedia, episode, podcast, isPlaying, setIsPlaying, visible, setVisible]
	)

	return <MediaPlayerContext.Provider value={contextValue}>{children}</MediaPlayerContext.Provider>
}
