import { Button } from "antd"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import React from "react"
import { useDispatch } from "react-redux"
import { toggleImportPodcastModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"
import styled from "styled-components"

const StyledButton = styled(Button)`
	width: 117px;
	height: 117px;
	background: ${({ theme }) => theme.neutral1};
	border: 1px dashed ${({ theme }) => theme.textSecondary};
	color: ${({ theme }) => theme.textSecondary};
	font-size: 24px;
	border-radius: ${({ theme }) => theme.radiusLg};

	&:hover,
	&:focus,
	&:active {
		background: ${({ theme }) => theme.neutral1};
		border: 1px dashed ${({ theme }) => theme.podpalBlue};
		color: ${({ theme }) => theme.podpalBlue};
	}
`

export function AddPodcastButton() {
	const dispatch = useDispatch()
	const { exceedsPodcastLimit } = useLimitChecker()

	return (
		<StyledButton
			onClick={() => {
				if (exceedsPodcastLimit()) {
					dispatch(toggleLimitsExceededModalVisible())
				} else {
					dispatch(toggleImportPodcastModalVisible())
				}
			}}
		>
			+
		</StyledButton>
	)
}
