import React from "react"
import Icon from "@ant-design/icons"

const ARROW_BACK_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="16px" height="16px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
	</svg>
)

export const ArrowBackIcon = props => <Icon component={ARROW_BACK_SVG} {...props} />
