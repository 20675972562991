import React from "react"
import { Upload } from "antd"
import { PlusIcon } from "assets/icons/UserSettings/MyPodcasts"
import "./ImageUpload.scss"

const ImageUpload = ({ beforeUpload, className, imageUrl }) => {
	const uploadButton = (
		<div className="image-upload-button">
			<PlusIcon />
			<div>Upload</div>
		</div>
	)
	return (
		<Upload
			accept=".jpg, .jpeg, .png"
			beforeUpload={beforeUpload}
			className={`image-upload ${className}`}
			listType="picture-card"
		>
			<div className="image-upload-container">
				{imageUrl ? <img className="image-upload-image" src={imageUrl} alt="avatar" /> : uploadButton}
				<div className="image-upload-thumbnail-overlay"></div>
			</div>
		</Upload>
	)
}

export default ImageUpload
