import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col, DatePicker, TimePicker, Form } from "antd"
import PodpalModal from "components/common/Modals/PodpalModal"
import {
	setEpisodeIdForScheduledReleaseDateModal,
	toggleChangeScheduledReleaseDateModalVisible
} from "redux/actions/Episodes"
import { FIELD_NAMES } from "constants/strings/UserSettings/MyPodcasts/Form"
import moment from "moment"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { publishEpisode, updateEpisode } from "services/episode.service"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { useQueryClient } from "react-query"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { useEpisode } from "services/queries/Episodes"
import { PUBLISH_SUCCESSFUL, REPUBLISH_SUCCESSFUL } from "constants/strings/Episodes"
import useToggle from "hooks/useToggle"

export function ChangeScheduledReleaseDateModal() {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()
	const [form] = Form.useForm()

	const authUser = useSelector(state => state.app.authUser)
	const [releaseDate, setReleaseDate] = useState(null)
	const [releaseTime, setReleaseTime] = useState(null)

	const [loading, toggleLoading] = useToggle(false)

	const visible = useSelector(state => state.episode.changeScheduledReleaseDateModalVisible)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const episodeId = useSelector(state => state.episode.scheduledReleaseDateModalEpisodeID)

	const { data } = useEpisode(episodeId)

	useEffect(() => {
		if (data?.episode?.lastPublishedDate) {
			const date = moment(data.episode.lastPublishedDate)
			setReleaseDate(date)
			setReleaseTime(date)
		} else if (data?.episode?.scheduledReleaseDate) {
			const date = moment(data.episode.scheduledReleaseDate)
			setReleaseDate(date)
			setReleaseTime(date)
		} else if (data?.episode?.plannedReleaseDate) {
			const date = moment(data.episode.plannedReleaseDate)
			setReleaseDate(date)
			setReleaseTime(date)
		}

		return () => {
			setReleaseTime(moment())
			setReleaseDate(moment())
		}
	}, [data])

	const handleCancel = () => {
		dispatch(setEpisodeIdForScheduledReleaseDateModal(null))
		dispatch(toggleChangeScheduledReleaseDateModalVisible())
	}

	const handleSave = async () => {
		try {
			let datetime = releaseDate
			if (datetime) {
				datetime.set({ hour: releaseTime.get("hour"), minute: releaseTime.get("minute"), seconds: 0 })
			} else if (releaseTime) {
				throw new Error("Release Date exists but Release Time has a value. Both must have value to save a Release Date.")
			}

			if (datetime.isAfter(moment())) {
				toggleLoading()
				try {
					await updateEpisode({
						...data.episode,
						[FIELD_NAMES.SCHEDULED_RELEASE_DATE]: datetime,
						requestUserId: authUser.uid
					})
					successAlert("Successfully scheduled episode for publishing!")
					dispatch(toggleChangeScheduledReleaseDateModalVisible())
					dispatch(setEpisodeIdForScheduledReleaseDateModal(null))
					queryClient.invalidateQueries(["episodes", data.episode.podcastId])
					queryClient.invalidateQueries(["episode", episodeId])
					setReleaseDate(null)
					setReleaseTime(null)
					toggleLoading()
				} catch (e) {
					errorAlert("Failed to schedule episode for publishing.")
					toggleLoading()
				}
			} else {
				toggleLoading()
				try {
					await publishEpisode({
						podcastId: selectedPodcast?.id,
						episodeId: episodeId,
						manualTrigger: true
					})
					successAlert(data?.episode?.isPublished ? REPUBLISH_SUCCESSFUL : PUBLISH_SUCCESSFUL)
					queryClient.invalidateQueries(["episode", episodeId])
					toggleLoading()
					dispatch(setEpisodeIdForScheduledReleaseDateModal(null))
					dispatch(toggleChangeScheduledReleaseDateModalVisible())
				} catch (e) {
					errorAlert("Error publishing episode.")
					toggleLoading()
				}
			}
		} catch (e) {
			if (!releaseDate && releaseTime) {
				errorAlert("Release Date required to save.")
			} else if (!releaseTime && releaseDate) {
				errorAlert("Release Time required to save.")
			} else {
				errorAlert("Unable to save Release Date.")
			}
		}
	}

	return (
		<PodpalModal
			centered
			className="change-release-date-modal"
			visible={visible}
			title="Confirm Publishing Date"
			onOk={handleSave}
			onCancel={handleCancel}
			width={420}
			destroyOnClose
			footer={[
				<PodpalButton variant="secondary" onClick={handleCancel}>
					Cancel
				</PodpalButton>,
				<PodpalButton type="primary" onClick={handleSave} disabled={!releaseDate || !releaseTime} loading={loading}>
					Confirm
				</PodpalButton>
			]}
		>
			<Form form={form} onFinish={handleSave}>
				<Form.Item name={FIELD_NAMES.SCHEDULED_RELEASE_DATE} rules={[{ required: true }]}>
					<Row gutter={[0, 12]}>
						<Col>
							<p>
								Please confirm that you would like to publish this episode at the scheduled date and time shown below. If not,
								please change the publishing date now.
							</p>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={14}>
							<DatePicker
								disabledDate={current => {
									return current && current < moment().subtract(1, "d") // Need to include current day in selectable options.
								}}
								style={{ width: "100%" }}
								value={releaseDate}
								onChange={time => setReleaseDate(time)}
								format="dddd, M/D/YYYY"
								size="large"
							/>
						</Col>
						<Col span={10}>
							<TimePicker
								style={{ width: "100%" }}
								use12Hours
								format="h:mm A"
								value={releaseTime}
								onChange={time => setReleaseTime(time)}
								size="large"
							/>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</PodpalModal>
	)
}
