import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import useWindowSize from "hooks/useWindowSize"
import { Row, Col } from "antd"
import { toggleAddNewPodcastDrawer, toggleImportPodcastModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { toggleUserSettingsSidebarCollapsed } from "redux/actions/UserSettings/UserSettingsSidebar"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { NewPodcastFormDrawer } from "components/common/Drawers/NewPodcastFormDrawer"
import emptyPodcastImage from "assets/podcasts/emptypodcast.svg"
import emptyPodcastMobileImage from "assets/podcasts/emptypodcast-mobile.svg"
import { BREAKPOINT_XS } from "constants/breakpoints"
import "./EmptyPodcastPage.scss"

export const EmptyPodcastPage = () => {
	const dispatch = useDispatch()
	const addNewPodcastDrawerVisible = useSelector(state => state.addNewPodcast.addNewPodcastDrawerVisible)
	const userSettingsSidebarCollapsed = useSelector(state => state.userSettingsSidebar.collapsed)

	const size = useWindowSize()
	const isMobile = size.width ? size.width <= BREAKPOINT_XS : false

	useEffect(() => {
		if (isMobile && !userSettingsSidebarCollapsed) {
			dispatch(toggleUserSettingsSidebarCollapsed())
		}
	}, [isMobile, dispatch, userSettingsSidebarCollapsed])

	const emptyPodcastBanner = (
		<Col>
			<Row>
				<img src={isMobile ? emptyPodcastMobileImage : emptyPodcastImage} alt="empty-podcast" />
			</Row>
		</Col>
	)

	return (
		<Row align="middle" justify="center" className="empty-podcast-page" gutter={isMobile ? 0 : 48}>
			<Col>
				<Row justify={isMobile ? "center" : null}>
					<Col>
						<h2>
							Everything you need to
							<br />
							manage your podcast!
						</h2>
					</Col>
				</Row>
				<Row gutter={[0, 24]}>
					<Col>
						{isMobile ? (
							<p>
								Podpal helps you plan, publish, and promote your show without all the stress and clutter. Start from scratch or
								import your existing RSS feed to get started.
							</p>
						) : (
							<p>
								Podpal helps you plan, publish, and promote your show
								<br />
								without all the stress and clutter. Start from scratch or
								<br />
								import your existing RSS feed to get started.
							</p>
						)}
					</Col>
				</Row>
				{isMobile && <Row gutter={[0, { xs: 24 }]}>{emptyPodcastBanner}</Row>}
				<Row gutter={[12, { xs: 16 }]}>
					<Col xs={24} sm={8}>
						{isMobile ? (
							<PodpalButton
								shape="round"
								fullWidth
								type="primary"
								size="large"
								onClick={() => dispatch(toggleAddNewPodcastDrawer())}
							>
								New Podcast
							</PodpalButton>
						) : (
							<PodpalButton type="primary" size="large" onClick={() => dispatch(toggleAddNewPodcastDrawer())}>
								New Podcast
							</PodpalButton>
						)}
					</Col>
					<Col xs={24} sm={8}>
						{isMobile ? (
							<PodpalButton
								shape="round"
								fullWidth
								type="primary"
								variant="outlined"
								size="large"
								onClick={() => dispatch(toggleImportPodcastModalVisible())}
							>
								Import an existing show
							</PodpalButton>
						) : (
							<PodpalButton
								size="large"
								type="primary"
								variant="outlined"
								className="import-existing-button"
								onClick={() => dispatch(toggleImportPodcastModalVisible())}
							>
								Import an existing show
							</PodpalButton>
						)}
					</Col>
				</Row>
			</Col>
			{!isMobile && emptyPodcastBanner}
			<NewPodcastFormDrawer
				maskClosable={false}
				visible={addNewPodcastDrawerVisible}
				onClose={() => dispatch(toggleAddNewPodcastDrawer())}
			/>
		</Row>
	)
}
