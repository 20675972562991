import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import configureStore from "./redux/store"
import { ThemeProvider } from "styled-components"
import { PersistGate } from "redux-persist/integration/react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import layoutVariables from "styles/scss/layout.scss"
import colors from "styles/scss/color.scss"
import typeDefinitions from "styles/scss/type.scss"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

const theme = {
	primary: colors.podpalBlue,
	primaryHoverDark: colors.podpalBlueHoverDark,
	...colors,
	...layoutVariables,
	...typeDefinitions
}

const { store, persistor } = configureStore()
const queryClient = new QueryClient()

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "", { apiVersion: "2020-08-27" })

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const Index = (
	<Provider store={store}>
		<PersistGate loading={<div>loading...</div>} persistor={persistor}>
			<Elements stripe={stripe}>
				<ThemeProvider theme={theme}>
					<QueryClientProvider client={queryClient}>
						<App />
						<ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
					</QueryClientProvider>
				</ThemeProvider>
			</Elements>
		</PersistGate>
	</Provider>
)

ReactDOM.render(Index, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
