import React, { useContext } from "react"
import { Row, Col } from "antd"
import styled, { DefaultTheme, ThemeContext } from "styled-components"
import { useHistory } from "react-router-dom"
import podpalLogo from "assets/Logo_Black.png"
import PodpalLogoWhite from "assets/logo.png"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { GET_STARTED } from "constants/routes"
import notFoundImage from "assets/notfound.svg"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"

interface TextProps {
	font: keyof DefaultTheme
	color: DefaultTheme
}

const Text = styled.span.attrs((props: TextProps) => ({
	font: props.font,
	color: props.color
}))`
	font: ${({ font }) => font};
	color: ${({ color }) => color};
`
const MobileTopBar = styled(Row)`
	width: 100%;
	height: 64px;
	background: ${({ theme }) => theme.podpalBlue};
	padding: 0px 35px;
`

const StyledNotFoundPage = styled.div`
	width: 100%;
	height: 100vh;
	padding: ${props => props.theme.spaceLg};
	background: ${props => props.theme.neutral1};
	overflow-y: scroll;

	.logo {
		width: 151px;
		height: 59px;
	}

	.private-beta-mail {
		color: ${props => props.theme.podpalBlue};
	}

	a {
		color: ${({ theme }) => theme.podpalBlue};
	}

	@media screen and (max-width: ${({ theme }) => theme.xs}) {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`

export function NotFoundPage() {
	const history = useHistory()
	const theme = useContext(ThemeContext)
	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	return (
		<>
			{isMobile && (
				<MobileTopBar justify="space-between" align="middle">
					<Col>
						<a href="https://www.podpal.com/">
							<img src={PodpalLogoWhite} alt="podpal" className="logo" height={30} />
						</a>
					</Col>
					<Col>
						<Row justify="center" align="middle" gutter={8}>
							<Col>
								<PodpalButton variant="white" size="large" href={GET_STARTED}>
									Get Started
								</PodpalButton>
							</Col>
						</Row>
					</Col>
				</MobileTopBar>
			)}
			<StyledNotFoundPage>
				{!isMobile && (
					<Row justify="space-between" align="middle" gutter={[0, 106]}>
						<Col>
							<a href="https://www.podpal.com/">
								<img src={podpalLogo} className="logo" alt="podpal-logo" />
							</a>
						</Col>
						<Col>
							<Row align="middle" gutter={24}>
								<Col>New to Podpal?</Col>
								<Col>
									<PodpalButton
										type="primary"
										size="large"
										onClick={() => {
											history.push(GET_STARTED)
										}}
									>
										Get Started
									</PodpalButton>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<Row justify="center" align="middle" gutter={[0, 48]}>
					<Col style={{ textAlign: "center" }}>
						<Text font={theme.fontLgPlus} color={theme.textPrimary}>
							We’re sorry but this page doesn’t exist.
						</Text>
					</Col>
				</Row>
				<Row justify="center" align="middle" gutter={[0, 24]}>
					<Col style={{ textAlign: "center" }}>
						<img src={notFoundImage} alt="not-found" />
					</Col>
				</Row>
				<Row justify="center" align="middle">
					<Col style={{ textAlign: "center" }}>
						<Text font={theme.fontMd} color={theme.textPrimary}>
							Please check the URL and contact us if you believe you’ve reached this page in error.
						</Text>
					</Col>
				</Row>
				<Row justify="center" align="middle">
					<Col style={{ textAlign: "center" }}>
						<a href="mailto:support@podpal.com">Contact Support</a>
					</Col>
				</Row>
			</StyledNotFoundPage>
		</>
	)
}
