import React, { useEffect } from "react"
import styled from "styled-components"
import { Progress, Row, Col, Upload, Tooltip } from "antd"
import { usePodcastRecordings } from "services/queries/Recordings"
import { usePodcastInfo } from "services/queries/PodcastInfo"
import RecordingsTable from "components/Recordings/RecordingsTable"
import PodpalButton from "components/common/Buttons/PodpalButton"
import colors from "styles/scss/color.scss"
import { useSelector } from "react-redux"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import { mapSubscriptionTypeToPodcastLimit } from "constants/planLimits"
import { formatFileSize } from "utils/recording"
import { usePodcastRecordingsUpload } from "hooks/Recordings/usePodcastRecordingsUpload"
import { usePodcastAccessValidator } from "services/queries/PodcastRole"
import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
import recordingsZeroStateImage from "assets/zerostates/Recordings.svg"
import { useUserInfo } from "services/queries/UserInfo"
import { NO_ACCESS } from "constants/routes"
import { useHistory } from "react-router"

const StyledSecondaryHeading = styled.span`
	font: ${props => props.theme.fontSm};
	color: ${props => props.theme.gray7};
`

const StyledHeading = styled.span`
	font: ${props => props.theme.fontLg};
	color: ${props => props.theme.textPrimary};
`

const StyledDangerText = styled.span`
	font: ${props => props.theme.fontSm};
	color: ${props => props.theme.podpalRedDark};
`

const StyledSecondaryText = styled.span`
	font: ${props => props.theme.fontSm};
	color: ${props => props.theme.textSecondary};
`

const StyledPrimaryText = styled.span`
	font: ${props => props.theme.fontSm};
	color: ${props => props.theme.textPrimary};
`

const RecordingsPage = () => {
	const size = useWindowSize()

	const history = useHistory()
	const authUser = useSelector(state => state.app.authUser)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const isMobile = size.width <= BREAKPOINT_XS

	const { isLoading: recordingsLoading, error: recordingsError, data: recordingsData } = usePodcastRecordings(
		selectedPodcast?.id
	)
	const { data: podcastInfoData } = usePodcastInfo(selectedPodcast?.id)
	const { data: userInfoData } = useUserInfo(authUser.uid)

	const {
		isLoading: podcastAccessLoading,
		error: podcastAccessError,
		data: podcastAccessData
	} = usePodcastAccessValidator({ userId: authUser?.uid, podcastId: selectedPodcast?.id })

	const isAudioPodcast = selectedPodcast?.mediaFormat === "audio"

	const { beforeUpload, uploadMedia } = usePodcastRecordingsUpload(selectedPodcast?.id)

	const recordingUpload = (
		<Upload
			accept={isAudioPodcast ? ".mp3,.m4a" : ".mp4,.m4v,.mov"}
			beforeUpload={beforeUpload}
			showUploadList={false}
			customRequest={uploadMedia}
		>
			<PodpalButton type="primary" size="large" disabled={!selectedPodcast}>
				Upload Recording
			</PodpalButton>
		</Upload>
	)

	const uploadButton = !selectedPodcast ? (
		<Tooltip placement="bottomRight" title="Create a podcast to upload a recording.">
			{recordingUpload}
		</Tooltip>
	) : (
		recordingUpload
	)

	useEffect(() => {
		if (!podcastAccessLoading && !podcastAccessError && podcastAccessData && !podcastAccessData.hasAccess) {
			history.push(NO_ACCESS)
		}
	}, [history, podcastAccessLoading, podcastAccessError, podcastAccessData])

	if (
		!recordingsLoading &&
		!recordingsError &&
		(!recordingsData?.recordingsWithEpisodes || recordingsData.recordingsWithEpisodes.length === 0)
	) {
		return (
			<div className="home-page-container">
				<Row justify="center" align="middle" style={{ height: "100%" }}>
					<Col>
						<ZeroStateCard
							title="Upload Your Recording"
							description="There’s no need to store your files in multiple places. You can save your recordings in Podpal and give your team access!"
							image={recordingsZeroStateImage}
							customPrimaryButton={uploadButton}
						/>
					</Col>
				</Row>
			</div>
		)
	}

	const planLimits = mapSubscriptionTypeToPodcastLimit(userInfoData?.userInfo.subscriptionType)
	const recordingStorageUsed = podcastInfoData?.podcastInfo?.recordingStorageUsed ?? 0
	const recordingStorageAllowed = planLimits.recordingStorageLimit * Math.pow(1024, 3)
	const percentRecordingStorageUsed = (recordingStorageUsed / recordingStorageAllowed) * 100

	return (
		<div className="home-page-container">
			{!isMobile && (
				<Row justify="start" align="middle" gutter={[0, 12]}>
					<Col>
						<StyledSecondaryHeading>{selectedPodcast?.title}</StyledSecondaryHeading>
					</Col>
				</Row>
			)}
			<Row justify="start" align="middle" gutter={[0, 12]}>
				<Col span={24}>
					<StyledHeading>Recordings</StyledHeading>
				</Col>
			</Row>
			<Row justify="space-between" align="middle" gutter={[0, 24]} style={{ minWidth: "354px" }}>
				<Col xs={12} xl={8}>
					<Row>
						<Col xs={24}>
							<StyledPrimaryText>Cloud Storage Used</StyledPrimaryText>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Row gutter={8}>
								<Col xs={16} sm={8} xl={8}>
									<Progress
										percent={percentRecordingStorageUsed}
										strokeColor={percentRecordingStorageUsed < 100 ? colors.podpalBlue : colors.podpalRedDark}
										trailColor={colors.neutral2}
										showInfo={false}
									/>
								</Col>
								<Col xs={24} sm={12} xl={8}>
									{percentRecordingStorageUsed < 100 ? (
										<StyledSecondaryText>
											<strong>{`${formatFileSize(podcastInfoData?.podcastInfo?.recordingStorageUsed, false)}`}</strong>
											{` of ${planLimits.recordingStorageLimit}GB`}
										</StyledSecondaryText>
									) : (
										<>
											<StyledDangerText>
												<strong>{`${formatFileSize(podcastInfoData?.podcastInfo?.recordingStorageUsed, false)}`}</strong>
											</StyledDangerText>
											<StyledSecondaryText>{` of ${planLimits.recordingStorageLimit}GB`}</StyledSecondaryText>
										</>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col>{recordingUpload}</Col>
			</Row>
			<Row justify="center" align="middle">
				<Col span={24}>
					<RecordingsTable />
				</Col>
			</Row>
		</div>
	)
}

export default RecordingsPage
