import { BONUS_TRAILER_TYPE_MAP, EPISODE_TYPE_OPTIONS } from "constants/strings/Episodes"

export const formatFileSize = (filesize, hasSpace) => {
	if (!filesize || filesize === 0) {
		return "0GB"
	}
	const suffixes = ["KB", "MB", "GB"]
	let currSize = parseInt(filesize)
	let currSuffix = null
	let i = 0
	while (currSize >= 1000) {
		currSize /= 1024
		currSuffix = suffixes[i++]
	}

	if (currSuffix === "KB" || currSuffix === "MB") {
		currSize = currSize.toFixed(1)
	} else if (currSuffix === "GB") {
		currSize = currSize.toFixed(2)
	}

	const space = hasSpace ? " " : ""

	return currSize + space + currSuffix
}

export const mapFileTypeToExtension = fileType => {
	switch (fileType) {
		case "audio/mpeg":
			return ".mp3"
		case "audio/x-m4a":
			return ".m4a"
		case "video/mp4":
			return ".mp4"
		case "video/x-m4v":
			return ".m4v"
		case "video/quicktime":
			return ".mov"
		default:
			return null
	}
}

export const mapToEpisodeType = episodeType => {
	switch (episodeType) {
		case EPISODE_TYPE_OPTIONS.TRAILER:
			return "Trailer"
		case EPISODE_TYPE_OPTIONS.BONUS:
			return "Bonus"
		default:
			return "Full"
	}
}

export const mapEpisodeTypeToText = ({ episodeType, trailerType, bonusType }) => {
	if (episodeType === EPISODE_TYPE_OPTIONS.TRAILER) {
		return BONUS_TRAILER_TYPE_MAP[trailerType] || "Show Trailer"
	} else if (episodeType === EPISODE_TYPE_OPTIONS.BONUS) {
		// if bonus use bonusType as version
		return BONUS_TRAILER_TYPE_MAP[bonusType] || "Show Bonus"
	} else {
		return "Full Episode"
	}
}
