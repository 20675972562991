import React from "react"
import Icon, { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import colors from "styles/scss/color.scss"

const CURRENT_PLAN_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="rgb(77, 77, 77)">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
	</svg>
)

export const CurrentPlanIcon = props => <Icon component={CURRENT_PLAN_SVG} {...props} />

const CURRENT_PLAN_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="rgb(78, 115, 255)">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z" />
	</svg>
)

export const CurrentPlanFilledIcon = props => <Icon component={CURRENT_PLAN_FILLED_SVG} {...props} />

const OFFERS_AND_UPGRADES_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="16px" height="16px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z" />
		<circle cx="6.5" cy="6.5" r="1.5" />
	</svg>
)

export const OffersAndUpgradesIcon = props => <Icon component={OFFERS_AND_UPGRADES_SVG} {...props} />

// const CURRENT_PLAN_HOVER_SVG = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z"/><circle cx="6.5" cy="6.5" r="1.5"/></svg>
// );

// export const CurrentPlanHoverIcon = props => <Icon component={CURRENT_PLAN_HOVER_SVG} {...props} />;

const OFFERS_AND_UPGRADES_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="16px" height="16px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z" />
	</svg>
)

export const OffersAndUpgradesFilledIcon = props => <Icon component={OFFERS_AND_UPGRADES_FILLED_SVG} {...props} />

const PAYMENT_METHOD_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
	</svg>
)

export const PaymentMethodIcon = props => <Icon component={PAYMENT_METHOD_SVG} {...props} />

const INVOICES_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19.5 3.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5zM19 19.09H5V4.91h14v14.18zM6 15h12v2H6zm0-4h12v2H6zm0-4h12v2H6z" />
	</svg>
)

export const InvoicesIcon = props => <Icon component={INVOICES_SVG} {...props} />

const INVOICES_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z" />
	</svg>
)

export const InvoicesFilledIcon = props => <Icon component={INVOICES_FILLED_SVG} {...props} />

const PLAN_CHECK_SVG = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="14px"
		viewBox="0 0 24 24"
		width="14px"
		fill={colors.podpalBlueHoverLight}
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
	</svg>
)

export const PlanCheckIcon = props => <Icon component={PLAN_CHECK_SVG} {...props} />

const QUESTION_MARK_SVG = () => (
	<svg height="14px" viewBox="0 0 24 24" width="14px" fill={colors.gray7}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
	</svg>
)

export const QuestionMarkIcon = props => <Icon component={QUESTION_MARK_SVG} {...props} />

export const SuccessIcon = props => <CheckCircleFilled style={{ color: colors.podpalGreenLight }} {...props} />

export const ErrorIcon = props => <CloseCircleFilled style={{ color: colors.podpalRedLight }} {...props} />

const WHITE_GLOVE_SERVICE_FEATURE_CHECKMARK_SVG = () => (
	<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.00109 10.2392L2.50109 6.73919C2.11109 6.34919 1.49109 6.34919 1.10109 6.73919C0.711094 7.12919 0.711094 7.74919 1.10109 8.13919L5.29109 12.3292C5.68109 12.7192 6.31109 12.7192 6.70109 12.3292L17.3011 1.73919C17.6911 1.34919 17.6911 0.729192 17.3011 0.339192C16.9111 -0.0508081 16.2911 -0.0508081 15.9011 0.339192L6.00109 10.2392Z"
			fill="currentColor"
		></path>
	</svg>
)

export const WhiteGloveServiceFeatureCheckmark = props => (
	<Icon component={WHITE_GLOVE_SERVICE_FEATURE_CHECKMARK_SVG} {...props} />
)
