import React from "react"
import Icon from "@ant-design/icons"

const PODCAST_DROPDOWN_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z" />
	</svg>
)

export const PodcastDropdownIcon = props => <Icon component={PODCAST_DROPDOWN_SVG} {...props} />

const LAUNCH_ICON_SVG = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path
			d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z"
			fill="white"
		/>
	</svg>
)

export const LaunchIcon = props => <Icon component={LAUNCH_ICON_SVG} {...props} />

const ADD_PODCAST_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="16px" height="16px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
	</svg>
)

export const AddPodcastIcon = props => <Icon component={ADD_PODCAST_SVG} {...props} />

const ADD_PODCAST_HEADER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" width="20px" height="20px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
	</svg>
)
export const AddPodcastHeaderIcon = props => <Icon component={ADD_PODCAST_HEADER_SVG} {...props} />

const PODCASTS_SVG = () => (
	<svg width="18" height="16" viewBox="0 0 14 19" fill="#4D4D4D" stroke="#4D4D4D" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9 9C9 10.1077 8.10772 11 7 11C5.89228 11 5 10.1077 5 9V3C5 1.89228 5.89228 1 7 1C8.10772 1 9 1.89228 9 3V9Z"
			stroke="inherit"
			fill="none"
			strokeWidth="2"
			className="microphone"
		/>
		<path
			d="M12 9C12 11.76 9.76 14 7 14C4.24 14 2 11.76 2 9H0C0 12.53 2.61 15.43 6 15.92V19H8V15.92C11.39 15.43 14 12.53 14 9H12Z"
			fill="inherit"
			stroke="none"
		/>
	</svg>
)

export const PodcastsIcon = props => <Icon component={PODCASTS_SVG} {...props} />

const PODCASTS_HOVER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="16px">
		<path d="M0 0h24v24H0z" stroke="rgb(78, 115, 255)" />
		<path
			fill="rgb(78, 115, 255)"
			d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
		/>
	</svg>
)

export const PodcastsHoverIcon = props => <Icon component={PODCASTS_HOVER_SVG} {...props} />

const PODCASTS_FILLED_SVG = () => (
	<svg width="18" height="16" viewBox="0 0 14 19" fill="rgb(78, 115, 255)" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9 9C9 10.1077 8.10772 11 7 11C5.89228 11 5 10.1077 5 9V3C5 1.89228 5.89228 1 7 1C8.10772 1 9 1.89228 9 3V9Z"
			fill="inherit"
			stroke="rgb(78, 115, 255)"
			strokeWidth="2"
		/>
		<path
			d="M12 9C12 11.76 9.76 14 7 14C4.24 14 2 11.76 2 9H0C0 12.53 2.61 15.43 6 15.92V19H8V15.92C11.39 15.43 14 12.53 14 9H12Z"
			fill="inherit"
			stroke="none"
		/>
	</svg>
)

export const PodcastsFilledIcon = props => <Icon component={PODCASTS_FILLED_SVG} {...props} />

const HOME_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
	</svg>
)

export const HomeIcon = props => <Icon component={HOME_SVG} {...props} />

const HOME_HOVER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
	</svg>
)

export const HomeHoverIcon = props => <Icon component={HOME_HOVER_SVG} {...props} />

const HOME_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
	</svg>
)

export const HomeFilledIcon = props => <Icon component={HOME_FILLED_SVG} {...props} />

const DASHBOARD_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z" />
	</svg>
)

export const DashboardIcon = props => <Icon component={DASHBOARD_SVG} {...props} />

const DASHBOARD_HOVER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="20px" height="20px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z" />
	</svg>
)

export const DashboardHoverIcon = props => <Icon component={DASHBOARD_HOVER_SVG} {...props} />

const DASHBOARD_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
	</svg>
)

export const DashboardFilledIcon = props => <Icon component={DASHBOARD_FILLED_SVG} {...props} />

const RECORDINGS_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 14v3c0 .55-.45 1-1 1h-1v-4h2M7 14v4H6c-.55 0-1-.45-1-1v-3h2m5-13c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z" />
	</svg>
)

export const RecordingsIcon = props => <Icon component={RECORDINGS_SVG} {...props} />

export const RECORDINGS_HOVER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="20px" height="20px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 14v3c0 .55-.45 1-1 1h-1v-4h2M7 14v4H6c-.55 0-1-.45-1-1v-3h2m5-13c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z" />
	</svg>
)

export const RecordingsHoverIcon = props => <Icon component={RECORDINGS_HOVER_SVG} {...props} />

const RECORDINGS_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" opacity=".1" />
		<path d="M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z" />
	</svg>
)

export const RecordingsFilledIcon = props => <Icon component={RECORDINGS_FILLED_SVG} {...props} />

const CONTACTS_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M20.84 4.22c-.05-.12-.11-.23-.18-.34-.14-.21-.33-.4-.54-.54-.11-.07-.22-.13-.34-.18-.24-.1-.5-.16-.78-.16h-1V1h-2v2H8V1H6v2H5c-.42 0-.8.13-1.12.34-.21.14-.4.33-.54.54-.07.11-.13.22-.18.34-.1.24-.16.5-.16.78v14c0 1.1.89 2 2 2h14c.28 0 .54-.06.78-.16.12-.05.23-.11.34-.18.21-.14.4-.33.54-.54.21-.32.34-.71.34-1.12V5c0-.28-.06-.54-.16-.78zM5 19V5h14v14H5zm7-6.12c-2.03 0-6 1.08-6 3.58V18h12v-1.53c0-2.51-3.97-3.59-6-3.59zM8.31 16c.69-.56 2.38-1.12 3.69-1.12s3.01.56 3.69 1.12H8.31zM12 12c1.65 0 3-1.35 3-3s-1.35-3-3-3-3 1.35-3 3 1.35 3 3 3zm0-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
	</svg>
)

export const ContactsIcon = props => <Icon component={CONTACTS_SVG} {...props} />

const CONTACTS_HOVER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="20px" height="20px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M20.84 4.22c-.05-.12-.11-.23-.18-.34-.14-.21-.33-.4-.54-.54-.11-.07-.22-.13-.34-.18-.24-.1-.5-.16-.78-.16h-1V1h-2v2H8V1H6v2H5c-.42 0-.8.13-1.12.34-.21.14-.4.33-.54.54-.07.11-.13.22-.18.34-.1.24-.16.5-.16.78v14c0 1.1.89 2 2 2h14c.28 0 .54-.06.78-.16.12-.05.23-.11.34-.18.21-.14.4-.33.54-.54.21-.32.34-.71.34-1.12V5c0-.28-.06-.54-.16-.78zM5 19V5h14v14H5zm7-6.12c-2.03 0-6 1.08-6 3.58V18h12v-1.53c0-2.51-3.97-3.59-6-3.59zM8.31 16c.69-.56 2.38-1.12 3.69-1.12s3.01.56 3.69 1.12H8.31zM12 12c1.65 0 3-1.35 3-3s-1.35-3-3-3-3 1.35-3 3 1.35 3 3 3zm0-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
	</svg>
)

export const ContactsHoverIcon = props => <Icon component={CONTACTS_HOVER_SVG} {...props} />

const CONTACTS_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z" />
	</svg>
)

export const ContactsFilledIcon = props => <Icon component={CONTACTS_FILLED_SVG} {...props} />

const CALENDAR_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(77, 77, 77)" width="18px" height="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z" />
	</svg>
)

export const CalendarIcon = props => <Icon component={CALENDAR_SVG} {...props} />

const CALENDAR_HOVER_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="20px" height="20px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z" />
	</svg>
)

export const CalendarHoverIcon = props => <Icon component={CALENDAR_HOVER_SVG} {...props} />

const CALENDAR_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(78, 115, 255)" width="18px" height="18px">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
	</svg>
)

export const CalendarFilledIcon = props => <Icon component={CALENDAR_FILLED_SVG} {...props} />

const ANALYTICS_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="18px" fill="#4d4d4d" viewBox="0 0 24 24" width="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path
			d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm7.93 9H13V4.07c3.61.45 6.48 3.32 6.93 6.93zM4 12c0-4.07 3.06-7.44 7-7.93v15.86c-3.94-.49-7-3.86-7-7.93zm9 7.93V13h6.93c-.45 3.61-3.32 6.48-6.93 6.93z"
			fill="inherit"
		/>
	</svg>
)

export const AnalyticsIcon = props => <Icon component={ANALYTICS_SVG} {...props} />

const ANALYTICS_FILLED_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path
			d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"
			fill="rgb(78, 115, 255)"
		/>
	</svg>
)

export const AnalyticsFilledIcon = props => <Icon component={ANALYTICS_FILLED_SVG} {...props} />

const COLLAPSED_NEW_EPISODE_SVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16px" height="16px">
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
	</svg>
)

export const CollapsedNewEpisodeIcon = props => <Icon component={COLLAPSED_NEW_EPISODE_SVG} {...props} />
