import React, { useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { SignInPage } from "containers/SignInPage"
import GetStartedPage from "containers/GetStarted"
import BouncerPage from "containers/BouncerPage"
import { SettingsPage } from "containers/SettingsPage"
import { PodPage } from "containers/PodPage"
import * as ROUTES from "constants/routes"
import "./App.scss"
import { useDispatch } from "react-redux"
import firebase from "auth/firebase"
import { handleFirebaseRedirect } from "redux/actions/GetStarted"
import { changeAuthUser } from "redux/actions/App"
import ProtectedRoute from "Session/ProtectedRoute"
import { HomePage } from "containers/HomePage"
import PodpalLoadingSpinnerOverlay from "containers/PodpalLoadingSpinnerOverlay"
import { PalPage } from "containers/PalPage"
import { NotFoundPage } from "containers/NotFoundPage"
import { NoAccessPage } from "containers/NoAccessPage"
import { AuthLoading } from "containers/AuthLoading"
// import { logoutUser } from "services/firebase.service"

const App = () => {
	const dispatch = useDispatch()
	// logoutUser()

	useEffect(() => {
		const unsubscribe = firebase.auth.onAuthStateChanged(async authUser => {
			if (authUser) {
				//if (!authUser.emailVerified) {
				//	await logoutUser()
				//	verifyEmailModal(authUser.uid)
				//} else {
				firebase.auth.getRedirectResult().then(res => {
					// REDIRECT OCCURRED
					if (res.user) {
						if (res.additionalUserInfo.isNewUser) dispatch(handleFirebaseRedirect(res))
					}
				})
				//}
			}
			dispatch(changeAuthUser(authUser ? authUser : null))
		})
		return () => {
			unsubscribe()
		}
	}, [dispatch])

	return (
		<>
			<PodpalLoadingSpinnerOverlay />
			<Router>
				<Switch>
					<Route
						path={`${ROUTES.GET_STARTED_LOADING}`}
						render={() => (
							<AuthLoading>
								<GetStartedPage />
							</AuthLoading>
						)}
					/>
					<Route path={`${ROUTES.GET_STARTED}`} component={GetStartedPage} />
					<Route path={`${ROUTES.BOUNCER}`} component={BouncerPage} />
					<Route
						path={`${ROUTES.SIGN_IN_LOADING}`}
						render={() => (
							<AuthLoading>
								<SignInPage />
							</AuthLoading>
						)}
					/>
					<Route path={`${ROUTES.SIGN_IN}`} component={SignInPage} />
					<Route path={`${ROUTES.POD}`} component={PodPage} />
					<Route path={`${ROUTES.PAL}`} component={PalPage} />
					<Route path={`${ROUTES.EPISODE}`} render={() => <h1>Episode Page</h1>} />
					<Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
					<Route path={ROUTES.NO_ACCESS} component={NoAccessPage} />
					<ProtectedRoute path={`${ROUTES.SETTINGS}`} component={SettingsPage} />
					<ProtectedRoute path="/" component={HomePage} />
				</Switch>
			</Router>
		</>
	)
}

export default App
