import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Navbar } from "components/Navbar"
import { ModalContainer } from "./ModalContainer"
import { DrawerContainer } from "./DrawerContainer"
import { setSelectedPodcast } from "redux/actions/App"

interface NavbarLayoutProps {
	children: JSX.Element
}

const NavbarLayout = ({ children }: NavbarLayoutProps) => {
	const dispatch = useDispatch()

	useEffect(() => {
		return () => {
			dispatch(setSelectedPodcast(null))
		}
	}, [dispatch])
	return (
		<>
			<Navbar />
			<ModalContainer />
			<DrawerContainer />
			{children}
		</>
	)
}

export default NavbarLayout
