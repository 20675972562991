import { SET_SOCIAL_MEDIA_FORM_IS_LOADING, SET_SOCIAL_MEDIA_FORM_IS_MODIFIED } from "constants/actionTypes"

const initialState = {
	isLoading: false,
	isModified: false,
	instagram: "",
	facebook: "",
	twitter: "",
	linkedin: "",
	youtube: "",
	patreon: "",
	cashapp: ""
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_SOCIAL_MEDIA_FORM_IS_LOADING: {
			return {
				...state,
				isLoading: action.payload.isLoading
			}
		}
		case SET_SOCIAL_MEDIA_FORM_IS_MODIFIED: {
			return {
				...state,
				isModified: action.payload.isModified
			}
		}
		default: {
			return state
		}
	}
}
