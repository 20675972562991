import axios from "axios"

const COMMENT_SERVICE_BASE_URL = "/api/twirp/podpal.comments.CommentsService"

export interface Comment {
	id?: string
	content: string
	fromUser: string
	episodeId: string
}

export const createComment = ({ content, fromUser, episodeId }: Comment) =>
	axios.post(`${COMMENT_SERVICE_BASE_URL}/CreateComment`, {
		comment: {
			content,
			fromUser,
			episodeId
		}
	})

export const deleteComment = (commentId: string) =>
	axios.post(`${COMMENT_SERVICE_BASE_URL}/DeleteComment`, {
		commentId
	})

export const getCommentsByEpisodeId = (episodeId: string) =>
	axios.post(`${COMMENT_SERVICE_BASE_URL}/GetCommentsByEpisodeId`, {
		episodeId
	})

export const updateComment = (comment: Comment) =>
	axios.post(`${COMMENT_SERVICE_BASE_URL}/UpdateComment`, {
		comment
	})
