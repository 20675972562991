import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import ReactEmojiPicker from "emoji-picker-react"

const EmojiPickerPositioner = styled.div`
	position: absolute;
	top: -20px;
	left: -20px;
	z-index: 999;
	transform: scale(0.8);
`

const EmojiPicker = ({ onEmojiSelected, open, onClickOutside }) => {
	const containerRef = useRef()

	const handleClickOutside = React.useCallback(
		event => {
			if (open && containerRef.current && !containerRef.current.contains(event.target)) {
				onClickOutside()
			}
		},
		[open]
	)

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, false)
		return () => {
			document.removeEventListener("click", handleClickOutside, false)
		}
	}, [handleClickOutside])

	return (
		<EmojiPickerPositioner>
			<div ref={containerRef}>
				<ReactEmojiPicker open={open} onEmojiClick={onEmojiSelected} />
			</div>
		</EmojiPickerPositioner>
	)
}

export default EmojiPicker
