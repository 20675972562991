import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Row, Col, Input, Form, Space, Tooltip } from "antd"
import { useSelector } from "react-redux"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import PodpalButton from "components/common/Buttons/PodpalButton"
import DistributionCard from "./DistributionCard"
import ApplePodcastsIcon from "assets/podcasts/distribution/apple-podcasts.svg"
import SpotifyIcon from "assets/podcasts/distribution/spotify.svg"
import GooglePodcastsIcon from "assets/podcasts/distribution/google-podcasts.svg"
import AmazonMusicIcon from "assets/podcasts/distribution/amazon-music.svg"
import PandoraIcon from "assets/podcasts/distribution/pandora.svg"
import StitcherIcon from "assets/podcasts/distribution/stitcher.svg"
import RadioPublicIcon from "assets/podcasts/distribution/radiopublic.png"
import IHeartRadioIcon from "assets/podcasts/distribution/iheartradio.png"
import {
	APPLE_PODCASTS_STEPS,
	SPOTIFY_STEPS,
	GOOGLE_PODCASTS_STEPS,
	AMAZON_MUSIC_STEPS,
	PANDORA_STEPS,
	STITCHER_STEPS,
	RADIO_PUBLIC_STEPS,
	IHEART_RADIO_STEPS
} from "constants/strings/UserSettings/MyPodcasts/RSSAndDistribution"
import "./RSSAndDistribution.scss"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"
import layout from "styles/scss/layout.scss"
import distributionImage from "assets/podcasts/distribution.svg"
import { useStripeCustomerPortal } from "hooks/SubscriptionAndBilling/useStripeCustomerPortal"
import { useUserInfo } from "services/queries/UserInfo"
import { useHistory } from "react-router"
import { PLAN_USAGE, PODCAST_SETTINGS, REDIRECT, SETTINGS, SUBSCRIPTION_AND_BILLING } from "constants/routes"
import { usePodcastRole } from "services/queries/PodcastRole"
import { ShowAccess } from "services/types/podcastRoles"

const StyledText = styled.p`
	color: ${props => props.theme.textSecondary};
	font: ${props => props.theme.fontMd};
	font-weight: 400 !important;
`

const StyledNumber = styled.span`
	font-size: ${props => props.theme.spaceSm};
	background: ${props => props.theme.primary};
	margin-left: ${props => props.theme.spaceMd};
	color: ${props => props.theme.textInverse};
	border-radius: 200px;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledRow = styled(Row)`
	height: 100%;

	h2 {
		color: ${({ theme }) => theme.neutral3};
		font: ${({ theme }) => theme.fontLgPlus};
	}

	p {
		color: ${({ theme }) => theme.neutral3};
		text-align: start;
		font: ${({ theme }) => theme.fontMd};
		font-weight: 400 !important;
	}

	@media only screen and (max-width: ${({ theme }) => theme.xs};) {
		h2,
		p {
			text-align: center;
		}
	}
`

const RSSAndDistribution = () => {
	const history = useHistory()
	const { exceedsImportEpisodesLimit, exceedsStorageLimit } = useLimitChecker()

	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	const authUser = useSelector(state => state.app.authUser)
	const { data: userInfoData } = useUserInfo(authUser.uid)

	const { openCustomerPortal } = useStripeCustomerPortal({ customerId: userInfoData?.userInfo?.customerId })

	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const [rssUrl, setRssUrl] = useState(null)

	const podcastId = selectedPodcast?.id
	const { data: podcastRoleData } = usePodcastRole({ podcastId: podcastId, userId: authUser.uid })

	const basePath = `${SETTINGS}${PODCAST_SETTINGS}/${podcastId}`

	useEffect(() => {
		if (selectedPodcast?.rssFeedUrl?.length > 0) {
			setRssUrl(selectedPodcast.rssFeedUrl)
		}
	}, [selectedPodcast])

	const isEditor = podcastRoleData?.podcastRole?.showAccess === ShowAccess.editor
	const hasShowAccess = !isEditor
	const isRedirected = selectedPodcast?.isRedirected

	if (exceedsImportEpisodesLimit() || exceedsStorageLimit()) {
		const paymentMethodBanner = (
			<Col>
				<Row>
					<img src={distributionImage} alt="distribution" />
				</Row>
			</Col>
		)

		const colStyle = isMobile
			? {
					textAlign: "center"
			  }
			: null

		return (
			<StyledRow align="middle" justify="center" gutter={48}>
				<Col>
					<Row>
						<Col style={colStyle}>
							{isMobile ? (
								<h2 style={{ marginTop: layout.spaceLg }}>Upgrade to access your feed!</h2>
							) : (
								<h2>Upgrade to access your feed!</h2>
							)}
						</Col>
					</Row>
					<Row gutter={[0, 24]}>
						<Col style={colStyle}>
							{isMobile ? (
								<span>
									We’re excited that you’re interested in migrating your podcast to Podpal, but you’ve exceeded the limits of
									your current plan. To activate your RSS Feed, you need to upgrade your account.
								</span>
							) : (
								<span>
									We’re excited that you’re interested in migrating your
									<br />
									podcast to Podpal, but you’ve exceeded the limits of
									<br />
									your current plan. To activate your RSS Feed, you need
									<br />
									to upgrade your account.
								</span>
							)}
						</Col>
					</Row>
					{isMobile && <Row gutter={[0, { xs: 24 }]}>{paymentMethodBanner}</Row>}
					<Row gutter={[8, { xs: 16 }]}>
						<Col xs={24} lg={12}>
							{isMobile ? (
								<PodpalButton shape="round" fullwidth type="primary" size="large" onClick={openCustomerPortal}>
									Upgrade Subscription
								</PodpalButton>
							) : (
								<PodpalButton type="primary" size="large" onClick={openCustomerPortal}>
									Upgrade Subscription
								</PodpalButton>
							)}
						</Col>
						<Col xs={24} lg={12}>
							{isMobile ? (
								<PodpalButton
									shape="round"
									fullwidth
									variant="secondary"
									size="large"
									onClick={() => {
										history.push(`${SETTINGS}${SUBSCRIPTION_AND_BILLING}${PLAN_USAGE}`)
									}}
								>
									View Plan Usage
								</PodpalButton>
							) : (
								<PodpalButton
									variant="secondary"
									size="large"
									onClick={() => {
										history.push(`${SETTINGS}${SUBSCRIPTION_AND_BILLING}${PLAN_USAGE}`)
									}}
								>
									View Plan Usage
								</PodpalButton>
							)}
						</Col>
					</Row>
				</Col>
				{!isMobile && paymentMethodBanner}
			</StyledRow>
		)
	}

	const data = [
		{
			distributorName: "Apple Podcasts",
			distributorDescription:
				"Over 75% of podcast listening occurs on Apple Podcasts! Even better, many podcast apps use Apple’s RSS feed requirements. By registering with Apple Podcasts, you’ll lay the foundation of exposure to a massive listening audience!",
			distributorIcon: ApplePodcastsIcon,
			distributorSteps: APPLE_PODCASTS_STEPS,
			distributorRegistrationLink: "https://podcastsconnect.apple.com/",
			distributorRegistrationPreamble: {
				link: "https://appleid.apple.com/account",
				linkText: "Create an Apple ID",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?podcasts.apple.com\/(.*)/,
			distributorPropertyName: "applePodcastsUrl"
		},
		{
			distributorName: "Spotify",
			distributorDescription:
				"Spotify has enhanced its music-focused platform to also highlight podcast content. Register your show with Spotify to take advantage of their discovery features and growing audience of both iOS and Android podcast listeners.",
			distributorIcon: SpotifyIcon,
			distributorSteps: SPOTIFY_STEPS,
			distributorRegistrationLink: "https://podcasters.spotify.com",
			distributorRegistrationPreamble: {
				link: "https://www.spotify.com/us/home/",
				linkText: "Create a Spotify account",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?open.spotify.com\/(.*)/,
			distributorPropertyName: "spotifyUrl"
		},
		{
			distributorName: "Google Podcasts",
			distributorDescription:
				"Claiming your show with Google Podcasts makes it available in the Google Podcasts app (iOS and Android), Google Search, Google Home, Content Action for the Google Assistant, and Android Auto in your car. Get on Google!",
			distributorIcon: GooglePodcastsIcon,
			distributorSteps: GOOGLE_PODCASTS_STEPS,
			distributorRegistrationLink: "https://podcastsmanager.google.com/add-feed",
			distributorRegistrationPreamble: {
				link: "https://accounts.google.com/signup/v2/webcreateaccount?flowName=GlifWebSignIn&flowEntry=SignUp",
				linkText: "Create a new Google account",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?podcasts.google.com\/(.*)/,
			distributorPropertyName: "googlePodcastsUrl"
		},
		{
			distributorName: "Amazon Music",
			distributorDescription:
				"Registering your show with Amazon Music makes it available on Alexa-enabled devices and on Audible, the world’s most popular audiobooks service. “Alexa, play the ___ podcast.” Fill in the blank with your show now!",
			distributorIcon: AmazonMusicIcon,
			distributorSteps: AMAZON_MUSIC_STEPS,
			distributorRegistrationLink:
				"https://artists.amazonmusicbackstage.com/s/Test/a5a296da-0c34-4ca0-9f49-13d0c5a5cccf?_rid=DlEk0M_Va!kfMzr1ZYSIFimwMSLYxpcp_9UV7dkkrhhHDyz13PBO_CrFUZGpknsoJat7xPd891M_ClR83d5PgCA!!7siErH!T9s99PTVl9xqPD5_jYdu1M_Div_Sd04im0Mf1WCLAFdgubUlz8WIfkBiZY0OMlbleftLkjJ4t6jDi0SRhgqU0JcuNvIgpAeq45XLUMM7GAjnd3Z4nGiZn6KiK8zNRkMF_DPqNKQB02dPLV!IKWJ28GdTwjt2hhImlV2gCa5pf0YRQclFCH1mdlHFQRoLbfRC6JolTCokbdQYaTY3MddgfdBSWMUdj0uY&_mult=True&_themeId=fb0dba41-2a25-416d-88fd-abcf0151d9e0&_data=yEbdlvcb!wQu1Yl2AxquKyUoq41w4!f5WyuIbP_x5hGGa8p!NnuAVXuajDJiRWaZZ7i89immufzbnULMtIKlC3!6YgfrjC10_a6FM8hlgCrfmWGipgTah2y0_aGCW2G0aiOOtQDx8zhRlsFf67lfBIAwzWd8Ahe0T84aQIAZKolqVs3PTjMJEiOwyNdT9KVytK!pDPJZ3EIsyW6M9TOhDR4PuvxqPYtwY6ljGPi60L9RCv!q8GtdiTm2_hchMRF14dCZzDwHGK0NLam8OzV370EJipFBQRHHP51tOfV2dopJG4pxoy0t2w--&_sup=mailto%3ano-reply%40artists.amazonmusicbackstage.com&_dspvw=d&_view=d",
			distributorRegistrationPreamble: {
				link: null,
				linkText: null,
				text: "Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?music.amazon.com\/(.*)/,
			distributorPropertyName: "amazonMusicUrl"
		},
		{
			distributorName: "Pandora",
			distributorDescription:
				"Pandora doesn’t just recommend podcast shows. It recommends individual episodes based on a listener’s specific content interests. This creates a great opportunity for your show to be discovered by your target audience.",
			distributorIcon: PandoraIcon,
			distributorSteps: PANDORA_STEPS,
			distributorRegistrationLink: "https://www.ampplaybook.com/podcasts",
			distributorRegistrationPreamble: {
				link: "https://www.pandora.com/account/register?ampsso=amp",
				linkText: "Create a new Pandora account",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?pandora.com\/(.*)/,
			distributorPropertyName: "pandoraUrl"
		},
		{
			distributorName: "Stitcher",
			distributorDescription:
				"Stitcher is another large podcasting directory that works as a search engine and a mobile app. It’s a popular choice among Android listeners and has been integrated into more than 50 car models.",
			distributorIcon: StitcherIcon,
			distributorSteps: STITCHER_STEPS,
			distributorRegistrationLink: "https://partners.stitcher.com/join",
			distributorRegistrationPreamble: {
				link: "https://partners.stitcher.com/",
				linkText: "Create a new Stitcher account",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?stitcher.com\/(.*)/,
			distributorPropertyName: "stitcherUrl"
		},
		{
			distributorName: "RadioPublic",
			distributorDescription:
				"RadioPublic is a podcast streaming platform focused on helping podcasters grow their businesses by making content easily searchable for listeners. With both iOS and Android apps, RadioPublic listeners will have easy access to your show.",
			distributorIcon: RadioPublicIcon,
			distributorSteps: RADIO_PUBLIC_STEPS,
			distributorRegistrationLink: "https://podcasters.radiopublic.com/signup",
			distributorRegistrationPreamble: {
				link: "https://podcasters.radiopublic.com/signup",
				linkText: "Create a new RadioPublic account",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?radiopublic.com\/(.*)/,
			distributorPropertyName: "radioPublicUrl"
		},
		{
			distributorName: "iHeartRadio",
			distributorDescription:
				"iHeartRadio is a top streaming platform with over 150 million users enjoying content from its radio stations, music and podcast libraries. By registering your podcast with iHeartRadio, your content will be available across multiple devices including wearables, in-car stereos and smart speakers.",
			distributorIcon: IHeartRadioIcon,
			distributorSteps: IHEART_RADIO_STEPS,
			distributorRegistrationLink: "https://podcasters.iheart.com/#submit-podcast",
			distributorRegistrationPreamble: {
				link: "https://www.iheart.com/sdk/auth/?frame=ihr_bridge&amp=https%3A%2F%2Fus.api.iheart.com",
				linkText: "Create a new iHeartRadio account",
				text:
					"if you don’t already have one. Click the “Start Registration” button above to begin the registration process:"
			},
			distributorUrlValidation: /(^http:\/\/|^https:\/\/)(www.)?iheart.com\/(.*)/,
			distributorPropertyName: "iheartRadioUrl"
		}
	]

	const distributionCards = data.map(distributor => (
		<Row gutter={[0, 16]}>
			<Col>
				<DistributionCard
					distributorName={distributor.distributorName}
					distributorDescription={distributor.distributorDescription}
					distributorIcon={distributor.distributorIcon}
					distributorRegistrationLink={distributor.distributorRegistrationLink}
					distributorRegistrationPreamble={distributor.distributorRegistrationPreamble}
					distributorSteps={distributor.distributorSteps}
					distributorUrlValidation={distributor.distributorUrlValidation}
					distributorPropertyName={distributor.distributorPropertyName}
				/>
			</Col>
		</Row>
	))

	return (
		<div className="rss-and-distribution-page">
			<Row gutter={[0, 16]}>
				<Col span={24}>
					<Form layout="vertical">
						<Form.Item
							label="My RSS Feed URL"
							tooltip={{
								placement: "rightTop",
								title:
									"Podcasts work by sending information from your podcast host, such as Podpal.com, to directories using something called an RSS Feed. The directories can then stream your podcast details and episodes in their apps to podcast listeners."
							}}
						>
							<Input
								value={rssUrl}
								disabled
								size="large"
								addonAfter={
									<PodpalButton
										className="rss-url-copy-button"
										type="primary"
										size="large"
										onClick={() => {
											navigator.clipboard
												.writeText(rssUrl)
												.then(() => {
													successAlert("Copied to clipboard.")
												})
												.catch(err => {
													errorAlert("Failed to copy to clipboard.")
												})
										}}
									>
										Copy
									</PodpalButton>
								}
							/>
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<Row gutter={[0, 24]}>
				<Col>
					<Space size={8}>
						<PodpalButton
							type="primary"
							size="large"
							href={rssUrl}
							target="_blank"
							rel="noopener noreferrer"
							disabled={isRedirected}
						>
							View my RSS Feed
						</PodpalButton>
						{!isRedirected &&
							(hasShowAccess ? (
								<PodpalButton variant="outlined" size="large" danger onClick={() => history.push(`${basePath}${REDIRECT}`)}>
									Request a 301 Redirect
								</PodpalButton>
							) : (
								<Tooltip
									title="You do not have the permissions to redirect this podcast. Please ask a show Owner or Admin to make this change."
									placement="bottom"
								>
									<PodpalButton size="large" disabled>
										Request a 301 Redirect
									</PodpalButton>
								</Tooltip>
							))}
					</Space>
				</Col>
			</Row>
			<Row gutter={[0, 16]}>
				<Col span={24}>
					<p className="rss-and-distribution-page-title">RSS Feed Distribution</p>
				</Col>
			</Row>
			<Row gutter={[0, 16]}>
				<Col span={24}>
					<p className="rss-and-distribution-page-text">
						You will need to accomplish the following before your podcast will be discoverable in podcast media players like
						Apple Podcasts, Spotify, and Google Podcasts:
					</p>
				</Col>
			</Row>
			<Row gutter={[6, 12]}>
				<Col span={24}>
					<Space align="start" size={12}>
						<StyledNumber>1</StyledNumber>
						<StyledText>
							If you migrated this podcast to Podpal from another host, you must{" "}
							<strong>complete the 301 Redirect process</strong> at your old host <strong>prior</strong> to publishing new
							episodes.
						</StyledText>
					</Space>
				</Col>
			</Row>
			<Row gutter={[6, 12]}>
				<Col span={24}>
					<Space align="start" size={12}>
						<StyledNumber>2</StyledNumber>
						<StyledText>
							If you created this podcast with Podpal, you must <strong>publish at least 1 episode</strong> prior to
							registering with podcast platforms.
						</StyledText>
					</Space>
				</Col>
			</Row>
			<Row gutter={[6, 24]}>
				<Col span={24}>
					<Space align="start" size={12}>
						<StyledNumber>3</StyledNumber>
						<StyledText>
							<strong>Register</strong> your podcast with the streaming platforms below.
						</StyledText>
					</Space>
				</Col>
			</Row>
			<Row gutter={[16, 24]}>
				<Col>
					<p className="rss-and-distribution-page-title">Podcast Distribution Status</p>
				</Col>
				<Col span={24}>
					<p className="rss-and-distribution-page-text">
						At a minimum, we recommend you register your podcast with the following podcast streaming platforms. It’s quick
						and easy. Just follow the steps listed for each application!
					</p>
				</Col>
			</Row>
			{distributionCards}
		</div>
	)
}

export default RSSAndDistribution
