import React, { useState } from "react"
import { Row, Col, Switch, Space } from "antd"
import { PlanCard } from "../Cards/PlanCard"
// TODO - We may add white glove services back in, so leaving it commented out
// import guru from "assets/subscriptionandbilling/guru.svg"
// import coach from "assets/subscriptionandbilling/coach.svg"
// import editor from "assets/subscriptionandbilling/editor.svg"
// import { WhiteGloveServiceCard, WhiteGloveServiceFeatureProps } from "../Cards/WhiteGloveServiceCard"
import styled from "styled-components"
import { ProductAndPrice, ProductMetadata } from "services/billing.service"
import { FREE_TIER_LIMITS, STARTER_TIER_LIMITS, PRO_TIER_LIMITS, AGENCY_TIER_LIMITS } from "constants/planLimits"
import { useSelector } from "react-redux"
import { RootState } from "index"
import { useUserInfo } from "services/queries/UserInfo"

const Container = styled.div`
	margin-top: ${({ theme }) => theme.spaceLg};
	margin-left: ${({ theme }) => theme.spaceXs};

	a {
		color: ${({ theme }) => theme.podpalBlue};
	}

	.ant-switch-checked {
		background: ${({ theme }) => theme.podpalBlue};
	}
`

export function OffersAndUpgradesPage() {
	const authUser = useSelector((state: RootState) => state.app.authUser)

	const { data: userInfoData } = useUserInfo(authUser.uid)

	const freeTierMetrics: ProductMetadata = {
		podcasts: FREE_TIER_LIMITS.podcasts.toString(),
		episodes: FREE_TIER_LIMITS.episodes.toString(),
		collaborators: FREE_TIER_LIMITS.collaborators.toString(),
		hasWebsite: true,
		storage: `${FREE_TIER_LIMITS.recordingStorageLimit.toString()}GB`,
		downloadsPerMonth: FREE_TIER_LIMITS.downloadsPerMonth.toString(),
		hasVideo: false,
		hasAudienceInsights: false
	}

	const starterTierMetrics: ProductMetadata = {
		podcasts: STARTER_TIER_LIMITS.podcasts.toString(),
		episodes: STARTER_TIER_LIMITS.episodes.toString(),
		collaborators: STARTER_TIER_LIMITS.collaborators.toString(),
		hasWebsite: true,
		storage: `${STARTER_TIER_LIMITS.recordingStorageLimit.toString()}GB`,
		downloadsPerMonth: STARTER_TIER_LIMITS.downloadsPerMonth.toString(),
		hasVideo: false,
		hasAudienceInsights: false
	}

	const proTierMetrics: ProductMetadata = {
		podcasts: PRO_TIER_LIMITS.podcasts.toString(),
		episodes: "Unlimited",
		collaborators: PRO_TIER_LIMITS.collaborators.toString(),
		hasWebsite: true,
		storage: `${PRO_TIER_LIMITS.recordingStorageLimit.toString()}GB`,
		downloadsPerMonth: PRO_TIER_LIMITS.downloadsPerMonth.toString(),
		hasVideo: true,
		hasAudienceInsights: false
	}

	const agencyTierMetrics: ProductMetadata = {
		podcasts: AGENCY_TIER_LIMITS.podcasts.toString(),
		episodes: "Unlimited",
		collaborators: AGENCY_TIER_LIMITS.collaborators.toString(),
		hasWebsite: true,
		storage: `${AGENCY_TIER_LIMITS.recordingStorageLimit.toString()}GB`,
		downloadsPerMonth: `${(AGENCY_TIER_LIMITS.downloadsPerMonth / 1000).toString()}K`,
		hasVideo: true,
		hasAudienceInsights: false
	}

	let MONTHLY_DATA: ProductAndPrice[]

	if (process.env.NODE_ENV === "production" && process.env.REACT_APP_STRIPE_ENV !== "development") {
		MONTHLY_DATA = [
			{
				name: "Free",
				price: 0,
				priceId: "price_1HlSn3FeqCtdlhXSZuuIlEZT",
				metadata: freeTierMetrics,
				productId: undefined,
				description: "Have an idea for a podcast? Start here."
			},
			{
				name: "Starter",
				price: 25,
				priceId: "price_1HlT4HFeqCtdlhXSsyxHnWu5",
				metadata: starterTierMetrics,
				productId: undefined,
				description: "Perfect for taking your podcast to the next level."
			},
			{
				name: "Pro",
				price: 40,
				priceId: "price_1HlTCmFeqCtdlhXSdfa6i4A0",
				metadata: proTierMetrics,
				productId: undefined,
				description: "Great for pro podcasters and teams who publish often."
			},
			{
				name: "Agency",
				price: 199,
				priceId: "price_1IZ0lLFeqCtdlhXS5A81X1rZ",
				metadata: agencyTierMetrics,
				productId: undefined,
				description: "For agencies and networks with multiple podcasts."
			}
		]
	} else {
		MONTHLY_DATA = [
			{
				name: "Free",
				price: 0,
				priceId: "price_1J1DqNFeqCtdlhXSmpFN1sxI",
				metadata: freeTierMetrics,
				productId: undefined,
				description: "Have an idea for a podcast? Start here."
			},
			{
				name: "Starter",
				price: 25,
				priceId: "price_1JCRJvFeqCtdlhXSD0tVhEEK",
				metadata: starterTierMetrics,
				productId: undefined,
				description: "Perfect for taking your podcast to the next level."
			},
			{
				name: "Pro",
				price: 40,
				priceId: "price_1JCRKIFeqCtdlhXSnCHmYWdk",
				metadata: proTierMetrics,
				productId: undefined,
				description: "Great for pro podcasters and teams who publish often."
			},
			{
				name: "Agency",
				price: 199,
				priceId: "price_1JCRKfFeqCtdlhXS9FPmR0Gp",
				metadata: agencyTierMetrics,
				productId: undefined,
				description: "For agencies and networks with multiple podcasts."
			}
		]
	}

	const monthlyDataPlans = MONTHLY_DATA.map((productAndPrice: ProductAndPrice, index: number) => (
		<Col key={productAndPrice.name}>
			<PlanCard productAndPrice={productAndPrice} isMonthly subscriptionLevel={index} />
		</Col>
	))

	let YEARLY_DATA: ProductAndPrice[]

	if (process.env.NODE_ENV === "production" && process.env.REACT_APP_STRIPE_ENV !== "development") {
		YEARLY_DATA = [
			{
				name: "Free",
				price: 0,
				priceId: "price_1HlSn3FeqCtdlhXSZuuIlEZT",
				metadata: freeTierMetrics,
				productId: undefined,
				description: "Have an idea for a podcast? Start here."
			},
			{
				name: "Starter",
				price: 19,
				priceId: "price_1HlTRbFeqCtdlhXSRbSDVCOg",
				metadata: starterTierMetrics,
				productId: undefined,
				description: "Perfect for taking your podcast to the next level."
			},
			{
				name: "Pro",
				price: 29,
				priceId: "price_1HlTSwFeqCtdlhXSCqEPpLUx",
				metadata: proTierMetrics,
				productId: undefined,
				description: "Great for pro podcasters and teams who publish often."
			},
			{
				name: "Agency",
				price: 159,
				priceId: "price_1IZ0jwFeqCtdlhXSNjMJ6xMJ",
				metadata: agencyTierMetrics,
				productId: undefined,
				description: "For agencies and networks with multiple podcasts."
			}
		]
	} else {
		YEARLY_DATA = [
			{
				name: "Free",
				price: 0,
				priceId: "price_1J1DqNFeqCtdlhXSmpFN1sxI",
				metadata: freeTierMetrics,
				productId: undefined,
				description: "Have an idea for a podcast? Start here."
			},
			{
				name: "Starter",
				price: 19,
				priceId: "price_1JCRLAFeqCtdlhXS6Vrit8No",
				metadata: starterTierMetrics,
				productId: undefined,
				description: "Perfect for taking your podcast to the next level."
			},
			{
				name: "Pro",
				price: 29,
				priceId: "price_1JCRNDFeqCtdlhXS9r4z6VUg",
				metadata: proTierMetrics,
				productId: undefined,
				description: "Great for pro podcasters and teams who publish often."
			},
			{
				name: "Agency",
				price: 159,
				priceId: "price_1JCROBFeqCtdlhXSeqTjeKoK",
				metadata: agencyTierMetrics,
				productId: undefined,
				description: "For agencies and networks with multiple podcasts."
			}
		]
	}

	const yearlyDataPlans = YEARLY_DATA.map((productAndPrice: ProductAndPrice, index: number) => (
		<Col key={productAndPrice.name}>
			<PlanCard productAndPrice={productAndPrice} isMonthly={false} subscriptionLevel={index} />
		</Col>
	))

	// TODO - We may add white glove services back in, so leaving it commented out
	// interface WhiteGloveServiceMetadata {
	//     image: JSX.Element
	//     productAndPrice: ProductAndPrice
	//     description: string
	//     features: WhiteGloveServiceFeatureProps[]
	// }

	// let WHITE_GLOVE_SERVICE_DATA: WhiteGloveServiceMetadata[]

	// let placeholderMetadata: ProductMetadata = {
	//     podcasts: "",
	//     episodes: "",
	//     collaborators: "",
	//     hasWebsite: false,
	//     storage: "",
	//     downloadsPerMonth: "",
	//     hasVideo: false,
	//     hasAudienceInsights: false,
	// }

	// const altText = {
	//     guru: "white glove with dish, headphones, pencil, and text bubble",
	//     coach: "white glove with dish, notes and text bubble",
	//     editor: "white glove with dish and pencil",
	// }

	// if (process.env.NODE_ENV === "production") {
	//     WHITE_GLOVE_SERVICE_DATA = [
	//         {
	//             image: <img src={guru} alt={altText.guru} />,
	//             productAndPrice: {
	//                 name: "The Guru",
	//                 price: 2500,
	//                 priceId: "",
	//                 productId: "prod_MZ9JCSswH60zeg",
	//                 metadata: placeholderMetadata,
	//             },
	//             description: "",
	//             features: [
	//                 {
	//                     feature: "Professional Editing",
	//                     detail: "for 10 pre-recorded episodes"
	//                 },
	//                 {
	//                     feature: "1-on-1 Training",
	//                     detail: "Session with a Podpal Pro"
	//                 },
	//                 {
	//                     feature: "Monthly",
	//                     detail: "check-ins with our Podcast Mentor"
	//                 },
	//                 {
	//                     feature: "Podcast Registration",
	//                     detail: "to top platforms done for you."
	//                 },
	//                 {
	//                     feature: "Audio Player",
	//                     detail: "installation on your personal website."
	//                 },
	//                 {
	//                     feature: "FREE",
	//                     detail: "1-Year Subscription to Podpal Pro."
	//                 },
	//             ]
	//         },
	//         {
	//             image: <img src={coach} alt={altText.coach} />,
	//             productAndPrice: {
	//                 name: "The Coach",
	//                 price: 1500,
	//                 priceId: "",
	//                 productId: "prod_MZ9F3y0Zg1VOkv",
	//                 metadata: placeholderMetadata,
	//             },
	//             description: "",
	//             features: [
	//                 {
	//                     feature: "Professional Editing",
	//                     detail: "for 5 pre-recorded episodes"
	//                 },
	//                 {
	//                     feature: "1-on-1 Training",
	//                     detail: "Session with a Podpal Pro"
	//                 },
	//                 {
	//                     feature: "Quarterly",
	//                     detail: "check-ins with our Podcast Mentor."
	//                 },
	//                 {
	//                     feature: "Podcast Registration",
	//                     detail: "to top platforms done for you."
	//                 },
	//                 {
	//                     feature: "FREE",
	//                     detail: "1-Year Subscription to Podpal Pro."
	//                 },
	//             ]
	//         },
	//         {
	//             image: <img src={editor} alt={altText.editor} />,
	//             productAndPrice: {
	//                 name: "The Editor",
	//                 price: 500,
	//                 priceId: "",
	//                 productId: "prod_MZ8xBQQv1tHYyx",
	//                 metadata: placeholderMetadata,
	//             },
	//             description: "",
	//             features: [
	//                 {
	//                     feature: "Professional Editing",
	//                     detail: "for 5 pre-recorded episodes"
	//                 },
	//                 {
	//                     feature: "Reduce",
	//                     detail: "Background noise."
	//                 },
	//                 {
	//                     feature: "Remove",
	//                     detail: "mistakes, 'ums', or long pauses."
	//                 },
	//                 {
	//                     feature: "Add",
	//                     detail: "your intros, outros, CTAs, and background music."
	//                 },
	//                 {
	//                     feature: "Mix",
	//                     detail: "your audio to give it that extra polish."
	//                 },
	//             ]
	//         },
	//     ]
	// } else {
	//     WHITE_GLOVE_SERVICE_DATA = [
	//         {
	//             image: <img src={guru} alt={altText.guru} />,
	//             productAndPrice: {
	//                 name: "The Guru",
	//                 price: 2500,
	//                 priceId: "",
	//                 productId: "prod_MrlhUySBbjk37B",
	//                 metadata: placeholderMetadata,
	//             },
	//             description: "",
	//             features: [
	//                 {
	//                     feature: "Professional Editing",
	//                     detail: "for 10 pre-recorded episodes"
	//                 },
	//                 {
	//                     feature: "1-on-1 Training",
	//                     detail: "Session with a Podpal Pro"
	//                 },
	//                 {
	//                     feature: "Monthly",
	//                     detail: "check-ins with our Podcast Mentor"
	//                 },
	//                 {
	//                     feature: "Podcast Registration",
	//                     detail: "to top platforms done for you."
	//                 },
	//                 {
	//                     feature: "Audio Player",
	//                     detail: "installation on your personal website."
	//                 },
	//                 {
	//                     feature: "FREE",
	//                     detail: "1-Year Subscription to Podpal Pro."
	//                 },
	//             ]
	//         },
	//         {
	//             image: <img src={coach} alt={altText.coach} />,
	//             productAndPrice: {
	//                 name: "The Coach",
	//                 price: 1500,
	//                 priceId: "",
	//                 productId: "prod_MrliHXFg8CVH9l",
	//                 metadata: placeholderMetadata,
	//             },
	//             description: "",
	//             features: [
	//                 {
	//                     feature: "Professional Editing",
	//                     detail: "for 5 pre-recorded episodes"
	//                 },
	//                 {
	//                     feature: "1-on-1 Training",
	//                     detail: "Session with a Podpal Pro"
	//                 },
	//                 {
	//                     feature: "Quarterly",
	//                     detail: "check-ins with our Podcast Mentor."
	//                 },
	//                 {
	//                     feature: "Podcast Registration",
	//                     detail: "to top platforms done for you."
	//                 },
	//                 {
	//                     feature: "FREE",
	//                     detail: "1-Year Subscription to Podpal Pro."
	//                 },
	//             ]
	//         },
	//         {
	//             image: <img src={editor} alt={altText.editor} />,
	//             productAndPrice: {
	//                 name: "The Editor",
	//                 price: 500,
	//                 priceId: "",
	//                 productId: "prod_MrljlqZnpyv89s",
	//                 metadata: placeholderMetadata,
	//             },
	//             description: "",
	//             features: [
	//                 {
	//                     feature: "Professional Editing",
	//                     detail: "for 5 pre-recorded episodes"
	//                 },
	//                 {
	//                     feature: "Reduce",
	//                     detail: "Background noise."
	//                 },
	//                 {
	//                     feature: "Remove",
	//                     detail: "mistakes, 'ums', or long pauses."
	//                 },
	//                 {
	//                     feature: "Add",
	//                     detail: "your intros, outros, CTAs, and background music."
	//                 },
	//                 {
	//                     feature: "Mix",
	//                     detail: "your audio to give it that extra polish."
	//                 },
	//             ]
	//         },
	//     ]
	// }

	// const whitegloveServices = WHITE_GLOVE_SERVICE_DATA.map((serviceMetadata: WhiteGloveServiceMetadata) => (
	//     <Col key={serviceMetadata.productAndPrice.name}>
	//         <WhiteGloveServiceCard image={serviceMetadata.image} productAndPrice={serviceMetadata.productAndPrice} description={serviceMetadata.description} features={serviceMetadata.features} />
	//     </Col>
	// ))

	const [plans, setPlans] = useState<JSX.Element[] | undefined>(
		userInfoData?.userInfo.isMonthly ? monthlyDataPlans : yearlyDataPlans
	)
	const defaultChecked = userInfoData?.userInfo.isMonthly ? false : true

	return (
		<Container>
			<Row justify="center" align="middle" gutter={[0, 24]}>
				<Space>
					<p>Monthly</p>
					<Switch
						defaultChecked={defaultChecked}
						onChange={checked => {
							if (checked) {
								setPlans(yearlyDataPlans)
							} else {
								setPlans(monthlyDataPlans)
							}
						}}
					/>
					<p>Yearly</p>
				</Space>
			</Row>
			<Row justify="center" align="middle" gutter={[24, 24]}>
				{plans}
			</Row>
			{/* <Row justify="center" align="middle" gutter={[24, 24]}>{whitegloveServices}</Row> */}
			<Row justify="center">
				<Col>
					<a href="https://forms.gle/khbaJv8nnDqDCbj57" target="_blank" rel="noopener noreferrer">
						Contact us
					</a>{" "}
					to request a plan downgrade.
				</Col>
			</Row>
		</Container>
	)
}
