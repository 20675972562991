import React from "react"

import { Select } from "antd"

import "./BasicSelect.scss"

const { Option } = Select

const BasicSelect = ({ className, size, onChange, options, placeholder, showSearch, value, filterOption }) => {
	const OPTIONS = Object.keys(options).map(option => (
		<Option key={options[option]} value={option}>
			{options[option]}
		</Option>
	))

	return (
		<Select
			className={`basic-select ${className ?? ""}`}
			size={size}
			onChange={onChange}
			placeholder={placeholder}
			showSearch={showSearch}
			value={value}
			filterOption={filterOption}
		>
			{OPTIONS}
		</Select>
	)
}

export default BasicSelect
