import {
	RETRIEVE_PODCAST_DATA_LOADING,
	RETRIEVE_PODCAST_DATA_SUCCESS,
	RETRIEVE_PODCAST_DATA_ERROR,
	RETRIEVE_PODCAST_ROLE_BY_USER_ID_LOADING,
	RETRIEVE_PODCAST_ROLE_BY_USER_ID_SUCCESS,
	RETRIEVE_PODCAST_ROLE_BY_USER_ID_ERROR,
	CREATE_NEW_PODCAST_SUCCESS,
	SUBMIT_PODCAST_FORM_DATA_SUCCESS,
	TOGGLE_DELETE_PODCAST_MODAL_VISIBLE,
	DELETE_PODCAST_SUCCESS,
	REDIRECT_PODCAST_SUCCESS
} from "constants/actionTypes"
import { keysToCamel } from "utils/camelCaseUtils"

const initialState = {
	deletePodcastModalVisible: false,
	podcastRoles: {
		data: [],
		loading: false,
		error: null
	},
	podcasts: {
		data: [],
		loading: false,
		error: null
	}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case RETRIEVE_PODCAST_ROLE_BY_USER_ID_LOADING:
			return {
				...state,
				podcastRoles: {
					data: [],
					loading: true,
					error: null
				}
			}

		case RETRIEVE_PODCAST_ROLE_BY_USER_ID_SUCCESS:
			const retrievedPodcastRoles = action.payload.podcastRoles
			return {
				...state,
				podcastRoles: {
					data: retrievedPodcastRoles,
					loading: false,
					error: null
				}
			}

		case RETRIEVE_PODCAST_ROLE_BY_USER_ID_ERROR:
			return {
				...state,
				podcastRoles: {
					data: [],
					loading: false,
					error: action.payload.error
				}
			}

		case RETRIEVE_PODCAST_DATA_LOADING:
			return {
				...state,
				podcasts: {
					data: [],
					loading: true,
					error: null
				}
			}

		case CREATE_NEW_PODCAST_SUCCESS:
			let existingPodcasts = state.podcasts.data
			const newPodcast = action.payload.podcast
			existingPodcasts.push({ ...newPodcast })
			return {
				...state,
				podcasts: {
					data: existingPodcasts,
					loading: false,
					error: null
				}
			}

		case RETRIEVE_PODCAST_DATA_SUCCESS:
			let currentPodcasts = state.podcasts.data
			const retrievedPodcast = action.payload.podcast
			// This is to ensure that empty podcast responses are not included in the podcast array. Possibly will change with the backend error handling.
			if (!retrievedPodcast.id) {
				return state
			}
			const retrievedPodcastCamelKeys = keysToCamel(retrievedPodcast)
			retrievedPodcastCamelKeys.categories = retrievedPodcastCamelKeys.categories?.map(category => ({
				label: category,
				value: category
			}))
			currentPodcasts.push({ ...retrievedPodcastCamelKeys })
			currentPodcasts.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))

			return {
				...state,
				podcasts: {
					data: currentPodcasts,
					loading: false,
					error: null
				}
			}

		case RETRIEVE_PODCAST_DATA_ERROR:
			return {
				...state,
				podcasts: {
					data: [],
					loading: false,
					error: action.payload.error
				}
			}

		case SUBMIT_PODCAST_FORM_DATA_SUCCESS:
			const { updatedPodcast } = action.payload
			const updatedPodcastCamelKeys = keysToCamel(updatedPodcast)
			const updatedPodcasts = state.podcasts.data.map(podcast => {
				if (podcast.id === updatedPodcast.id) {
					return updatedPodcastCamelKeys
				}
				return podcast
			})
			updatedPodcasts.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))

			return {
				...state,
				podcasts: {
					data: updatedPodcasts,
					loading: false,
					error: null
				}
			}

		case TOGGLE_DELETE_PODCAST_MODAL_VISIBLE:
			return {
				...state,
				deletePodcastModalVisible: !state.deletePodcastModalVisible
			}

		case DELETE_PODCAST_SUCCESS:
			let deletedPodcastId = action.payload.podcastId
			let podcasts = state.podcasts.data
			for (let i = 0; i < podcasts.length; i++) {
				if (podcasts[i].id === deletedPodcastId) {
					podcasts.splice(i, 1)
					break
				}
			}
			return {
				...state,
				podcasts: {
					data: podcasts,
					loading: false,
					error: null
				}
			}

		case REDIRECT_PODCAST_SUCCESS:
			let redirectedPodcastId = action.payload.podcastId
			let newPodcasts = state.podcasts.data
			for (const podcast of newPodcasts) {
				if (podcast.id === redirectedPodcastId) {
					break
				}
			}
			return {
				...state,
				podcasts: {
					data: newPodcasts,
					loading: false,
					error: null
				}
			}

		default:
			return state
	}
}
