import { Col, Row } from "antd"
import React from "react"
import "./AppsAndIntegrationsCard.scss"

export const AppsAndIntegrationsCard = ({ icon, iconImageAlt, description, title, action }) => {
	return (
		<Row className="apps-and-integrations-card">
			<Col span={24} className="title">
				<Row>
					<Col span={6}>
						<img className="icon" src={icon} alt={iconImageAlt} />
					</Col>
					<Col span={18} className="title-text">
						{title}
					</Col>
				</Row>
			</Col>
			<Col span={24} className="body">
				{description}
			</Col>
			<Col span={24} className="action">
				{action}
			</Col>
		</Row>
	)
}
