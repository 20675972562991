import React, { ReactNode } from "react"
import { Tooltip } from "antd"
import { ManagementTooltipIcon } from "assets/icons/Episodes"

interface EpisodeManagementTooltipProps {
	tooltipText: ReactNode
}

export function EpisodeManagementTooltip({ tooltipText }: EpisodeManagementTooltipProps) {
	return (
		<Tooltip title={tooltipText} placement="right">
			<ManagementTooltipIcon />
		</Tooltip>
	)
}
