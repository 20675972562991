import React from "react"
import { Card, Spin, Progress } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { XIcon } from "assets/icons/Common/SimpleFeedback"
import PodpalButton from "components/common/Buttons/PodpalButton"
import "./index.scss"

const UploadRecordingCard = React.forwardRef(({ filename, progress, hasCancelButton = true }, ref) => {
	return (
		<Card className="upload-card">
			<span className="content">
				<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				<span className="filename">{filename}</span>
				{hasCancelButton && <PodpalButton icon={<XIcon />} shape="circle" type="text" onClick={ref?.current?.cancel} />}
			</span>
			<div className="progress">
				{progress < 100 ? (
					<Progress percent={progress} />
				) : (
					<Progress format={() => "Processing..."} percent={progress} status="active" className="processing" />
				)}
			</div>
		</Card>
	)
})

export default UploadRecordingCard
