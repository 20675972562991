import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Divider, Button } from "antd"
import PodpalCard from "components/common/Cards/PodpalCard"
import RichTextEditor from "components/common/Input/RichTextEditor"
import useRichTextEditor from "hooks/useRichTextEditor"
import { NoCommentsIcon } from "assets/icons/Comments"
import { useSelector } from "react-redux"
import { useUser } from "services/queries/User"
import Comment from "./Comment"
import { createComment, deleteComment, updateComment } from "services/comment.service"
import { useGetComments } from "services/queries/Comment"
import { usePodcastTeam } from "services/queries/Podcasts"

const StyledButton = styled(Button)`
	border-radius: 500px;
`

const NoCommentsContainer = styled.div`
	text-align: center;
	margin-bottom: 24px;
`

const NoCommentsHeader = styled.h4`
	margin-bottom: 0;
`

const NoCommentsP = styled.p`
	font-size: 14px;
	font-weight: 400;
`

const maxShowNotesLength = 4000

const TestComments = [
	{
		id: "testCommentId",
		author: "Han Solo",
		avatar: "https://i.pravatar.cc/300",
		createdAt: 1726802050460,
		content:
			"<p>We supply a series of design principles, <strong>practical</strong> patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>",
		reactions: {
			"1f603": { count: 1 },
			"2764-fe0f": { count: 2 }
		},
		currentUserReaction: "2764-fe0f"
	}
]

const NoComments = () => (
	<NoCommentsContainer>
		<NoCommentsIcon />
		<NoCommentsHeader>No comments ... yet</NoCommentsHeader>
		<NoCommentsP>Let's get the conversation going!</NoCommentsP>
	</NoCommentsContainer>
)

const CommentSection = ({ className, episodeId }) => {
	const authUser = useSelector(state => state.app.authUser)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)
	const { data: userData } = useUser(authUser.uid)
	const { data: podcastTeamData } = usePodcastTeam(selectedPodcast?.id)

	const { firstName, lastName, thumbnailUrl, email } = userData.user
	const fullName = (firstName ?? "") + " " + (lastName ?? "")

	const { isLoading, error, data } = useGetComments(episodeId)

	const teamMembers = podcastTeamData?.usersWithPodcastRoles
		.map(data => ({
			fullName: `${data?.user.firstName} ${data?.user.lastName}`,
			userName: data?.user.username,
			avatar: data?.user.thumbnailUrl,
			id: data?.user?.id
		}))
		.filter(user => user.id !== userData.user.id)

	useEffect(() => {
		if (!isLoading && !error) {
			setComments(data.comments || [])
		}
	}, [isLoading, error])

	const [comments, setComments] = useState([])
	const { handleChange, setLength, content } = useRichTextEditor("", maxShowNotesLength)
	const [showAllComments, setShowAllComments] = useState(false)

	const onSendButtonClick = () => {
		const commentRequestBody = {
			fromUser: authUser.uid,
			episodeId,
			content
		}
		createComment(commentRequestBody).then(res => {
			if (res?.data?.comment) {
				setComments([
					...comments,
					{
						...res?.data?.comment,
						author_display_name: fullName.trim() || email,
						thumbnail_url: thumbnailUrl
					}
				])
			}
		})
	}

	const onUpdateComments = async (comment, action) => {
		if (action === "Delete") {
			await deleteComment(comment.id)
			const index = comments.findIndex(el => el.id === comment.id)
			const newComments = [...comments.slice(0, index), ...comments.slice(index + 1)]
			setComments(newComments)
		} else {
			await updateComment(comment)
			const index = comments.findIndex(el => el.id === comment.id)
			const newComments = [...comments.slice(0, index), comment, ...comments.slice(index + 1)]
			setComments(newComments)
		}
	}

	const onShowAllCommentsClick = () => {
		setShowAllComments(true)
	}

	const shouldShowAllCommentsButton = comments.length > 3 && !showAllComments

	const commentsToShow = shouldShowAllCommentsButton ? comments.slice(comments.length - 3) : comments

	return (
		<PodpalCard className={className}>
			<h2>Comments</h2>
			{shouldShowAllCommentsButton && (
				<Divider>
					<StyledButton onClick={onShowAllCommentsClick}>View older comments</StyledButton>
				</Divider>
			)}
			{comments.length === 0 ? (
				<NoComments />
			) : (
				<>
					{commentsToShow.map((comment, index) => {
						return (
							<Comment
								comment={comment}
								teamMembers={teamMembers}
								onUpdateComments={onUpdateComments}
								key={comment.id}
								showConnector={comments.length > 1 && index !== comments.length - 1}
							/>
						)
					})}
				</>
			)}
			<div style={{ marginTop: "20px" }}>
				<RichTextEditor
					setValue={handleChange}
					setLength={setLength}
					defaultValue={""}
					placeholder="Add your comments here!"
					height="150px"
					maxLength={20}
					onSendButtonClick={onSendButtonClick}
					showFooter
					teamMembers={teamMembers}
				/>
			</div>
		</PodpalCard>
	)
}

export default CommentSection
