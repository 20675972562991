import { useState } from "react"

const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
let audioSource

export default function useAudioUpload({ maxDurationSeconds, onLoad, onRemove }) {
	const [webAudio, setWebAudio] = useState(null)
	const [localAudio, setLocalAudio] = useState(null)
	const [audioErrorMessage, setAudioErrorMessage] = useState("")
	const [audioBioRemoved, setAudioBioRemoved] = useState(false)

	const validateAudioFile = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			const byteReader = new FileReader()
			// Decode mp3, then reject if duration is too long. Otherwise, resolve and save audio data.
			reader.readAsArrayBuffer(file)
			reader.onload = async () => {
				const blob = new Blob([reader.result], { type: "audio/*" })
				const url = window.URL.createObjectURL(blob)
				setLocalAudio(url)
				audioCtx.decodeAudioData(
					reader.result,
					buf => {
						if (maxDurationSeconds != null && buf.duration > maxDurationSeconds) {
							reject()
							setAudioErrorMessage(`Your audio bio must be ${maxDurationSeconds} seconds or less.`)
							setLocalAudio(null)
							return
						}
						setAudioErrorMessage("")
						byteReader.readAsDataURL(file)
						byteReader.onload = async () => {
							onLoad(byteReader.result)
							setAudioBioRemoved(false)
							resolve()
						}
					},
					err => {
						console.error("Error decoding audio bio:", err)
						reject()
					}
				)
			}
		})
	}

	const handleRemoveLocalAudio = () => {
		// Stop currently playing audio
		if (audioSource?.buffer) {
			audioSource.stop()
		}
		setAudioBioRemoved(true)
		setWebAudio(null)
		setLocalAudio(null)
		setAudioErrorMessage("")
		onRemove()
	}

	return {
		webAudio,
		setWebAudio,
		localAudio,
		setLocalAudio,
		audioErrorMessage,
		setAudioErrorMessage,
		audioBioRemoved,
		setAudioBioRemoved,
		validateAudioFile,
		handleRemoveLocalAudio
	}
}
