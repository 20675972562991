import { createCustomerPortalSession } from "../../services/billing.service"
import { errorAlert } from "components/common/Alerts/ErrorAlert"

interface StripeCustomerPortalProps {
	customerId: string | undefined
}

// Use this hook when you need to open a stripe customer portal for a user. Can change payment method, view invoices, etc.
export function useStripeCustomerPortal({ customerId }: StripeCustomerPortalProps) {
	const openCustomerPortal = async () => {
		try {
			const res = await createCustomerPortalSession(customerId)
			window.open(res.data.session_url, "_blank")
		} catch (e) {
			errorAlert("An unexpected error occurred.")
		}
	}

	return {
		openCustomerPortal
	}
}
