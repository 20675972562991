import React from "react"

export const UnreadNotificationIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="8.00004" cy="8.00004" r="5.00004" fill="#F1564B" />
		<circle cx="8.00004" cy="8.00004" r="5.00004" stroke="white" />
	</svg>
)

export const DeleteNotificationIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.9998 1.99988H1.99984C1.2665 1.99988 0.666504 2.59988 0.666504 3.33321V12.6665C0.666504 13.3999 1.2665 13.9999 1.99984 13.9999H13.9998C14.7332 13.9999 15.3332 13.3999 15.3332 12.6665V3.33321C15.3332 2.59988 14.7332 1.99988 13.9998 1.99988ZM13.9998 11.9999C13.9998 12.3665 13.6998 12.6665 13.3332 12.6665H2.6665C2.29984 12.6665 1.99984 12.3665 1.99984 11.9999V3.99988C1.99984 3.63321 2.29984 3.33321 2.6665 3.33321H13.3332C13.6998 3.33321 13.9998 3.63321 13.9998 3.99988V11.9999ZM10.1932 5.79988C9.93317 5.53988 9.51317 5.53988 9.25317 5.79988L7.99984 7.05988L6.73984 5.79988C6.47984 5.53988 6.05984 5.53988 5.79984 5.79988C5.53984 6.05988 5.53984 6.47988 5.79984 6.73988L7.05984 7.99988L5.79984 9.25988C5.53984 9.51988 5.53984 9.93988 5.79984 10.1999C6.05984 10.4599 6.47984 10.4599 6.73984 10.1999L7.99984 8.93988L9.25984 10.1999C9.51984 10.4599 9.93984 10.4599 10.1998 10.1999C10.4598 9.93988 10.4598 9.51988 10.1998 9.25988L8.93984 7.99988L10.1998 6.73988C10.4532 6.48654 10.4532 6.05988 10.1932 5.79988Z"
			fill="#4D4D4D"
		/>
	</svg>
)
