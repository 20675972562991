import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { usePodcasts } from "services/queries/Podcasts"
import { Col, Row } from "antd"
import PodcastCard from "components/Podcasts/PodcastCard"
import { ZeroStateCard } from "components/common/Cards/ZeroStateCard"
import { toggleImportPodcastModalVisible } from "redux/actions/Podcasts/AddNewPodcast"
import { AddPodcastButton } from "components/Podcasts/AddPodcastButton"
import myPodcastsZeroStateImage from "assets/zerostates/MyPodcasts.svg"
import { RootState } from "index"
import { Podcast } from "services/types/podcast"
import { useLimitChecker } from "hooks/SubscriptionAndBilling/useLimitChecker"
import { toggleLimitsExceededModalVisible } from "redux/actions/SubscriptionAndBilling"
import useWindowSize from "hooks/useWindowSize"
import { BREAKPOINT_XS } from "constants/breakpoints"

export function MyPodcastsPage() {
	const dispatch = useDispatch()

	const authUser = useSelector((state: RootState) => state.app.authUser)
	const { isLoading, isError, data } = usePodcasts(authUser?.uid)

	const { exceedsPodcastLimit } = useLimitChecker()

	const size = useWindowSize()
	const isMobile = size.width <= BREAKPOINT_XS

	if (isLoading) {
		return <div className="home-page-container">Loading podcasts...</div>
	}

	if (isError) {
		return (
			<div className="home-page-container">
				<h1 className="home-page-heading">My Podcasts</h1>
				<p>Error loading podcasts...</p>
			</div>
		)
	}

	if (!data?.podcasts || data?.podcasts?.length === 0) {
		return (
			<div className="home-page-container">
				<Row justify="center" align="middle" style={{ height: "100%" }}>
					<Col>
						<ZeroStateCard
							title="Add Your First Podcast"
							description="Start from scratch or import your existing RSS feed to get started. "
							image={myPodcastsZeroStateImage}
							primaryButtonProps={{
								text: "Start a New Podcast",
								onClick: () => {
									if (exceedsPodcastLimit()) {
										dispatch(toggleLimitsExceededModalVisible())
									} else {
										dispatch(toggleImportPodcastModalVisible())
									}
								}
							}}
						/>
					</Col>
				</Row>
			</div>
		)
	}

	const podcastCards = data?.podcasts?.map((podcast: Podcast) => {
		return (
			<Col key={podcast.id}>
				<PodcastCard podcast={podcast} />
			</Col>
		)
	})

	const addPodcastButtonColStyle = {
		width: "224px",
		height: "308px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}

	return (
		<div className="home-page-container">
			<h1 className="home-page-heading">My Podcasts</h1>
			<Row gutter={[24, 24]} align="middle" justify={isMobile ? "center" : undefined}>
				{podcastCards}
				{podcastCards && (
					<Col style={addPodcastButtonColStyle}>
						<AddPodcastButton />
					</Col>
				)}
			</Row>
		</div>
	)
}
