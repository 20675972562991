import axios from "axios"

export const findMigrationInfoByPodcastID = podcastID =>
	axios.post("/api/twirp/podpal.administration.MigrationInfoService/FindByPodcastID", {
		podcastId: podcastID
	})

export const updateMigrationInfo = migrationInfo =>
	axios.post("/api/twirp/podpal.administration.MigrationInfoService/Update", {
		migrationInfo
	})
