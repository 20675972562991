import styled from "styled-components"

interface CurvedHeaderProps {
	color?: string
	isEpisodePage?: boolean
}

export const CurvedHeader = styled.div<CurvedHeaderProps>`
	&:after {
		content: "";
		display: block;
		border-radius: 45% 45%;
		width: 140vw;
		height: 550px;
		background-color: ${({ color, theme }) => color ?? theme.podpalBlue};
		margin-top: -250px;
		margin-left: -20vw;
	}
	@media only screen and (max-width: 700px) {
		&:after {
			height: 600px;
			width: 150vw;
			margin-left: -30vw;
			margin-top: ${({ isEpisodePage }) => (isEpisodePage ? "-250px" : "-150px")};
		}
	}
	@media only screen and (max-width: ${({ theme }) => theme.xs}) {
		&:after {
			height: 700px;
			width: 200vw;
			margin-left: -50vw;
			margin-top: ${({ isEpisodePage }) => (isEpisodePage ? "-350px" : "-150px")};
		}
	}
`
