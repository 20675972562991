import { TOGGLE_USER_SETTINGS_DROPDOWN } from "constants/actionTypes"

const initialState = {
	visible: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_USER_SETTINGS_DROPDOWN:
			return { visible: !state.visible }

		default:
			return state
	}
}
