import styled from "styled-components"
import React, { useEffect, useState } from "react"
import { Row, Col, Form, Input, Select } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { toggleRequest301ModalVisible } from "redux/actions/UserSettings/MyPodcasts/RSSAndDistribution"
import PodpalModal from "components/common/Modals/PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"
import thankYouImage from "assets/podcasts/distribution/301-redirect.svg"

const { Option } = Select

const StyledHeading = styled.p`
	font: ${props => props.theme.fontLg};
	color: ${props => props.theme.textPrimary};
`

const StyledTitle = styled.p`
	color: ${props => props.theme.textPrimary};
	font: ${props => props.theme.fontSm};
	font-weight: 500 !important;
`

const StyledText = styled.p`
	color: ${props => props.theme.textSecondary};
	font: ${props => props.theme.fontSm};
`

const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 0;
	}
`

const Request301Modal = () => {
	const dispatch = useDispatch()
	const visible = useSelector(state => state.podcastRSSAndDistribution.request301ModalVisible)

	const [form] = Form.useForm()

	const MODAL_SEQUENECES = {
		ENTER_RSS_INFO: "ENTER_RSS_INFO",
		CONFIRMATION: "CONFIRMATION"
	}

	const [currentSequence, setCurrentSequence] = useState(MODAL_SEQUENECES.ENTER_RSS_INFO)
	const [modalWidth, setModalWidth] = useState(420)
	useEffect(() => {
		if (currentSequence === MODAL_SEQUENECES.CONFIRMATION) {
			setModalWidth(480)
		} else {
			setModalWidth(420)
		}
	}, [currentSequence, MODAL_SEQUENECES.CONFIRMATION])

	const handleClose = () => {
		dispatch(toggleRequest301ModalVisible())
		setCurrentSequence(MODAL_SEQUENECES.ENTER_RSS_INFO)
	}

	const handleSave = () => {
		setCurrentSequence(MODAL_SEQUENECES.CONFIRMATION)
	}

	const PODCAST_HOSTS = [
		"Anchor",
		"Blubrry",
		"Buzzsprout",
		"Feedburner",
		"Libsyn",
		"Podbean",
		"Simplecast",
		"SoundCloud",
		"Spreaker",
		"Transistor"
	]
	const podcastHostOptions = PODCAST_HOSTS.map(host => (
		<Option key={host} value={host}>
			{host}
		</Option>
	))

	const enterRSSInfoBody = (
		<>
			<Row justify="center" gutter={[0, 12]}>
				<Col span={24}>
					<StyledText>
						We’re sorry to that you’re planning to leave Podpal, but we’ll make this transition super easy for you. Just enter
						your new RSS Feed URL below, and we’ll take care of the rest!
					</StyledText>
				</Col>
			</Row>
			<Row gutter={[0, 12]}>
				<Col>
					<StyledTitle>New RSS Feed Info</StyledTitle>
				</Col>
			</Row>
			<StyledForm form={form}>
				<Row gutter={[0, 12]}>
					<Col span={24}>
						<Form.Item>
							<Select size="large" placeholder="Where are you moving your feed?">
								{podcastHostOptions}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							hasFeedback
							rules={[
								{
									type: "url",
									message: "Please enter a valid RSS Feed URL."
								}
							]}
						>
							<Input size="large" placeholder="Paste new RSS Feed URL here." />
						</Form.Item>
					</Col>
				</Row>
			</StyledForm>
		</>
	)

	const enterRSSInfoFooter = [
		<PodpalButton variant="secondary" onClick={handleClose}>
			Cancel
		</PodpalButton>,
		<PodpalButton type="primary" onClick={handleSave}>
			Save
		</PodpalButton>
	]

	const confirmationBody = (
		<>
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<StyledHeading>Thank You!</StyledHeading>
				</Col>
			</Row>
			<Row justify="center" gutter={[0, 24]}>
				<Col>
					<img src={thankYouImage} alt="thank-you" />
				</Col>
			</Row>
			<Row justify="center" gutter={[0, 4]}>
				<Col>
					<StyledText>
						<strong>We still got love for you!</strong>
					</StyledText>
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					<StyledText>Hope you find what you’re looking for at your new host.</StyledText>
				</Col>
			</Row>
		</>
	)

	const confirmationFooter = [
		<PodpalButton type="primary" onClick={handleClose}>
			OK
		</PodpalButton>
	]

	let body, footer
	switch (currentSequence) {
		case MODAL_SEQUENECES.ENTER_RSS_INFO:
			body = enterRSSInfoBody
			footer = enterRSSInfoFooter
			break
		case MODAL_SEQUENECES.CONFIRMATION:
			body = confirmationBody
			footer = confirmationFooter
			break
		default:
			break
	}

	return (
		<PodpalModal
			centered
			maskClosable={false}
			title="Request 301 Redirect"
			visible={visible}
			width={modalWidth}
			onCancel={handleClose}
			footer={footer}
		>
			{body}
		</PodpalModal>
	)
}

export default Request301Modal
