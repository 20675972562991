import { Row, Col, Popconfirm, Tooltip } from "antd"
import React, { useEffect } from "react"
import PodpalButton from "components/common/Buttons/PodpalButton"
import "./AppsAndIntegrations.scss"
import FacebookLogo from "assets/icons/Common/SocialMedia/facebook-icon.png"
import GoogleLogo from "assets/icons/Common/SocialMedia/Google-icon.png"
import GrammarlyLogo from "assets/icons/Common/SocialMedia/icon-grammarly.png"
import { AppsAndIntegrationsCard } from "components/common/Cards/AppsAndIntegrationsCard"
import {
	linkFacebookAccount,
	linkGoogleAccount,
	setFacebookAuthenticationOnLoad,
	setGoogleAuthenticationOnLoad,
	unlinkFacebookAccount,
	unlinkGoogleAccount
} from "redux/actions/UserSettings/MyAccount/AppsAndIntegrations"
import { connect, useSelector } from "react-redux"
import { CheckCircleFilled, TrashFilled } from "assets/icons/Common/SimpleFeedback"
import { useUser } from "services/queries/User"

const AppsAndIntegrations = ({
	linkFacebookAccount,
	linkGoogleAccount,
	isAuthenticatedWithFacebook,
	isAuthenticatedWithGoogle,
	unlinkFacebookAccount,
	unlinkGoogleAccount,
	setFacebookAuthenticationOnLoad,
	setGoogleAuthenticationOnLoad
}) => {
	// CHECK IF THE USER IS ALREADY AUTHENTICATED WITH A PROVIDER
	const fetchAuthUserProviders = () => {
		if (authUser) {
			//&& authUser.emailVerified) {
			authUser.providerData.forEach(async provider => {
				switch (provider.providerId) {
					case "facebook.com":
						await setFacebookAuthenticationOnLoad(true)
						break
					case "google.com":
						await setGoogleAuthenticationOnLoad(true)
						break
					default:
						break
				}
			})
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchAuthUserProviders, [])

	const authUser = useSelector(state => state.app.authUser)

	const { data } = useUser(authUser.uid)
	const providers = data?.providers

	// Display this if the user is connected for that provider
	const Connected = ({ removeAction, disabled }) => {
		const deleteButton = (
			<Popconfirm
				placement="bottom"
				title="Are you sure you want to remove this connection?"
				onConfirm={removeAction}
				okText="Yes"
				cancelText="No"
				disabled={disabled}
			>
				<TrashFilled className="trash-icon" />
			</Popconfirm>
		)

		return (
			<Row justify="space-between" align="middle" className="action-wrapper">
				<Col span={10}>
					<p className="success-text">Connected</p>
				</Col>
				<Col span={4}>
					<CheckCircleFilled className="check-icon" />
				</Col>
				<Col span={4} offset={6} className="trash-wrapper">
					{disabled ? (
						<Tooltip placement="bottom" title="You must have at least one login method.">
							{deleteButton}
						</Tooltip>
					) : (
						deleteButton
					)}
				</Col>
			</Row>
		)
	}

	const isAuthenticatedWithPassword = providers?.includes("password")
	const isOnlyFacebook = isAuthenticatedWithFacebook && !isAuthenticatedWithGoogle && !isAuthenticatedWithPassword
	const isOnlyGoogle = isAuthenticatedWithGoogle && !isAuthenticatedWithFacebook && !isAuthenticatedWithPassword

	const FacebookAction = isAuthenticatedWithFacebook ? (
		<Tooltip placement="bottom" title="You must have at least one login method.">
			<Connected removeAction={unlinkFacebookAccount} disabled={isOnlyFacebook} />
		</Tooltip>
	) : (
		<Row justify="end" className="action-wrapper">
			<Col span={12}>
				<PodpalButton type="primary" size="large" onClick={linkFacebookAccount}>
					Connect
				</PodpalButton>
			</Col>
		</Row>
	)

	const GoogleAction = isAuthenticatedWithGoogle ? (
		<Connected removeAction={unlinkGoogleAccount} disabled={isOnlyGoogle} />
	) : (
		<Row justify="end" className="action-wrapper">
			{/* {!data.user.email?.endsWith("@gmail.com") ? (
				<Tooltip placement="bottomRight" title="Your email needs to be a valid Google email.">
					<PodpalButton size="large" disabled>
						Connect
					</PodpalButton>		
				</Tooltip>
			) : ( */}
			<PodpalButton type="primary" size="large" onClick={linkGoogleAccount}>
				Connect
			</PodpalButton>
			{/* )} */}
		</Row>
	)

	const GrammarlyAction = (
		<Row justify="end" className="action-wrapper">
			<PodpalButton href="https://www.grammarly.com/" type="primary" size="large" target="blank">
				Add Extension
			</PodpalButton>
		</Row>
	)

	return (
		<div className="apps-and-integrations">
			<Row gutter={(24, 24)}>
				<Col>
					<AppsAndIntegrationsCard
						title="Facebook"
						description="Login with your Facebook account."
						iconImageAlt="Facebook"
						action={FacebookAction}
						icon={FacebookLogo}
					></AppsAndIntegrationsCard>
				</Col>
				<Col>
					<AppsAndIntegrationsCard
						title="Google"
						description="Login with your Google account."
						iconImageAlt="Google"
						action={GoogleAction}
						icon={GoogleLogo}
					></AppsAndIntegrationsCard>
				</Col>
				<Col>
					<AppsAndIntegrationsCard
						title="Grammarly"
						description="Install the Grammarly browser extension to check spelling and grammar."
						iconImageAlt="Grammarly"
						action={GrammarlyAction}
						icon={GrammarlyLogo}
					></AppsAndIntegrationsCard>
				</Col>
			</Row>
		</div>
	)
}

const mapStateToProps = state => ({
	isAuthenticatedWithGoogle: state.appsAndIntegrations.isAuthenticatedWithGoogle,
	isAuthenticatedWithFacebook: state.appsAndIntegrations.isAuthenticatedWithFacebook
})

const mapDispatchToProps = dispatch => ({
	linkFacebookAccount: () => dispatch(linkFacebookAccount()),
	linkGoogleAccount: () => dispatch(linkGoogleAccount()),
	unlinkFacebookAccount: () => dispatch(unlinkFacebookAccount()),
	unlinkGoogleAccount: () => dispatch(unlinkGoogleAccount()),
	setGoogleAuthenticationOnLoad: isAuthenticated => dispatch(setGoogleAuthenticationOnLoad(isAuthenticated)),
	setFacebookAuthenticationOnLoad: isAuthenticated => dispatch(setFacebookAuthenticationOnLoad(isAuthenticated))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppsAndIntegrations)
