import { useState } from "react"

export default function useRichTextEditor(defaultValue, maxLength) {
	const [content, setContent] = useState(defaultValue)
	const [contentLength, setContentLength] = useState(0)
	const [overLength, setOverLength] = useState(false)

	const handleChange = value => {
		setContent(value)
	}

	const setLength = value => {
		setContentLength(value)
		if (maxLength) {
			setOverLength(contentLength > maxLength)
		}
	}

	return {
		setLength,
		contentLength,
		overLength,
		content,
		handleChange
	}
}
