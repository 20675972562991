import { useQuery } from "react-query"
import { getCommentsByEpisodeId } from "services/comment.service"

export const useGetComments = id => {
	return useQuery(
		["commentsByEpisode", id],
		async () => {
			if (!id) return null
			const { data } = await getCommentsByEpisodeId(id)
			return data
		},
		{
			enabled: !!id
		}
	)
}
