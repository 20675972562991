import React from "react"
import PodpalButton from "components/common/Buttons/PodpalButton"
import { useDispatch, useSelector } from "react-redux"
import { toggleDeleteRecordingModalVisible } from "redux/actions/Recordings"
import PodpalModal from "../../PodpalModal"
import { deleteRecording } from "services/recording.service"
import useToggle from "hooks/useToggle"
import { errorAlert } from "components/common/Alerts/ErrorAlert"
import { successAlert } from "components/common/Alerts/SuccessAlert"
import { useQueryClient } from "react-query"
import { useEpisodeProgress } from "services/queries/EpisodeProgress"
import { STATUS } from "constants/strings/Episodes/episodeStatusConstant"
import { updateEpisodeProgress } from "services/episodeProgress.service"

const DeleteRecordingModal = () => {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()

	const visible = useSelector(state => state.recording.deleteRecordingModalVisible)
	const selectedPodcast = useSelector(state => state.app.selectedPodcast)

	const config = useSelector(state => state.recording.deleteRecordingModalConfig)

	const handleClose = () => dispatch(toggleDeleteRecordingModalVisible())

	const [loading, toggleLoading] = useToggle(false)

	const { data: episodeProgressData } = useEpisodeProgress(config?.episodeID)
	const steps = [
		{
			displayText: "Episode Setup",
			stepName: "setup",
			status: episodeProgressData?.episodeProgress?.setup ?? 0
		},
		{
			displayText: "Invite Guests",
			stepName: "inviteGuests",
			status: episodeProgressData?.episodeProgress?.inviteGuests ?? 0
		},
		{
			displayText: "Schedule Interview",
			stepName: "scheduleInterview",
			status: episodeProgressData?.episodeProgress?.scheduleInterview ?? 0
		},
		{
			displayText: "Upload Recording",
			stepName: "uploadAudio",
			status: episodeProgressData?.episodeProgress?.uploadAudio ?? 0
		},
		{
			displayText: "Write Show Notes",
			stepName: "writeShowNotes",
			status: episodeProgressData?.episodeProgress?.writeShowNotes ?? 0
		},
		{
			displayText: "Review Episode",
			stepName: "reviewEpisode",
			status: episodeProgressData?.episodeProgress?.reviewEpisode ?? 0
		}
	]

	const handleMarkAsInProgress = async progressStep => {
		if (!episodeProgressData?.episodeProgress?.episodeId) return
		const stepIndex = steps.findIndex(step => step.stepName === progressStep)
		steps[stepIndex].status = STATUS.IN_PROGRESS
		episodeProgressData.episodeProgress.currentStep = steps.find(step => step.status === STATUS.IN_PROGRESS)?.displayText
		try {
			await updateEpisodeProgress({ ...episodeProgressData.episodeProgress, [progressStep]: STATUS.IN_PROGRESS })
			queryClient.invalidateQueries(["episodeProgress", episodeProgressData.episodeProgress.episodeId])
		} catch (e) {
			errorAlert("Failed to mark step as done.")
		}
	}

	const handleDeleteRecording = async () => {
		toggleLoading()
		try {
			await deleteRecording(config.recording)
			handleMarkAsInProgress("uploadAudio")
			toggleLoading()
			handleClose()
			queryClient.invalidateQueries(["recording", config?.episodeID])
			queryClient.invalidateQueries(["recordings", selectedPodcast?.id])
			queryClient.invalidateQueries(["unusedRecordings", selectedPodcast?.id])
			queryClient.invalidateQueries(["podcastInfo", selectedPodcast?.id])
			successAlert("Your recording was successfully deleted!")
		} catch (e) {
			toggleLoading()
			handleClose()
			errorAlert("Sorry, we were unable to delete your recording.")
		}
	}

	return (
		<PodpalModal
			centered
			title="Delete Recording"
			visible={visible}
			onCancel={handleClose}
			width={420}
			footer={[
				<PodpalButton key="cancel" variant="secondary" onClick={handleClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton key="deleterecording" danger loading={loading} onClick={handleDeleteRecording}>
					Delete Recording
				</PodpalButton>
			]}
		>
			{"This recording file will be permanently deleted from Podpal."}
		</PodpalModal>
	)
}

export default DeleteRecordingModal
