import React from "react"
import Icon from "@ant-design/icons"

const SEND_ICON = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.75312 15.2999L15.8406 9.68989C16.4481 9.42739 16.4481 8.57239 15.8406 8.30989L2.75312 2.69989C2.25813 2.48239 1.71062 2.84989 1.71062 3.38239L1.70312 6.83989C1.70312 7.21489 1.98062 7.53739 2.35562 7.58239L12.9531 8.99989L2.35562 10.4099C1.98062 10.4624 1.70312 10.7849 1.70312 11.1599L1.71062 14.6174C1.71062 15.1499 2.25813 15.5174 2.75312 15.2999Z"
			fill="white"
		/>
	</svg>
)

export const SendIcon = (props: any) => <Icon {...props} component={SEND_ICON} />
