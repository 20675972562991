import { TOGGLE_LIMITS_EXCEEDED_MODAL_VISIBLE } from "constants/actionTypes"

const initialState = {
	limitsExceededModalVisible: false
}

export function subscriptionAndBillingReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_LIMITS_EXCEEDED_MODAL_VISIBLE:
			return {
				...state,
				limitsExceededModalVisible: !state.limitsExceededModalVisible
			}
		default:
			return state
	}
}
