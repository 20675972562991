import Modal from "antd/lib/modal/Modal"
import styled from "styled-components"

const PodpalModal = styled(Modal)`
	// .ant-btn {
	// 	&:hover,
	// 	&:focus,
	// 	&:active {
	// 		color: ${props => props.theme.primary};
	// 		border-color: ${props => props.theme.primary};
	// 	}
	// }
	.ant-btn-primary {
		background: ${props => props.theme.primary};
		border-color: ${props => props.theme.primary};
		&:hover,
		&:focus,
		&:active {
			background: ${props => props.theme.primaryHoverDark};
			color: ${props => props.theme.neutral0};
		}
	}
	ul.delete-podcast-list {
		li {
			font: ${props => props.theme.fontSm};
		}
	}
`
export default PodpalModal
