import React from "react"
import Icon from "@ant-design/icons"

const AT_PEOPLE_ICON = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.5987 3.68985C7.52367 3.33152 3.33201 7.52319 3.69034 12.5982C3.99034 17.0065 7.84034 20.3315 12.257 20.3315H15.332C15.7903 20.3315 16.1653 19.9565 16.1653 19.4982C16.1653 19.0399 15.7903 18.6649 15.332 18.6649H12.2737C9.16534 18.6649 6.31534 16.6482 5.54034 13.6399C4.29867 8.80652 8.79867 4.29819 13.632 5.54819C16.6487 6.31485 18.6653 9.16485 18.6653 12.2732V13.1899C18.6653 13.8482 18.0737 14.4982 17.4153 14.4982C16.757 14.4982 16.1653 13.8482 16.1653 13.1899V12.1482C16.1653 10.0565 14.682 8.17319 12.6153 7.88152C9.78201 7.47319 7.39034 9.92319 7.89867 12.7732C8.18201 14.3649 9.42367 15.6815 10.9987 16.0565C12.532 16.4149 13.9903 15.9232 14.9487 14.9482C15.6903 15.9649 17.1737 16.4982 18.532 15.9565C19.6487 15.5149 20.332 14.3732 20.332 13.1732V12.2649C20.332 7.83985 17.007 3.98985 12.5987 3.68985ZM11.9987 14.4982C10.6153 14.4982 9.49867 13.3815 9.49867 11.9982C9.49867 10.6149 10.6153 9.49819 11.9987 9.49819C13.382 9.49819 14.4987 10.6149 14.4987 11.9982C14.4987 13.3815 13.382 14.4982 11.9987 14.4982Z"
			fill="#4D4D4D"
		/>
	</svg>
)

export const AtPeopleIcon = (props: any) => <Icon {...props} component={AT_PEOPLE_ICON} />
