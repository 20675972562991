import styled from "styled-components"
import React from "react"
import { Row, Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { toggleStop301ModalVisible } from "redux/actions/UserSettings/MyPodcasts/RSSAndDistribution"
import PodpalModal from "components/common/Modals/PodpalModal"
import PodpalButton from "components/common/Buttons/PodpalButton"

const StyledText = styled.p`
	color: ${props => props.theme.textSecondary};
	font: ${props => props.theme.fontSm};
`

const Stop301RedirectModal = () => {
	const dispatch = useDispatch()
	const visible = useSelector(state => state.podcastRSSAndDistribution.stop301ModalVisible)

	const handleClose = () => {
		dispatch(toggleStop301ModalVisible())
	}

	return (
		<PodpalModal
			centered
			maskClosable={false}
			title="Request 301 Redirect"
			visible={visible}
			width={420}
			onCancel={handleClose}
			footer={[
				<PodpalButton variant="secondary" onClick={handleClose}>
					Cancel
				</PodpalButton>,
				<PodpalButton type="primary">Stop 301 Redirect</PodpalButton>
			]}
		>
			<Row>
				<Col>
					<StyledText>
						So you changed your mind? We knew you would! To come back to your first love, just click “Stop” below.
					</StyledText>
				</Col>
			</Row>
		</PodpalModal>
	)
}

export default Stop301RedirectModal
