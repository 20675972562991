import {
	SET_SOCIAL_MEDIA_FORM_IS_MODIFIED,
	SET_SOCIAL_MEDIA_FORM_IS_LOADING,
	SET_SELECTED_PODCAST
} from "constants/actionTypes"
import { updatePodcast } from "services/podcast.service"

export const setSocialMediaFormIsModified = isModified => ({
	type: SET_SOCIAL_MEDIA_FORM_IS_MODIFIED,
	payload: {
		isModified
	}
})

export const toggleSocialMediaFormIsLoading = isLoading => ({
	type: SET_SOCIAL_MEDIA_FORM_IS_LOADING,
	payload: {
		isLoading
	}
})

export const submitSocialMediaFormData = ({ podcastId, data, userId }) => async dispatch => {
	dispatch({ type: SET_SOCIAL_MEDIA_FORM_IS_LOADING, payload: { isLoading: true } })

	return await updatePodcast({ podcastId, data, userId })
}

export const submitSocialMediaFormDataSuccess = updatedPodcast => async dispatch => {
	dispatch({
		type: SET_SELECTED_PODCAST,
		payload: {
			podcast: updatedPodcast
		}
	})
}
