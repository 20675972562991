import React from "react"
import RichTextEditor from "components/common/Input/RichTextEditor"
import useRichTextEditor from "hooks/useRichTextEditor"

const CommentEditor = ({ comment, cancelEdit, updateComment, teamMembers }) => {
	const defaultValue = comment.content
	const { handleChange, setLength, content } = useRichTextEditor(defaultValue, 4000)
	const onCancelEditClick = () => {
		handleChange(defaultValue)
		cancelEdit()
	}

	const onFinishEditClick = () => {
		updateComment({ ...comment, content })
		cancelEdit()
	}
	return (
		<>
			<RichTextEditor
				setValue={handleChange}
				setLength={setLength}
				defaultValue={defaultValue}
				placeholder="Add your comments here!"
				height="150px"
				maxLength={20}
				showFooter
				isEditing
				teamMembers={teamMembers}
				onCancelEditClick={onCancelEditClick}
				onFinishEditClick={onFinishEditClick}
			/>
		</>
	)
}

export default CommentEditor
