import { useQuery } from "react-query"
import { keysToCamel } from "utils/camelCaseUtils"
import { GetUsersByPodcastIDResponse, FindUserByProfileURLResponse, FindUserResponse } from "../types/user"
import { findUser, findUserByProfileUrl, getUsersByPodcastID } from "../user.service"
import firebase from "auth/firebase/firebase"

export const useUser = (userId: string) => {
	return useQuery<FindUserResponse>(
		["user", userId],
		async () => {
			const { data } = await findUser(userId)
			const providerData = firebase.auth.currentUser?.providerData
			// populate data.providers array with providerIds
			if (providerData && providerData.length > 0) {
				data.providers = []
				providerData.forEach(provider => {
					if (provider?.providerId) data.providers.push(provider.providerId)
				})
			}

			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{ enabled: !!userId }
	)
}

export const useUserByProfileUrl = (profileUrl: string) => {
	return useQuery<FindUserByProfileURLResponse>(["user", profileUrl], async () => {
		if (!profileUrl) return null
		const { data } = await findUserByProfileUrl(profileUrl)
		const camelResponse = keysToCamel(data)
		return camelResponse
	})
}

export const useUsersByPodcastId = (podcastId: string) => {
	return useQuery<GetUsersByPodcastIDResponse>(
		["users", podcastId],
		async () => {
			const { data } = await getUsersByPodcastID(podcastId)
			const camelResponse = keysToCamel(data)
			return camelResponse
		},
		{
			enabled: !!podcastId
		}
	)
}
