/**
 * Input field names
 */

export const LABEL_NAMES = {
	PHOTO: "Photo",
	FIRST_NAME: "First Name",
	LAST_NAME: "Last Name",
	USERNAME: "Username",
	CREATOR_PROFILE_URL: "Creator Profile URL",
	BIO: "Bio",
	AUDIO_BIO: "Audio Bio",
	COLOR_PICKER: "Profile Color Theme",
	BIRTHDAY: "Birthday",
	INSTAGRAM_HANDLE: "Instagram",
	FACEBOOK_HANDLE: "Facebook",
	TWITTER_HANDLE: "Twitter",
	LINKEDIN_HANDLE: "Linkedin",
	EMAIL: "Email",
	PASSWORD: "Current Password",
	CURRENT_PASSWORD: "Current Password",
	NEW_PASSWORD: "New Password",
	CONFIRM_NEW_PASSWORD: "Confirm New Password"
}

export const FIELD_NAMES = {
	PHOTO: "thumbnailUrl",
	EMAIL: "email",
	FIRST_NAME: "firstName",
	LAST_NAME: "lastName",
	USERNAME: "username",
	CREATOR_PROFILE_URL: "creatorProfileUrl",
	BIO: "bio",
	AUDIO_BIO: "audioBioFile",
	COLOR_PICKER: "profileColor",
	BIRTHDAY: "dateOfBirth",
	INSTAGRAM_HANDLE: "instagramHandle",
	FACEBOOK_HANDLE: "facebookHandle",
	TWITTER_HANDLE: "twitterHandle",
	LINKEDIN_HANDLE: "linkedinHandle",
	NEW_EMAIL: "newEmail",
	NEW_EMAIL_CONFIRM_PASSWORD: "newEmailConfirmPassword",
	NEW_EMAIL_VERIFICATION_CODE: "newEmailVerificationCode",
	PASSWORD: "password",
	CURRENT_PASSWORD: "currentPassword",
	NEW_PASSWORD: "newPassword",
	CONFIRM_NEW_PASSWORD: "confirmNewPassword",
	ENABLE_TWO_FA_CONFIRM_PASSWORD: "enableTwoFAConfirmPassword",
	SECURITY_QUESTION: "securityQuestion",
	SECURITY_QUESTION_ANSWER: "securityQuestionAnswer",
	PHONE_NUMBER: "phoneNumber",
	PHONE_NUMBER_COUNTRY_CODE: "phoneNumberCountryCode",
	VERIFICATION_CODE: "verificationCode",
	CHANGE_TWO_FA_CONFIRM_PASSWORD: "changeTwoFAConfirmPassword",
	CHANGE_TWO_FA_SECURITY_QUESTION_ANSWER: "changeTwoFASecurityQuestionAnswer",
	CHANGE_TWO_FA_PHONE_NUMBER: "changeTwoFAPhoneNumber",
	CHANGE_TWO_FA_PHONE_NUMBER_COUNTRY_CODE: "changeTwoFAPhoneNumberCountryCode",
	CHANGE_TWO_FA_VERIFICATION_CODE: "changeTwoFAVerificationCode",
	REMOVE_TWO_FA_CONFIRM_PASSWORD: "removeTwoFAConfirmPassword",
	REMOVE_TWO_FA_SECURITY_QUESTION_ANSWER: "removeTwoFASecurityQuestionAnswer",
	REMOVE_TWO_FA_PHONE_NUMBER: "removeTwoFAPhoneNumber",
	REMOVE_TWO_FA_VERIFICATION_CODE: "removeTwoFAVerificationCode"
}

export const SECURITY_QUESTIONS = {
	MOTHER_MAIDEN_NAME: "What is your mother’s maiden name?",
	FATHER_MIDDLE_NAME: "What is your father’s middle name?",
	FAVORITE_HOLIDAY: "What is your favorite holiday?",
	NICKNAME: "What was your nickname growing up?",
	HIGH_SCHOOL_MASCOT: "What was your high school mascot?"
}

export const SECURITY_QUESTION_MAP = new Map()
SECURITY_QUESTION_MAP.set(1, SECURITY_QUESTIONS.MOTHER_MAIDEN_NAME)
SECURITY_QUESTION_MAP.set(2, SECURITY_QUESTIONS.FATHER_MIDDLE_NAME)
SECURITY_QUESTION_MAP.set(3, SECURITY_QUESTIONS.FAVORITE_HOLIDAY)
SECURITY_QUESTION_MAP.set(4, SECURITY_QUESTIONS.NICKNAME)
SECURITY_QUESTION_MAP.set(5, SECURITY_QUESTIONS.HIGH_SCHOOL_MASCOT)

/**
 * Error Messages
 */
export const PHOTO_TOO_LARGE_ERROR_MESSAGE = "Please choose an image less than 16MB."
export const PHOTO_INVALID_FILE_TYPE_ERROR_MESSAGE = "Unacceptable file type"
export const NAME_WITH_NUMBERS_ERROR_MESSAGE = "Name cannot include numbers"
export const NAME_TOO_SHORT_ERROR_MESSAGE = "Name must be at least 2 characters"
export const NAME_TOO_LONG_ERROR_MESSAGE = "Name cannot exceed 25 characters"
export const USERNAME_AVAILABLE_MESSAGE = ""
export const USERNAME_UNAVAILABLE_ERROR_MESSAGE = "Sorry, this username is taken"
export const USERNAME_INVALID_ERROR_MESSAGE = "Sorry, username does not meet our criteria"
export const INVALID_BIO_FIELD_ERROR_MESSAGE = "Bio cannot exceed 500 characters"
export const BIRTHDAY_TOO_YOUNG_ERROR_MESSAGE = "You must be at least 13 years old"
export const BIRTHDAY_IN_FUTURE_ERROR_MESSAGE = "Date cannot be in the future."
export const INVALID_EMAIL_ERROR_MESSAGE = "Please enter a valid email address."
export const DUPLICATE_EMAIL_ERROR_MESSAGE = "This email is already associated with a Podpal account."
export const INCORRECT_PASSWORD_ERROR_MESSAGE = "Password is incorrect."
export const INVALID_PASSWORD_ERROR_MESSAGE = "Password is invalid."
export const EMPTY_NEW_PASSWORD_ERROR_MESSAGE = "Please enter a new password first."
export const CONFIRM_NEW_PASSWORD_ERROR_MESSAGE = "Please confirm your password."
export const INCORRECT_SECURITY_QUESTION_ANSWER_ERROR_MESSAGE = "Answer does not match user’s saved answer."
export const INVALID_PHONE_NUMBER_ERROR_MESSAGE = "Please enter a valid phone number."
export const INVALID_VERIFICATION_CODE_ERROR_MESSAGE = "The code you entered is incorrect."
export const PHONE_NUMBER_ALREADY_IN_USE = "The phone number you entered is already in use."
export const INCORRECT_PODCAST_NAME = "Name does not match."
export const INCORRECT_PODCAST_NAME_301_REDIRECT = "Name does not match the podcast you want to redirect."

/**
 * Password requirements
 */
export const PASSWORD_LENGTH_REQUIREMENT = "8 or more characters"
export const PASSWORD_UPPERCASE_REQUIREMENT = "At least 1 uppercase letter"
export const PASSWORD_LOWERCASE_REQUIREMENT = "At least 1 lowercase letter"
export const PASSWORD_NUMBER_REQUIREMENT = "At least 1 number"
export const PASSWORD_SPECIAL_CHARACTER_REQUIREMENT = "At least 1 special character ($ ! @ # ?)"
export const PASSWORD_MATCH_REQUIREMENT = "Passwords match"
