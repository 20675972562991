import React from "react"
import { connect } from "react-redux"
import { Switch, withRouter, Redirect } from "react-router-dom"
import { NOTIFICATIONS, SETTINGS, NOT_FOUND } from "constants/routes"
import GenericSettingsPage from "containers/SettingsPage/GenericSettingsPage"

import "./NotificationsPage.scss"

/**
 * @author Vidhur Kumar
 */
class NotificationsPage extends React.Component {
	render() {
		let data = [
			{
				KEY: 1,
				ICON: null,
				FILLED_ICON: null,
				TITLE: "Tab 1",
				CONTENT: "Tab 1"
			},
			{
				KEY: 2,
				ICON: null,
				FILLED_ICON: null,
				TITLE: "Tab 2",
				CONTENT: "Tab 2"
			}
			// {
			// 	KEY: 3,
			// 	ICON: null,
			// 	FILLED_ICON: null,
			// 	TITLE: "Tab 3",
			// 	CONTENT: "Tab 3",
			// },
			// {
			// 	KEY: 4,
			// 	ICON: null,
			// 	FILLED_ICON: null,
			// 	TITLE: "Tab 4",
			// 	CONTENT: "Tab 4",
			// },
		]
		return (
			<GenericSettingsPage heading="Notifications" settingsTabsArray={data} basePath={`${SETTINGS}${NOTIFICATIONS}`}>
				<Switch>
					<Redirect to={NOT_FOUND} />
				</Switch>
			</GenericSettingsPage>
		)
	}
}

export default withRouter(connect()(NotificationsPage))
