import {
	TOGGLE_ADD_NEW_EPISODE_DRAWER,
	TOGGLE_ADD_NEW_EPISODE_CONFIRMATION_MODAL_VISIBLE,
	SET_EPISODE_ID_FOR_CONFIRMATION_MODAL,
	SET_EPISODE_ID_FOR_INITIAL_VALUES,
	SET_EPISODE_LAST_PUBLISHED_DATE,
	TOGGLE_IMPORT_EPISODES_STATUS_MODAL_VISIBLE,
	SET_PODCAST_ID_FOR_IMPORT_EPISODES_MODAL
} from "constants/actionTypes"

const initialState = {
	addNewEpisodeDrawerVisible: false,
	addNewEpisodeConfirmationModalVisible: false,
	newEpisodeId: null,
	initialValuesEpisodeId: null,
	lastPublishedDate: null,
	importEpisodesStatusModalVisible: false,
	importEpisodesPodcastID: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_ADD_NEW_EPISODE_DRAWER:
			return {
				...state,
				addNewEpisodeDrawerVisible: !state.addNewEpisodeDrawerVisible
			}
		case TOGGLE_ADD_NEW_EPISODE_CONFIRMATION_MODAL_VISIBLE:
			return {
				...state,
				addNewEpisodeConfirmationModalVisible: !state.addNewEpisodeConfirmationModalVisible
			}

		case SET_EPISODE_ID_FOR_CONFIRMATION_MODAL:
			return {
				...state,
				newEpisodeId: action.payload.episodeId
			}

		case SET_EPISODE_ID_FOR_INITIAL_VALUES:
			return {
				...state,
				initialValuesEpisodeId: action.payload.episodeId
			}
		case SET_EPISODE_LAST_PUBLISHED_DATE:
			return {
				...state,
				lastPublishedDate: action.payload.lastPublishedDate
			}
		case TOGGLE_IMPORT_EPISODES_STATUS_MODAL_VISIBLE:
			return {
				...state,
				importEpisodesStatusModalVisible: !state.importEpisodesStatusModalVisible
			}
		case SET_PODCAST_ID_FOR_IMPORT_EPISODES_MODAL:
			return {
				...state,
				importEpisodesPodcastID: action.payload.podcastID
			}
		default:
			return state
	}
}
